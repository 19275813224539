import filesaver from 'file-saver';
const { saveAs } = filesaver;
import FileHelpers from '@/lib/FileHelpers';

export default class Download {

  public static isMSBrowser(): boolean {
    //@ts-ignore
    return typeof window !== "undefined" && window.navigator && window.navigator.msSaveBlob !== undefined;
  }

  public static triggerDownload(filename: string, blobOrUrl: Blob | string) {
    saveAs(blobOrUrl, FileHelpers.sanitizeFilename(filename));
  }

  public static triggerIFrame(url: string) {
    const iframe = `<iframe width="100%" height="100%" style="border: 0;" src="${url}"></iframe>`;
    document.open();
    document.write(iframe);
    document.close();
    document.body.style.margin = '0';
  }
}
