import Labels from './Labels';

const languages = [ 'de', 'fr', 'it', 'en' ];

export function getDefaultLanguage(): string {
  if (typeof window === "undefined") { return 'de'; }
  let lang = window.navigator.language.slice(0, 2);
  if (!languages.some((i) => i === lang)) {
    lang = 'de';
  }
  return lang;
}

export default class Settings {
  public languages = languages;

  public language: string = 'de';

  public labels: Labels = new Labels(this);

  public switchAddressType = false;

  public windowEnvelopeNorm = 'SN';

  public linkPosition = 'c';
  public pdfCutLines = true;
  public displayStructuredBookingInfo = false;

  public generatePDFA = true;
  public pdfAttachSwissQRBillText = true;
  public pdfBundleSize = 100;
  public pdfBleed = false;
  public pdfCropMarks = false;

  public autoReloadLastUsedItems = false;
  public autoCopyScannedDataToClipboard = false;
  public forceUtf8OnTableLoad = false;
  public recordScannedVideo = false;

  public paymentPartPosition = 'f';
  public multiPaymentPartsPosition = '1b';

  public errorHandlingEnabled = false;
  public errorCount = 0;

  constructor(defaultLanguage: string) {
    this.language = defaultLanguage;
  }

  get insideElectron(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf(' electron/') > -1;
  }

  get domain(): string {
    return 'test.qr-rechnung.net';
  }
  get signallingServerUrl(): string {
    return 'wss://connect.qr-rechnung.net';
  }

  get contactLink(): string {
    const f = String.fromCharCode(0x2d);
    const i = String.fromCharCode(0x2e);
    const c = String.fromCharCode(0x40);
    const g = String.fromCharCode(0x65);
    const b = String.fromCharCode(0x66);
    const h = String.fromCharCode(0x68);
    const a = String.fromCharCode(0x6e);
    const d = String.fromCharCode(0x71);
    const e = String.fromCharCode(0x72);
    const j = String.fromCharCode(0x74);

    return ['i' + a, b + 'o', c, d + e, f, 'r' + g + 'c' + h, a + 'u' + a + 'g', i, a + 'e' + j].join('');
  }

  get feedbackLink(): string {
    const f = String.fromCharCode(0x2d);
    const i = String.fromCharCode(0x2e);
    const c = String.fromCharCode(0x40);
    const m = String.fromCharCode(0x61);
    const k = String.fromCharCode(0x62);
    const l = String.fromCharCode(0x63);
    const g = String.fromCharCode(0x65);
    const b = String.fromCharCode(0x66);
    const h = String.fromCharCode(0x68);
    const a = String.fromCharCode(0x6e);
    const d = String.fromCharCode(0x71);
    const e = String.fromCharCode(0x72);
    const j = String.fromCharCode(0x74);

    return [b + g, g, 'd' + k, m, l + 'k', c, d + e, f, 'r' + g + 'c' + h, a + 'u' + a + 'g', i, a + 'e' + j].join('');
  }

  public updateLanguage(language: string) {
    this.language = language;
  }

  public updateSwitchAddressType(switchAddressType: boolean) {
    this.switchAddressType = switchAddressType;
  }

  public updateWindowEnvelopeNorm(windowEnvelopeNorm: string) {
    this.windowEnvelopeNorm = windowEnvelopeNorm;
  }

  public updateLinkPosition(linkPosition: string) {
    this.linkPosition = linkPosition;
  }

  public updatePdfCutLines(pdfCutLines: boolean) {
    this.pdfCutLines = pdfCutLines;
  }

  public updateDisplayStructuredBookingInfo(displayStructuredBookingInfo: boolean) {
    this.displayStructuredBookingInfo = displayStructuredBookingInfo;
  }

  public updateGeneratePDFA(generatePDFA: boolean) {
    this.generatePDFA = generatePDFA;
  }

  public updatePdfAttachSwissQRBillText(pdfAttachSwissQRBillText: boolean) {
    this.pdfAttachSwissQRBillText = pdfAttachSwissQRBillText;
  }

  public updatePdfBundleSize(pdfBundleSize: number) {
    this.pdfBundleSize = pdfBundleSize;
  }

  public updatePdfBleed(pdfBleed: boolean) {
    this.pdfBleed = pdfBleed;
  }

  public updatePdfCropMarks(pdfCropMarks: boolean) {
    this.pdfCropMarks = pdfCropMarks;
  }

  public updateAutoReloadLastUsedItems(autoReloadLastUsedItems: boolean) {
    this.autoReloadLastUsedItems = autoReloadLastUsedItems;
  }

  public updateAutoCopyScannedDataToClipboard(autoCopyScannedDataToClipboard: boolean) {
    this.autoCopyScannedDataToClipboard = autoCopyScannedDataToClipboard;
  }

  public updateForceUtf8OnTableLoad(forceUtf8OnTableLoad: boolean) {
    this.forceUtf8OnTableLoad = forceUtf8OnTableLoad;
  }

  public updateRecordScannedVideo(recordScannedVideo: boolean) {
    this.recordScannedVideo = recordScannedVideo;
  }
  
  public updateMultiPaymentPartsPosition(multiPaymentPartsPosition: string) {
    this.multiPaymentPartsPosition = multiPaymentPartsPosition;
  }
}
