import { QrCode } from '@/lib/qrcodegen/qrcodegen';

export default class QRCodeMatrix {
  public static createFromQRCode(qrcode: QrCode) {
    return new QRCodeMatrix(qrcode, undefined, qrcode.size);
  }
  public static createFromArray(data: Uint8ClampedArray) {
    return new QRCodeMatrix(undefined, data.slice(), Math.sqrt(data.length));
  }

  private qrcode?: QrCode;
  private array?: Uint8ClampedArray;
  public size: number;

  constructor(qrcode: QrCode | undefined, array: Uint8ClampedArray | undefined, size: number) {
    this.qrcode = qrcode;
    this.array = array;
    this.size = size;
  }

  public isDark(x: number, y: number): boolean {
    if (this.qrcode) return this.qrcode.getModule(x, y);
    if (this.array) return this.array[y * this.size + x] !== 0;
    return false;
  }
}
