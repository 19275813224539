export default class List<T> {
  public items: T[] = [];

  constructor(items?: T[] | undefined) {
    this.items = items || [];
  }

  public get length() {
    return this.items.length;
  }

  public add(item: T) {
    this.items.push(item);
  }

  public removeAt(index: number) {
    this.items.splice(index, 1);
  }

  public remove(item: T) {
    const index = this.items.indexOf(item);
    if (index === -1) { return; }
    this.removeAt(index);
  }
}
