import Download from './Download';

export default class FileHelpers {
  public static readAsArrayBufferAsync(file: File): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = () => reject();
      reader.onabort = () => reject();
      reader.readAsArrayBuffer(file);
    });
  }

  public static readAsBinaryStringAsync(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject();
      reader.onabort = () => reject();
      reader.readAsBinaryString(file);
    });
  }

  public static readAsDataURLAsync(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject();
      reader.onabort = () => reject();
      reader.readAsDataURL(file);
    });
  }

  public static readAsTextAsync(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject();
      reader.onabort = () => reject();
      reader.readAsText(file);
    });
  }

  public static createFile(fileBits: BlobPart[], fileName: string, type: string): File {
    if (Download.isMSBrowser()) {
      const blobFile: any = new Blob(fileBits, { type });
      blobFile.lastModifiedDate = new Date();
      blobFile.name = fileName;
      return blobFile as File;
    } else {
      return new File(fileBits, fileName, { type });
    }
  }

  public static createFilenameWithSuffix(origFilenameOrEmpty: string, suffix: string, extension: string): string {
    if (origFilenameOrEmpty === '') return `${suffix}.${extension}`;
    const pos = origFilenameOrEmpty.lastIndexOf('.');
    const base = pos === -1 ? origFilenameOrEmpty : origFilenameOrEmpty.substring(0, pos);
    return `${base} ${suffix}.${extension}`;
  }

  public static sanitizeFilename(filename: string): string {
    const re = /[/?<>\\:*|"\n\r\t]/g;
    return filename.replace(re, '_');
  }
}
