import Labels from './Labels';
import Qrch from '@/store/qrch/Qrch';
import Sanitize from './qrch/Sanitize';
import Settings from './Settings';
import countryNames from 'i18n-iso-countries';

export default class Validation {
  private settings: Settings;
  
  constructor(settings: Settings) {
    this.settings = settings;
  }

  get labels(): Labels {
    return this.settings.labels;
  }

  get allowedCharactersError(): string {
    switch (this.settings.language) {
      case 'de': return `Ungültige Zeichen! Siehe Hilfe > ${this.labels.allowedCharacters}`;
      case 'fr': return `Caractères invalides! Voir Aide > ${this.labels.allowedCharacters}`;
      case 'it': return `Caratteri non validi! Vedi Aiuto > ${this.labels.allowedCharacters}`;
      case 'en': return `Invalid characters! See Help > ${this.labels.allowedCharacters}`;
      default: return `Invalid characters! See Help > ${this.labels.allowedCharacters}`;
    }
  }

  get allowedCharactersErrorV23(): string {
    switch (this.settings.language) {
      case 'de': return `Ungültige Zeichen! (Gültig ab 21. Nov 2025) Siehe Hilfe > ${this.labels.allowedCharacters}`;
      case 'fr': return `Caractères invalides! (Valable à partir du 21 nov 2025) Voir Aide > ${this.labels.allowedCharacters}`;
      case 'it': return `Caratteri non validi! (Valido dal 21 nov 2025) Vedi Aiuto > ${this.labels.allowedCharacters}`;
      case 'en': return `Invalid characters! (Valid from Nov 21, 2025) See Help > ${this.labels.allowedCharacters}`;
      default: return `Invalid characters! (Valid from Nov 21, 2025) See Help > ${this.labels.allowedCharacters}`;
    }
  }

  get numericError(): string {
    switch (this.settings.language) {
      case 'de': return 'Ungültige Zeichen! Zulässig: 0-9';
      case 'fr': return 'Caractères invalides! Autorisé: 0-9';
      case 'it': return 'Caratteri non validi! Consentiti: 0-9';
      case 'en': return 'Invalid characters! Allowed: 0-9';
      default: return 'Invalid characters! Allowed: 0-9';
    }
  }

  get alphanumericError(): string {
    switch (this.settings.language) {
      case 'de': return 'Ungültige Zeichen! Zulässig: A-Z a-z 0-9';
      case 'fr': return 'Caractères invalides! Autorisé: A-Z a-z 0-9';
      case 'it': return 'Caratteri non validi! Consentiti: A-Z a-z 0-9';
      case 'en': return 'Invalid characters! Allowed: A-Z a-z 0-9';
      default: return 'Invalid characters! Allowed: A-Z a-z 0-9';
    }
  }

  get decimalError(): string {
    switch (this.settings.language) {
      case 'de': return 'Ungültige Zeichen! Zulässig: 0-9 und .';
      case 'fr': return 'Caractères invalides! Autorisé: 0-9 et .';
      case 'it': return 'Caratteri non validi! Consentiti: 0-9 e .';
      case 'en': return 'Invalid characters! Allowed: 0-9 and .';
      default: return 'Invalid characters! Allowed: 0-9 and .';
    }
  }

  get dateError(): string {
    switch (this.settings.language) {
      case 'de': return 'Ungültige Zeichen! Zulässig: 0-9 und - (JJJJ-MM-TT)';
      case 'fr': return 'Caractères invalides! Autorisé: 0-9 et - (AAAA-MM-JJ)';
      case 'it': return 'Caratteri non validi! Consentiti: 0-9 e - (AAAA-MM-GG)';
      case 'en': return 'Invalid characters! Allowed: 0-9 and - (YYYY-MM-DD)';
      default: return 'Invalid characters! Allowed: 0-9 and - (YYYY-MM-DD)';
    }
  }

  public getIbanValidToolTip(qrch: Qrch): string {
    const valid = qrch.ibanValid;
    switch (valid) {
      case 0: return '';
      case 1: return this.labels.checkDigitCorrect;
      case 2: return this.labels.checkDigitWrong;
      case 3: return this.labels.ibanValidToolTipNeedsQrIban;
      case 4: return this.labels.ibanValidToolTipNeedsIban;
      case 5: return this.labels.ibanValidToolTipNeedsWirIban;
      case 6: return this.labels.ibanValidToolTipWrongCountryCode;
      default: return '';
    }
  }

  public getQrchValidationErrors(qrch: Qrch): string[] {
    const errors: string[] = []
    if (qrch.qrType !== 'SPC') { errors.push(`Header -> QRType: ${this.labels.wrongValue} '${qrch.qrType}'`)}
    if (qrch.version.substr(0, 2) !== '02') { errors.push(`Header -> Version: ${this.labels.wrongValue} '${qrch.version}'`)}
    if (qrch.coding !== '1') { errors.push(`Header -> Coding: ${this.labels.wrongValue} '${qrch.coding}'`)}

    if (qrch.ibanQR === '') {
      errors.push(`${this.labels.creditorLabel} -> ${this.labels.ibanLabel}: ${this.labels.missing}`);
    } else {
      switch (qrch.ibanValid) {
        case 0: errors.push(`${this.labels.creditorLabel} -> ${this.labels.ibanLabel}: ${this.labels.incomplete}`); break;
        case 2: errors.push(`${this.labels.creditorLabel} -> ${this.labels.ibanLabel}: ${this.labels.checkDigitWrong}`); break;
        case 3: errors.push(`${this.labels.creditorLabel} -> ${this.labels.ibanLabel}: ${this.labels.ibanValidToolTipNeedsQrIban}`); break;
        case 4: errors.push(`${this.labels.creditorLabel} -> ${this.labels.ibanLabel}: ${this.labels.ibanValidToolTipNeedsIban}`); break;
        case 5: errors.push(`${this.labels.creditorLabel} -> ${this.labels.ibanLabel}: ${this.labels.ibanValidToolTipNeedsWirIban}`); break;
        case 6: errors.push(`${this.labels.creditorLabel} -> ${this.labels.ibanLabel}: ${this.labels.ibanValidToolTipWrongCountryCode}`); break;
      }
    }

    if (qrch.creditor.name === '') { errors.push(`${this.labels.creditorLabel} -> ${this.labels.nameLabel}: ${this.labels.missing}`); }
    if (!this.isAllowedCharactersAndNewLines(qrch.creditor.name)) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.nameLabel}: ${this.allowedCharactersError}`); }
    if (Sanitize.replaceNewlines(qrch.creditor.name).length > 70) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.nameLabel}: ${this.labels.longerThanAllowed}`); }
    if (qrch.creditor.type === 'K') {
      if (!this.isAllowedCharacters(qrch.creditor.streetNameOrAddressLine1)) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.address1Label}: ${this.allowedCharactersError}`); }
      if (qrch.creditor.streetNameOrAddressLine1.length > 70) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.address1Label}: ${this.labels.longerThanAllowed}`); }
      if (qrch.creditor.buildingNumberOrAddressLine2 === '') { errors.push(`${this.labels.creditorLabel} -> ${this.labels.address2Label}: ${this.labels.missing}`); }
      if (!this.isAllowedCharacters(qrch.creditor.buildingNumberOrAddressLine2)) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.address2Label}: ${this.allowedCharactersError}`); }
      if (qrch.creditor.buildingNumberOrAddressLine2.length > 70) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.address2Label}: ${this.labels.longerThanAllowed}`); }
      if (qrch.creditor.postalCode !== '') { errors.push(`${this.labels.creditorLabel} -> ${this.labels.postalCodeLabel}: ${this.labels.mustRemainEmpty}`); }
      if (qrch.creditor.town !== '') { errors.push(`${this.labels.creditorLabel} -> ${this.labels.townLabel}: ${this.labels.mustRemainEmpty}`); }
    } else if (qrch.creditor.type === 'S') {
      if (!this.isAllowedCharacters(qrch.creditor.streetNameOrAddressLine1)) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.streetNameLabel}: ${this.allowedCharactersError}`); }
      if (qrch.creditor.streetNameOrAddressLine1.length > 70) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.streetNameLabel}: ${this.labels.longerThanAllowed}`); }
      if (!this.isAllowedCharacters(qrch.creditor.buildingNumberOrAddressLine2)) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.numberLabel}: ${this.allowedCharactersError}`); }
      if (qrch.creditor.buildingNumberOrAddressLine2.length > 16) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.numberLabel}: ${this.labels.longerThanAllowed}`); }
      if (qrch.creditor.postalCode === '') { errors.push(`${this.labels.creditorLabel} -> ${this.labels.postalCodeLabel}: ${this.labels.missing}`); }
      if (!this.isAllowedCharacters(qrch.creditor.postalCode)) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.postalCodeLabel}: ${this.allowedCharactersError}`); }
      if (qrch.creditor.postalCode.length > 16) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.postalCodeLabel}: ${this.labels.longerThanAllowed}`); }
      if (qrch.creditor.town === '') { errors.push(`${this.labels.creditorLabel} -> ${this.labels.townLabel}: ${this.labels.missing}`); }
      if (!this.isAllowedCharacters(qrch.creditor.town)) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.townLabel}: ${this.allowedCharactersError}`); }
      if (qrch.creditor.town.length > 35) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.townLabel}: ${this.labels.longerThanAllowed}`); }
    } else {
      errors.push(`${this.labels.creditorLabel} -> ${this.labels.addressType}: ${this.labels.invalidValue}`);
    }
    if (qrch.creditor.country === '') { errors.push(`${this.labels.creditorLabel} -> ${this.labels.countryLabel}: ${this.labels.missing}`); }
    else if (!countryNames.isValid(qrch.creditor.country)) { errors.push(`${this.labels.creditorLabel} -> ${this.labels.countryLabel}: ${this.labels.invalidValue}`); }

    if (qrch.ultimateCreditor.enabled) { errors.push(`${this.labels.ultimateCreditorLabel}: ${this.labels.mustRemainEmpty}`); }

    if (!this.isDecimal(qrch.amount)) { errors.push(`${this.labels.amountLabel}: ${this.decimalError}`); }
    if (qrch.amount.length > 12) { errors.push(`${this.labels.amountLabel}: ${this.labels.longerThanAllowed}`); }

    if (!qrch.currencies.includes(qrch.currency)) { errors.push(`${this.labels.currencyLabel}: ${this.labels.invalidValue}`); }

    if (qrch.ultimateDebtor.enabled) {
      if (qrch.ultimateDebtor.name === '') { errors.push(`${this.labels.debtorLabel} -> ${this.labels.nameLabel}: ${this.labels.missing}`); }
      if (!this.isAllowedCharactersAndNewLines(qrch.ultimateDebtor.name)) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.nameLabel}: ${this.allowedCharactersError}`); }
      if (Sanitize.replaceNewlines(qrch.ultimateDebtor.name).length > 70) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.nameLabel}: ${this.labels.longerThanAllowed}`); }
      if (qrch.ultimateDebtor.type === 'K') {
        if (!this.isAllowedCharacters(qrch.ultimateDebtor.streetNameOrAddressLine1)) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.address1Label}: ${this.allowedCharactersError}`); }
        if (qrch.ultimateDebtor.streetNameOrAddressLine1.length > 70) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.address1Label}: ${this.labels.longerThanAllowed}`); }
        if (qrch.ultimateDebtor.buildingNumberOrAddressLine2 === '') { errors.push(`${this.labels.debtorLabel} -> ${this.labels.address2Label}: ${this.labels.missing}`); }
        if (!this.isAllowedCharacters(qrch.ultimateDebtor.buildingNumberOrAddressLine2)) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.address2Label}: ${this.allowedCharactersError}`); }
        if (qrch.ultimateDebtor.buildingNumberOrAddressLine2.length > 70) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.address2Label}: ${this.labels.longerThanAllowed}`); }
        if (qrch.ultimateDebtor.postalCode !== '') { errors.push(`${this.labels.debtorLabel} -> ${this.labels.postalCodeLabel}: ${this.labels.mustRemainEmpty}`); }
        if (qrch.ultimateDebtor.town !== '') { errors.push(`${this.labels.debtorLabel} -> ${this.labels.townLabel}: ${this.labels.mustRemainEmpty}`); }
      } else if (qrch.ultimateDebtor.type === 'S') {
        if (!this.isAllowedCharacters(qrch.ultimateDebtor.streetNameOrAddressLine1)) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.streetNameLabel}: ${this.allowedCharactersError}`); }
        if (qrch.ultimateDebtor.streetNameOrAddressLine1.length > 70) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.streetNameLabel}: ${this.labels.longerThanAllowed}`); }
        if (!this.isAllowedCharacters(qrch.ultimateDebtor.buildingNumberOrAddressLine2)) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.numberLabel}: ${this.allowedCharactersError}`); }
        if (qrch.ultimateDebtor.buildingNumberOrAddressLine2.length > 16) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.numberLabel}: ${this.labels.longerThanAllowed}`); }
        if (qrch.ultimateDebtor.postalCode === '') { errors.push(`${this.labels.debtorLabel} -> ${this.labels.postalCodeLabel}: ${this.labels.missing}`); }
        if (!this.isAllowedCharacters(qrch.ultimateDebtor.postalCode)) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.postalCodeLabel}: ${this.allowedCharactersError}`); }
        if (qrch.ultimateDebtor.postalCode.length > 16) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.postalCodeLabel}: ${this.labels.longerThanAllowed}`); }
        if (qrch.ultimateDebtor.town === '') { errors.push(`${this.labels.debtorLabel} -> ${this.labels.townLabel}: ${this.labels.missing}`); }
        if (!this.isAllowedCharacters(qrch.ultimateDebtor.town)) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.townLabel}: ${this.allowedCharactersError}`); }
        if (qrch.ultimateDebtor.town.length > 35) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.townLabel}: ${this.labels.longerThanAllowed}`); }
      } else {
        errors.push(`${this.labels.debtorLabel} -> ${this.labels.addressType}: ${this.labels.invalidValue}`);
      }
      if (qrch.ultimateDebtor.country === '') { errors.push(`${this.labels.debtorLabel} -> ${this.labels.countryLabel}: ${this.labels.missing}`); }
      else if (!countryNames.isValid(qrch.ultimateDebtor.country)) { errors.push(`${this.labels.debtorLabel} -> ${this.labels.countryLabel}: ${this.labels.invalidValue}`); }
    }

    if (qrch.referenceType !== 'QRR' && qrch.referenceType !== 'SCOR' && qrch.referenceType !== 'NON') {errors.push(`${this.labels.referenceTypeLabel}: ${this.labels.invalidValue}`); }
    if (qrch.hasReference) {
      switch (qrch.referenceValid) {
        case 0: errors.push(`${this.labels.referenceLabel}: ${this.labels.incomplete}`); break;
        case 2: errors.push(`${this.labels.referenceLabel}: ${this.labels.checkDigitWrong}`); break;
      }
    }
    if (!this.isAllowedCharactersAndNewLines(qrch.unstructuredMessage)) { errors.push(`${this.labels.unstructuredMessageLabel}: ${this.allowedCharactersError}`); }
    if (Sanitize.replaceNewlines(qrch.unstructuredMessage).length > 140) { errors.push(`${this.labels.unstructuredMessageLabel}: ${this.labels.longerThanAllowed}`); }

    if (qrch.trailer !== 'EPD') { errors.push(`Trailer: ${this.labels.wrongValue} '${qrch.trailer}'`)}

    const structured = qrch.hasStructuredBookingInformationSwico ? qrch.structuredBookingInformationSwico.getQRData : qrch.structuredBookingInformationGeneric;
    if (!this.isAllowedCharacters(structured)) { errors.push(`${this.labels.invoiceInfoLabel}: ${this.allowedCharactersError}`); }
    if (qrch.structuredLength > 140) { errors.push(`${this.labels.invoiceInfoLabel}: ${this.labels.longerThanAllowed}`); }

    if (Sanitize.replaceNewlines(qrch.unstructuredMessage).length + qrch.structuredLength > 140) { errors.push(`${this.labels.unstructuredMessageLabel} + ${this.labels.invoiceInfoLabel}: ${this.labels.longerThanAllowed}`); }

    if (qrch.alternativeSchemes.length > 2) { errors.push(`${this.labels.alternativeSchemesLabel}: ${this.labels.longerThanAllowed}`); }
    qrch.alternativeSchemes.forEach((alternativeScheme, index) => {
      if (!this.isAllowedCharacters(alternativeScheme.value)) { errors.push(`${this.labels.alternativeSchemesLabel} ${index + 1}: ${this.allowedCharactersError}`); }
      if (alternativeScheme.value.length > 100) { errors.push(`${this.labels.alternativeSchemesLabel} ${index + 1}: ${this.labels.longerThanAllowed}`); }
    });

    return errors;
  }


  public isAllowedCharactersAndNewLines(value: string): boolean {
    return /^([a-zA-Z0-9.,;:'+\-/()?*[\]{}\\`´~ ]|[!"#%&<>÷=@_$£]|[àáâäçèéêëìíîïñòóôöùúûüýßÀÁÂÄÇÈÉÊËÌÍÎÏÒÓÔÖÙÚÛÜÑ\r\n])*$/.test(value);
  }
  public isAllowedCharactersAndNewLinesV23(value: string): boolean {
    return /^([\u0020-\u007e\u00a0-\u00ff\u0100-\u017f\u0218-\u021b\u20ac\r\n])*$/.test(value);
  }
  public allowedCharactersAndNewLinesRule(value: string): boolean | string {
    return this.isAllowedCharactersAndNewLines(value) || (this.isAllowedCharactersAndNewLinesV23(value) ? this.allowedCharactersErrorV23 : this.allowedCharactersError);
  }

  public isAllowedCharacters(value: string): boolean {
    return /^([a-zA-Z0-9.,;:'+\-/()?*[\]{}\\`´~ ]|[!"#%&<>÷=@_$£]|[àáâäçèéêëìíîïñòóôöùúûüýßÀÁÂÄÇÈÉÊËÌÍÎÏÒÓÔÖÙÚÛÜÑ])*$/.test(value);
  }
  public isAllowedCharactersV23(value: string): boolean {
    return /^([\u0020-\u007e\u00a0-\u00ff\u0100-\u017f\u0218-\u021b\u20ac])*$/.test(value);
  }
  public allowedCharactersRule(value: string): boolean | string {
    return this.isAllowedCharacters(value) || (this.isAllowedCharactersV23(value) ? this.allowedCharactersErrorV23 : this.allowedCharactersError);
  }

  public isNumeric(value: string): boolean {
    return /^[0-9]*$/.test(value);
  }
  public numericRule(value: string): boolean | string {
    return this.isNumeric(value) || this.numericError;
  }

  public isNumericSpace(value: string): boolean {
    return /^[0-9 ]*$/.test(value);
  }
  public numericSpaceRule(value: string): boolean | string {
    return this.isNumericSpace(value) || this.numericError;
  }

  public isAlphanumeric(value: string): boolean {
    return /^[a-zA-Z0-9]*$/.test(value);
  }
  public alphanumericRule(value: string): boolean | string {
    return this.isAlphanumeric(value) || this.alphanumericError;
  }

  public isAlphanumericSpace(value: string): boolean {
    return /^[a-zA-Z0-9 ]*$/.test(value);
  }
  public alphanumericSpaceRule(value: string): boolean | string {
    return this.isAlphanumericSpace(value) || this.alphanumericError;
  }

  public isDecimal(value: string): boolean {
    return /^[0-9.]*$/.test(value);
  }
  public decimalRule(value: string): boolean | string {
    return this.isDecimal(value) || this.decimalError;
  }
  public isSanitizedDecimal(value: string): boolean {
    const sanitized = Sanitize.decimal(value);
    return this.isDecimal(sanitized);
  }
  public sanitizedDecimalRule(value: string): boolean | string {
    return this.isSanitizedDecimal(value) || this.decimalError;
  }

  public isDate(value: string): boolean {
    return /^([0-9]{4}-[0-9]{2}-[0-9]{2})?$/.test(value);
  }
  public dateRule(value: string): boolean | string {
    return this.isDate(value) || this.dateError;
  }
}
