export default class StoredTemplate {
  public static fromObject(item: any): StoredTemplate {
    return new StoredTemplate(item.name, item.heading, item.sender, item.address,
      item.content, item.addressPosition, item.paymentPartPosition,
      item.backgroundPdfData, item.backgroundPdfFilename);
  }

  public name = '';
  public heading: any = {};
  public sender: any = {};
  public address: any = {};
  public content: any = {};
  public addressPosition = '';
  public paymentPartPosition = '';
  public backgroundPdfData?: ArrayBuffer;
  public backgroundPdfFilename?: string;

  constructor(name: string, heading: any, sender: any, address: any, content: any,
              addressPosition: string, paymentPartPosition: string,
              backgroundPdfData?: ArrayBuffer, backgroundPdfFilename?: string) {
    this.name = name;
    this.heading = heading;
    this.sender = sender;
    this.address = address;
    this.content = content;
    this.addressPosition = addressPosition;
    this.paymentPartPosition = paymentPartPosition;
    this.backgroundPdfData = backgroundPdfData;
    this.backgroundPdfFilename = backgroundPdfFilename;
  }
}
