import { reactive } from 'vue';
import Qrch from './store/qrch/Qrch';
import Qrepc from './store/qrepc/Qrepc';
import Settings, { getDefaultLanguage } from './store/Settings';
import StoredPushSubscription from './lib/StoredPushSubscription';
import StoredQrch from './lib/StoredQrch';
import StoredQrchList from './lib/StoredQrchList';
import StoredTemplate from './lib/StoredTemplate';
import StoredEmailTemplate from './lib/StoredEmailTemplate';
import Template from './lib/Template';
import EmailTemplate from './lib/EmailTemplate';
import WithKey from './lib/WithKey';
import WithKeySelected from './lib/WithKeySelected';
import NumberHelpers from './lib/NumberHelpers';
import StoredSettings from './lib/StoredSettings';

export interface IMyStore {
  settings: Settings;

  storedQrch: Array<WithKeySelected<StoredQrch>>;
  qrch: Qrch;
  qrchKey: string | undefined;
  qrchName: string | undefined;
  qrchModified: boolean;
  qrchSortOrder: string;

  storedQrchLists: Array<WithKeySelected<StoredQrchList>>;
  qrchList: Qrch[];
  qrchListKey: string | undefined;
  qrchListName: string | undefined;
  qrchListModified: boolean;
  qrchListSortOrder: string;
  qrchListSelectedIndices: number[];
  qrchListSelected: Qrch[];

  storedTemplates: Array<WithKey<StoredTemplate>>;
  template: Template;
  templateKey: string | undefined;
  templateName: string | undefined;
  templateModified: boolean;
  templateSortOrder: string;

  storedEmailTemplates: Array<WithKey<StoredEmailTemplate>>;
  emailTemplate: EmailTemplate;
  emailTemplateKey: string | undefined;
  emailTemplateName: string | undefined;
  emailTemplateModified: boolean;
  emailTemplateSortOrder: string;

  uploadedPdfFilename: string;
  uploadedPdfDoc?: Blob;
  uploadedPdfPages: number;
  uploadedPdfModified: boolean;

  storedPushSubscriptions: Array<WithKeySelected<StoredPushSubscription>>;

  storedSettings: StoredSettings;

  qrepc: Qrepc;
}

export class MyStore {
  public settings = new Settings(getDefaultLanguage());

  public storedQrch = new Array<WithKeySelected<StoredQrch>>();
  public qrch = new Qrch(this.settings);
  public qrchKey: string | undefined = undefined;
  public qrchName: string | undefined = undefined;
  public qrchModified = false;
  public qrchSortOrder = 'key+';

  public storedQrchLists = new Array<WithKeySelected<StoredQrchList>>();
  public qrchList = Qrch.getDemoList(this.settings);
  public qrchListKey: string | undefined = undefined;
  public qrchListName: string | undefined = undefined;
  public qrchListModified = false;
  public qrchListSortOrder = 'key+';
  public qrchListSelectedIndices = NumberHelpers.getRange(this.qrchList.length);
  public get qrchListSelected(): Qrch[] {
    return this.qrchList.filter((_v, i) => this.qrchListSelectedIndices.includes(i));
  }

  public storedTemplates = new Array<WithKey<StoredTemplate>>();
  public template = new Template();
  public templateKey: string | undefined = undefined;
  public templateName: string | undefined = undefined;
  public templateModified = false;
  public templateSortOrder = 'key+';

  public storedEmailTemplates = new Array<WithKey<StoredEmailTemplate>>();
  public emailTemplate = new EmailTemplate();
  public emailTemplateKey: string | undefined = undefined;
  public emailTemplateName: string | undefined = undefined;
  public emailTemplateModified = false;
  public emailTemplateSortOrder = 'key+';

  public uploadedPdfFilename = '';
  public uploadedPdfDoc?: Blob = undefined;
  public uploadedPdfPages = 1;
  public uploadedPdfModified = false;

  public storedPushSubscriptions = new Array<WithKeySelected<StoredPushSubscription>>();

  public storedSettings = new StoredSettings(this.settings.language, 'key+', 'key+', 'key+', 'key+', 'f', 'c', true, false, false, 'SN', true, true, 100, false, false, false, false, false, false, '', '', '', '', '1b');

  public qrepc = new Qrepc(this.settings);
}

export const store: IMyStore = reactive(new MyStore());
