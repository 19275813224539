export default class SwicoVatImport {
  public vatRate = 0;
  public vatAmount = 0;

  constructor(value?: string | undefined) {
    if (value !== undefined) {
      this.initFromQRData(value);
    }
  }

  public get getQRData() {
    return `${this.encodeFloat(this.vatRate)}:${this.encodeFloat(this.vatAmount)}`;
  }

  public updateVatRate(vatRate: number) {
    this.vatRate = vatRate;
  }

  public updateVatAmount(vatAmount: number) {
    this.vatAmount = vatAmount;
  }

  public initFromQRData(payload: string) {
    const parts = payload.split(':');
    this.vatRate = Number.parseFloat(parts[0]);
    this.vatAmount = Number.parseFloat(parts[1]);
  }

  public encodeFloat(float: number): string {
    return Number.isFinite(float) ? float.toLocaleString('en', {minimumIntegerDigits: 1, useGrouping: false}) : '';
  }
}
