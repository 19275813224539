export default class StringHelpers {
  public static isBreakableCharacter(character: string): boolean {
    const letterOrDecimalNumber = /\p{Letter}|\p{Decimal_Number}/u.test(character);
    return !letterOrDecimalNumber;
  }

  public static searchFittingPrefix(text: string, targetWidth: number, getWidth: (text: string) => number, postfix: string): number {
    // binary search a just fitting prefix (l fits, r not)
    let l = 0;
    let r = text.length - 1;
    while (r - l > 1) {
      const m = Math.floor((l + r) / 2);
      const t = text.substr(0, m) + postfix;
      if (getWidth(t) <= targetWidth) {
        l = m;
      } else {
        r = m;
      }
    }
    return l;
  }

  public static splitTextToWidth(text: string, targetWidth: number, getWidth: (text: string) => number, binarySearch: boolean): string[] {
    if (getWidth(text) <= targetWidth) { return [text]; }
    // first search a just fitting prefix (l fits, r not)
    const l = binarySearch ? this.searchFittingPrefix(text, targetWidth, getWidth, '') : targetWidth;
    // then search a breakable character from its end at l
    for (let i = l; i > 0; i--) {
      const c = text.substring(i - 1, i);
      if (this.isBreakableCharacter(c)) {
        return [text.substring(0, i), text.substring(i)];
      }
    }
    // no breakable character found
    return [text.substring(0, l), text.substring(l)];
  }

  public static splitTextToLinesOfWidth(text: string, targetWidth: number, getWidth: (text: string) => number, binarySearch: boolean): string[] {
    const lines: string[] = [];
    let rest = text;
    for (;;) {
      const split = this.splitTextToWidth(rest, targetWidth, getWidth, binarySearch);
      if (split.length === 1) {
        lines.push(rest);
        return lines;
      }
      lines.push(split[0]);
      rest = split[1];
    }
  }

  public static splitLinesToLinesOfWidth(lines: string[], targetWidth: number, getWidth: (text: string) => number, binarySearch: boolean): string[] {
    const result: string[] = [];
    for (const line of lines) {
      const splitLines = this.splitTextToLinesOfWidth(line, targetWidth, getWidth, binarySearch);
      result.push(...splitLines);
    }
    return result;
  }

  public static splitTextToLinesOfFixedWidth(text: string, maxWidth: number): string[] {
    const re = new RegExp('.{1,' + maxWidth + '}', 'g');
    const result = text.match(re);
    if (result === null) return [text];
    return result;
  }

  public static trimTextToWidth(text: string, targetWidth: number, getWidth: (text: string) => number): string {
    if (getWidth(text) <= targetWidth) { return text; }
    const l = this.searchFittingPrefix(text, targetWidth, getWidth, '…');
    return text.substr(0, l) + '…';
  }

  public static asciiStringToUint8Array(asciiString: string): Uint8Array {
    const length = asciiString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = asciiString.charCodeAt(i);
    }
    return bytes;
  }

  public static utf8StringToUint8Array(utf8String: string): Uint8Array {
    const encoder = new TextEncoder();
    const bytes = encoder.encode(utf8String);
    return bytes;
  }

  public static arrayBufferToUtf8String(bytes: ArrayBuffer): string {
    const decoder = new TextDecoder();
    return decoder.decode(bytes);
  }

  public static arrayBufferToBase64String(buffer: ArrayLike<number> | ArrayBufferLike): string {
    let binaryString = '';
    const bytes = new Uint8Array(buffer);
    const length = bytes.length;
    for (let i = 0; i < length; i++) {
      binaryString += String.fromCharCode(bytes[i]);
    }
    return btoa(binaryString);
  }

  public static base64StringToArrayBuffer(base64String: string): ArrayBuffer {
    const binaryString = atob(base64String);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public static arrayBufferToHexString(buffer: ArrayBuffer, separator: string | undefined): string {
    const array = Array.from(new Uint8Array(buffer));
    return this.arrayToHexString(array, separator);
  }

  public static arrayToHexString(array: number[], separator: string | undefined): string {
    separator = separator ?? '';
    return array.map(b => b.toString(16).padStart(2, '0')).join(separator);
  }

  public static zeroPad(value: number, maxValue: number): string {
    const maxLength = maxValue.toString().length;
    return value.toString().padStart(maxLength, '0');
  }

  public static escapeXml(unsafe: string) {
    return unsafe.replace(/[<>&'"]/g, (c: string) => {
      switch (c) {
          case '<': return '&lt;';
          case '>': return '&gt;';
          case '&': return '&amp;';
          case '\'': return '&apos;';
          case '"': return '&quot;';
          default: return c;
      }
    });
  }

  public static jsonToString(value: any): string {
    const seen = new WeakSet();
    const replacer = (_: any, value: any) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
    return JSON.stringify(value, replacer);
  }

  public static getPropertyWithDefault(data: any, key: string, deflt: string): string {
    return Object.prototype.hasOwnProperty.call(data, key) ? `${data[key]}`.trim() : deflt;
  }
}
