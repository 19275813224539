import Labels from "@/store/Labels";

export default class EmailTemplate {
  public to: any = {};
  public cc: any = {};
  public bcc: any = {};
  public subject: any = {};
  public content: any = {};
  public attachments: File[] = [];
  public metadata: any = {};
}

export function createDefaultEmailTemplate(labels: Labels): EmailTemplate {
  const to: any[] = [
    { insert: { placeholder: { id: 'Email', label: 'Email' }}},
  ];

  const cc: any[] = [
    { insert: '\n' },
  ];

  const bcc: any[] = [
    { insert: '\n' },
  ];

  const subject: any[] = [
    { insert: { placeholder: { id: 'info', label: labels.unstructuredMessageLabel }}},
  ];

  const content: any[] = [
    { insert: { placeholder: { id: 'Anrede', label: 'Anrede' }}},
    { insert: '\n' },
    { insert: '\n' },
    { insert: '\n' },
    { insert: { placeholder: { id: 'paymentPart', label: labels.paymentTitleLabel }}},
    { insert: '\n' },
    { insert: '\n' },
    { insert: '\n' },
    { insert: labels.regards + '\n' },
    { insert: { placeholder: { id: 'creditor_name', label: labels.creditorLabel + ' ' + labels.nameLabel }}},
    { insert: '\n' },
    { insert: '\n' },
    { insert: '\n' },
  ];

  const template: EmailTemplate = new EmailTemplate();
  template.to = to;
  template.cc = cc;
  template.bcc = bcc;
  template.subject = subject;
  template.content = content;
  return template;
}
