import countryNames from 'i18n-iso-countries';
import countryNamesDe from 'i18n-iso-countries/langs/de.json';
import countryNamesFr from 'i18n-iso-countries/langs/fr.json';
import countryNamesIt from 'i18n-iso-countries/langs/it.json';
import countryNamesEn from 'i18n-iso-countries/langs/en.json';
countryNames.registerLocale(countryNamesDe);
countryNames.registerLocale(countryNamesFr);
countryNames.registerLocale(countryNamesIt);
countryNames.registerLocale(countryNamesEn);
import Sanitize from './Sanitize';
import StringHelpers from '@/lib/StringHelpers';

export type AddressType = 'S' | 'K';

export default class Address {
  public enabled = true;
  public type: AddressType = 'S';
  public name = '';
  public streetNameOrAddressLine1 = '';
  public buildingNumberOrAddressLine2 = '';
  public postalCode = '';
  public town = '';
  public country = 'CH';

  constructor(enabled: boolean) {
    this.enabled = enabled;
  }

  public get getAllFields() {
    const fields = [];
    fields.push(String(this.enabled));
    fields.push(this.type);
    fields.push(this.name);
    fields.push(this.streetNameOrAddressLine1);
    fields.push(this.buildingNumberOrAddressLine2);
    fields.push(this.postalCode);
    fields.push(this.town);
    fields.push(this.country);
    return fields;
  }

  public get getQRFields() {
    const fields = [];
    fields.push(this.enabled ? this.type : '');
    fields.push(this.enabled ? Sanitize.text(Sanitize.replaceNewlines(this.name), 70) : '');
    if (this.type === 'S') {
      fields.push(this.enabled ? Sanitize.text(this.streetNameOrAddressLine1, 70) : '');
      fields.push(this.enabled ? Sanitize.text(this.buildingNumberOrAddressLine2, 16) : '');
      fields.push(this.enabled ? Sanitize.text(this.postalCode, 16) : '');
      fields.push(this.enabled ? Sanitize.text(this.town, 35) : '');
    } else {
      fields.push(this.enabled ? Sanitize.text(this.streetNameOrAddressLine1, 70) : '');
      fields.push(this.enabled ? Sanitize.text(this.buildingNumberOrAddressLine2, 70) : '');
      fields.push('');
      fields.push('');
    }
    fields.push(this.enabled ? this.country : '');
    return fields;
  }

  public get getPermalinkFields() {
    const fields = [];
    fields.push(this.enabled ? this.type : '');
    fields.push(this.enabled ? Sanitize.textAndNewLines(this.name, 70) : '');
    if (this.type === 'S') {
      fields.push(this.enabled ? Sanitize.text(this.streetNameOrAddressLine1, 70) : '');
      fields.push(this.enabled ? Sanitize.text(this.buildingNumberOrAddressLine2, 16) : '');
      fields.push(this.enabled ? Sanitize.text(this.postalCode, 16) : '');
      fields.push(this.enabled ? Sanitize.text(this.town, 35) : '');
    } else {
      fields.push(this.enabled ? Sanitize.text(this.streetNameOrAddressLine1, 70) : '');
      fields.push(this.enabled ? Sanitize.text(this.buildingNumberOrAddressLine2, 70) : '');
      fields.push('');
      fields.push('');
    }
    fields.push(this.enabled ? this.country : '');
    return fields;
  }

  public get getQRData() {
    return this.getQRFields.join('\r\n');
  }

  public get address1() {
    const addr1 = Sanitize.text(this.streetNameOrAddressLine1, 70);
    const addr2l16 = Sanitize.text(this.buildingNumberOrAddressLine2, 16);
    return this.type === 'S' ? addr1 + ' ' + addr2l16 : addr1;
  }

  public get address2() {
    const addr2l70 = Sanitize.text(this.buildingNumberOrAddressLine2, 70);
    const postalCode = Sanitize.text(this.postalCode, 16);
    const town = Sanitize.text(this.town, 35);
    return this.type === 'S' ? postalCode + ' ' + town : addr2l70;
  }

  public getPostalAddressCountry(language: string) {
    if (this.country === 'CH' || this.country === 'LI') {
      return '';
    }
    return countryNames.isValid(this.country) ? countryNames.getName(this.country, language)?.toUpperCase() ?? '' : this.country;
  }

  public getQrInfoLines(postalAddress: boolean, language: string): string[] {
    const lines: string[] = [];
    lines.push(...Sanitize.splitNewlinesOnce(Sanitize.textAndNewLines(this.name, 70)));
    const addr1 = Sanitize.text(this.streetNameOrAddressLine1, 70);
    const addr2l16 = Sanitize.text(this.buildingNumberOrAddressLine2, 16);
    const addr2l70 = Sanitize.text(this.buildingNumberOrAddressLine2, 70);
    if (this.type === 'S') {
      if (addr1 !== '' || addr2l16 !== '') {
        lines.push(addr1 + ' ' + addr2l16);
      }
      let addr2 = Sanitize.text(this.postalCode, 16) + ' ' + Sanitize.text(this.town, 35);
      if (this.country !== 'CH' && !postalAddress) {
        addr2 = `${this.country} - ${addr2}`;
      }
      lines.push(addr2);
    } else {
      if (addr1 !== '') {
        lines.push(addr1);
      }
      let addr2 = addr2l70;
      if (this.country !== 'CH' && !postalAddress) {
        addr2 = `${this.country} - ${addr2}`;
      }
      lines.push(addr2);
    }
    if (this.country !== 'CH' && this.country !== 'LI' && postalAddress) {
      const country = countryNames.isValid(this.country) ? countryNames.getName(this.country, language)?.toUpperCase() ?? '' : this.country;
      lines.push(country);
    }
    return lines;
  }

  public updateEnabled(enabled: boolean) {
    this.enabled = enabled;
  }
  public updateType(type: AddressType) {
    if (this.type === 'S' && type === 'K') {
      this.streetNameOrAddressLine1 = (this.streetNameOrAddressLine1 + ' ' + this.buildingNumberOrAddressLine2).trim();
      this.buildingNumberOrAddressLine2 = (this.postalCode + ' ' + this.town).trim();
      this.postalCode = '';
      this.town = '';
    } else if (this.type === 'K' && type === 'S') {
      const line1 = this.streetNameOrAddressLine1.trim().split(' ');
      const line2 = this.buildingNumberOrAddressLine2.trim().split(' ');
      if (line1.length < 2) {
        // Keep single words in street name
        this.streetNameOrAddressLine1 = line1.join(' ');
        this.buildingNumberOrAddressLine2 = '';
      } else {
        this.buildingNumberOrAddressLine2 = line1.splice(-1).join(' ');
        this.streetNameOrAddressLine1 = line1.join(' ');
      }
      this.postalCode = line2.slice(0, 1).join(' ');
      this.town = line2.slice(1).join(' ');
    }
    this.type = type;
  }
  public updateName(name: string) {
    this.name = name;
  }
  public updateStreetNameOrAddressLine1(streetNameOrAddressLine1: string) {
    this.streetNameOrAddressLine1 = streetNameOrAddressLine1;
  }
  public updateBuildingNumberOrAddressLine2(buildingNumberOrAddressLine2: string) {
    this.buildingNumberOrAddressLine2 = buildingNumberOrAddressLine2;
  }
  public updatePostalCode(postalCode: string) {
    this.postalCode = postalCode;
  }
  public updateTown(town: string) {
    this.town = town;
  }
  public updateCountry(country: string) {
    this.country = country;
  }
  public initFromQRData(payload: string[], alwaysEnabled: boolean) {
    this.clear();
    if (payload.length < 1) { return; }
    const type = payload.shift()!;
    this.type = type === 'K' ? 'K' : 'S';
    if (payload.length < 1) { return; }
    this.name = payload.shift()!;
    this.enabled = alwaysEnabled || this.name !== '';
    if (payload.length < 1) { return; }
    this.streetNameOrAddressLine1 = payload.shift()!;
    if (payload.length < 1) { return; }
    this.buildingNumberOrAddressLine2 = payload.shift()!;
    if (payload.length < 1) { return; }
    this.postalCode = payload.shift()!;
    if (payload.length < 1) { return; }
    this.town = payload.shift()!;
    if (payload.length < 1) { return; }
    this.country = payload.shift()! || 'CH';
  }

  public setTableData(tableData: any, prefix: string) {
    tableData[prefix + 'AdrTp'] = this.type;
    tableData[prefix + 'Name'] = Sanitize.textAndNewLines(this.name, 70);
    if (this.type === 'S') {
      tableData[prefix + 'StrtNmOrAdrLine1'] = Sanitize.text(this.streetNameOrAddressLine1, 70);
      tableData[prefix + 'BldgNbOrAdrLine2'] = Sanitize.text(this.buildingNumberOrAddressLine2, 16);
      tableData[prefix + 'PstCd'] = Sanitize.text(this.postalCode, 16);
      tableData[prefix + 'TwnNm'] = Sanitize.text(this.town, 35);
    } else {
      tableData[prefix + 'StrtNmOrAdrLine1'] = Sanitize.text(this.streetNameOrAddressLine1, 70);
      tableData[prefix + 'BldgNbOrAdrLine2'] = Sanitize.text(this.buildingNumberOrAddressLine2, 70);
      tableData[prefix + 'PstCd'] = '';
      tableData[prefix + 'TwnNm'] = '';
    }
    tableData[prefix + 'Ctry'] = this.country;
  }

  public static canInitFromTableData(tableData: any, prefix: string): boolean {
    return StringHelpers.getPropertyWithDefault(tableData, prefix + 'Name', '') !== '';
  }

  public initFromTableData(tableData: any, prefix: string, alwaysEnabled: boolean) {
    this.type = StringHelpers.getPropertyWithDefault(tableData, prefix + 'AdrTp', 'S') as AddressType;
    this.name = StringHelpers.getPropertyWithDefault(tableData, prefix + 'Name', '');
    this.streetNameOrAddressLine1 = StringHelpers.getPropertyWithDefault(tableData, prefix + 'StrtNmOrAdrLine1', '');
    this.buildingNumberOrAddressLine2 = StringHelpers.getPropertyWithDefault(tableData, prefix + 'BldgNbOrAdrLine2', '');
    this.postalCode = StringHelpers.getPropertyWithDefault(tableData, prefix + 'PstCd', '');
    this.town = StringHelpers.getPropertyWithDefault(tableData, prefix + 'TwnNm', '');
    this.country = StringHelpers.getPropertyWithDefault(tableData, prefix + 'Ctry', 'CH');
    this.enabled = alwaysEnabled || this.name !== '';
  }

  public clear() {
    this.type = 'S';
    this.name = '';
    this.streetNameOrAddressLine1 = '';
    this.buildingNumberOrAddressLine2 = '';
    this.postalCode = '';
    this.town = '';
    this.country = 'CH';
  }
}
