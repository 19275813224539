import { default as aboutTMvQn2lezMMeta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/about.vue?macro=true";
import { default as createNIvdEeZmxIMeta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/create.vue?macro=true";
import { default as emailtPfBBkj1heMeta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/email.vue?macro=true";
import { default as feedbackXm6sR7cdIsMeta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/feedback.vue?macro=true";
import { default as helprnCCiLmANVMeta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/help.vue?macro=true";
import { default as indexXWCDDPsTPNMeta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/index.vue?macro=true";
import { default as insertJ3eNZe8KCUMeta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/insert.vue?macro=true";
import { default as newsF3xp3q9WOtMeta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/news.vue?macro=true";
import { default as privacy9U8kZwBYmkMeta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/privacy.vue?macro=true";
import { default as savedyU6VIg8iSbMeta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/saved.vue?macro=true";
import { default as scanAUgmQMADCrMeta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/scan.vue?macro=true";
import { default as settingsT60iD6RHRgMeta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/settings.vue?macro=true";
import { default as tablezpZvDt076CMeta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/table.vue?macro=true";
import { default as viewsfvEOqkmpTMeta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/view.vue?macro=true";
import { default as writeOhEukenAT4Meta } from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/write.vue?macro=true";
import { default as scan57fb3Ep0KtMeta } from "~/pages/scan.vue?macro=true";
import { default as createMjcbP3CLcjMeta } from "~/pages/create.vue?macro=true";
import { default as viewoKw0xukuf2Meta } from "~/pages/view.vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/about.vue")
  },
  {
    name: "create",
    path: "/create",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/create.vue")
  },
  {
    name: "email",
    path: "/email",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/email.vue")
  },
  {
    name: "feedback",
    path: "/feedback",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/feedback.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/help.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/index.vue")
  },
  {
    name: "insert",
    path: "/insert",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/insert.vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/news.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/privacy.vue")
  },
  {
    name: "saved",
    path: "/saved",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/saved.vue")
  },
  {
    name: "scan",
    path: "/scan",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/scan.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/settings.vue")
  },
  {
    name: "table",
    path: "/table",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/table.vue")
  },
  {
    name: "view",
    path: "/view",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/view.vue")
  },
  {
    name: "write",
    path: "/write",
    component: () => import("E:/development/QR-Rechnung/Vue/qr-rechnung.net/pages/write.vue")
  },
  {
    name: "scan-id",
    path: "/scan/:id()",
    component: () => import("~/pages/scan.vue")
  },
  {
    name: "permalink",
    path: "/b,:path(.*)",
    component: () => import("~/pages/create.vue")
  },
  {
    name: "view",
    path: "/view:path(.*)",
    component: () => import("~/pages/view.vue")
  },
  {
    name: "support",
    path: "/support:id(.*)",
    component: () => import("~/pages/index.vue")
  }
]