import List from '@/store/List';
import Sanitize from './Sanitize';
import SwicoCondition from './SwicoCondition';
import SwicoVatRate from './SwicoVatRate';
import SwicoVatImport from './SwicoVatImport';

export default class Swico {
  public static canRead(structuredInfo: string): boolean {
    return structuredInfo.startsWith(this.prefix);
  }

  public static prefix = '//S1/';
  public static invoiceNumberTag = '10';
  public static invoiceDateTag = '11';
  public static customerReferenceTag = '20';
  public static vatNumberTag = '30';
  public static vatDateTag = '31';
  public static vatDetailsTag = '32';
  public static vatImportTag = '33';
  public static conditionsTag = '40';

  public hasInvoiceNumber = false;
  public hasInvoiceDate = false;
  public hasCustomerReference = false;
  public hasVatNumber = false;
  public hasVatStartDate = false;
  public hasVatEndDate = false;

  public invoiceNumber = '';
  public invoiceDate = '';
  public customerReference = '';
  public vatNumber = '';
  public vatStartDate = '';
  public vatEndDate = '';

  public vatDetails: List<SwicoVatRate> = new List<SwicoVatRate>();
  public vatImports: List<SwicoVatImport> = new List<SwicoVatImport>();
  public conditions: List<SwicoCondition> = new List<SwicoCondition>();

  public get getQRFields() {
    const fields = [];
    if (this.hasInvoiceNumber) {
      fields.push(Swico.invoiceNumberTag);
      fields.push(this.escape(Sanitize.text(this.invoiceNumber)));
    }
    if (this.hasInvoiceDate) {
      fields.push(Swico.invoiceDateTag);
      fields.push(this.encodeDate(this.invoiceDate));
    }
    if (this.hasCustomerReference) {
      fields.push(Swico.customerReferenceTag);
      fields.push(this.escape(Sanitize.text(this.customerReference)));
    }
    if (this.hasVatNumber) {
      fields.push(Swico.vatNumberTag);
      fields.push(Sanitize.numeric(this.encodeVatNumber(this.vatNumber)));
    }
    if (this.hasVatStartDate) {
      fields.push(Swico.vatDateTag);
      if (this.hasVatEndDate) {
        fields.push(this.encodeDate(this.vatStartDate) + this.encodeDate(this.vatEndDate));
      } else {
        fields.push(this.encodeDate(this.vatStartDate));
      }
    }
    if (this.vatDetails.length > 0) {
      fields.push(Swico.vatDetailsTag);
      fields.push(this.vatDetails.items.map((detail) => detail.getQRData).join(';'));
    }
    if (this.vatImports.length > 0) {
      fields.push(Swico.vatImportTag);
      fields.push(this.vatImports.items.map((detail) => detail.getQRData).join(';'));
    }
    if (this.conditions.length > 0) {
      fields.push(Swico.conditionsTag);
      fields.push(this.conditions.items.map((detail) => detail.getQRData).join(';'));
    }
    return fields;
  }

  public get getQRData() {
    return Swico.prefix + this.getQRFields.join('/');
  }

  public updateHasInvoiceNumber(hasInvoiceNumber: boolean) {
    this.hasInvoiceNumber = hasInvoiceNumber;
  }
  public updateHasInvoiceDate(hasInvoiceDate: boolean) {
    this.hasInvoiceDate = hasInvoiceDate;
  }
  public updateHasCustomerReference(hasCustomerReference: boolean) {
    this.hasCustomerReference = hasCustomerReference;
  }
  public updateHasVatNumber(hasVatNumber: boolean) {
    this.hasVatNumber = hasVatNumber;
  }
  public updateHasVatStartDate(hasVatStartDate: boolean) {
    this.hasVatStartDate = hasVatStartDate;
  }
  public updateHasVatEndDate(hasVatEndDate: boolean) {
    this.hasVatEndDate = hasVatEndDate;
  }

  public updateInvoiceNumber(invoiceNumber: string) {
    this.invoiceNumber = invoiceNumber;
  }
  public updateInvoiceDate(invoiceDate: string) {
    this.invoiceDate = invoiceDate;
  }
  public updateCustomerReference(customerReference: string) {
    this.customerReference = customerReference;
  }
  public updateVatNumber(vatNumber: string) {
    this.vatNumber = vatNumber;
  }
  public updateVatStartDate(vatStartDate: string) {
    this.vatStartDate = vatStartDate;
  }
  public updateVatEndDate(vatEndDate: string) {
    this.vatEndDate = vatEndDate;
  }

  public initFromQRData(structuredInfo: string) {
    if (!Swico.canRead(structuredInfo)) { return; }
    const info = structuredInfo.substring(Swico.prefix.length);

    this.reset();

    // split info at slashes that are not escaped:
    const items: string[] = [];
    let pos = 0;
    let currentItem = '';
    let escaped = false;
    while (pos < info.length) {
      const c = info[pos];
      if (escaped) {
        escaped = false;
        currentItem += c;
      } else if (c === '\\') {
        escaped = true;
      } else if (c === '/') {
        items.push(currentItem);
        currentItem = '';
      } else {
        currentItem += c;
      }
      pos++;
    }
    if (currentItem !== '') {
      items.push(currentItem);
      currentItem = '';
    }

    while (items.length > 1) {
      const tag = items.shift();
      const value = items.shift();
      if (value === undefined || value === '') { continue; }
      switch (tag) {
        case Swico.invoiceNumberTag:
          this.hasInvoiceNumber = true;
          this.invoiceNumber = value;
          break;
        case Swico.invoiceDateTag:
          this.hasInvoiceDate = true;
          this.invoiceDate = this.decodeDate(value);
          break;
        case Swico.customerReferenceTag:
          this.hasCustomerReference = true;
          this.customerReference = value;
          break;
        case Swico.vatNumberTag:
          this.hasVatNumber = true;
          this.vatNumber = this.decodeVatNumber(value);
          break;
        case Swico.vatDateTag:
          this.hasVatStartDate = true;
          if (value.length === 12) {
            this.hasVatEndDate = true;
            this.vatStartDate = this.decodeDate(value.substring(0, 6));
            this.vatEndDate = this.decodeDate(value.substring(6, 12));
          } else {
            this.vatStartDate = this.decodeDate(value.substring(0, 6));
          }
          break;
        case Swico.vatDetailsTag:
          this.vatDetails = new List<SwicoVatRate>(value.split(';').map((item) => new SwicoVatRate(item)));
          break;
        case Swico.vatImportTag:
          this.vatImports = new List<SwicoVatImport>(value.split(';').map((item) => new SwicoVatImport(item)));
          break;
        case Swico.conditionsTag:
          this.conditions = new List<SwicoCondition>(value.split(';').map((item) => new SwicoCondition(item)));
          break;
      }
    }
  }

  public reset() {
    this.hasInvoiceNumber = false;
    this.hasInvoiceDate = false;
    this.hasCustomerReference = false;
    this.hasVatNumber = false;
    this.hasVatStartDate = false;
    this.hasVatEndDate = false;

    this.invoiceNumber = '';
    this.invoiceDate = '';
    this.customerReference = '';
    this.vatNumber = '';
    this.vatStartDate = '';
    this.vatEndDate = '';

    this.vatDetails = new List<SwicoVatRate>();
    this.vatImports = new List<SwicoVatImport>();
    this.conditions = new List<SwicoCondition>();
  }

  public encodeDate(dateString: string): string {
    if (dateString === undefined || dateString === '') { return ''; }
    const date = new Date(dateString);
    return `${this.zeroPad(date.getFullYear() - 2000)}` +
           `${this.zeroPad(date.getMonth() + 1)}` +
           `${this.zeroPad(date.getDate())}`;
  }
  public decodeDate(date: string): string {
    const year = parseInt(date.substring(0, 2), 10) + 2000;
    const month = parseInt(date.substring(2, 4), 10) - 1;
    const day = parseInt(date.substring(4, 6), 10);
    return new Date(Date.UTC(year, month, day)).toISOString().substring(0, 10);
  }
  public encodeVatNumber(value: string): string {
    return value.replace(/(\D)/g, '');  // remove all non-digits
  }
  public decodeVatNumber(value: string): string {
    return `CHE-${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}`;
  }
  public zeroPad(value: number): string {
    return value.toLocaleString('en', {minimumIntegerDigits: 2, useGrouping: false});
  }
  public escape(value: string): string {
    return value.replace(/(\\|\/)/g, '\\$1');
  }
}
