export default class StoredPushSubscription {
    public static fromObject(item: any): StoredPushSubscription {
      return new StoredPushSubscription(item.name, item.subscription, item.metadata);
    }
  
    public name = '';
    public subscription: any = {};
    public metadata = {};

    constructor(name: string, subscription: any, metadata: any) {
      this.name = name;
      this.subscription = subscription;
      this.metadata = metadata;
    }
  }
  