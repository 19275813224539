export default class StoredEmailTemplate {
  public static fromObject(item: any): StoredEmailTemplate {
    return new StoredEmailTemplate(item.name, item.to, item.cc, item.bcc, item.subject, item.content, item.attachments, item.metadata);
  }

  public name = '';
  public to: any = {};
  public cc: any = {};
  public bcc: any = {};
  public subject: any = {};
  public content: any = {};
  public attachments: any[] = [];
  public metadata: any = {};

  constructor(name: string, to: any, cc: any, bcc: any, subject: any, content: any, attachments: any[], metadata: any) {
    this.name = name;
    this.to = to;
    this.cc = cc;
    this.bcc = bcc;
    this.subject = subject;
    this.content = content;
    this.attachments = attachments;
    this.metadata = metadata;
  }
}
