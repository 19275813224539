import { type AppConfig, type ComponentPublicInstance } from 'vue';
import Settings from '@/store/Settings';
import StringHelpers from '@/lib/StringHelpers';

export default class ErrorHandler {
  private settings: Settings;
  private senderEmail = '';

  private vueAppConfig: AppConfig | undefined;

  constructor(settings: Settings) {
    this.settings = settings;
  }

  get saneHash(): string {
    const hash = window.location.hash;
    if (hash.startsWith('#/b')) return '#/b';
    if (hash.startsWith('#/view')) return '#/view';
    return hash;
  }

  get saneLocation(): string {
    const loc = window.location;
    if (loc.hash.startsWith('#/b')) loc.hash = '#/b';
    if (loc.hash.startsWith('#/view')) loc.hash = '#/view';
    return loc.toString();
  }

  public enableErrorReports(supportCode: string): boolean {
    try {
      if (typeof window === "undefined") { return false; }
      if (!supportCode.startsWith('QR')) return false;
      const b = atob(supportCode.substring(2));
      if (!b.startsWith('QR')) return false;
      this.senderEmail = b.substring(2);
      this.settings.errorHandlingEnabled = true;
      this.settings.errorCount = 0;
      window.onerror = (message: string | Event, source?: string, lineno?: number, colno?: number, error?: object) =>
        this.handleScriptError(message, source, lineno, colno, error);
      window.onunhandledrejection = (event: PromiseRejectionEvent) =>
        this.handlePromiseError(event);
      if (this.vueAppConfig) {
        this.vueAppConfig.errorHandler = (error, instance, info) => this.handleVueError(error, instance, info);
      }
      return true;
    } catch (ex) {
      return false;
    }
  }

  public registerVueInstance(vueAppConfig: AppConfig) {
    this.vueAppConfig = vueAppConfig;
  }

  private handleVueError(error: any, instance: ComponentPublicInstance | null, info: string) {
    const formData = new FormData();
    formData.append('subject', `Vue | ${this.saneHash} | ${error.name}: ${error.message}`);
    formData.append('type', `vue`);
    formData.append('error_name', error.name);
    formData.append('error_message', error.message);
    formData.append('error_stack', error.stack ?? '');
    formData.append('vue_stack', this.getVueComponentStack(instance));
    formData.append('info', info);
    this.sendErrorReport(formData);
  }

  private getVueComponentStack(instance: ComponentPublicInstance | null) {
    let com = instance;
    const stack: string[] = [];
    while (com) {
      const name = this.getVueComponentName(com);
      const attrs = StringHelpers.jsonToString(com.$attrs);
      stack.push(`${name}  ${attrs}`);
      com = com.$parent;
    }
    return stack.join('\n');
  }

  private getVueComponentName(vm: ComponentPublicInstance): string {
    if (vm.$root === vm) {
      return '[root]';
    }
    const name = vm.$options.name || vm.$options._componentTag;
    return (
      (name ? '<' + name + '>' : '[anon]') +
      (vm.$options.__file ? ' at ' + vm.$options.__file : '')
    );
  }  

  private addFormData(prefix: string, that: any, formData: FormData) {
    try {
      formData.append(prefix + 'typeof', typeof that);
      if (that instanceof Object) {
        formData.append(prefix + 'json', JSON.stringify(that, Object.getOwnPropertyNames(that)));
      }
      if (that instanceof ErrorEvent) {
        formData.append(prefix + 'message', that.message ?? '');
        formData.append(prefix + 'filename', that.filename ?? '');
        formData.append(prefix + 'lineno', that.lineno?.toString() ?? '');
        formData.append(prefix + 'colno', that.colno?.toString() ?? '');
        if (that.error) { this.addFormData('error_', that.error, formData); }
      }
      if (that instanceof PromiseRejectionEvent) {
        if (that.promise) { this.addFormData('promise_', that.promise, formData); }
        if (that.reason) { this.addFormData('reason_', that.reason, formData); }
      }
      if (that instanceof Event) {
        formData.append(prefix + 'bubbles', that.bubbles?.toString() ?? '');
        formData.append(prefix + 'cancelable', that.cancelable?.toString() ?? '');
        formData.append(prefix + 'composed', that.composed?.toString() ?? '');
        if (that.currentTarget) { this.addFormData('currentTarget_', that.currentTarget, formData); }
        formData.append(prefix + 'defaultPrevented', that.defaultPrevented?.toString() ?? '');
        formData.append(prefix + 'eventPhase', that.eventPhase?.toString() ?? '');
        formData.append(prefix + 'isTrusted', that.isTrusted?.toString() ?? '');
        if (that.target) { this.addFormData('target_', that.target, formData); }
        formData.append(prefix + 'type', that.type?.toString() ?? '');
      }
      if (that instanceof Error) {
        formData.append(prefix + 'name', that.name ?? '');
        formData.append(prefix + 'message', that.message ?? '');
        formData.append(prefix + 'stack', that.stack ?? '');
        //@ts-ignore
        if (typeof that.cause === 'object') { 
          //@ts-ignore
          this.addFormData('cause_', that.cause, formData);
        //@ts-ignore
        } else if (typeof that.cause === 'string') {
          //@ts-ignore
          formData.append(prefix + 'cause', that.cause ?? '');
        }
        formData.append(prefix + 'toString', that.toString() ?? '');
      }
      if (that instanceof Promise) {
        formData.append(prefix + 'toString', that.toString() ?? '');
      }
    } catch (e) {
      formData.append(prefix + 'catch', `Error while listing error properties: ${e}`);
    }
  }

  public handleCatchError(error: any) {
    console.error(error);
    if (!this.settings.errorHandlingEnabled) { return; }
    const formData = new FormData();
    if (error instanceof ErrorEvent) {
      formData.append('subject', `OnCatch | ${this.saneHash} | ErrorEvent: ${error.message}`);
      formData.append('type', `catch`);
      this.addFormData('errorevent_', error, formData);
    } else if (error instanceof Error) {
      formData.append('subject', `OnCatch | ${this.saneHash} | ${error.name}: ${error.message}`);
      formData.append('type', `catch`);
      this.addFormData('error_', error, formData);
    } else {
      formData.append('subject', `OnCatch | ${this.saneHash} | Unknown`);
      formData.append('type', `catch`);
      this.addFormData('unknown_', error, formData);
    }
    this.sendErrorReport(formData);
  }

  private handleScriptError(message: string | Event, source?: string, lineno?: number, colno?: number, error?: any) {
    const formData = new FormData();
    formData.append('subject', `OnError | ${this.saneHash} | ${message.toString()}`);
    formData.append('type', `window.onerror`);
    if (typeof message === 'string') {
      formData.append('message', message);
    } else {
      this.addFormData('message_', message, formData);
    }
    formData.append('source', source ?? '');
    formData.append('lineno', `${lineno}`);
    formData.append('colno', `${colno}`);
    if (error) {
      this.addFormData('error_', error, formData);
    }
    this.sendErrorReport(formData);
  }

  private handlePromiseError(event: PromiseRejectionEvent) {
    const formData = new FormData();
    formData.append('subject', `OnUnhandledRejection | ${this.saneHash} | ${event.reason}`);
    formData.append('type', `window.onunhandledrejection`);
    this.addFormData('event_', event, formData);
    this.sendErrorReport(formData);
  }

  private sendErrorReport(formData: FormData) {
    formData.append('conf', 'qr_rechnung_net_error_report');
    formData.append('charset', 'utf-8');
    formData.append('email', this.senderEmail);
    formData.append('location', this.saneLocation);
    formData.append('language', this.settings.language);
    formData.append('vendor', navigator.vendor);
    formData.append('window', `${window.innerWidth} x ${window.innerHeight}`);
    formData.append('screen', `${window.screen.width} x ${window.screen.height}`);
    if (document.lastModified) {
      formData.append('last_modified', `${new Date(document.lastModified).toISOString()}`);
    }
    navigator.sendBeacon('/cgi-sys/FormMail.cgi', formData);
    this.settings.errorCount += 1;
  }
}
