import { DBSchema, IDBPDatabase, openDB } from 'idb';
import { toRawDeep } from './VueHelpers';
import StoredQrch from './StoredQrch';
import StoredQrchList from './StoredQrchList';
import StoredTemplate from './StoredTemplate';
import StoredEmailTemplate from './StoredEmailTemplate';
import WithKey from './WithKey';
import WithKeySelected from './WithKeySelected';
import StoredSettings from './StoredSettings';
import StoredPushSubscription from './StoredPushSubscription';

interface QrRechnungDBSchema extends DBSchema {
  'qrch': {
    key: string;
    value: {
      name: string;
      language: string;
      qrdata: string;
      metadata: any;
    };
  };
  'qrchList': {
    key: string;
    value: {
      name: string;
      languageList: string[];
      qrdataList: string[];
      metadataList: any[];
    };
  };
  'template': {
    key: string;
    value: {
      name: string;
      heading: object;
      sender: object;
      address: object;
      content: object;
      addressPosition: string;
      paymentPartPosition: string;
      backgroundPdfData?: ArrayBuffer;
      backgroundPdfFilename?: string;
    };
  };
  'emailTemplate': {
    key: string;
    value: {
      name: string;
      to: object;
      cc: object;
      bcc: object;
      subject: object;
      content: object;
      attachments: object[];
      metadata: any;
    };
  };
  'pushSubscription': {
    key: string;
    value: {
      name: string;
      subscription: any;
      metadata: any;
    };
  };
  'settings': {
    key: string;
    value: {
      language: string;
      qrchSortOrder: string;
      qrchListSortOrder: string;
      templateSortOrder: string;
      emailTemplateSortOrder: string;
      paymentPartPosition: string;
      linkPosition: string;
      pdfCutLines: boolean;
      displayStructuredBookingInfo: boolean;
      switchAddressType: boolean;
      windowEnvelopeNorm: string;
      generatePDFA: boolean;
      pdfAttachSwissQRBillText: boolean;
      pdfBundleSize: number;
      pdfBleed: boolean;
      pdfCropMarks: boolean;
      autoReloadLastUsedItems: boolean;
      autoCopyScannedDataToClipboard: boolean;
      forceUtf8OnTableLoad: boolean;
      recordScannedVideo: boolean;
      lastUsedQrchKey: string;
      lastUsedQrchListKey: string;
      lastUsedTemplateKey: string;
      lastUsedEmailTemplateKey: string;
      multiPaymentPartsPosition: string;
    };
  };
}

export default class Database {
  private dbEnabled = false;
  private db: IDBPDatabase<QrRechnungDBSchema> | null = null;

  public get enabled(): boolean {
    return this.dbEnabled;
  }

  public async openDatabaseAsync() {
    if (typeof window === "undefined") { return; }
    if (!window.indexedDB) { return; }
    try {
      this.db = await openDB<QrRechnungDBSchema>('qr-rechnung.net', 8, {
        upgrade: (db, oldVersion /*, newVersion, transaction */) => {
          if (oldVersion < 4) {
            db.createObjectStore('qrch', { keyPath: 'key', autoIncrement: true });
            db.createObjectStore('template', { keyPath: 'key', autoIncrement: true });
          }
          if (oldVersion < 5) {
            db.createObjectStore('settings');
          }
          if (oldVersion < 6) {
            db.createObjectStore('qrchList', { keyPath: 'key', autoIncrement: true });
          }
          if (oldVersion < 7) {
            db.createObjectStore('pushSubscription', { keyPath: 'key', autoIncrement: true });
          }
          if (oldVersion < 8) {
            db.createObjectStore('emailTemplate', { keyPath: 'key', autoIncrement: true });
          }
        },
      });
    } catch (ex) {
      console.log(`opendb() failed`, ex);
      return;
    }
    this.dbEnabled = true;
  }

  public async getQRBillsAsync(fallbackLanguage: string): Promise<Array<WithKeySelected<StoredQrch>>> {
    const items: Array<WithKeySelected<StoredQrch>> = [];
    if (!this.dbEnabled) {
      return items;
    }
    let cursor = await this.db!.transaction('qrch').store.openCursor();
    while (cursor) {
      items.push(new WithKeySelected<StoredQrch>(cursor.key, StoredQrch.fromObject(cursor.value, fallbackLanguage)));
      cursor = await cursor.continue();
    }
    return items;
  }

  public async addQRBillAsync(item: StoredQrch): Promise<WithKeySelected<StoredQrch>> {
    const key = await this.db!.add('qrch', toRawDeep(item));
    return new WithKeySelected<StoredQrch>(key, item);
  }

  public async updateQRBillAsync(itemWithKey: WithKeySelected<StoredQrch>) {
    await this.db!.put('qrch', toRawDeep(itemWithKey.combined));
  }

  public async removeQRBillAsync(key: string) {
    await this.db!.delete('qrch', key);
  }

  public async getQRBillListsAsync(fallbackLanguage: string): Promise<Array<WithKeySelected<StoredQrchList>>> {
    const items: Array<WithKeySelected<StoredQrchList>> = [];
    if (!this.dbEnabled) {
      return items;
    }
    let cursor = await this.db!.transaction('qrchList').store.openCursor();
    while (cursor) {
      items.push(new WithKeySelected<StoredQrchList>(cursor.key, StoredQrchList.fromObject(cursor.value, fallbackLanguage)));
      cursor = await cursor.continue();
    }
    return items;
  }

  public async addQRBillListAsync(item: StoredQrchList): Promise<WithKeySelected<StoredQrchList>> {
    const key = await this.db!.add('qrchList', toRawDeep(item));
    return new WithKeySelected<StoredQrchList>(key, item);
  }

  public async updateQRBillListAsync(itemWithKey: WithKeySelected<StoredQrchList>) {
    await this.db!.put('qrchList', toRawDeep(itemWithKey.combined));
  }

  public async removeQRBillListAsync(key: string) {
    await this.db!.delete('qrchList', key);
  }

  public async getTemplatesAsync(): Promise<Array<WithKey<StoredTemplate>>> {
    const items: Array<WithKey<StoredTemplate>> = [];
    if (!this.dbEnabled) {
      return items;
    }
    let cursor = await this.db!.transaction('template').store.openCursor();
    while (cursor) {
      items.push(new WithKey<StoredTemplate>(cursor.key, StoredTemplate.fromObject(cursor.value)));
      cursor = await cursor.continue();
    }
    return items;
  }

  public async addTemplateAsync(item: StoredTemplate): Promise<WithKey<StoredTemplate>> {
    const key = await this.db!.add('template', toRawDeep(item));
    return new WithKey<StoredTemplate>(key, item);
  }

  public async updateTemplateAsync(itemWithKey: WithKey<StoredTemplate>) {
    await this.db!.put('template', toRawDeep(itemWithKey.combined));
  }

  public async removeTemplateAsync(key: string) {
    await this.db!.delete('template', key);
  }

  public async getEmailTemplatesAsync(): Promise<Array<WithKey<StoredEmailTemplate>>> {
    const items: Array<WithKey<StoredEmailTemplate>> = [];
    if (!this.dbEnabled) {
      return items;
    }
    let cursor = await this.db!.transaction('emailTemplate').store.openCursor();
    while (cursor) {
      items.push(new WithKey<StoredEmailTemplate>(cursor.key, StoredEmailTemplate.fromObject(cursor.value)));
      cursor = await cursor.continue();
    }
    return items;
  }

  public async addEmailTemplateAsync(item: StoredEmailTemplate): Promise<WithKey<StoredEmailTemplate>> {
    const key = await this.db!.add('emailTemplate', toRawDeep(item));
    return new WithKey<StoredEmailTemplate>(key, item);
  }

  public async updateEmailTemplateAsync(itemWithKey: WithKey<StoredEmailTemplate>) {
    await this.db!.put('emailTemplate', toRawDeep(itemWithKey.combined));
  }

  public async removeEmailTemplateAsync(key: string) {
    await this.db!.delete('emailTemplate', key);
  }

  public async getPushSubscriptionsAsync(): Promise<Array<WithKeySelected<StoredPushSubscription>>> {
    const items: Array<WithKeySelected<StoredPushSubscription>> = [];
    if (!this.dbEnabled) {
      return items;
    }
    let cursor = await this.db!.transaction('pushSubscription').store.openCursor();
    while (cursor) {
      items.push(new WithKeySelected<StoredPushSubscription>(cursor.key, StoredPushSubscription.fromObject(cursor.value)));
      cursor = await cursor.continue();
    }
    return items;
  }

  public async addPushSubscriptionAsync(item: StoredPushSubscription): Promise<WithKeySelected<StoredPushSubscription>> {
    const key = await this.db!.add('pushSubscription', toRawDeep(item));
    return new WithKeySelected<StoredPushSubscription>(key, item);
  }

  public async updatePushSubscriptionAsync(itemWithKey: WithKeySelected<StoredPushSubscription>) {
    await this.db!.put('pushSubscription', toRawDeep(itemWithKey.combined));
  }

  public async removePushSubscriptionAsync(key: string) {
    await this.db!.delete('pushSubscription', key);
  }

  public async getSettingsWithDefaultAsync(defaultSettings: StoredSettings): Promise<StoredSettings> {
    if (!this.dbEnabled) {
      return defaultSettings;
    }
    const settings = await this.db!.get('settings', 'settings');
    if (settings) {
      return StoredSettings.fromObject(settings);
    }

    await this.updateSettingsAsync(defaultSettings);
    return defaultSettings;
  }

  public async updateSettingsAsync(settings: StoredSettings) {
    await this.db!.put('settings', toRawDeep(settings), 'settings');
  }
}
