import WithKey from './WithKey';

export default class WithKeySelected<T> extends WithKey<T> {
  public selected: boolean;

  constructor(key: any, item: T) {
    super(key, item);
    this.selected = false;
  }
}
