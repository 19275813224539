export default class CheckDigits {
  public static ibanCheck(iban: string): number {
    const country = iban.substr(0, 2);
    const checkdigits = iban.substr(2, 2);
    const account = iban.substr(4);
    return this.ibanCheckDigits(country, account) === checkdigits ? 1 : 0;
  }

  public static ibanCheckDigits(country: string, account: string): string {
    const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const modulus = 97;
    const radix = 10;
    const numeric = Array.from(`${account.toUpperCase()}${country}`)
      .map((c) => alphabet.indexOf(c))
      .filter((c) => c >= 0)
      .join('');
    const input = numeric + '00';
    const sum = input.split('')
      .map((c) => alphabet.indexOf(c))
      .reduce((carry, pos) => (carry * radix + pos) % modulus, 0);
    const checksum = (modulus + 1 - (sum % modulus)) % modulus;
    const fixedChecksum = checksum < 2 ? checksum + modulus : checksum;
    return String(fixedChecksum).padStart(2, '0');
  }

  public static ibanAmendCheckDigits(country: string, account: string): string {
    const bare = account.toUpperCase().replace(/[^A-Z0-9]/g, '');
    if (bare.startsWith(country)) return bare;
    const checkDigits = CheckDigits.ibanCheckDigits(country, bare);
    return `${country}${checkDigits}${bare}`;
  }

  public static qrReferenceCheckDigit(qrref: string): number {
    const permutation = [0, 9, 4, 6, 8, 2, 7, 1, 3, 5];
    let remainder = 0;
    for (const digitChar of qrref) {
      const digit = parseInt(digitChar, 10);
      const offset = (remainder + digit) % 10;
      remainder = permutation[offset];
    }
    return (10 - remainder) % 10;
  }

  public static qrReferenceAmendCheckDigit(qrref: string): string {
    const bare = qrref.replace(/[^0-9]/g, '');
    if (bare.length > 26) return bare;
    const base = bare.padStart(26, '0');
    const checkDigit = CheckDigits.qrReferenceCheckDigit(base);
    return `${base}${checkDigit}`;
  }

  public static vatNumberCheckDigit(uid: string): number {
    const factors = [5, 4, 3, 2, 7, 6, 5, 4];
    let sum = 0;
    for (let i = 0; i < 8; i++) {
      const digit = parseInt(uid[i], 10);
      sum += factors[i] * digit;
    }
    const mod = sum % 11;
    return mod === 0 ? 0 : 11 - mod;
  }
}
