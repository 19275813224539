export default class Sanitize {
  public static replaceNewlines(value: string): string {
    let sanitized = value.replace(/ (?:\r\n|\r|\n)/g, ' '); // space + new line -> space
    sanitized = sanitized.replace(/\r\n|\r|\n/g, ' '); // new line -> space
    return sanitized;
  }

  public static splitNewlinesOnce(text: string): string[] {
    const sep = /\r\n|\r|\n/g;
    const search = sep.exec(text);
    if (search === null) {
      return [text];
    }
    return [text.slice(0, search.index), this.replaceNewlines(text.slice(sep.lastIndex))];
  }

  public static textAndNewLines(value: string, length?: number): string {
    let sanitized = value.replace(/[^a-zA-Z0-9.,;:'+\-/()?*[\]{}\\`´~ !"#%&<>÷=@_$£àáâäçèéêëìíîïñòóôöùúûüýßÀÁÂÄÇÈÉÊËÌÍÎÏÒÓÔÖÙÚÛÜÑ\r\n]/g, '');
    if (length !== undefined) {
      sanitized = sanitized.substr(0, length);
    }
    return sanitized;
  }

  public static text(value: string, length?: number): string {
    let sanitized = value.replace(/[^a-zA-Z0-9.,;:'+\-/()?*[\]{}\\`´~ !"#%&<>÷=@_$£àáâäçèéêëìíîïñòóôöùúûüýßÀÁÂÄÇÈÉÊËÌÍÎÏÒÓÔÖÙÚÛÜÑ]/g, '');
    if (length !== undefined) {
      sanitized = sanitized.substr(0, length);
    }
    return sanitized;
  }

  public static numeric(value: string, length?: number): string {
    let sanitized = value.replace(/[^0-9]/g, '').substr(0, length);
    if (length !== undefined) {
      sanitized = sanitized.substr(0, length);
    }
    return sanitized;
  }

  public static alphanumeric(value: string, length?: number): string {
    let sanitized = value.replace(/[^a-zA-Z0-9]/g, '').substr(0, length);
    if (length !== undefined) {
      sanitized = sanitized.substr(0, length);
    }
    return sanitized;
  }

  public static decimal(value: string, length?: number): string {
    let sanitized = value.replace(/[^0-9.]/g, '').substr(0, length);
    if (length !== undefined) {
      sanitized = sanitized.substr(0, length);
    }
    return sanitized;
  }

  public static demoIbans = [
    'CH6431961000004421557',
    'CH4431999123000889012',
    'CH5204835012345671000',
    'CH5800791123000889012',
    'CHXXXXXXXXXXXXXXXXXXX',
  ];

  public static demoIbanQR(ibanQR: string): string {
    return this.demoIbans.includes(ibanQR) ? 'CHXXXXXXXXXXXXXXXXXXX' : ibanQR;
  }
}
