import Labels from "@/store/Labels";

export default class Template {
  public heading: any = {};
  public sender: any = {};
  public address: any = {};
  public content: any = {};
  public addressPosition = 'r';
  public paymentPartPosition = 'f';
  public backgroundPdf?: File = undefined;
}

export function createDefaultTemplate(labels: Labels): Template {
  const heading: any[] = [
    { insert: { placeholder: { id: 'beginNoEmptyLines', label: labels.beginNoEmptyLinesLabel }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'creditor', label: labels.creditorLabel }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'endNoEmptyLines', label: labels.endNoEmptyLinesLabel }}},
    { insert: '\n' },
    { insert: '\n' },
    { insert: 'Tel:\t\t+41 12 345 67 89\n' },
    { insert: 'Email:\tinfo@example.com\n' },
    { insert: '\n' },
    { insert: { placeholder: { id: 'beginNoEmptyLines', label: labels.beginNoEmptyLinesLabel }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'invoiceDateLine', label: labels.invoiceDateLineLabel }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'invoiceNumberLine', label: labels.invoiceNumberLineLabel }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'customerReferenceLine', label: labels.customerReferenceLineLabel }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'vatNumberLine', label: labels.vatNumberLineLabel }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'vatDateLine', label: labels.vatDateLineLabel }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'ibanLine', label: labels.ibanLineLabel }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'amountLine', label: labels.amountLineLabel }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'conditionsLine', label: labels.conditionsLabel }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'endNoEmptyLines', label: labels.endNoEmptyLinesLabel }}},
    { insert: '\n' },
    { insert: '\n' },
    { insert: '\n' },
    { insert: { placeholder: { id: 'today', label: labels.today }}},
    { insert: '\n' },
  ];

  const sender: any[] = [
    { insert: { placeholder: { id: 'creditorLine', label: labels.creditorLineLabel }}},
  ];

  const address: any[] = [
    { insert: { placeholder: { id: 'beginNoEmptyLines', label: labels.beginNoEmptyLinesLabel }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'debtor_name', label: labels.debtorLabel + ' ' + labels.nameLabel }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'debtor_address1', label: labels.debtorLabel + ' ' + labels.address1Label }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'debtor_address2', label: labels.debtorLabel + ' ' + labels.address2Label }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'debtor_country', label: labels.debtorLabel + ' ' + labels.countryLabel }}},
    { insert: '\n' },
    { insert: { placeholder: { id: 'endNoEmptyLines', label: labels.endNoEmptyLinesLabel }}},
    { insert: '\n' },
  ];

  const content: any[] = [
    { insert: labels.invoice, attributes: { bold: true } },
    { insert: '\n', attributes: { header: 1 } },
    { insert: { placeholder: { id: 'info', label: labels.unstructuredMessageLabel }}},
    { insert: '\n', attributes: { header: 2 } },
    { insert: '\n' },
    { insert: { placeholder: { id: 'Anrede', label: 'Anrede' }}},
    { insert: '\n' },
    { insert: '\n' },
    { insert: '\n' },
    { insert: '\n' },
    { insert: '\n' },
    { insert: '\n' },
    { insert: labels.regards + '\n' },
    { insert: { placeholder: { id: 'creditor_name', label: labels.creditorLabel
      + ' ' + labels.nameLabel }}},
  ];
  const template: Template = new Template();
  template.heading = heading;
  template.sender = sender;
  template.address = address;
  template.content = content;
  return template;
}
