export default class SwicoCondition {
  public discountRate = 0;
  public dueDays = 0;

  constructor(value?: string | undefined) {
    if (value !== undefined) {
      this.initFromQRData(value);
    }
  }

  public get getQRData() {
    return `${this.encodeFloat(this.discountRate)}:${this.encodeInt(this.dueDays)}`;
  }

  public updateDiscountRate(discountRate: number) {
    this.discountRate = discountRate;
  }

  public updateDueDays(dueDays: number) {
    this.dueDays = dueDays;
  }

  public initFromQRData(payload: string) {
    const parts = payload.split(':');
    if (parts.length >= 2) {
      this.discountRate = Number.parseFloat(parts[0]);
      this.dueDays = Number.parseInt(parts[1], 10);
    } else {
      this.discountRate = 0;
      this.dueDays = 0;
    }
  }

  public encodeFloat(float: number): string {
    return Number.isFinite(float) ? float.toLocaleString('en', {minimumIntegerDigits: 1, useGrouping: false}) : '';
  }
  public encodeInt(int: number): string {
    return Number.isInteger(int) ? int.toFixed(0) : '';
  }
}
