import Settings from '@/store/Settings';

export default class Labels {
  readonly settings!: Settings;

  constructor(settings: Settings) {
    this.settings = settings;
  }

  public get acceptancePointLabel(): string {
    return Labels.acceptancePointLabel(this.settings.language);
  }
  public static acceptancePointLabel(language: string): string {
    switch (language) {
      case 'de': return 'Annahmestelle';
      case 'fr': return 'Point de dépôt';
      case 'it': return 'Punto di accettazione';
      case 'en': return 'Acceptance point';
      default: return 'Acceptance point';
    }
  }
  public get activate(): string {
    return Labels.activate(this.settings.language);
  }
  public static activate(language: string): string {
    switch (language) {
      case 'de': return 'Aktivieren';
      case 'fr': return 'Activer';
      case 'it': return 'Attivare';
      case 'en': return 'Activate';
      default: return 'Activate';
    }
  }
  public get activateAmount(): string {
    return Labels.activateAmount(this.settings.language);
  }
  public static activateAmount(language: string): string {
    switch (language) {
      case 'de': return 'Betrag aktivieren';
      case 'fr': return 'Activer montant';
      case 'it': return 'Attivare importo';
      case 'en': return 'Activate amount';
      default: return 'Activate amount';
    }
  }
  public get activateCustomerReference(): string {
    return Labels.activateCustomerReference(this.settings.language);
  }
  public static activateCustomerReference(language: string): string {
    switch (language) {
      case 'de': return 'Kundenreferenz aktivieren';
      case 'fr': return 'Activer référence client';
      case 'it': return 'Attivare riferimento del cliente';
      case 'en': return 'Activate customer reference';
      default: return 'Activate customer reference';
    }
  }
  public get activateDebtor(): string {
    return Labels.activateDebtor(this.settings.language);
  }
  public static activateDebtor(language: string): string {
    switch (language) {
      case 'de': return 'Zahlbar durch aktivieren';
      case 'fr': return 'Activer payable par';
      case 'it': return 'Attivare pagabile da';
      case 'en': return 'Activate payable by';
      default: return 'Activate payable by';
    }
  }
  public get activateInvoiceDate(): string {
    return Labels.activateInvoiceDate(this.settings.language);
  }
  public static activateInvoiceDate(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungsdatum aktivieren';
      case 'fr': return 'Activer date de facture';
      case 'it': return 'Attivare data della fattura';
      case 'en': return 'Activate invoice date';
      default: return 'Activate invoice date';
    }
  }
  public get activateInvoiceInfo(): string {
    return Labels.activateInvoiceInfo(this.settings.language);
  }
  public static activateInvoiceInfo(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungsinformationen (Swico) aktivieren';
      case 'fr': return 'Activer informations de facture (Swico)';
      case 'it': return 'Attivare informazioni di fatturazione (Swico)';
      case 'en': return 'Activate billing information (Swico)';
      default: return 'Activate billing information (Swico)';
    }
  }
  public get activateInvoiceNumber(): string {
    return Labels.activateInvoiceNumber(this.settings.language);
  }
  public static activateInvoiceNumber(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungsnummer aktivieren';
      case 'fr': return 'Activer numéro de facture';
      case 'it': return 'Attivare numero di fattura';
      case 'en': return 'Activate invoice number';
      default: return 'Activate invoice number';
    }
  }
  public get activateVatEndDate(): string {
    return Labels.activateVatEndDate(this.settings.language);
  }
  public static activateVatEndDate(language: string): string {
    switch (language) {
      case 'de': return 'MWST End-Datum aktivieren';
      case 'fr': return 'Activer date de fin TVA';
      case 'it': return 'Attivare data di fine IVA';
      case 'en': return 'Activate VAT end date';
      default: return 'Activate VAT end date';
    }
  }
  public get activateVatNumber(): string {
    return Labels.activateVatNumber(this.settings.language);
  }
  public static activateVatNumber(language: string): string {
    switch (language) {
      case 'de': return 'MWST UID Nummer aktivieren';
      case 'fr': return 'Activer numéro UID de TVA';
      case 'it': return 'Attivare numero UID IVA';
      case 'en': return 'Activate VAT number';
      default: return 'Activate VAT number';
    }
  }
  public get activateVatStartDate(): string {
    return Labels.activateVatStartDate(this.settings.language);
  }
  public static activateVatStartDate(language: string): string {
    switch (language) {
      case 'de': return 'MWST (Anfangs-)Datum aktivieren';
      case 'fr': return 'Activer date (de début) TVA';
      case 'it': return 'Attivare data (di inizio) IVA';
      case 'en': return 'Activate VAT (start) date';
      default: return 'Activate VAT (start) date';
    }
  }
  public get add(): string {
    return Labels.add(this.settings.language);
  }
  public static add(language: string): string {
    switch (language) {
      case 'de': return 'Hinzufügen';
      case 'fr': return 'Ajouter';
      case 'it': return 'Aggiungi';
      case 'en': return 'Add';
      default: return 'Add';
    }
  }
  public get addEBill(): string {
    return Labels.addEBill(this.settings.language);
  }
  public static addEBill(language: string): string {
    switch (language) {
      case 'de': return 'eBill hinzufügen';
      case 'fr': return 'Ajouter eBill';
      case 'it': return 'Aggiungi eBill';
      case 'en': return 'Add eBill';
      default: return 'Add eBill';
    }
  }
  public get addOther(): string {
    return Labels.addOther(this.settings.language);
  }
  public static addOther(language: string): string {
    switch (language) {
      case 'de': return 'Anderes hinzufügen';
      case 'fr': return 'Ajouter autre';
      case 'it': return 'Aggiungi altro';
      case 'en': return 'Add another';
      default: return 'Add another';
    }
  }
  public get addTwint(): string {
    return Labels.addTwint(this.settings.language);
  }
  public static addTwint(language: string): string {
    switch (language) {
      case 'de': return 'TWINT hinzufügen';
      case 'fr': return 'Ajouter TWINT';
      case 'it': return 'Aggiungi TWINT';
      case 'en': return 'Add TWINT';
      default: return 'Add TWINT';
    }
  }
  public get addConditionLabel(): string {
    return Labels.addConditionLabel(this.settings.language);
  }
  public static addConditionLabel(language: string): string {
    switch (language) {
      case 'de': return 'Kondition hinzufügen';
      case 'fr': return 'Ajouter une condition';
      case 'it': return 'Aggiungi una condizione';
      case 'en': return 'Add condition';
      default: return 'Add condition';
    }
  }
  public get additionalInfoLabel(): string {
    return Labels.additionalInfoLabel(this.settings.language);
  }
  public static additionalInfoLabel(language: string): string {
    switch (language) {
      case 'de': return 'Zusätzliche Informationen';
      case 'fr': return 'Informations supplémentaires';
      case 'it': return 'Informazioni supplementari';
      case 'en': return 'Additional information';
      default: return 'Additional information';
    }
  }
  public get address(): string {
    return Labels.address(this.settings.language);
  }
  public static address(language: string): string {
    switch (language) {
      case 'de': return 'Adresse';
      case 'fr': return 'Adresse';
      case 'it': return 'Indirizzo';
      case 'en': return 'Address';
      default: return 'Address';
    }
  }
  public get addressType(): string {
    return Labels.addressType(this.settings.language);
  }
  public static addressType(language: string): string {
    switch (language) {
      case 'de': return 'Adresstyp';
      case 'fr': return 'Type d\'adresse';
      case 'it': return 'Tipo di indirizzo';
      case 'en': return 'Address type';
      default: return 'Address type';
    }
  }
  public get addressTypeSelection(): string {
    return Labels.addressTypeSelection(this.settings.language);
  }
  public static addressTypeSelection(language: string): string {
    switch (language) {
      case 'de': return 'Auswahl Adresstyp einblenden';
      case 'fr': return 'Afficher la sélection du type d\'adresse';
      case 'it': return 'Mostra la selezione del tipo di indirizzo';
      case 'en': return 'Show address type selection';
      default: return 'Show address type selection';
    }
  }
  public get addressTypeSelectionToolTip(): string {
    return Labels.addressTypeSelectionToolTip(this.settings.language);
  }
  public static addressTypeSelectionToolTip(language: string): string {
    switch (language) {
      case 'de': return 'In der Eingabemaske Optionsfelder zur Auswahl des Adresstyps (strukturiert/kombiniert) einblenden';
      case 'fr': return 'Afficher dans le masque de saisie des champs d\'option pour sélectionner le type d\'adresse (structurée/combinée)';
      case 'it': return 'Mostra i pulsanti radio nella maschera di input per selezionare il tipo di indirizzo (strutturato/combinato)';
      case 'en': return 'Show radio buttons in the input mask to select the address type (structured/combined)';
      default: return 'Show radio buttons in the input mask to select the address type (structured/combined)';
    }
  }
  public get address1Label(): string {
    return Labels.address1Label(this.settings.language);
  }
  public static address1Label(language: string): string {
    switch (language) {
      case 'de': return 'Addresse 1';
      case 'fr': return 'Adresse 1';
      case 'it': return 'Indirizzo 1';
      case 'en': return 'Address 1';
      default: return 'Address 1';
    }
  }
  public get address2Label(): string {
    return Labels.address2Label(this.settings.language);
  }
  public static address2Label(language: string): string {
    switch (language) {
      case 'de': return 'Addresse 2';
      case 'fr': return 'Adresse 2';
      case 'it': return 'Indirizzo 2';
      case 'en': return 'Address 2';
      default: return 'Address 2';
    }
  }
  public get addVatRateLabel(): string {
    return Labels.addVatRateLabel(this.settings.language);
  }
  public static addVatRateLabel(language: string): string {
    switch (language) {
      case 'de': return 'MWST Satz hinzufügen';
      case 'fr': return 'Ajouter un taux de TVA';
      case 'it': return 'Aggiungi un\'aliquota IVA';
      case 'en': return 'Add VAT rate';
      default: return 'Add VAT rate';
    }
  }
  public get all(): string {
    return Labels.all(this.settings.language);
  }
  public static all(language: string): string {
    switch (language) {
      case 'de': return 'Alle';
      case 'fr': return 'Tous';
      case 'it': return 'Tutti';
      case 'en': return 'All';
      default: return 'All';
    }
  }
  public get allowed(): string {
    return Labels.allowed(this.settings.language);
  }
  public static allowed(language: string): string {
    switch (language) {
      case 'de': return 'Erlaubt';
      case 'fr': return 'Autorisé';
      case 'it': return 'Consentito';
      case 'en': return 'Allowed';
      default: return 'Allowed';
    }
  }
  public get allowedNot(): string {
    return Labels.allowedNot(this.settings.language);
  }
  public static allowedNot(language: string): string {
    switch (language) {
      case 'de': return 'Nicht erlaubt';
      case 'fr': return 'Non autorisé';
      case 'it': return 'Non consentito';
      case 'en': return 'Not allowed';
      default: return 'Not allowed';
    }
  }
  public get allowedCharacters(): string {
    return Labels.allowedCharacters(this.settings.language);
  }
  public static allowedCharacters(language: string): string {
    switch (language) {
      case 'de': return 'Zulässige Zeichen';
      case 'fr': return 'Caractères autorisés';
      case 'it': return 'Caratteri consentiti';
      case 'en': return 'Allowed characters';
      default: return 'Allowed characters';
    }
  }
  public get alternativeSchemesLabel(): string {
    return Labels.alternativeSchemesLabel(this.settings.language);
  }
  public static alternativeSchemesLabel(language: string): string {
    switch (language) {
      case 'de': return 'Alternative Verfahren';
      case 'fr': return 'Procédures alternatives';
      case 'it': return 'Procedure alternative';
      case 'en': return 'Alternative procedures';
      default: return 'Alternative procedures';
    }
  }
  public get alternativeSchemeNameLabel(): string {
    return Labels.alternativeSchemeNameLabel(this.settings.language);
  }
  public static alternativeSchemeNameLabel(language: string): string {
    switch (language) {
      case 'de': return 'Name AV';
      case 'fr': return 'Nom AV';
      case 'it': return 'Nome AV';
      case 'en': return 'Name AV';
      default: return 'Name AV';
    }
  }
  public get alternativeSchemeParameterLabel(): string {
    return Labels.alternativeSchemeParameterLabel(this.settings.language);
  }
  public static alternativeSchemeParameterLabel(language: string): string {
    switch (language) {
      case 'de': return 'Parameter';
      case 'fr': return 'Paramètres';
      case 'it': return 'Parametri';
      case 'en': return 'Parameters';
      default: return 'Parameters';
    }
  }
  public get amountInfoToolTip(): string {
    return Labels.amountInfoToolTip(this.settings.language);
  }
  public static amountInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Betrag der Zahlung. Das Element Betrag ist ohne führende Nullen inklusive Dezimaltrennzeichen und 2 Nachkomastellen anzugeben. Dezimal, maximal 12 Stellen zulässig, inklusive Dezimaltrennzeichen. Als Dezimaltrennzeichen ist nur das Punktzeichen (.) zulässig. Für eine Avisierung verwenden Sie den Betrag 0.00.';
      case 'fr': return 'Montant du paiement. L\'élément montant est à indiquer sans zéros de tête y compris séparateur décimal et deux décimales. Décimal, 12 positions au maximum admises, y compris séparateur décimal. Seul le point («.») est admis comme séparateur décimal. Utilisez le montant 0.00 pour un avis.';
      case 'it': return 'L\'importo del pagamento. L\'elemento di importo deve essere specificato senza zeri iniziali, compreso il separatore decimale e 2 cifre decimali. Decimale, un massimo di 12 cifre consentite, incluso il separatore decimale. Come separatore decimale è ammesso solo il carattere del periodo ("."). Utilizzate l\'importo 0.00 per un avviso.';
      case 'en': return 'The payment amount. The amount element is to be entered without leading zeroes, including decimal separators and two decimal places. Decimal, maximum 12-digits permitted, including decimal separators. Only decimal points (".") are permitted as decimal separators. For a notification, use the amount 0.00.';
      default: return 'The payment amount. The amount element is to be entered without leading zeroes, including decimal separators and two decimal places. Decimal, maximum 12-digits permitted, including decimal separators. Only decimal points (".") are permitted as decimal separators. For a notification, use the amount 0.00.';
    }
  }
  public get amountHintLabel(): string {
    return Labels.amountHintLabel(this.settings.language);
  }
  public static amountHintLabel(language: string): string {
    switch (language) {
      case 'de': return '0 = Avisierung (nicht zur Zahlung verwenden)';
      case 'fr': return '0 = avis (ne pas utiliser pour le paiement)';
      case 'it': return '0 = avviso (non utilizzare per il pagamento)';
      case 'en': return '0 = notification (do not use for payment)';
      default: return '0 = notification (do not use for payment)';
    }
  }
  public get amountLabel(): string {
    return Labels.amountLabel(this.settings.language);
  }
  public static amountLabel(language: string): string {
    switch (language) {
      case 'de': return 'Betrag';
      case 'fr': return 'Montant';
      case 'it': return 'Importo';
      case 'en': return 'Amount';
      default: return 'Amount';
    }
  }
  public get amountLineLabel(): string {
    return Labels.amountLineLabel(this.settings.language);
  }
  public static amountLineLabel(language: string): string {
    switch (language) {
      case 'de': return 'Betrag (Zeile)';
      case 'fr': return 'Montant (Ligne)';
      case 'it': return 'Importo (Linea)';
      case 'en': return 'Amount (Line)';
      default: return 'Amount (Line)';
    }
  }
  public get attachCurrentQrBill(): string {
    return Labels.attachCurrentQrBill(this.settings.language);
  }
  public static attachCurrentQrBill(language: string): string {
    switch (language) {
      case 'de': return 'Aktuelle QR-Rechnung anhängen';
      case 'fr': return 'Joindre la QR-facture actuelle';
      case 'it': return 'Allegare la QR-fattura attuale';
      case 'en': return 'Attach current QR-bill';
      default: return 'Attach current QR-bill';
    }
  }
  public get attachmentOptional(): string {
    return Labels.attachmentOptional(this.settings.language);
  }
  public static attachmentOptional(language: string): string {
    switch (language) {
      case 'de': return 'Anhang (optional)';
      case 'fr': return 'Pièce jointe (optionnel)';
      case 'it': return 'Allegato (opzionale)';
      case 'en': return 'Attachment (optional)';
      default: return 'Attachment (optional)';
    }
  }
  public get attachments(): string {
    return Labels.attachments(this.settings.language);
  }
  public static attachments(language: string): string {
    switch (language) {
      case 'de': return 'Anhänge';
      case 'fr': return 'Pièces jointes';
      case 'it': return 'Allegati';
      case 'en': return 'Attachments';
      default: return 'Attachments';
    }
  }
  public get autoCopyScannedDataToClipboard(): string {
    return Labels.autoCopyScannedDataToClipboard(this.settings.language);
  }
  public static autoCopyScannedDataToClipboard(language: string): string {
    switch (language) {
      case 'de': return 'Gescannte Daten automatisch in die Zwischenablage kopieren';
      case 'fr': return 'Copier automatiquement les données numérisées dans le presse-papiers';
      case 'it': return 'Copia automatica dei dati scansionati negli appunti';
      case 'en': return 'Copy scanned data to the clipboard automatically';
      default: return 'Copy scanned data to the clipboard automatically';
    }
  }
  public get autoReloadLastUsedItems(): string {
    return Labels.autoReloadLastUsedItems(this.settings.language);
  }
  public static autoReloadLastUsedItems(language: string): string {
    switch (language) {
      case 'de': return 'Zuletzt verwendete gespeicherte Elemente automatisch wieder laden';
      case 'fr': return 'Recharger automatiquement les éléments sauvegardés qui ont été utilisés pour la dernière fois';
      case 'it': return 'Ricaricare automaticamente gli elementi salvati che sono stati usati per ultimi';
      case 'en': return 'Automatically reload saved items that were last used';
      default: return 'Automatically reload saved items that were last used';
    }
  }
  public get autoReloadLastUsedItemsToolTip(): string {
    return Labels.autoReloadLastUsedItemsToolTip(this.settings.language);
  }
  public static autoReloadLastUsedItemsToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Bei einem Neustart der App werden diejenigen gespeicherten Zahlteile, Tabellen und Rechnungsvorlagen automatisch wieder geladen, welche zuletzt auf der Seite "Gespeicherte Daten" selektiert oder geöffnet wurden.';
      case 'fr': return 'Lors du redémarrage de l\'application, les sections de paiement, les tableaux et les modèles de facture enregistrés qui ont été sélectionnés ou ouverts en dernier sur la page "Données enregistrées" sont automatiquement rechargés.';
      case 'it': return 'Quando l\'applicazione viene riavviata, le sezioni di pagamento, le tabelle e i modelli di fattura salvati che sono stati selezionati o aperti per ultimi nella pagina "Dati salvati" vengono automaticamente ricaricati.';
      case 'en': return 'When the app is restarted, those saved payment parts, tables and invoice templates that were last selected or opened on the "Saved data" page are automatically reloaded.';
      default: return 'When the app is restarted, those saved payment parts, tables and invoice templates that were last selected or opened on the "Saved data" page are automatically reloaded.';
    }
  }
  public get backgroundPdf(): string {
    return Labels.backgroundPdf(this.settings.language);
  }
  public static backgroundPdf(language: string): string {
    switch (language) {
      case 'de': return 'Briefkopf/Hintergrund PDF';
      case 'fr': return 'Papier à lettres/Fond de page PDF';
      case 'it': return 'Carta intestata/sfondo PDF';
      case 'en': return 'Letterhead/Background PDF';
      default: return 'Letterhead/Background PDF';
    }
  }
  public get bccLabel(): string {
    return Labels.bccLabel(this.settings.language);
  }
  public static bccLabel(language: string): string {
    switch (language) {
      case 'de': return 'Blindkopie (BCC)';
      case 'fr': return 'Copie cachée à';
      case 'it': return 'Ccn';
      case 'en': return 'Bcc';
      default: return 'Bcc';
    }
  }
  public get beginNoEmptyLinesLabel(): string {
    return Labels.beginNoEmptyLinesLabel(this.settings.language);
  }
  public static beginNoEmptyLinesLabel(language: string): string {
    switch (language) {
      case 'de': return 'Start Keine Leeren Zeilen';
      case 'fr': return 'Démarrer Pas de lignes vides';
      case 'it': return 'Inizia Nessuna riga vuota';
      case 'en': return 'Begin No Empty Lines';
      default: return 'Begin No Empty Lines';
    }
  }
  public get bic(): string {
    return Labels.bic(this.settings.language);
  }
  public static bic(language: string): string {
    switch (language) {
      case 'de': return 'BIC';
      case 'fr': return 'BIC';
      case 'it': return 'BIC';
      case 'en': return 'BIC';
      default: return 'BIC';
    }
  }
  public get bleed(): string {
    return Labels.bleed(this.settings.language);
  }
  public static bleed(language: string): string {
    switch (language) {
      case 'de': return 'Anschnitt 3 mm';
      case 'fr': return 'Découpage 3 mm';
      case 'it': return 'Spurgo 3 mm';
      case 'en': return 'Bleed 3 mm';
      default: return 'Bleed 3 mm';
    }
  }
  public get bold(): string {
    return Labels.bold(this.settings.language);
  }
  public static bold(language: string): string {
    switch (language) {
      case 'de': return 'Fett';
      case 'fr': return 'Gras';
      case 'it': return 'Grassetto';
      case 'en': return 'Bold';
      default: return 'Bold';
    }
  }
  public get bulletList(): string {
    return Labels.bulletList(this.settings.language);
  }
  public static bulletList(language: string): string {
    switch (language) {
      case 'de': return 'Aufzählung';
      case 'fr': return 'Liste à puces';
      case 'it': return 'Lista puntata';
      case 'en': return 'Bullet list';
      default: return 'Bullet list';
    }
  }
  public get bundle(): string {
    return Labels.bundle(this.settings.language);
  }
  public static bundle(language: string): string {
    switch (language) {
      case 'de': return 'Bundle';
      case 'fr': return 'Liasse';
      case 'it': return 'Bundle';
      case 'en': return 'Bundle';
      default: return 'Bundle';
    }
  }
  public get calendar(): string {
    return Labels.calendar(this.settings.language);
  }
  public static calendar(language: string): string {
    switch (language) {
      case 'de': return 'Kalender';
      case 'fr': return 'Calendrier';
      case 'it': return 'Calendario';
      case 'en': return 'Calendar';
      default: return 'Calendar';
    }
  }
  public get calendarReminder(): string {
    return Labels.calendarReminder(this.settings.language);
  }
  public static calendarReminder(language: string): string {
    switch (language) {
      case 'de': return 'Kalender-Erinnerung';
      case 'fr': return 'Rappel de calendrier';
      case 'it': return 'Promemoria del calendario';
      case 'en': return 'Calendar reminder';
      default: return 'Calendar reminder';
    }
  }
  public get cameraError(): string {
    return Labels.cameraError(this.settings.language);
  }
  public static cameraError(language: string): string {
    switch (language) {
      case 'de': return 'Keine Kamera gefunden.';
      case 'fr': return 'Aucune caméra n\'a été trouvée.';
      case 'it': return 'Nessuna fotocamera trovata.';
      case 'en': return 'No camera found.';
      default: return 'No camera found.';
    }
  }
  public get cameraNotSupported(): string {
    return Labels.cameraNotSupported(this.settings.language);
  }
  public static cameraNotSupported(language: string): string {
    switch (language) {
      case 'de': return 'Der Browser unterstützt getUserMedia API nicht.';
      case 'fr': return 'Le navigateur ne supporte pas l\'API getUserMedia.';
      case 'it': return 'Il browser non supporta l\'API getUserMedia.';
      case 'en': return 'The browser does not support the getUserMedia API.';
      default: return 'The browser does not support the getUserMedia API.';
    }
  }
  public get cancel(): string {
    return Labels.cancel(this.settings.language);
  }
  public static cancel(language: string): string {
    switch (language) {
      case 'de': return 'Abbrechen';
      case 'fr': return 'Annuler';
      case 'it': return 'Annula';
      case 'en': return 'Cancel';
      default: return 'Cancel';
    }
  }
  public get ccLabel(): string {
    return Labels.ccLabel(this.settings.language);
  }
  public static ccLabel(language: string): string {
    switch (language) {
      case 'de': return 'Kopie (CC)';
      case 'fr': return 'Copie à';
      case 'it': return 'Cc';
      case 'en': return 'Cc';
      default: return 'Cc';
    }
  }
  public get checkDigit(): string {
    return Labels.checkDigit(this.settings.language);
  }
  public static checkDigit(language: string): string {
    switch (language) {
      case 'de': return 'Prüfziffer';
      case 'fr': return 'Chiffre de contrôle';
      case 'it': return 'Cifra di controllo';
      case 'en': return 'Check digit';
      default: return 'Check digit';
    }
  }
  public get checkDigitCorrect(): string {
    return Labels.checkDigitCorrect(this.settings.language);
  }
  public static checkDigitCorrect(language: string): string {
    switch (language) {
      case 'de': return 'Prüfziffer ist korrekt';
      case 'fr': return 'Le chiffre de contrôle est correct';
      case 'it': return 'Cifra di controllo è corretta';
      case 'en': return 'Check digit is correct';
      default: return 'Check digit is correct';
    }
  }
  public get checkDigitWrong(): string {
    return Labels.checkDigitWrong(this.settings.language);
  }
  public static checkDigitWrong(language: string): string {
    switch (language) {
      case 'de': return 'Prüfziffer ist falsch';
      case 'fr': return 'Le chiffre de contrôle est incorrect';
      case 'it': return 'Cifra di controllo non è corretta';
      case 'en': return 'Check digit is wrong';
      default: return 'Check digit is wrong';
    }
  }
  public get clearForm(): string {
    return Labels.clearForm(this.settings.language);
  }
  public static clearForm(language: string): string {
    switch (language) {
      case 'de': return 'Formular leeren';
      case 'fr': return 'Vider le formulaire';
      case 'it': return 'Svuota la forma';
      case 'en': return 'Clear form';
      default: return 'Clear form';
    }
  }
  public get clearPdf(): string {
    return Labels.clearPdf(this.settings.language);
  }
  public static clearPdf(language: string): string {
    switch (language) {
      case 'de': return 'Input PDF leeren';
      case 'fr': return 'Vider le PDF saisi';
      case 'it': return 'Svuota il PDF di ingresso';
      case 'en': return 'Clear input PDF';
      default: return 'Clear input PDF';
    }
  }
  public get close(): string {
    return Labels.close(this.settings.language);
  }
  public static close(language: string): string {
    switch (language) {
      case 'de': return 'Schliessen';
      case 'fr': return 'Fermer';
      case 'it': return 'Chiudere';
      case 'en': return 'Close';
      default: return 'Close';
    }
  }
  public get combinedLabel(): string {
    return Labels.combinedLabel(this.settings.language);
  }
  public static combinedLabel(language: string): string {
    switch (language) {
      case 'de': return 'Kombiniert';
      case 'fr': return 'Combinée';
      case 'it': return 'Combinato';
      case 'en': return 'Combined';
      default: return 'Combined';
    }
  }
  public get components(): string {
    return Labels.components(this.settings.language);
  }
  public static components(language: string): string {
    switch (language) {
      case 'de': return 'Bausteine';
      case 'fr': return 'Composants';
      case 'it': return 'Componenti';
      case 'en': return 'Building Blocks';
      default: return 'Building Blocks';
    }
  }
  public get conditionDaysLabel(): string {
    return Labels.conditionDaysLabel(this.settings.language);
  }
  public static conditionDaysLabel(language: string): string {
    switch (language) {
      case 'de': return 'Tage';
      case 'fr': return 'Jours';
      case 'it': return 'Giorni';
      case 'en': return 'Days';
      default: return 'Days';
    }
  }
  public get conditionDiscountHint(): string {
    return Labels.conditionDiscountHint(this.settings.language);
  }
  public static conditionDiscountHint(language: string): string {
    switch (language) {
      case 'de': return '0 = zahlbar in';
      case 'fr': return '0 = payable en';
      case 'it': return '0 = pagabile in';
      case 'en': return '0 = payable in';
      default: return '0 = payable in';
    }
  }
  public get conditionDiscountLabel(): string {
    return Labels.conditionDiscountLabel(this.settings.language);
  }
  public static conditionDiscountLabel(language: string): string {
    switch (language) {
      case 'de': return 'Skonto';
      case 'fr': return 'Escompte';
      case 'it': return 'Sconto';
      case 'en': return 'Discount';
      default: return 'Discount';
    }
  }
  public get conditionsInfoToolTip(): string {
    return Labels.conditionsInfoToolTip(this.settings.language);
  }
  public static conditionsInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Konditionen oder Liste der Konditionen. Die Konditionen können ein Skonto oder eine Liste von Skonti auflisten. Das Belegdatum dient als Referenzdatum. Jeder Skonto ist durch einen Prozentsatz und eine Frist (Tage) definiert. Die Angabe mit einem Prozentsatz gleich Null definiert die defaultmässige Zahlungsfrist der Rechnung (z.B. «0:30» für 30 Tage Netto).';
      case 'fr': return 'Conditions ou liste des conditions. Les conditions peuvent spécifier un escompte ou une liste d\'escomptes. La date du document sert de date de référence. Chaque escompte est défini par un pourcentage et un délai (jours). La mention avec le taux d\'escompte nul définit le délai de paiement par défaut de la facture (p. ex. «0:30» pour 30 jours nets).';
      case 'it': return 'Condizioni o elenco di condizioni. I termini e le condizioni possono fare riferimento a uno sconto o a un elenco di sconti. La data del buono vale come data di riferimento. Ogni sconto è definito da una percentuale e da una scadenza (in giorni). L\'indicazione con una percentuale pari a zero definisce la data di scadenza della fattura (ad es. "0:30" per 30 giorni netti).';
      case 'en': return 'Conditions or list of conditions. The terms and conditions may refer to a discount or list of discounts. The voucher date counts as the reference date. Each discount is defined by a percentage and a deadline (in days). The indication with a percentage rate equal to zero defines the default payment date of the invoice (e.g. «0:30» for 30 days net).';
      default: return 'Conditions or list of conditions. The terms and conditions may refer to a discount or list of discounts. The voucher date counts as the reference date. Each discount is defined by a percentage and a deadline (in days). The indication with a percentage rate equal to zero defines the default payment date of the invoice (e.g. «0:30» for 30 days net).';
    }
  }
  public get conditionsLabel(): string {
    return Labels.conditionsLabel(this.settings.language);
  }
  public static conditionsLabel(language: string): string {
    switch (language) {
      case 'de': return 'Konditionen';
      case 'fr': return 'Conditions';
      case 'it': return 'Condizioni';
      case 'en': return 'Conditions';
      default: return 'Conditions';
    }
  }
  public get conditionsLinePayable(): string {
    return Labels.conditionsLinePayable(this.settings.language);
  }
  public static conditionsLinePayable(language: string): string {
    switch (language) {
      case 'de': return 'Zahlbar';
      case 'fr': return 'Payable';
      case 'it': return 'Pagabile';
      case 'en': return 'Payable';
      default: return 'Payable';
    }
  }
  public get conditionsLineWith(): string {
    return Labels.conditionsLineWith(this.settings.language);
  }
  public static conditionsLineWith(language: string): string {
    switch (language) {
      case 'de': return 'mit';
      case 'fr': return 'avec';
      case 'it': return 'con il';
      case 'en': return 'with';
      default: return 'with';
    }
  }
  public get conditionsLineDiscount(): string {
    return Labels.conditionsLineDiscount(this.settings.language);
  }
  public static conditionsLineDiscount(language: string): string {
    switch (language) {
      case 'de': return '% Skonto';
      case 'fr': return '% d\'escompte';
      case 'it': return '% di sconto';
      case 'en': return '% discount';
      default: return '% discount';
    }
  }
  public get conditionsLineWithin(): string {
    return Labels.conditionsLineWithin(this.settings.language);
  }
  public static conditionsLineWithin(language: string): string {
    switch (language) {
      case 'de': return 'innerhalb';
      case 'fr': return 'dans les';
      case 'it': return 'entro';
      case 'en': return 'within';
      default: return 'within';
    }
  }
  public get conditionsLineDays(): string {
    return Labels.conditionsLineDays(this.settings.language);
  }
  public static conditionsLineDays(language: string): string {
    switch (language) {
      case 'de': return 'Tagen';
      case 'fr': return 'jours';
      case 'it': return 'giorni';
      case 'en': return 'days';
      default: return 'days';
    }
  }
  public get conditionsLineUntil(): string {
    return Labels.conditionsLineUntil(this.settings.language);
  }
  public static conditionsLineUntil(language: string): string {
    switch (language) {
      case 'de': return 'bis';
      case 'fr': return 'jusqu\'au';
      case 'it': return 'fino al';
      case 'en': return 'until';
      default: return 'until';
    }
  }
  public get conditionsLineNet(): string {
    return Labels.conditionsLineNet(this.settings.language);
  }
  public static conditionsLineNet(language: string): string {
    switch (language) {
      case 'de': return 'netto';
      case 'fr': return 'net';
      case 'it': return 'netto';
      case 'en': return 'net';
      default: return 'net';
    }
  }
  public get content(): string {
    return Labels.content(this.settings.language);
  }
  public static content(language: string): string {
    switch (language) {
      case 'de': return 'Inhalt';
      case 'fr': return 'Contenu';
      case 'it': return 'Contenuto';
      case 'en': return 'Content';
      default: return 'Content';
    }
  }
  public get copyDataToClipboard(): string {
    return Labels.copyDataToClipboard(this.settings.language);
  }
  public static copyDataToClipboard(language: string): string {
    switch (language) {
      case 'de': return 'Daten in Zwischenablage kopieren';
      case 'fr': return 'Copier les données dans le presse papier';
      case 'it': return 'Copia i dati negli appunti';
      case 'en': return 'Copy data to clipboard';
      default: return 'Copy data to clipboard';
    }
  }
  public get copyToClipboard(): string {
    return Labels.copyToClipboard(this.settings.language);
  }
  public static copyToClipboard(language: string): string {
    switch (language) {
      case 'de': return 'In Zwischenablage kopieren';
      case 'fr': return 'Copier dans le presse papier';
      case 'it': return 'Copia negli appunti';
      case 'en': return 'Copy to clipboard';
      default: return 'Copy to clipboard';
    }
  }
  public get count(): string {
    return Labels.count(this.settings.language);
  }
  public static count(language: string): string {
    switch (language) {
      case 'de': return 'Anzahl';
      case 'fr': return 'Nombre';
      case 'it': return 'Numero';
      case 'en': return 'Count';
      default: return 'Count';
    }
  }
  public get countryLabel(): string {
    return Labels.countryLabel(this.settings.language);
  }
  public static countryLabel(language: string): string {
    switch (language) {
      case 'de': return 'Land';
      case 'fr': return 'Pays';
      case 'it': return 'Nazione';
      case 'en': return 'Country';
      default: return 'Country';
    }
  }
  public get create(): string {
    return Labels.create(this.settings.language);
  }
  public static create(language: string): string {
    switch (language) {
      case 'de': return 'Kreieren';
      case 'fr': return 'Créer';
      case 'it': return 'Creare';
      case 'en': return 'Create';
      default: return 'Create';
    }
  }
  public get createEmailsFromTable(): string {
    return Labels.createEmailsFromTable(this.settings.language);
  }
  public static createEmailsFromTable(language: string): string {
    switch (language) {
      case 'de': return 'Emails aus Tabelle erstellen';
      case 'fr': return 'Créer des e-mails à partir du tableau';
      case 'it': return 'Crea e-mail dalla tabella';
      case 'en': return 'Create emails from table';
      default: return 'Create emails from table';
    }
  }
  public get createEmailsSeries(): string {
    return Labels.createEmailsSeries(this.settings.language);
  }
  public static createEmailsSeries(language: string): string {
    switch (language) {
      case 'de': return 'Serie von Emails erstellen';
      case 'fr': return 'Créer série d\'e-mails';
      case 'it': return 'Crea serie di e-mail';
      case 'en': return 'Create series of emails';
      default: return 'Create series of emails';
    }
  }
  public get createFromTable(): string {
    return Labels.createFromTable(this.settings.language);
  }
  public static createFromTable(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungen aus Tabelle erstellen';
      case 'fr': return 'Créer des factures à partir du tableau';
      case 'it': return 'Crea fatture dalla tabella';
      case 'en': return 'Create invoices from table';
      default: return 'Create invoices from table';
    }
  }
  public get createPaymentPart(): string {
    return Labels.createPaymentPart(this.settings.language);
  }
  public static createPaymentPart(language: string): string {
    switch (language) {
      case 'de': return 'Zahlteil erstellen';
      case 'fr': return 'Créer une section paiement';
      case 'it': return 'Crea sezione di pagamento';
      case 'en': return 'Create payment part';
      default: return 'Create payment part';
    }
  }
  public get createSeries(): string {
    return Labels.createSeries(this.settings.language);
  }
  public static createSeries(language: string): string {
    switch (language) {
      case 'de': return 'Serie von Rechnungen erstellen';
      case 'fr': return 'Créer série de factures';
      case 'it': return 'Crea serie di fatture';
      case 'en': return 'Create series of invoices';
      default: return 'Create series of invoices';
    }
  }
  public get creditorAddress1InfoToolTip(): string {
    return Labels.creditorAddress1InfoToolTip(this.settings.language);
  }
  public static creditorAddress1InfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Adresszeile 1 mit Strasse und Hausnummer bzw. Postfach. Maximal 70 Zeichen zulässig.';
      case 'fr': return 'Ligne d\'adresse 1 avec rue et numéro de maison ou case postale. 70 caractères au maximum admis.';
      case 'it': return 'Indirizzo linea 1, comprensivo di via e numero civico dell\'edificio o casella postale dall\'indirizzo del creditore. Massimo 70 caratteri consentiti.';
      case 'en': return 'Address line 1 including street and building number or P.O. Box from creditor\'s address. Maximum 70 characters permitted.';
      default: return 'Address line 1 including street and building number or P.O. Box from creditor\'s address. Maximum 70 characters permitted.';
    }
  }
  public get creditorAddress2InfoToolTip(): string {
    return Labels.creditorAddress2InfoToolTip(this.settings.language);
  }
  public static creditorAddress2InfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Adresszeile 2 mit Postleitzahl und Ort der Zahlungsempfängeradresse. Maximal 70 Zeichen zulässig';
      case 'fr': return 'Ligne d\'adresse 2 avec numéro postal d\'acheminement et localité de l\'adresse du créancier. 70 caractères au maximum admis.';
      case 'it': return 'Indirizzo linea 2, compreso il codice postale e la città dall\'indirizzo del creditore. Massimo 70 caratteri consentiti.';
      case 'en': return 'Address line 2 including postal code and town from creditor\'s address. Maximum 70 characters permitted.';
      default: return 'Address line 2 including postal code and town from creditor\'s address. Maximum 70 characters permitted.';
    }
  }
  public get creditorBuildingNumberInfoToolTip(): string {
    return Labels.creditorBuildingNumberInfoToolTip(this.settings.language);
  }
  public static creditorBuildingNumberInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Hausnummer der Zahlungsempfängeradresse. Maximal 16 Zeichen zulässig.';
      case 'fr': return 'Numéro de maison de l\'adresse du créancier. 16 caractères au maximum admis.';
      case 'it': return 'Numero dell\'edificio dall\'indirizzo del creditore. 16 caratteri ammessi.';
      case 'en': return 'Building number from creditor\'s address. 16 characters allowed.';
      default: return 'Building number from creditor\'s address. 16 characters allowed.';
    }
  }
  public get creditorCountryInfoToolTip(): string {
    return Labels.creditorCountryInfoToolTip(this.settings.language);
  }
  public static creditorCountryInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Land der Zahlungsempfängeradresse.';
      case 'fr': return 'Pays de l\'adresse du créancier.';
      case 'it': return 'Paese dall\'indirizzo del creditore.';
      case 'en': return 'Country from creditor\'s address.';
      default: return 'Country from creditor\'s address.';
    }
  }
  public get creditorLabel(): string {
    return Labels.creditorLabel(this.settings.language);
  }
  public static creditorLabel(language: string): string {
    switch (language) {
      case 'de': return 'Zahlbar an';
      case 'fr': return 'Payable à';
      case 'it': return 'Pagabile a';
      case 'en': return 'Payable to';
      default: return 'Payable to';
    }
  }
  public get creditorLineLabel(): string {
    return Labels.creditorLineLabel(this.settings.language);
  }
  public static creditorLineLabel(language: string): string {
    switch (language) {
      case 'de': return 'Zahlbar an (Zeile)';
      case 'fr': return 'Payable à (Ligne)';
      case 'it': return 'Pagabile a (Linea)';
      case 'en': return 'Payable to (Line)';
      default: return 'Payable to (Line)';
    }
  }
  public get creditorNameInfoToolTip(): string {
    return Labels.creditorNameInfoToolTip(this.settings.language);
  }
  public static creditorNameInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Name bzw. Firma des Zahlungsempfängers gemäss Kontobezeichnung. Anmerkung: entspricht immer dem Kontoinhaber. Maximal 70 Zeichen zulässig. Vorname (optional, falls verfügbar) + Name oder Firmenbezeichnung. Lange Namen können mit einem Zeilenumbruch auf zwei Zeilen aufgeteilt werden.';
      case 'fr': return 'Nom ou entreprise du créancier selon la désignation de compte. Remarque: correspond toujours au titulaire du compte. 70 caractères au maximum admis. Prénom (optionnel, si disponible) + nom ou raison sociale. Les noms longs peuvent être divisés en deux lignes avec un retour à la ligne.';
      case 'it': return 'Il nome o la società del creditore secondo il nome del conto. Commento: corrisponde sempre al titolare del conto. Sono ammessi al massimo 70 caratteri. Nome (opzionale, se disponibile) + cognome o ragione sociale. I nomi lunghi possono essere divisi in due righe con un\'interruzione di riga.';
      case 'en': return 'The creditor\'s name or company according to the account name. Comment: always matches the account holder. Maximum 70 characters permitted. First name (optional, if available) + last name or company name. Long names can be split into two lines with a line break.';
      default: return 'The creditor\'s name or company according to the account name. Comment: always matches the account holder. Maximum 70 characters permitted. First name (optional, if available) + last name or company name. Long names can be split into two lines with a line break.';
    }
  }
  public get creditorPostalCodeInfoToolTip(): string {
    return Labels.creditorPostalCodeInfoToolTip(this.settings.language);
  }
  public static creditorPostalCodeInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Postleitzahl der Zahlungsempfängeradresse. Maximal 16 Zeichen zulässig. Die Postleitzahl ist immer ohne vorangestellten Landescode anzugeben.';
      case 'fr': return 'Numéro postal d\'acheminement de l\'adresse du créancier. 16 caractères au maximum admis. Le code postal est toujours à indiquer sans code de pays de tête.';
      case 'it': return 'Codice postale dall\'indirizzo del creditore. Massimo 16 caratteri ammessi. Il codice postale deve essere fornito senza prefisso del paese.';
      case 'en': return 'Postal code from creditor\'s address. Maximum 16 characters permitted. The postal code must be provided without a country code prefix.';
      default: return 'Postal code from creditor\'s address. Maximum 16 characters permitted. The postal code must be provided without a country code prefix.';
    }
  }
  public get creditorStreetNameInfoToolTip(): string {
    return Labels.creditorStreetNameInfoToolTip(this.settings.language);
  }
  public static creditorStreetNameInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Strasse/Postfach der Zahlungsempfängeradresse. Maximal 70 Zeichen zulässig.';
      case 'fr': return 'Rue/case postale de l\'adresse du créancier. 70 caractères au maximum admis.';
      case 'it': return 'Via/Casella postale dall\'indirizzo del creditore. Massimo 70 caratteri consentiti.';
      case 'en': return 'Street/P.O. Box from creditor\'s address. Maximum 70 characters permitted.';
      default: return 'Street/P.O. Box from creditor\'s address. Maximum 70 characters permitted.';
    }
  }
  public get creditorTownInfoToolTip(): string {
    return Labels.creditorTownInfoToolTip(this.settings.language);
  }
  public static creditorTownInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Ort der Zahlungsempfängeradresse. Maximal 35 Zeichen zulässig.';
      case 'fr': return 'Lieu de l\'adresse du créancier. 35 caractères au maximum admis.';
      case 'it': return 'Città dall\'indirizzo del creditore. Massimo 35 caratteri ammessi.';
      case 'en': return 'Town from creditor\'s address. Maximum 35 characters permitted.';
      default: return 'Town from creditor\'s address. Maximum 35 characters permitted.';
    }
  }
  public get cropMarks(): string {
    return Labels.cropMarks(this.settings.language);
  }
  public static cropMarks(language: string): string {
    switch (language) {
      case 'de': return 'Schnittmarken';
      case 'fr': return 'Marques de coupe';
      case 'it': return 'Segni di taglio';
      case 'en': return 'Crop marks';
      default: return 'Crop marks';
    }
  }
  public get currencyCHF(): string {
    return Labels.currencyCHF(this.settings.language);
  }
  public static currencyCHF(language: string): string {
    switch (language) {
      case 'de': return 'Schweizer Franken (CHF)';
      case 'fr': return 'Franc suisse (CHF)';
      case 'it': return 'Franco svizzero (CHF)';
      case 'en': return 'Swiss Franc (CHF)';
      default: return 'Swiss Franc (CHF)';
    }
  }
  public get currencyCHW(): string {
    return Labels.currencyCHW(this.settings.language);
  }
  public static currencyCHW(language: string): string {
    switch (language) {
      case 'de': return 'WIR (CHW)';
      case 'fr': return 'WIR (CHW)';
      case 'it': return 'WIR (CHW)';
      case 'en': return 'WIR (CHW)';
      default: return 'WIR (CHW)';
    }
  }
  public get currencyEUR(): string {
    return Labels.currencyEUR(this.settings.language);
  }
  public static currencyEUR(language: string): string {
    switch (language) {
      case 'de': return 'Euro (EUR)';
      case 'fr': return 'Euro (EUR)';
      case 'it': return 'Euro (EUR)';
      case 'en': return 'Euro (EUR)';
      default: return 'Euro (EUR)';
    }
  }
  public get currencyInfoToolTip(): string {
    return Labels.currencyInfoToolTip(this.settings.language);
  }
  public static currencyInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Währung der Zahlung. Nur CHF und EUR zugelassen. (Innerhalb des WIR-Netzwerks ist auch CHW zugelassen.)';
      case 'fr': return 'Monnaie du paiement. Seuls CHF et EUR sont admis. (Au sein du réseau WIR, CHW est également autorisé.)';
      case 'it': return 'La valuta di pagamento. Sono ammessi solo CHF e EUR. (All\'interno della rete WIR, è ammesso anche il CHW.)';
      case 'en': return 'The payment currency. Only CHF and EUR are permitted. (Within the WIR network, CHW is also permitted.)';
      default: return 'The payment currency. Only CHF and EUR are permitted. (Within the WIR network, CHW is also permitted.)';
    }
  }
  public get currencyLabel(): string {
    return Labels.currencyLabel(this.settings.language);
  }
  public static currencyLabel(language: string): string {
    switch (language) {
      case 'de': return 'Währung';
      case 'fr': return 'Monnaie';
      case 'it': return 'Valuta';
      case 'en': return 'Currency';
      default: return 'Currency';
    }
  }
  public get currentDate(): string {
    return Labels.currentDate(this.settings.language);
  }
  public static currentDate(language: string): string {
    switch (language) {
      case 'de': return 'Heutiges Datum';
      case 'fr': return 'Date d\'aujourd\'hui';
      case 'it': return 'La data di oggi';
      case 'en': return 'Today\'s date';
      default: return 'Today\'s date';
    }
  }
  public get customerReferenceInfoToolTip(): string {
    return Labels.customerReferenceInfoToolTip(this.settings.language);
  }
  public static customerReferenceInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Kundenreferenz. Freier Text. Die Kundenreferenz ist eine vom Kunden mitgeteilte Referenz und dient der Zuordnung der Rechnung.';
      case 'fr': return 'Référence client. Texte libre. La référence du client est une référence fournie par le client et sert à l\'affectation de la facture.';
      case 'it': return 'Riferimento cliente. Testo libero. Il riferimento cliente è un riferimento inviato dal cliente e serve per identificare la fattura.';
      case 'en': return 'Customer reference. Free text. The customer reference is a reference sent by the customer and is used to identify the bill.';
      default: return 'Customer reference. Free text. The customer reference is a reference sent by the customer and is used to identify the bill.';
    }
  }
  public get customerReferenceLabel(): string {
    return Labels.customerReferenceLabel(this.settings.language);
  }
  public static customerReferenceLabel(language: string): string {
    switch (language) {
      case 'de': return 'Kundenreferenz';
      case 'fr': return 'Référence client';
      case 'it': return 'Riferimento del cliente';
      case 'en': return 'Customer reference';
      default: return 'Customer reference';
    }
  }
  public get customerReferenceLineLabel(): string {
    return Labels.customerReferenceLineLabel(this.settings.language);
  }
  public static customerReferenceLineLabel(language: string): string {
    switch (language) {
      case 'de': return 'Kundenreferenz (Zeile)';
      case 'fr': return 'Référence client (Ligne)';
      case 'it': return 'Riferimento del cliente (Linea)';
      case 'en': return 'Customer reference (Line)';
      default: return 'Customer reference (Line)';
    }
  }
  public get cutFormatsInfoToolTip(): string {
    return Labels.cutFormatsInfoToolTip(this.settings.language);
  }
  public static cutFormatsInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Ist der Zahlteil mit Empfangsschein in einer QR-Rechnung in Papierform integriert, ist eine Perforation zwischen den Angaben zur Rechnung und des Zahlteils mit Empfangsschein obligatorisch. Zwischen dem Zahlteil und dem Empfangsschein ist eine Perforation vorgegeben, falls die QR-Rechnung in Papierform erstellt wird. Wird die QR-Rechnung mit Zahlteil und Empfangsschein bzw. der Zahlteil mit Empfangsschein separat als PDF-Dokument erstellt und elektronisch versendet, muss das Format A6 des Zahlteils und des links angebrachten Empfangsscheins durch Linien gekennzeichnet werden.';
      case 'fr': return 'Si la section paiement avec récépissé est intégré dans une QR-facture sous forme papier, une perforation est obligatoire entre les indications concernant la facture et les indications de la section paiement avec récépissé. Une perforation est prédéfinie entre la section paiement et le récépissé, si la QR-facture est créée sous forme papier. Si la QR-facture avec section paiement et récépissé ou la section paiement avec récépissé séparé, est créée sous forme de document PDF et envoyée électroniquement, le format A6 de la section paiement et du récépissé placé à gauche doit être repéré par des lignes.';
      case 'it': return 'Se la parte di pagamento con ricevuta è integrata in una QR-fattura in forma cartacea, deve esserci una perforazione tra i dettagli della fattura e la parte di pagamento e la ricevuta. Se la QR-fattura viene generata su carta, deve esserci una perforazione tra la parte del pagamento e la ricevuta. Se la QR-fattura con parte di pagamento e ricevuta o la parte di pagamento separata con ricevuta sono generate come documento PDF e inviate elettronicamente, il formato A6 della parte di pagamento e la ricevuta a sinistra devono essere indicati con delle righe.';
      case 'en': return 'If the payment part with receipt is integrated in a QR-bill in paper form, there must be a perforation between the bill details and the payment part and receipt. There should be a perforation between the payment part and the receipt, if the QR-bill is generated on paper. If the QR-bill with payment part and receipt or the separate payment part with receipt are generated as a PDF document and sent electronically, the A6 format of the payment part and the receipt on the left must be indicated by lines.';
      default: return 'If the payment part with receipt is integrated in a QR-bill in paper form, there must be a perforation between the bill details and the payment part and receipt. There should be a perforation between the payment part and the receipt, if the QR-bill is generated on paper. If the QR-bill with payment part and receipt or the separate payment part with receipt are generated as a PDF document and sent electronically, the A6 format of the payment part and the receipt on the left must be indicated by lines.';
    }
  }
  public get cutLineText(): string {
    return Labels.cutLineText(this.settings.language);
  }
  public static cutLineText(language: string): string {
    switch (language) {
      case 'de': return '▼▼▼  Vor der Einzahlung abzutrennen  ▼▼▼';
      case 'fr': return '▼▼▼  A détacher avant le versement  ▼▼▼';
      case 'it': return '▼▼▼  Da staccare prima del versamento  ▼▼▼';
      case 'en': return '▼▼▼  Separate before paying in  ▼▼▼';
      default: return '▼▼▼  Separate before paying in  ▼▼▼';
    }
  }
  public get cutLineTextUp(): string {
    return Labels.cutLineTextUp(this.settings.language);
  }
  public static cutLineTextUp(language: string): string {
    switch (language) {
      case 'de': return '▲▲▲  Vor der Einzahlung abzutrennen  ▲▲▲';
      case 'fr': return '▲▲▲  A détacher avant le versement  ▲▲▲';
      case 'it': return '▲▲▲  Da staccare prima del versamento  ▲▲▲';
      case 'en': return '▲▲▲  Separate before paying in  ▲▲▲';
      default: return '▲▲▲  Separate before paying in  ▲▲▲';
    }
  }
  public get cutLineTextMultiLanguage(): string {
    return Labels.cutLineTextMultiLanguage(this.settings.language);
  }
  public static cutLineTextMultiLanguage(language: string): string {
    switch (language) {
      case 'de': return '▼▼▼  Vor der Einzahlung abzutrennen / A détacher avant le versement / Da staccare prima del versamento  ▼▼▼';
      case 'fr': return '▼▼▼  Vor der Einzahlung abzutrennen / A détacher avant le versement / Da staccare prima del versamento  ▼▼▼';
      case 'it': return '▼▼▼  Vor der Einzahlung abzutrennen / A détacher avant le versement / Da staccare prima del versamento  ▼▼▼';
      case 'en': return '▼▼▼  Vor der Einzahlung abzutrennen / A détacher avant le versement / Da staccare prima del versamento  ▼▼▼';
      default: return '▼▼▼  Vor der Einzahlung abzutrennen / A détacher avant le versement / Da staccare prima del versamento  ▼▼▼';
    }
  }
  public get dataset(): string {
    return Labels.dataset(this.settings.language);
  }
  public static dataset(language: string): string {
    switch (language) {
      case 'de': return 'Datensatz';
      case 'fr': return 'Ensemble de données';
      case 'it': return 'Set di dati';
      case 'en': return 'Data set';
      default: return 'Data set';
    }
  }
  public get debtorLabel(): string {
    return Labels.debtorLabel(this.settings.language);
  }
  public static debtorLabel(language: string): string {
    switch (language) {
      case 'de': return 'Zahlbar durch';
      case 'fr': return 'Payable par';
      case 'it': return 'Pagabile da';
      case 'en': return 'Payable by';
      default: return 'Payable by';
    }
  }
  public get deleteLabel(): string {
    return Labels.deleteLabel(this.settings.language);
  }
  public static deleteLabel(language: string): string {
    switch (language) {
      case 'de': return 'Entfernen';
      case 'fr': return 'Enlever';
      case 'it': return 'Rimuovere';
      case 'en': return 'Remove';
      default: return 'Remove';
    }
  }
  public get deleteAll(): string {
    return Labels.deleteAll(this.settings.language);
  }
  public static deleteAll(language: string): string {
    switch (language) {
      case 'de': return 'Alles entfernen';
      case 'fr': return 'Enlever tout';
      case 'it': return 'Rimuovi tutto';
      case 'en': return 'Remove all';
      default: return 'Remove all';
    }
  }
  public get displayStructuredBookingInfo(): string {
    return Labels.displayStructuredBookingInfo(this.settings.language);
  }
  public static displayStructuredBookingInfo(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungsinformationen (Swico) auf Zahlteil drucken';
      case 'fr': return 'Imprimer les informations de facture (Swico) sur la section paiement';
      case 'it': return 'Stampare le informazioni di fatturazione (Swico) sulla sezione pagamento';
      case 'en': return 'Print billing information (Swico) on payment part';
      default: return 'Print billing information (Swico) on payment part';
    }
  }
  public get downloadFile(): string {
    return Labels.downloadFile(this.settings.language);
  }
  public static downloadFile(language: string): string {
    switch (language) {
      case 'de': return 'Datei herunterladen';
      case 'fr': return 'Télécharger fichier';
      case 'it': return 'Scarica file';
      case 'en': return 'Download file';
      default: return 'Download file';
    }
  }
  public get downloadMultiple(): string {
    return Labels.downloadMultiple(this.settings.language);
  }
  public static downloadMultiple(language: string): string {
    switch (language) {
      case 'de': return 'Mehrere Rechnungen herunterladen';
      case 'fr': return 'Télécharger plusieurs factures';
      case 'it': return 'Scaricare più fatture';
      case 'en': return 'Download multiple invoices';
      default: return 'Download multiple invoices';
    }
  }
  public get downloadMultipleBundle(): string {
    return Labels.downloadMultipleBundle(this.settings.language, this.settings.pdfBundleSize);
  }
  public static downloadMultipleBundle(language: string, pdfBundleSize: number): string {
    switch (language) {
      case 'de': return `Gebündelte PDF-Dateien (${pdfBundleSize} Rechnungen) in einem Ordner generieren, zum Beispiel für den Ausdruck auf Papier. Die Bündelgrösse kann in den Einstellungen konfiguriert werden.\nBesser geeignet für sehr viele Rechnungen.`;
      case 'fr': return `Générer des fichiers PDF groupés (${pdfBundleSize} factures) dans un dossier, par exemple pour l'impression sur papier. La taille de la liasse peut être configurée dans les paramètres.\nMieux adapté aux très nombreuses factures.`;
      case 'it': return `Generare file PDF in bundle (${pdfBundleSize} fatture) in una cartella, ad esempio per la stampa su carta. La dimensione del bundle può essere configurata nelle impostazioni.\nPiù adatto per un gran numero di fatture.`;
      case 'en': return `Generate bundled PDF files (${pdfBundleSize} invoices) in a folder, for example for printing on paper. The bundle size can be configured in the settings.\nBetter suited for a large number of invoices.`;
      default: return `Generate bundled PDF files (${pdfBundleSize} invoices) in a folder, for example for printing on paper. The bundle size can be configured in the settings.\nBetter suited for a large number of invoices.`;
    }
  }
  public get downloadMultipleFolder(): string {
    return Labels.downloadMultipleFolder(this.settings.language);
  }
  public static downloadMultipleFolder(language: string): string {
    switch (language) {
      case 'de': return 'Einzelne PDF-Dateien in einem Ordner generieren, zum Beispiel für den elektronischen Versand.\nBesser geeignet für sehr viele Rechnungen.';
      case 'fr': return 'Générer des fichiers PDF individuels dans un dossier, par exemple pour la livraison électronique.\nMieux adapté aux très nombreuses factures.';
      case 'it': return 'Generare singoli file PDF in una cartella, ad esempio per la consegna elettronica.\nPiù adatto per un gran numero di fatture.';
      case 'en': return 'Generate individual PDF files in a folder, for example for electronic delivery.\nBetter suited for a large number of invoices.';
      default: return 'Generate individual PDF files in a folder, for example for electronic delivery.\nBetter suited for a large number of invoices.';
    }
  }
  public get downloadMultiplePDF(): string {
    return Labels.downloadMultiplePDF(this.settings.language);
  }
  public static downloadMultiplePDF(language: string): string {
    switch (language) {
      case 'de': return 'Mehrere Rechnungen in einem PDF herunterladen (zum Beispiel für den Ausdruck auf Papier)';
      case 'fr': return 'Télécharger plusieurs factures en un seul PDF (par exemple pour l\'impression sur papier)';
      case 'it': return 'Scaricare più fatture in un unico PDF (ad esempio per la stampa su carta)';
      case 'en': return 'Download multiple invoices in one PDF (for example for printing on paper)';
      default: return 'Download multiple invoices in one PDF (for example for printing on paper)';
    }
  }
  public get downloadMultipleZIP(): string {
    return Labels.downloadMultipleZIP(this.settings.language);
  }
  public static downloadMultipleZIP(language: string): string {
    switch (language) {
      case 'de': return 'Mehrere PDF-Rechnungen in einem ZIP-Archiv herunterladen (zum Beispiel für den elektronischen Versand)';
      case 'fr': return 'Télécharger plusieurs factures au format PDF dans une archive ZIP (par exemple pour la livraison électronique)';
      case 'it': return 'Scaricare più fatture PDF in un archivio ZIP (ad esempio per la consegna elettronica)';
      case 'en': return 'Download multiple PDF invoices in a ZIP archive (for example for electronic delivery)';
      default: return 'Download multiple PDF invoices in a ZIP archive (for example for electronic delivery)';
    }
  }
  public get downloadPDF(): string {
    return Labels.downloadPDF(this.settings.language);
  }
  public static downloadPDF(language: string): string {
    switch (language) {
      case 'de': return 'PDF herunterladen';
      case 'fr': return 'Télécharger PDF';
      case 'it': return 'Scarica PDF';
      case 'en': return 'Download PDF';
      default: return 'Download PDF';
    }
  }
  public get downloadQrBill(): string {
    return Labels.downloadQrBill(this.settings.language);
  }
  public static downloadQrBill(language: string): string {
    switch (language) {
      case 'de': return 'QR-Rechnung herunterladen';
      case 'fr': return 'Télécharger la QR-facture';
      case 'it': return 'Scarica il QR-fattura';
      case 'en': return 'Download the QR-bill';
      default: return 'Download the QR-bill';
    }
  }
  public get downloadSVG(): string {
    return Labels.downloadSVG(this.settings.language);
  }
  public static downloadSVG(language: string): string {
    switch (language) {
      case 'de': return 'SVG herunterladen';
      case 'fr': return 'Télécharger SVG';
      case 'it': return 'Scarica SVG';
      case 'en': return 'Download SVG';
      default: return 'Download SVG';
    }
  }
  public get downloadTableCSV(): string {
    return Labels.downloadTableCSV(this.settings.language);
  }
  public static downloadTableCSV(language: string): string {
    switch (language) {
      case 'de': return 'Tabelle mit kommagetrennten Werten herunterladen';
      case 'fr': return 'Télécharger tableau des valeurs séparées par des virgules';
      case 'it': return 'Scarica foglio dei valori separati da virgole';
      case 'en': return 'Download comma-separated values spreadsheet';
      default: return 'Download comma-separated values spreadsheet';
    }
  }
  public get downloadTableFODS(): string {
    return Labels.downloadTableFODS(this.settings.language);
  }
  public static downloadTableFODS(language: string): string {
    switch (language) {
      case 'de': return 'OpenDocument (Flat XML) Tabelle herunterladen';
      case 'fr': return 'Télécharger tableau OpenDocument (Flat XML)';
      case 'it': return 'Scarica foglio OpenDocument (Flat XML)';
      case 'en': return 'Download OpenDocument (Flat XML) spreadsheet';
      default: return 'Download OpenDocument (Flat XML) spreadsheet';
    }
  }
  public get downloadTableODS(): string {
    return Labels.downloadTableODS(this.settings.language);
  }
  public static downloadTableODS(language: string): string {
    switch (language) {
      case 'de': return 'OpenDocument Tabelle herunterladen';
      case 'fr': return 'Télécharger tableau OpenDocument';
      case 'it': return 'Scarica foglio OpenDocument';
      case 'en': return 'Download OpenDocument spreadsheet';
      default: return 'Download OpenDocument spreadsheet';
    }
  }
  public get downloadTableTSV(): string {
    return Labels.downloadTableTSV(this.settings.language);
  }
  public static downloadTableTSV(language: string): string {
    switch (language) {
      case 'de': return 'Tabelle mit tabulatorgetrennten Werten herunterladen';
      case 'fr': return 'Télécharger tableau des valeurs séparées par des tabulations';
      case 'it': return 'Scarica foglio dei valori separati da tabulazione';
      case 'en': return 'Download tab-separated values spreadsheet';
      default: return 'Download tab-separated values spreadsheet';
    }
  }
  public get downloadTableXLSX(): string {
    return Labels.downloadTableXLSX(this.settings.language);
  }
  public static downloadTableXLSX(language: string): string {
    switch (language) {
      case 'de': return 'Excel Tabelle herunterladen';
      case 'fr': return 'Télécharger tableau Excel';
      case 'it': return 'Scarica foglio Excel';
      case 'en': return 'Download Excel spreadsheet';
      default: return 'Download Excel spreadsheet';
    }
  }
  public get downloadTableXLS(): string {
    return Labels.downloadTableXLS(this.settings.language);
  }
  public static downloadTableXLS(language: string): string {
    switch (language) {
      case 'de': return 'Excel (97-2003) Tabelle herunterladen';
      case 'fr': return 'Télécharger tableau Excel (97-2003)';
      case 'it': return 'Scarica foglio Excel (97-2003)';
      case 'en': return 'Download Excel (97-2003) spreadsheet';
      default: return 'Download Excel (97-2003) spreadsheet';
    }
  }
  public get downloadVideo(): string {
    return Labels.downloadVideo(this.settings.language);
  }
  public static downloadVideo(language: string): string {
    switch (language) {
      case 'de': return 'Video herunterladen';
      case 'fr': return 'Télécharger vidéo';
      case 'it': return 'Scarica video';
      case 'en': return 'Download video';
      default: return 'Download video';
    }
  }
  public get dropHerePdf(): string {
    return Labels.dropHerePdf(this.settings.language);
  }
  public static dropHerePdf(language: string): string {
    switch (language) {
      case 'de': return 'Ziehen Sie eine PDF-Datei hierhin';
      case 'fr': return 'Déposer un fichier PDF ici';
      case 'it': return 'Scaricate qui un file PDF';
      case 'en': return 'Drop a PDF file here';
      default: return 'Drop a PDF file here';
    }
  }
  public get dropHere(): string {
    return Labels.dropHere(this.settings.language);
  }
  public static dropHere(language: string): string {
    switch (language) {
      case 'de': return 'Ziehen Sie eine Datei hierhin (PDF, Bild)';
      case 'fr': return 'Déposer un fichier ici (PDF, image)';
      case 'it': return 'Rilasciare un file qui (PDF, immagine)';
      case 'en': return 'Drop a file here (PDF, image)';
      default: return 'Drop a file here (PDF, image)';
    }
  }
  public get eachPage(): string {
    return Labels.eachPage(this.settings.language);
  }
  public static eachPage(language: string): string {
    switch (language) {
      case 'de': return 'Jede Seite';
      case 'fr': return 'Chaque page';
      case 'it': return 'Ogni pagina';
      case 'en': return 'Each page';
      default: return 'Each page';
    }
  }
  public get ebillBusinessCaseDate(): string {
    return Labels.ebillBusinessCaseDate(this.settings.language);
  }
  public static ebillBusinessCaseDate(language: string): string {
    switch (language) {
      case 'de': return 'Datum des Geschäftsfalls';
      case 'fr': return 'Date de la transaction commerciale';
      case 'it': return 'Data della transazione commerciale';
      case 'en': return 'Business case date';
      default: return 'Business case date';
    }
  }
  public get ebillDueInDays(): string {
    return Labels.ebillDueInDays(this.settings.language);
  }
  public static ebillDueInDays(language: string): string {
    switch (language) {
      case 'de': return 'Zahlbar in';
      case 'fr': return 'Payable en';
      case 'it': return 'Pagabile in';
      case 'en': return 'Payable in';
      default: return 'Payable in';
    }
  }
  public get ebillEmailOrRecipientID(): string {
    return Labels.ebillEmailOrRecipientID(this.settings.language);
  }
  public static ebillEmailOrRecipientID(language: string): string {
    switch (language) {
      case 'de': return 'Email oder Rechnungsempfänger ID';
      case 'fr': return 'E-mail ou ID du destinataire de la facture';
      case 'it': return 'E-mail o ID destinatario fattura';
      case 'en': return 'Email or bill recipient ID';
      default: return 'Email or bill recipient ID';
    }
  }
  public get ebillReferencedBill(): string {
    return Labels.ebillReferencedBill(this.settings.language);
  }
  public static ebillReferencedBill(language: string): string {
    switch (language) {
      case 'de': return 'Referenzierte Rechnung';
      case 'fr': return 'Facture référencée';
      case 'it': return 'Fattura di riferimento';
      case 'en': return 'Referenced bill';
      default: return 'Referenced bill';
    }
  }
  public get ebillReferenceNumber(): string {
    return Labels.ebillReferenceNumber(this.settings.language);
  }
  public static ebillReferenceNumber(language: string): string {
    switch (language) {
      case 'de': return 'Referenznummer';
      case 'fr': return 'Numéro de référence';
      case 'it': return 'Numero di riferimento';
      case 'en': return 'Reference number';
      default: return 'Reference number';
    }
  }
  public get ebillType(): string {
    return Labels.ebillType(this.settings.language);
  }
  public static ebillType(language: string): string {
    switch (language) {
      case 'de': return 'Typ';
      case 'fr': return 'Type';
      case 'it': return 'Tipo';
      case 'en': return 'Type';
      default: return 'Type';
    }
  }
  public get ebillTypeBill(): string {
    return Labels.ebillTypeBill(this.settings.language);
  }
  public static ebillTypeBill(language: string): string {
    switch (language) {
      case 'de': return 'Rechnung';
      case 'fr': return 'Facture';
      case 'it': return 'Fattura';
      case 'en': return 'Bill';
      default: return 'Bill';
    }
  }
  public get ebillTypeReminder(): string {
    return Labels.ebillTypeReminder(this.settings.language);
  }
  public static ebillTypeReminder(language: string): string {
    switch (language) {
      case 'de': return 'Mahnung';
      case 'fr': return 'Rappel';
      case 'it': return 'Sollecito';
      case 'en': return 'Reminder';
      default: return 'Reminder';
    }
  }
  public get ebillTypeRollover(): string {
    return Labels.ebillTypeRollover(this.settings.language);
  }
  public static ebillTypeRollover(language: string): string {
    switch (language) {
      case 'de': return 'Überrollende Rechnung';
      case 'fr': return 'Facture rectificative';
      case 'it': return 'Fattura di rinnovo';
      case 'en': return 'Rolled-over bill';
      default: return 'Rolled-over bill';
    }
  }
  public get editInvoiceTemplate(): string {
    return Labels.editInvoiceTemplate(this.settings.language);
  }
  public static editInvoiceTemplate(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungsvorlage bearbeiten';
      case 'fr': return 'Modifier modèle de facture';
      case 'it': return 'Modifica modello di fattura';
      case 'en': return 'Edit invoice template';
      default: return 'Edit invoice template';
    }
  }
  public get editorTitle(): string {
    return Labels.editorTitle(this.settings.language);
  }
  public static editorTitle(language: string): string {
    switch (language) {
      case 'de': return 'QR-Rechnung schreiben';
      case 'fr': return 'Ecrire QR-facture';
      case 'it': return 'Scrivi QR-fattura';
      case 'en': return 'Write QR-bill';
      default: return 'Write QR-bill';
    }
  }
  public get editPaymentPart(): string {
    return Labels.editPaymentPart(this.settings.language);
  }
  public static editPaymentPart(language: string): string {
    switch (language) {
      case 'de': return 'Zahlteil bearbeiten';
      case 'fr': return 'Modifier section paiement';
      case 'it': return 'Modifica sezione pagamento';
      case 'en': return 'Edit payment part';
      default: return 'Edit payment part';
    }
  }
  public get editReference(): string {
    return Labels.editReference(this.settings.language);
  }
  public static editReference(language: string): string {
    switch (language) {
      case 'de': return 'Referenz bearbeiten (Prüfziffer berechnen)';
      case 'fr': return 'Modifier la référence (calculer les chiffres de contrôle)';
      case 'it': return 'Modifica riferimento (calcolare le cifre di controllo)';
      case 'en': return 'Edit reference (calculate check digits)';
      default: return 'Edit reference (calculate check digits)';
    }
  }
  public get editTable(): string {
    return Labels.editTable(this.settings.language);
  }
  public static editTable(language: string): string {
    switch (language) {
      case 'de': return 'Tabelle bearbeiten';
      case 'fr': return 'Modifier le tableau';
      case 'it': return 'Modifica tabella';
      case 'en': return 'Edit table';
      default: return 'Edit table';
    }
  }
  public get email(): string {
    return Labels.email(this.settings.language);
  }
  public static email(language: string): string {
    switch (language) {
      case 'de': return 'Email';
      case 'fr': return 'Email';
      case 'it': return 'Email';
      case 'en': return 'Email';
      default: return 'Email';
    }
  }
  public get emailOptional(): string {
    return Labels.emailOptional(this.settings.language);
  }
  public static emailOptional(language: string): string {
    switch (language) {
      case 'de': return 'Email (optional, notwendig für eine Antwort)';
      case 'fr': return 'Email (optionnel, nécessaire pour une réponse)';
      case 'it': return 'Email (opzionale, necessario per una risposta)';
      case 'en': return 'Email (optional, necessary for an answer)';
      default: return 'Email (optional, necessary for an answer)';
    }
  }
  public get emailTemplate(): string {
    return Labels.emailTemplate(this.settings.language);
  }
  public static emailTemplate(language: string): string {
    switch (language) {
      case 'de': return 'Emailvorlage';
      case 'fr': return 'Modèle d\'email';
      case 'it': return 'Modello di email';
      case 'en': return 'Email template';
      default: return 'Email template';
    }
  }
  public get empty(): string {
    return Labels.empty(this.settings.language);
  }
  public static empty(language: string): string {
    switch (language) {
      case 'de': return 'Leer';
      case 'fr': return 'Vide';
      case 'it': return 'Vuoto';
      case 'en': return 'Empty';
      default: return 'Empty';
    }
  }
  public get emptyListHint(): string {
    return Labels.emptyListHint(this.settings.language);
  }
  public static emptyListHint(language: string): string {
    switch (language) {
      case 'de': return '(Keine Einträge)';
      case 'fr': return '(Aucune entrée)';
      case 'it': return '(Nessuna voce)';
      case 'en': return '(No entries)';
      default: return '(No entries)';
    }
  }
  public get endNoEmptyLinesLabel(): string {
    return Labels.endNoEmptyLinesLabel(this.settings.language);
  }
  public static endNoEmptyLinesLabel(language: string): string {
    switch (language) {
      case 'de': return 'Ende Keine Leeren Zeilen';
      case 'fr': return 'Fin Pas de lignes vides';
      case 'it': return 'Fine Nessuna riga vuota';
      case 'en': return 'End No Empty Lines';
      default: return 'End No Empty Lines';
    }
  }
  public get epcQrCode(): string {
    return Labels.epcQrCode(this.settings.language);
  }
  public static epcQrCode(language: string): string {
    switch (language) {
      case 'de': return 'EPC QR Code';
      case 'fr': return 'Code QR EPC';
      case 'it': return 'Codice QR EPC';
      case 'en': return 'EPC QR code';
      default: return 'EPC QR code';
    }
  }
  public get epcQrCodeTooltip(): string {
    return Labels.epcQrCodeTooltip(this.settings.language);
  }
  public static epcQrCodeTooltip(language: string): string {
    switch (language) {
      case 'de': return 'EPC QR Codes sind nur für die Währung Euro verfügbar';
      case 'fr': return 'Les codes QR EPC ne sont disponibles que pour l\'euro.';
      case 'it': return 'I codici QR EPC sono disponibili solo per la valuta euro';
      case 'en': return 'EPC QR codes are only available for the Euro currency';
      default: return 'EPC QR codes are only available for the Euro currency';
    }
  }
  public get example(): string {
    return Labels.example(this.settings.language);
  }
  public static example(language: string): string {
    switch (language) {
      case 'de': return 'Beispiel';
      case 'fr': return 'Exemple';
      case 'it': return 'Esempio';
      case 'en': return 'Example';
      default: return 'Example';
    }
  }
  public get export(): string {
    return Labels.export(this.settings.language);
  }
  public static export(language: string): string {
    switch (language) {
      case 'de': return 'Export';
      case 'fr': return 'Exporter';
      case 'it': return 'Esportare';
      case 'en': return 'Export';
      default: return 'Export';
    }
  }
  public get faq(): string {
    return Labels.faq(this.settings.language);
  }
  public static faq(language: string): string {
    switch (language) {
      case 'de': return 'Häufig gestellte Fragen';
      case 'fr': return 'Foire aux questions';
      case 'it': return 'Domande frequenti';
      case 'en': return 'Frequently asked questions';
      default: return 'Frequently asked questions';
    }
  }
  public get feedback(): string {
    return Labels.feedback(this.settings.language);
  }
  public static feedback(language: string): string {
    switch (language) {
      case 'de': return 'Feedback';
      case 'fr': return 'Feedback';
      case 'it': return 'Feedback';
      case 'en': return 'Feedback';
      default: return 'Feedback';
    }
  }
  public get feedbackCouldNotBeSent(): string {
    return Labels.feedbackCouldNotBeSent(this.settings.language);
  }
  public static feedbackCouldNotBeSent(language: string): string {
    switch (language) {
      case 'de': return 'Feedback konnte nicht gesendet werden.';
      case 'fr': return 'Le feedback n\'a pas pu être envoyé.';
      case 'it': return 'Il feedback non può essere inviato.';
      case 'en': return 'Feedback could not be sent.';
      default: return 'Feedback could not be sent.';
    }
  }
  public get feedbackSubtitle(): string {
    return Labels.feedbackSubtitle(this.settings.language);
  }
  public static feedbackSubtitle(language: string): string {
    switch (language) {
      case 'de': return 'Haben Sie einen Vorschlag, eine Frage oder haben Sie ein Problem gefunden? Bitte lassen Sie es mich wissen!';
      case 'fr': return 'Vous avez une suggestion, une question ou vous avez trouvé un problème? N\'hésitez pas à m\'en faire part!';
      case 'it': return 'Avete un suggerimento, una domanda o avete trovato un problema? Per favore fatemelo sapere!';
      case 'en': return 'Do you have a suggestion, a question or did you find a problem? Please let me know!';
      default: return 'Do you have a suggestion, a question or did you find a problem? Please let me know!';
    }
  }
  public get firstPage(): string {
    return Labels.firstPage(this.settings.language);
  }
  public static firstPage(language: string): string {
    switch (language) {
      case 'de': return 'Erste Seite';
      case 'fr': return 'Première page';
      case 'it': return 'Prima pagina';
      case 'en': return 'First page';
      default: return 'First page';
    }
  }
  public get folder(): string {
    return Labels.folder(this.settings.language);
  }
  public static folder(language: string): string {
    switch (language) {
      case 'de': return 'Ordner';
      case 'fr': return 'Dossier';
      case 'it': return 'Cartella';
      case 'en': return 'Folder';
      default: return 'Folder';
    }
  }
  public get forceUtf8OnTableLoad(): string {
    return Labels.forceUtf8OnTableLoad(this.settings.language);
  }
  public static forceUtf8OnTableLoad(language: string): string {
    switch (language) {
      case 'de': return 'Beim Laden einer Tabelle UTF-8-Kodierung erzwingen';
      case 'fr': return 'Forcer l\'encodage UTF-8 lors du chargement d\'un tableau';
      case 'it': return 'Forzare la codifica UTF-8 durante il caricamento di una tabella';
      case 'en': return 'Enforce UTF-8 encoding when loading a table';
      default: return 'Enforce UTF-8 encoding when loading a table';
    }
  }
  public get format(): string {
    return Labels.format(this.settings.language);
  }
  public static format(language: string): string {
    switch (language) {
      case 'de': return 'Format';
      case 'fr': return 'Format';
      case 'it': return 'Formato';
      case 'en': return 'Format';
      default: return 'Format';
    }
  }
  public get formatSeparationLines(): string {
    return Labels.formatSeparationLines(this.settings.language);
  }
  public static formatSeparationLines(language: string): string {
    switch (language) {
      case 'de': return 'Format (Trennlinien)';
      case 'fr': return 'Format (lignes de séparation)';
      case 'it': return 'Formato (linee di separazione)';
      case 'en': return 'Format (separation lines)';
      default: return 'Format (separation lines)';
    }
  }
  public get frame(): string {
    return Labels.frame(this.settings.language);
  }
  public static frame(language: string): string {
    switch (language) {
      case 'de': return 'Rahmen';
      case 'fr': return 'Cadre';
      case 'it': return 'Cornice';
      case 'en': return 'Frame';
      default: return 'Frame';
    }
  }
  public get fromLabel(): string {
    return Labels.fromLabel(this.settings.language);
  }
  public static fromLabel(language: string): string {
    switch (language) {
      case 'de': return 'Von';
      case 'fr': return 'De';
      case 'it': return 'Da';
      case 'en': return 'From';
      default: return 'From';
    }
  }
  public get furtherAddressFields(): string {
    return Labels.furtherAddressFields(this.settings.language);
  }
  public static furtherAddressFields(language: string): string {
    switch (language) {
      case 'de': return 'Weitere Adress-Felder:';
      case 'fr': return 'Autres champs d\'adresse :';
      case 'it': return 'Altri campi di indirizzo:';
      case 'en': return 'Further address fields:';
      default: return 'Further address fields:';
    }
  }
  public get generatePDFA(): string {
    return Labels.generatePDFA(this.settings.language);
  }
  public static generatePDFA(language: string): string {
    switch (language) {
      case 'de': return 'PDF/A-3 erzeugen';
      case 'fr': return 'Générer PDF/A-3';
      case 'it': return 'Generare PDF/A-3';
      case 'en': return 'Generate PDF/A-3';
      default: return 'Generate PDF/A-3';
    }
  }
  public get generateFromTable(): string {
    return Labels.generateFromTable(this.settings.language);
  }
  public static generateFromTable(language: string): string {
    switch (language) {
      case 'de': return 'Aus Tabelle generieren';
      case 'fr': return 'Générer à partir d\'un tableau';
      case 'it': return 'Generare dalla tabella';
      case 'en': return 'Generate from table';
      default: return 'Generate from table';
    }
  }
  public get heading(): string {
    return Labels.heading(this.settings.language);
  }
  public static heading(language: string): string {
    switch (language) {
      case 'de': return 'Briefkopf';
      case 'fr': return 'En-tête';
      case 'it': return 'Intestazione';
      case 'en': return 'Letterhead';
      default: return 'Letterhead';
    }
  }
  public get heading1(): string {
    return Labels.heading1(this.settings.language);
  }
  public static heading1(language: string): string {
    switch (language) {
      case 'de': return 'Überschrift 1';
      case 'fr': return 'Titre 1';
      case 'it': return 'Intestazione 1';
      case 'en': return 'Heading 1';
      default: return 'Heading 1';
    }
  }
  public get heading2(): string {
    return Labels.heading2(this.settings.language);
  }
  public static heading2(language: string): string {
    switch (language) {
      case 'de': return 'Überschrift 2';
      case 'fr': return 'Titre 2';
      case 'it': return 'Intestazione 2';
      case 'en': return 'Heading 2';
      default: return 'Heading 2';
    }
  }
  public get heading3(): string {
    return Labels.heading3(this.settings.language);
  }
  public static heading3(language: string): string {
    switch (language) {
      case 'de': return 'Überschrift 3';
      case 'fr': return 'Titre 3';
      case 'it': return 'Intestazione 3';
      case 'en': return 'Heading 3';
      default: return 'Heading 3';
    }
  }
  public get help(): string {
    return Labels.help(this.settings.language);
  }
  public static help(language: string): string {
    switch (language) {
      case 'de': return 'Hilfe';
      case 'fr': return 'Aide';
      case 'it': return 'Aiuto';
      case 'en': return 'Help';
      default: return 'Help';
    }
  }
  public get home(): string {
    return Labels.home(this.settings.language);
  }
  public static home(language: string): string {
    switch (language) {
      case 'de': return 'Start';
      case 'fr': return 'Accueil';
      case 'it': return 'Inizio';
      case 'en': return 'Home';
      default: return 'Home';
    }
  }
  public get iban(): string {
    return Labels.iban(this.settings.language);
  }
  public static iban(language: string): string {
    switch (language) {
      case 'de': return 'IBAN';
      case 'fr': return 'IBAN';
      case 'it': return 'IBAN';
      case 'en': return 'IBAN';
      default: return 'IBAN';
    }
  }
  public get ibanInfoToolTip(): string {
    return Labels.ibanInfoToolTip(this.settings.language);
  }
  public static ibanInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'IBAN bzw. QR-IBAN des Begünstigten. Feste Länge: 21 alphanumerische Zeichen, nur IBANs mit CH- oder LI-Landescode zulässig.';
      case 'fr': return 'IBAN ou QR-IBAN du créancier. Longueur fixe: 21 caractères alphanumériques, IBAN seulement admis avec code de pays CH ou LI.';
      case 'it': return 'IBAN o QR-IBAN';
      case 'en': return 'IBAN or QR-IBAN of the creditor. Fixed length: 21 alphanumeric characters, only IBANs with CH or LI country code permitted.';
      default: return 'IBAN or QR-IBAN of the creditor. Fixed length: 21 alphanumeric characters, only IBANs with CH or LI country code permitted.';
    }
  }
  public get ibanLabel(): string {
    return Labels.ibanLabel(this.settings.language);
  }
  public static ibanLabel(language: string): string {
    switch (language) {
      case 'de': return 'Konto';
      case 'fr': return 'Compte';
      case 'it': return 'Conto';
      case 'en': return 'Account';
      default: return 'Account';
    }
  }
  public get ibanLineLabel(): string {
    return Labels.ibanLineLabel(this.settings.language);
  }
  public static ibanLineLabel(language: string): string {
    switch (language) {
      case 'de': return 'Konto (Zeile)';
      case 'fr': return 'Compte (Ligne)';
      case 'it': return 'Conto (Linea)';
      case 'en': return 'Account (Line)';
      default: return 'Account (Line)';
    }
  }
  public get ibanValidToolTipNeedsIban(): string {
    return Labels.ibanValidToolTipNeedsIban(this.settings.language);
  }
  public static ibanValidToolTipNeedsIban(language: string): string {
    switch (language) {
      case 'de': return 'Dies ist eine QR-IBAN. Die selektierte Ausprägung benötigt aber eine normale IBAN.';
      case 'fr': return 'Il s\'agit d\'un QR-IBAN. Cependant, la variante choisie nécessite un IBAN normal.';
      case 'it': return 'Questo è un QR-IBAN. Tuttavia, la variante selezionata richiede un normale IBAN.';
      case 'en': return 'This is a QR-IBAN. However, the selected variant requires a normal IBAN.';
      default: return 'This is a QR-IBAN. However, the selected variant requires a normal IBAN.';
    }
  }
  public get ibanValidToolTipNeedsQrIban(): string {
    return Labels.ibanValidToolTipNeedsQrIban(this.settings.language);
  }
  public static ibanValidToolTipNeedsQrIban(language: string): string {
    switch (language) {
      case 'de': return 'Dies ist eine normale IBAN. Die selektierte Ausprägung benötigt aber eine QR-IBAN.';
      case 'fr': return 'Il s\'agit d\'un IBAN normale. Cependant, la variante choisie nécessite un QR-IBAN.';
      case 'it': return 'Questo è un normale IBAN. Tuttavia, la variante selezionata richiede un QR-IBAN.';
      case 'en': return 'This is a normal IBAN. However, the selected variant requires a QR-IBAN.';
      default: return 'This is a normal IBAN. However, the selected variant requires a QR-IBAN.';
    }
  }
  public get ibanValidToolTipNeedsWirIban(): string {
    return Labels.ibanValidToolTipNeedsWirIban(this.settings.language);
  }
  public static ibanValidToolTipNeedsWirIban(language: string): string {
    switch (language) {
      case 'de': return 'Die Währung WIR (CHW) ist nur mit einer IBAN (oder QR-IBAN) der WIR Bank zulässig.';
      case 'fr': return 'La monnaie WIR (CHW) n\'est autorisée qu\'avec un IBAN (ou QR-IBAN) de la Banque WIR.';
      case 'it': return 'La valuta WIR (CHW) è consentita solo con un IBAN (o QR-IBAN) della Banca WIR.';
      case 'en': return 'The currency WIR (CHW) is only permitted with an IBAN (or QR-IBAN) of the WIR Bank.';
      default: return 'The currency WIR (CHW) is only permitted with an IBAN (or QR-IBAN) of the WIR Bank.';
    }
  }
  public get ibanValidToolTipWrongCountryCode(): string {
    return Labels.ibanValidToolTipWrongCountryCode(this.settings.language);
  }
  public static ibanValidToolTipWrongCountryCode(language: string): string {
    switch (language) {
      case 'de': return 'Nur IBANs (oder QR-IBANs) mit CH- oder LI-Landescode sind zulässig.';
      case 'fr': return 'Seuls les IBAN (ou QR-IBAN) avec un code de pays CH ou LI sont autorisés.';
      case 'it': return 'Sono ammessi solo IBAN (o QR IBAN) con codice paese CH o LI.';
      case 'en': return 'Only IBANs (or QR IBANs) with CH or LI country code are allowed.';
      default: return 'Only IBANs (or QR IBANs) with CH or LI country code are allowed.';
    }
  }
  public get import(): string {
    return Labels.import(this.settings.language);
  }
  public static import(language: string): string {
    switch (language) {
      case 'de': return 'Import';
      case 'fr': return 'Importer';
      case 'it': return 'Importare';
      case 'en': return 'Import';
      default: return 'Import';
    }
  }
  public get incomplete(): string {
    return Labels.incomplete(this.settings.language);
  }
  public static incomplete(language: string): string {
    switch (language) {
      case 'de': return 'unvollständig';
      case 'fr': return 'incomplet';
      case 'it': return 'incompleto';
      case 'en': return 'incomplete';
      default: return 'incomplete';
    }
  }
  public get incrementInvoiceNumber(): string {
    return Labels.incrementInvoiceNumber(this.settings.language);
  }
  public static incrementInvoiceNumber(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungsnummer hochzählen';
      case 'fr': return 'Incrémenter le numéro de facture';
      case 'it': return 'Incrementare il numero di fattura';
      case 'en': return 'Increment invoice number';
      default: return 'Increment invoice number';
    }
  }
  public get incrementMessage(): string {
    return Labels.incrementMessage(this.settings.language);
  }
  public static incrementMessage(language: string): string {
    switch (language) {
      case 'de': return 'Mitteilung hochzählen';
      case 'fr': return 'Incrémenter le message';
      case 'it': return 'Incrementare il messaggio';
      case 'en': return 'Increment message';
      default: return 'Increment message';
    }
}
  public get incrementReference(): string {
    return Labels.incrementReference(this.settings.language);
  }
  public static incrementReference(language: string): string {
    switch (language) {
      case 'de': return 'Referenz hochzählen';
      case 'fr': return 'Incrémenter la référence';
      case 'it': return 'Incrementare il riferimento';
      case 'en': return 'Increment reference';
      default: return 'Increment reference';
    }
  }
  public get inFavorOfLabel(): string {
    return Labels.inFavorOfLabel(this.settings.language);
  }
  public static inFavorOfLabel(language: string): string {
    switch (language) {
      case 'de': return 'Zugunsten';
      case 'fr': return 'En faveur de';
      case 'it': return 'A favore di';
      case 'en': return 'In favour of';
      default: return 'In favour of';
    }
  }
  public get info(): string {
    return Labels.info(this.settings.language);
  }
  public static info(language: string): string {
    switch (language) {
      case 'de': return 'Info';
      case 'fr': return 'Info';
      case 'it': return 'Info';
      case 'en': return 'Info';
      default: return 'Info';
    }
  }
  public get insert(): string {
    return Labels.insert(this.settings.language);
  }
  public static insert(language: string): string {
    switch (language) {
      case 'de': return 'Einfügen';
      case 'fr': return 'Insérer';
      case 'it': return 'Inserisci';
      case 'en': return 'Insert';
      default: return 'Insert';
    }
  }
  public get insertIntoPDF(): string {
    return Labels.insertIntoPDF(this.settings.language);
  }
  public static insertIntoPDF(language: string): string {
    switch (language) {
      case 'de': return 'In Dokument einfügen';
      case 'fr': return 'Insérer dans un document';
      case 'it': return 'Inserire nel documento';
      case 'en': return 'Insert into document';
      default: return 'Insert into document';
    }
  }
  public get insertIntoTable(): string {
    return Labels.insertIntoTable(this.settings.language);
  }
  public static insertIntoTable(language: string): string {
    switch (language) {
      case 'de': return 'In Tabelle einfügen';
      case 'fr': return 'Insérer dans un tableau';
      case 'it': return 'Inserire nella tabella';
      case 'en': return 'Insert into table';
      default: return 'Insert into table';
    }
  }
  public get insertLink(): string {
    return Labels.insertLink(this.settings.language);
  }
  public static insertLink(language: string): string {
    switch (language) {
      case 'de': return 'Link einfügen';
      case 'fr': return 'Insérer lien';
      case 'it': return 'Inserisci link';
      case 'en': return 'Insert Link';
      default: return 'Insert Link';
    }
  }
  public get install(): string {
    return Labels.install(this.settings.language);
  }
  public static install(language: string): string {
    switch (language) {
      case 'de': return 'Installieren';
      case 'fr': return 'Installer';
      case 'it': return 'Installare';
      case 'en': return 'Install';
      default: return 'Install';
    }
  }
  public get invalidValue(): string {
    return Labels.invalidValue(this.settings.language);
  }
  public static invalidValue(language: string): string {
    switch (language) {
      case 'de': return 'Unzulässiger Wert';
      case 'fr': return 'Valeur non valide';
      case 'it': return 'Valore non valido';
      case 'en': return 'Invalid value';
      default: return 'Invalid value';
    }
  }
  public get invoice(): string {
    return Labels.invoice(this.settings.language);
  }
  public static invoice(language: string): string {
    switch (language) {
      case 'de': return 'Rechnung';
      case 'fr': return 'Facture';
      case 'it': return 'Fattura';
      case 'en': return 'Invoice';
      default: return 'Invoice';
    }
  }
  public get invoiceDateInfoToolTip(): string {
    return Labels.invoiceDateInfoToolTip(this.settings.language);
  }
  public static invoiceDateInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Belegdatum. Das Belegdatum entspricht dem Rechnungsdatum; es dient als Referenzdatum für die Konditionen. Zusammen mit dem Feld Konditionen 0:n kann ein Fälligkeitsdatum der Rechnung berechnet werden (Zahlbar bis n Tage nach Belegdatum).';
      case 'fr': return 'Date du document. La date du document correspond à la date de la facture; elle sert de date de référence pour les conditions. Il est possible de calculer une date d\'échéance de la facture à partir de ce champ et du champ Conditions 0:n (Payable à n jours après la date du document).';
      case 'it': return 'Data del documento. La data del voucher è la stessa della data della fattura; viene utilizzata come data di riferimento per i termini e le condizioni. Insieme al campo Condizioni 0:n, è possibile calcolare una data di scadenza della fattura (pagabile entro n giorni dalla data del voucher).';
      case 'en': return 'Voucher date. The voucher date is the same as the date of the invoice; it is used as the reference date for the terms and conditions. Together with the field Conditions 0:n, a due date of the invoice can be calculated (payable within n days after the voucher date).';
      default: return 'Voucher date. The voucher date is the same as the date of the invoice; it is used as the reference date for the terms and conditions. Together with the field Conditions 0:n, a due date of the invoice can be calculated (payable within n days after the voucher date).';
    }
  }
  public get invoiceDateLabel(): string {
    return Labels.invoiceDateLabel(this.settings.language);
  }
  public static invoiceDateLabel(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungsdatum';
      case 'fr': return 'Date de facture';
      case 'it': return 'Data della fattura';
      case 'en': return 'Invoice date';
      default: return 'Invoice date';
    }
  }
  public get invoiceDateLineLabel(): string {
    return Labels.invoiceDateLineLabel(this.settings.language);
  }
  public static invoiceDateLineLabel(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungsdatum (Zeile)';
      case 'fr': return 'Date de facture (Ligne)';
      case 'it': return 'Data della fattura (Linea)';
      case 'en': return 'Invoice date (Line)';
      default: return 'Invoice date (Line)';
    }
  }
  public get invoiceInfoLabel(): string {
    return Labels.invoiceInfoLabel(this.settings.language);
  }
  public static invoiceInfoLabel(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungsinformationen (Swico)';
      case 'fr': return 'Informations de facture (Swico)';
      case 'it': return 'Informazioni di fatturazione (Swico)';
      case 'en': return 'Billing information (Swico)';
      default: return 'Billing information (Swico)';
    }
  }
  public get invoiceInfoToolTip(): string {
    return Labels.invoiceInfoToolTip(this.settings.language);
  }
  public static invoiceInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungsinformationen enthalten Daten für die automatisierte Verbuchung der Rechnung. Die Daten werden nicht mit der Zahlung weitergeleitet.';
      case 'fr': return 'Les informations structurelles de l\'émetteur de factures contiennent des données pour la comptabilisation automatisée de la facture. Les données ne sont pas transmises avec le paiement.';
      case 'it': return 'I dati di fatturazione contengono dati per la contabilizzazione automatica della fattura. I dati non vengono trasmessi con il pagamento.';
      case 'en': return 'Bill information contains data for the automated booking of the bill. The data is not forwarded with the payment.';
      default: return 'Bill information contains data for the automated booking of the bill. The data is not forwarded with the payment.';
    }
  }
  public get invoiceNumberInfoToolTip(): string {
    return Labels.invoiceNumberInfoToolTip(this.settings.language);
  }
  public static invoiceNumberInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Belegnummer. Freier Text.';
      case 'fr': return 'Numéro du document. Texte libre.';
      case 'it': return 'Numero del documento. Testo libero.';
      case 'en': return 'Voucher number. Free text.';
      default: return 'Voucher number. Free text.';
    }
  }
  public get invoiceNumberLabel(): string {
    return Labels.invoiceNumberLabel(this.settings.language);
  }
  public static invoiceNumberLabel(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungsnummer';
      case 'fr': return 'Numéro de facture';
      case 'it': return 'Numero di fattura';
      case 'en': return 'Invoice number';
      default: return 'Invoice number';
    }
  }
  public get invoiceNumberLineLabel(): string {
    return Labels.invoiceNumberLineLabel(this.settings.language);
  }
  public static invoiceNumberLineLabel(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungsnummer (Zeile)';
      case 'fr': return 'Numéro de facture (Ligne)';
      case 'it': return 'Numero di fattura (Linea)';
      case 'en': return 'Invoice number (Line)';
      default: return 'Invoice number (Line)';
    }
  }
  public get invoiceTemplate(): string {
    return Labels.invoiceTemplate(this.settings.language);
  }
  public static invoiceTemplate(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungsvorlage';
      case 'fr': return 'Modèle de facture';
      case 'it': return 'Modello di fattura';
      case 'en': return 'Invoice template';
      default: return 'Invoice template';
    }
  }
  public get italic(): string {
    return Labels.italic(this.settings.language);
  }
  public static italic(language: string): string {
    switch (language) {
      case 'de': return 'Kursiv';
      case 'fr': return 'Italique';
      case 'it': return 'Corsivo';
      case 'en': return 'Italic';
      default: return 'Italic';
    }
  }
  public get languageEnglish(): string {
    return Labels.languageEnglish(this.settings.language);
  }
  public static languageEnglish(language: string): string {
    switch (language) {
      case 'de': return 'Englisch';
      case 'fr': return 'Anglais';
      case 'it': return 'Inglese';
      case 'en': return 'English';
      default: return 'English';
    }
  }
  public get languageFrench(): string {
    return Labels.languageFrench(this.settings.language);
  }
  public static languageFrench(language: string): string {
    switch (language) {
      case 'de': return 'Französisch';
      case 'fr': return 'Français';
      case 'it': return 'Francese';
      case 'en': return 'French';
      default: return 'French';
    }
  }
  public get languageGerman(): string {
    return Labels.languageGerman(this.settings.language);
  }
  public static languageGerman(language: string): string {
    switch (language) {
      case 'de': return 'Deutsch';
      case 'fr': return 'Allemand';
      case 'it': return 'Tedesco';
      case 'en': return 'German';
      default: return 'German';
    }
  }
  public get languageItalian(): string {
    return Labels.languageItalian(this.settings.language);
  }
  public static languageItalian(language: string): string {
    switch (language) {
      case 'de': return 'Italienisch';
      case 'fr': return 'Italien';
      case 'it': return 'Italiano';
      case 'en': return 'Italian';
      default: return 'Italian';
    }
  }
  public get languageLabel(): string {
    return Labels.languageLabel(this.settings.language);
  }
  public static languageLabel(language: string): string {
    switch (language) {
      case 'de': return 'Sprache';
      case 'fr': return 'Langue';
      case 'it': return 'Lingua';
      case 'en': return 'Language';
      default: return 'Language';
    }
  }
  public get languagesInfoToolTip(): string {
    return Labels.languagesInfoToolTip(this.settings.language);
  }
  public static languagesInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Die QR-Rechnung kann in den Korrespondenzsprachen Deutsch, Französisch, Italienisch und Englisch erstellt werden. Dem Rechnungssteller ist die Wahl der Korrespondenzsprache freigestellt.';
      case 'fr': return 'La QR-facture peut être établie dans les langues de correspondance allemand, français, italien et anglais. Le choix de la langue de correspondance est libre pour l\'émetteur de factures.';
      case 'it': return 'La QR-fattura può essere generata in una delle seguenti lingue di corrispondenza: Tedesco, Francese, Italiano e Inglese. L\'emittente della fattura è libero di scegliere la lingua di corrispondenza utilizzata.';
      case 'en': return 'The QR-bill can be generated in any of these correspondence languages: German, French, Italian and English. The bill issuer is free to choose the correspondence language used.';
      default: return 'The QR-bill can be generated in any of these correspondence languages: German, French, Italian and English. The bill issuer is free to choose the correspondence language used.';
    }
  }
  public get lastPage(): string {
    return Labels.lastPage(this.settings.language);
  }
  public static lastPage(language: string): string {
    switch (language) {
      case 'de': return 'Letzte Seite';
      case 'fr': return 'Dernière page';
      case 'it': return 'Ultima pagina';
      case 'en': return 'Last page';
      default: return 'Last page';
    }
  }
  public get left(): string {
    return Labels.left(this.settings.language);
  }
  public static left(language: string): string {
    switch (language) {
      case 'de': return 'Links';
      case 'fr': return 'Gauche';
      case 'it': return 'Sinistra';
      case 'en': return 'Left';
      default: return 'Left';
    }
  }
  public get link(): string {
    return Labels.link(this.settings.language);
  }
  public static link(language: string): string {
    switch (language) {
      case 'de': return 'Link';
      case 'fr': return 'Lien';
      case 'it': return 'Link';
      case 'en': return 'Link';
      default: return 'Link';
    }
  }
  public get linkPositionC(): string {
    return Labels.linkPositionC(this.settings.language);
  }
  public static linkPositionC(language: string): string {
    switch (language) {
      case 'de': return 'Zentriert, direkt über der Trennlinie';
      case 'fr': return 'Centré, directement au-dessus de la ligne de séparation';
      case 'it': return 'Centrato, direttamente sopra la linea di separazione';
      case 'en': return 'Centered, directly above the separation line';
      default: return 'Centered, directly above the separation line';
    }
  }
  public get linkPositionL(): string {
    return Labels.linkPositionL(this.settings.language);
  }
  public static linkPositionL(language: string): string {
    switch (language) {
      case 'de': return 'Links, direkt über der Trennlinie';
      case 'fr': return 'À gauche, directement au-dessus de la ligne de séparation';
      case 'it': return 'A sinistra, direttamente sopra la linea di separazione';
      case 'en': return 'Left, directly above the separation line';
      default: return 'Left, directly above the separation line';
    }
  }
  public get linkPositionR(): string {
    return Labels.linkPositionR(this.settings.language);
  }
  public static linkPositionR(language: string): string {
    switch (language) {
      case 'de': return 'Rechts, direkt über der Trennlinie';
      case 'fr': return 'À droite, directement au-dessus de la ligne de séparation';
      case 'it': return 'A destra, direttamente sopra la linea di separazione';
      case 'en': return 'Right, directly above the separation line';
      default: return 'Right, directly above the separation line';
    }
  }
  public get linkPositionAC(): string {
    return Labels.linkPositionAC(this.settings.language);
  }
  public static linkPositionAC(language: string): string {
    switch (language) {
      case 'de': return 'Zentriert, mit Abstand für vorgedruckten Trenntext';
      case 'fr': return 'Centré, avec un espace pour le texte de séparation préimprimé';
      case 'it': return 'Centrato, con spazio per il testo di separazione prestampato';
      case 'en': return 'Centered, with space for preprinted separation text';
      default: return 'Centered, with space for preprinted separation text';
    }
  }
  public get linkPositionT(): string {
    return Labels.linkPositionT(this.settings.language);
  }
  public static linkPositionT(language: string): string {
    switch (language) {
      case 'de': return 'Integriert mit Trenntext';
      case 'fr': return 'Intégré avec le texte de séparation';
      case 'it': return 'Integrato con testo di separazione';
      case 'en': return 'Integrated with separation text';
      default: return 'Integrated with separation text';
    }
  }
  public get linkPositionTitle(): string {
    return Labels.linkPositionTitle(this.settings.language);
  }
  public static linkPositionTitle(language: string): string {
    switch (language) {
      case 'de': return 'Position des Links';
      case 'fr': return 'Position du lien';
      case 'it': return 'Posizione del link';
      case 'en': return 'Link position';
      default: return 'Link position';
    }
  }
  public get loadTable(): string {
    return Labels.loadTable(this.settings.language);
  }
  public static loadTable(language: string): string {
    switch (language) {
      case 'de': return 'Tabelle laden';
      case 'fr': return 'Charger tableau';
      case 'it': return 'Carica tabella';
      case 'en': return 'Load table';
      default: return 'Load table';
    }
  }
  public get longerThanAllowed(): string {
    return Labels.longerThanAllowed(this.settings.language);
  }
  public static longerThanAllowed(language: string): string {
    switch (language) {
      case 'de': return 'Länger als erlaubt';
      case 'fr': return 'Plus long que permis';
      case 'it': return 'Più lungo del consentito';
      case 'en': return 'Longer than allowed';
      default: return 'Longer than allowed';
    }
  }
  public get machineReadableAttachments(): string {
    return Labels.machineReadableAttachments(this.settings.language);
  }
  public static machineReadableAttachments(language: string): string {
    switch (language) {
      case 'de': return 'Maschinenlesbare Anhänge:';
      case 'fr': return 'Pièces jointes lisibles par machine:';
      case 'it': return 'Allegati leggibili a macchina:';
      case 'en': return 'Machine-readable attachments:';
      default: return 'Machine-readable attachments:';
    }
  }
  public get menuButtonTooltip(): string {
    return Labels.menuButtonTooltip(this.settings.language);
  }
  public static menuButtonTooltip(language: string): string {
    switch (language) {
      case 'de': return 'Menü';
      case 'fr': return 'Menu';
      case 'it': return 'Menu';
      case 'en': return 'Menu';
      default: return 'Menu';
    }
  }
  public get message(): string {
    return Labels.message(this.settings.language);
  }
  public static message(language: string): string {
    switch (language) {
      case 'de': return 'Mitteilung';
      case 'fr': return 'Message';
      case 'it': return 'Messagio';
      case 'en': return 'Message';
      default: return 'Message';
    }
  }
  public get missing(): string {
    return Labels.missing(this.settings.language);
  }
  public static missing(language: string): string {
    switch (language) {
      case 'de': return 'fehlt';
      case 'fr': return 'manque';
      case 'it': return 'manca';
      case 'en': return 'missing';
      default: return 'missing';
    }
  }
  public get mobileDevice(): string {
    return Labels.mobileDevice(this.settings.language);
  }
  public static mobileDevice(language: string): string {
    switch (language) {
      case 'de': return 'Mobiles Gerät';
      case 'fr': return 'Appareil Mobile';
      case 'it': return 'Dispositivo Mobile';
      case 'en': return 'Mobile Device';
      default: return 'Mobile Device';
    }
  }
  public get multiPaymentPartsPositionA4Only(): string {
    return Labels.multiPaymentPartsPositionA4Only(this.settings.language);
  }
  public static multiPaymentPartsPositionA4Only(language: string): string {
    switch (language) {
      case 'de': return 'Nur für Papierformat A4';
      case 'fr': return 'Pour le format du papier A4 seulement';
      case 'it': return 'Solo per il formato carta A4';
      case 'en': return 'For paper format A4 only';
      default: return 'For paper format A4 only';
    }
  }
  public get multiPaymentPartsPositionTitle(): string {
    return Labels.multiPaymentPartsPositionTitle(this.settings.language);
  }
  public static multiPaymentPartsPositionTitle(language: string): string {
    switch (language) {
      case 'de': return 'Papierformat';
      case 'fr': return 'Format du papier';
      case 'it': return 'Formato carta';
      case 'en': return 'Paper format';
      default: return 'Paper format';
    }
  }
  public get multiPaymentPartsPosition1a5(): string {
    return Labels.multiPaymentPartsPosition1a5(this.settings.language);
  }
  public static multiPaymentPartsPosition1a5(language: string): string {
    switch (language) {
      case 'de': return 'A5 quer unten';
      case 'fr': return 'A5 horizontal bas';
      case 'it': return 'A5 paesaggio fondo';
      case 'en': return 'A5 landscape bottom';
      default: return 'A5 landscape bottom';
    }
  }
  public get multiPaymentPartsPosition1a6(): string {
    return Labels.multiPaymentPartsPosition1a6(this.settings.language);
  }
  public static multiPaymentPartsPosition1a6(language: string): string {
    switch (language) {
      case 'de': return 'Nur Zahlteil';
      case 'fr': return 'Section paiement seulement';
      case 'it': return 'Sezione pagamento solo';
      case 'en': return 'Payment part only';
      default: return 'Payment part only';
    }
  }
  public get multiPaymentPartsPosition1b(): string {
    return Labels.multiPaymentPartsPosition1b(this.settings.language);
  }
  public static multiPaymentPartsPosition1b(language: string): string {
    switch (language) {
      case 'de': return 'A4 unten';
      case 'fr': return 'A4 bas';
      case 'it': return 'A4 fondo';
      case 'en': return 'A4 bottom';
      default: return 'A4 bottom';
    }
  }
  public get multiPaymentPartsPosition12b(): string {
    return Labels.multiPaymentPartsPosition12b(this.settings.language);
  }
  public static multiPaymentPartsPosition12b(language: string): string {
    switch (language) {
      case 'de': return 'A4 1/2 unten';
      case 'fr': return 'A4 1/2 bas';
      case 'it': return 'A4 1/2 fondo';
      case 'en': return 'A4 1/2 bottom';
      default: return 'A4 1/2 bottom';
    }
  }
  public get multiPaymentPartsPosition12c(): string {
    return Labels.multiPaymentPartsPosition12c(this.settings.language);
  }
  public static multiPaymentPartsPosition12c(language: string): string {
    switch (language) {
      case 'de': return 'A4 1/2 mitte';
      case 'fr': return 'A4 1/2 centre';
      case 'it': return 'A4 1/2 centro';
      case 'en': return 'A4 1/2 center';
      default: return 'A4 1/2 center';
    }
  }
  public get multiPaymentPartsPosition12t(): string {
    return Labels.multiPaymentPartsPosition12t(this.settings.language);
  }
  public static multiPaymentPartsPosition12t(language: string): string {
    switch (language) {
      case 'de': return 'A4 1/2 oben';
      case 'fr': return 'A4 1/2 haut';
      case 'it': return 'A4 1/2 top';
      case 'en': return 'A4 1/2 top';
      default: return 'A4 1/2 top';
    }
  }
  public get multiPaymentPartsPosition2b(): string {
    return Labels.multiPaymentPartsPosition2b(this.settings.language);
  }
  public static multiPaymentPartsPosition2b(language: string): string {
    switch (language) {
      case 'de': return 'A4 unten + unten';
      case 'fr': return 'A4 bas + bas';
      case 'it': return 'A4 fondo + fondo';
      case 'en': return 'A4 bottom + bottom';
      default: return 'A4 bottom + bottom';
    }
  }
  public get multiPaymentPartsPosition2c(): string {
    return Labels.multiPaymentPartsPosition2c(this.settings.language);
  }
  public static multiPaymentPartsPosition2c(language: string): string {
    switch (language) {
      case 'de': return 'A4 mitte + unten';
      case 'fr': return 'A4 centre + bas';
      case 'it': return 'A4 centro + fondo';
      case 'en': return 'A4 center + bottom';
      default: return 'A4 center + bottom';
    }
  }
  public get multiPaymentPartsPosition2t(): string {
    return Labels.multiPaymentPartsPosition2t(this.settings.language);
  }
  public static multiPaymentPartsPosition2t(language: string): string {
    switch (language) {
      case 'de': return 'A4 oben + unten';
      case 'fr': return 'A4 haut + bas';
      case 'it': return 'A4 top + fondo';
      case 'en': return 'A4 top + bottom';
      default: return 'A4 top + bottom';
    }
  }
  public get multiple(): string {
    return Labels.multiple(this.settings.language);
  }
  public static multiple(language: string): string {
    switch (language) {
      case 'de': return 'Mehrere';
      case 'fr': return 'Plusieurs';
      case 'it': return 'Più';
      case 'en': return 'Multiple';
      default: return 'Multiple';
    }
  }
  public get multiSelection(): string {
    return Labels.multiSelection(this.settings.language);
  }
  public static multiSelection(language: string): string {
    switch (language) {
      case 'de': return 'Multi-Selektion';
      case 'fr': return 'Multi Sélection';
      case 'it': return 'Multi Selezione';
      case 'en': return 'Multi Selection';
      default: return 'Multi Selection';
    }
  }
  public get mustRemainEmpty(): string {
    return Labels.mustRemainEmpty(this.settings.language);
  }
  public static mustRemainEmpty(language: string): string {
    switch (language) {
      case 'de': return 'Muss vorerst leer bleiben';
      case 'fr': return 'Doit rester vide pour le moment';
      case 'it': return 'Deve rimanere vuoto per il momento';
      case 'en': return 'Must remain empty for now';
      default: return 'Must remain empty for now';
    }
  }
  public get nameLabel(): string {
    return Labels.nameLabel(this.settings.language);
  }
  public static nameLabel(language: string): string {
    switch (language) {
      case 'de': return 'Name';
      case 'fr': return 'Nom';
      case 'it': return 'Nome';
      case 'en': return 'Name';
      default: return 'Name';
    }
  }
  public get nameOptional(): string {
    return Labels.nameOptional(this.settings.language);
  }
  public static nameOptional(language: string): string {
    switch (language) {
      case 'de': return 'Name (optional)';
      case 'fr': return 'Nom (optionnel)';
      case 'it': return 'Nome (opzionale)';
      case 'en': return 'Name (optional)';
      default: return 'Name (optional)';
    }
  }
  public get newPage(): string {
    return Labels.newPage(this.settings.language);
  }
  public static newPage(language: string): string {
    switch (language) {
      case 'de': return 'Neue Seite';
      case 'fr': return 'Nouvelle page';
      case 'it': return 'Nuova pagina';
      case 'en': return 'New page';
      default: return 'New page';
    }
  }
  public get news(): string {
    return Labels.news(this.settings.language);
  }
  public static news(language: string): string {
    switch (language) {
      case 'de': return 'Neuigkeiten';
      case 'fr': return 'Nouvelles';
      case 'it': return 'Novità';
      case 'en': return 'News';
      default: return 'News';
    }
  }
  public get newsTitle(): string {
    return Labels.newsTitle(this.settings.language);
  }
  public static newsTitle(language: string): string {
    switch (language) {
      case 'de': return 'Neuigkeiten zu QR-Rechnung.net';
      case 'fr': return 'Nouvelles sur QR-Rechnung.net (seulement en allemand)';
      case 'it': return 'Novità su QR-Rechnung.net (solo in tedesco)';
      case 'en': return 'News about QR-Rechnung.net (only in german)';
      default: return 'News about QR-Rechnung.net (only in german)';
    }
  }
  public get newsFeed(): string {
    return Labels.newsFeed(this.settings.language);
  }
  public static newsFeed(language: string): string {
    switch (language) {
      case 'de': return 'RSS-Feed';
      case 'fr': return 'Flux RSS';
      case 'it': return 'Feed RSS';
      case 'en': return 'RSS feed';
      default: return 'RSS feed';
    }
  }
  public get nextPage(): string {
    return Labels.nextPage(this.settings.language);
  }
  public static nextPage(language: string): string {
    switch (language) {
      case 'de': return 'Nächste Seite';
      case 'fr': return 'Page suivante';
      case 'it': return 'Pagina successiva';
      case 'en': return 'Next page';
      default: return 'Next page';
    }
  }
  public get noQRCodeFoundInImage(): string {
    return Labels.noQRCodeFoundInImage(this.settings.language);
  }
  public static noQRCodeFoundInImage(language: string): string {
    switch (language) {
      case 'de': return 'Kein QR Code gefunden im Bild.';
      case 'fr': return 'Aucune code QR n\'a été trouvée dans l\'image.';
      case 'it': return 'Nessun codice QR trovato nell\'immagine.';
      case 'en': return 'No QR code found in image.';
      default: return 'No QR code found in image.';
    }
  }
  public get normal(): string {
    return Labels.normal(this.settings.language);
  }
  public static normal(language: string): string {
    switch (language) {
      case 'de': return 'Normal';
      case 'fr': return 'Ordinaire';
      case 'it': return 'Normale';
      case 'en': return 'Normal';
      default: return 'Normal';
    }
  }
  public get notSupportedByThisBrowser(): string {
    return Labels.notSupportedByThisBrowser(this.settings.language);
  }
  public static notSupportedByThisBrowser(language: string): string {
    switch (language) {
      case 'de': return 'Wird von diesem Browser nicht unterstützt.';
      case 'fr': return 'Non supporté par ce navigateur.';
      case 'it': return 'Non supportato da questo browser.';
      case 'en': return 'Not supported by this browser.';
      default: return 'Not supported by this browser.';
    }
  }
  public get numberLabel(): string {
    return Labels.numberLabel(this.settings.language);
  }
  public static numberLabel(language: string): string {
    switch (language) {
      case 'de': return 'Nummer';
      case 'fr': return 'Numéro';
      case 'it': return 'Numero';
      case 'en': return 'Number';
      default: return 'Number';
    }
  }
  public get numberOfInvoices(): string {
    return Labels.numberOfInvoices(this.settings.language);
  }
  public static numberOfInvoices(language: string): string {
    switch (language) {
      case 'de': return 'Anzahl Rechnungen';
      case 'fr': return 'Nombre de factures';
      case 'it': return 'Numero di fatture';
      case 'en': return 'Number of invoices';
      default: return 'Number of invoices';
    }
  }
  public get open(): string {
    return Labels.open(this.settings.language);
  }
  public static open(language: string): string {
    switch (language) {
      case 'de': return 'Öffnen';
      case 'fr': return 'Ouvrir';
      case 'it': return 'Aprire';
      case 'en': return 'Open';
      default: return 'Open';
    }
  }
  public get options(): string {
    return Labels.options(this.settings.language);
  }
  public static options(language: string): string {
    switch (language) {
      case 'de': return 'Optionen';
      case 'fr': return 'Options';
      case 'it': return 'Opzioni';
      case 'en': return 'Options';
      default: return 'Options';
    }
  }
  public get orderedList(): string {
    return Labels.orderedList(this.settings.language);
  }
  public static orderedList(language: string): string {
    switch (language) {
      case 'de': return 'Geordnete Liste';
      case 'fr': return 'Liste ordonnée';
      case 'it': return 'Lista ordinata';
      case 'en': return 'Ordered list';
      default: return 'Ordered list';
    }
  }
  public get page(): string {
    return Labels.page(this.settings.language);
  }
  public static page(language: string): string {
    switch (language) {
      case 'de': return 'Seite';
      case 'fr': return 'Page';
      case 'it': return 'Pagina';
      case 'en': return 'Page';
      default: return 'Page';
    }
  }
  public get paperInvoice(): string {
    return Labels.paperInvoice(this.settings.language);
  }
  public static paperInvoice(language: string): string {
    switch (language) {
      case 'de': return 'Papier-Rechnung (Ausdruck auf perforiertes Papier)';
      case 'fr': return 'Facture papier (imprimer sur papier perforé)';
      case 'it': return 'Fattura cartacea (stampa su carta perforata)';
      case 'en': return 'Paper invoice (printing on perforated paper)';
      default: return 'Paper invoice (printing on perforated paper)';
    }
  }

  public get pasteFromClipboard(): string {
    return Labels.pasteFromClipboard(this.settings.language);
  }
  public static pasteFromClipboard(language: string): string {
    switch (language) {
      case 'de': return 'Aus Zwischenablage einfügen';
      case 'fr': return 'Coller depuis le presse-papiers';
      case 'it': return 'Incolla dagli appunti';
      case 'en': return 'Paste from clipboard';
      default: return 'Paste from clipboard';
    }
  }

  public get payableByLabel(): string {
    return Labels.payableByLabel(this.settings.language);
  }
  public static payableByLabel(language: string): string {
    switch (language) {
      case 'de': return 'Zahlbar durch';
      case 'fr': return 'Payable par';
      case 'it': return 'Pagabile da';
      case 'en': return 'Payable by';
      default: return 'Payable by';
    }
  }
  public get payableByBlankLabel(): string {
    return Labels.payableByBlankLabel(this.settings.language);
  }
  public static payableByBlankLabel(language: string): string {
    switch (language) {
      case 'de': return 'Zahlbar durch (Name/Adresse)';
      case 'fr': return 'Payable par (nom/adresse)';
      case 'it': return 'Pagabile da (nome/indirizzo)';
      case 'en': return 'Payable by (name/address)';
      default: return 'Payable by (name/address)';
    }
  }
  public get payableToLabel(): string {
    return Labels.payableToLabel(this.settings.language);
  }
  public static payableToLabel(language: string): string {
    switch (language) {
      case 'de': return 'Konto / Zahlbar an';
      case 'fr': return 'Compte / Payable à';
      case 'it': return 'Conto / Pagabile a';
      case 'en': return 'Account / Payable to';
      default: return 'Account / Payable to';
    }
  }
  public get paymentInfoLabel(): string {
    return Labels.paymentInfoLabel(this.settings.language);
  }
  public static paymentInfoLabel(language: string): string {
    switch (language) {
      case 'de': return 'Zahlungsinformationen';
      case 'fr': return 'Informations de paiement';
      case 'it': return 'Informazioni di pagamento';
      case 'en': return 'Payment information';
      default: return 'Payment information';
    }
  }
  public get paymentInfoToolTip(): string {
    return Labels.paymentInfoToolTip(this.settings.language);
  }
  public static paymentInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Zahlungsinformationen enthalten Daten, die mit der Zahlung weitergeleitet werden.';
      case 'fr': return 'Les informations de paiement contiennent des données qui sont transmises avec le paiement.';
      case 'it': return 'Le informazioni di pagamento contengono i dati che vengono trasmessi con il pagamento.';
      case 'en': return 'Payment information contains data that is forwarded with the payment.';
      default: return 'Payment information contains data that is forwarded with the payment.';
    }
  }
  public get paymentTitleLabel(): string {
    return Labels.paymentTitleLabel(this.settings.language);
  }
  public static paymentTitleLabel(language: string): string {
    switch (language) {
      case 'de': return 'Zahlteil';
      case 'fr': return 'Section paiement';
      case 'it': return 'Sezione pagamento';
      case 'en': return 'Payment part';
      default: return 'Payment part';
    }
  }
  public get payOnlineWithSwissQrCode(): string {
    return Labels.payOnlineWithSwissQrCode(this.settings.language);
  }
  public static payOnlineWithSwissQrCode(language: string): string {
    switch (language) {
      case 'de': return 'Online bezahlen mit Swiss QR Code';
      case 'fr': return 'Payer en ligne avec Swiss QR Code';
      case 'it': return 'Pagare online con Swiss QR Code';
      case 'en': return 'Pay online with Swiss QR Code';
      default: return 'Pay online with Swiss QR Code';
    }
  }
  public get pdfBundleSize(): string {
    return Labels.pdfBundleSize(this.settings.language);
  }
  public static pdfBundleSize(language: string): string {
    switch (language) {
      case 'de': return 'PDF Bündelgrösse';
      case 'fr': return 'Taille de la liasse PDF';
      case 'it': return 'Dimensione del bundle PDF';
      case 'en': return 'PDF bundle size';
      default: return 'PDF bundle size';
    }
  }
  public get pdfInvoice(): string {
    return Labels.pdfInvoice(this.settings.language);
  }
  public static pdfInvoice(language: string): string {
    switch (language) {
      case 'de': return 'PDF-Rechnung (elektronischer Versand)';
      case 'fr': return 'Facture PDF (livraison électronique)';
      case 'it': return 'Fattura PDF (consegna elettronica)';
      case 'en': return 'PDF invoice (electronic delivery)';
      default: return 'PDF invoice (electronic delivery)';
    }
  }
  public get pdfInvoiceLabel(): string {
    return Labels.pdfInvoiceLabel(this.settings.language);
  }
  public static pdfInvoiceLabel(language: string): string {
    switch (language) {
      case 'de': return 'PDF-Rechnung';
      case 'fr': return 'Facture PDF';
      case 'it': return 'Fattura PDF';
      case 'en': return 'PDF invoice';
      default: return 'PDF invoice';
    }
  }
  public get pdfLoadError(): string {
    return Labels.pdfLoadError(this.settings.language);
  }
  public static pdfLoadError(language: string): string {
    switch (language) {
      case 'de': return 'Konnte PDF nicht laden';
      case 'fr': return 'Impossible de charger le PDF';
      case 'it': return 'Impossibile caricare il PDF';
      case 'en': return 'Could not load PDF';
      default: return 'Could not load PDF';
    }
  }
  public get pdfPageHasNotA4Width(): string {
    return Labels.pdfPageHasNotA4Width(this.settings.language);
  }
  public static pdfPageHasNotA4Width(language: string): string {
    switch (language) {
      case 'de': return 'Die gewählte Seite hat nicht die erforderliche Breite von A4.';
      case 'fr': return 'La page choisie n\'a pas la largeur requise de A4.';
      case 'it': return 'La pagina scelta non ha la larghezza richiesta di A4.';
      case 'en': return 'The chosen page does not have the required width of A4.';
      default: return 'The chosen page does not have the required width of A4.';
    }
  }
  public get pdfPageHasNotEnoughHeight(): string {
    return Labels.pdfPageHasNotEnoughHeight(this.settings.language);
  }
  public static pdfPageHasNotEnoughHeight(language: string): string {
    switch (language) {
      case 'de': return 'Die gewählte Seite hat nicht die erforderliche Mindesthöhe von 11 cm.';
      case 'fr': return 'La page choisie n\'a pas la hauteur minimale requise de 11 cm.';
      case 'it': return 'La pagina scelta non ha l\'altezza minima richiesta di 11 cm.';
      case 'en': return 'The chosen page does not have the required minimum height of 11 cm.';
      default: return 'The chosen page does not have the required minimum height of 11 cm.';
    }
  }
  public get permalink(): string {
    return Labels.permalink(this.settings.language);
  }
  public static permalink(language: string): string {
    switch (language) {
      case 'de': return 'Permalink';
      case 'fr': return 'Permalien';
      case 'it': return 'Permalink';
      case 'en': return 'Permalink';
      default: return 'Permalink';
    }
  }
  public get permalinkSave(): string {
    return Labels.permalinkSave(this.settings.language);
  }
  public static permalinkSave(language: string): string {
    switch (language) {
      case 'de': return 'Sichern Sie sich den Permalink als Lesezeichen oder Favoriten um später wieder auf die Daten zuzugreifen. Oder ziehen Sie den Permalink-Knopf auf den Desktop oder in einen Ordner, um eine Verknüpfung zu erstellen.';
      case 'fr': return 'Enregistrez le permalien comme un signet ou un favori pour accéder à l\'information plus tard. Vous pouvez également faire glisser le bouton de permalien sur le bureau ou dans un dossier pour créer un raccourci.';
      case 'it': return 'Salva il permalink come segnalibro o come preferito per accedere alle informazioni in un secondo momento. Oppure trascinare il pulsante permalink sul desktop o su una cartella per creare un collegamento.';
      case 'en': return 'Save the permalink as bookmark or favorite to later access the data again. Or drag the permalink button to the desktop or a folder to create a shortcut.';
      default: return 'Save the permalink as bookmark or favorite to later access the data again. Or drag the permalink button to the desktop or a folder to create a shortcut.';
    }
  }
  public get position(): string {
    return Labels.position(this.settings.language);
  }
  public static position(language: string): string {
    switch (language) {
      case 'de': return 'Position';
      case 'fr': return 'Position';
      case 'it': return 'Posizione';
      case 'en': return 'Position';
      default: return 'Position';
    }
  }
  public get postalCodeLabel(): string {
    return Labels.postalCodeLabel(this.settings.language);
  }
  public static postalCodeLabel(language: string): string {
    switch (language) {
      case 'de': return 'Postleitzahl';
      case 'fr': return 'Code postal';
      case 'it': return 'Numero postale';
      case 'en': return 'Postal Code';
      default: return 'Postal Code';
    }
  }
  public get prepress(): string {
    return Labels.prepress(this.settings.language);
  }
  public static prepress(language: string): string {
    switch (language) {
      case 'de': return 'Druckvorstufe';
      case 'fr': return 'Prépresse';
      case 'it': return 'Prestampa';
      case 'en': return 'Prepress';
      default: return 'Prepress';
    }
  }
  public get previewLabel(): string {
    return Labels.previewLabel(this.settings.language);
  }
  public static previewLabel(language: string): string {
    switch (language) {
      case 'de': return 'Vorschau';
      case 'fr': return 'Aperçu';
      case 'it': return 'Anteprima';
      case 'en': return 'Preview';
      default: return 'Preview';
    }
  }
  public get previewOfRow(): string {
    return Labels.previewOfRow(this.settings.language);
  }
  public static previewOfRow(language: string): string {
    switch (language) {
      case 'de': return 'Vorschau von Zeile';
      case 'fr': return 'Aperçu de la ligne';
      case 'it': return 'Anteprima della riga';
      case 'en': return 'Preview of row';
      default: return 'Preview of row';
    }
  }
  public get previousPage(): string {
    return Labels.previousPage(this.settings.language);
  }
  public static previousPage(language: string): string {
    switch (language) {
      case 'de': return 'Vorherige Seite';
      case 'fr': return 'Page précédente';
      case 'it': return 'Pagina precedente';
      case 'en': return 'Previous page';
      default: return 'Previous page';
    }
  }
  public get privacy(): string {
    return Labels.privacy(this.settings.language);
  }
  public static privacy(language: string): string {
    switch (language) {
      case 'de': return 'Datenschutz';
      case 'fr': return 'Protection des données';
      case 'it': return 'Privacy';
      case 'en': return 'Privacy';
      default: return 'Privacy';
    }
  }
  public get pushConnection(): string {
    return Labels.pushConnection(this.settings.language);
  }
  public static pushConnection(language: string): string {
    switch (language) {
      case 'de': return 'Push-Verbindung';
      case 'fr': return 'Connexion push';
      case 'it': return 'Connessione push';
      case 'en': return 'Push connection';
      default: return 'Push connection';
    }
  }
  public get pushConnectionInfo(): string {
    return Labels.pushConnectionInfo(this.settings.language);
  }
  public static pushConnectionInfo(language: string): string {
    switch (language) {
      case 'de': return 'Push-Mitteilungen ermöglichen ein einfacheres Starten von mobilem Scanning, ohne dass ein Verbindungs-QR-Code gescannt werden muss.';
      case 'fr': return 'Les messages push permettent de lancer plus facilement la numérisation mobile sans avoir à scanner un code QR de connexion.';
      case 'it': return 'I messaggi push facilitano l\'avvio della scansione mobile senza dover scansionare un codice QR di connessione.';
      case 'en': return 'Push notifications allow for an easier launch of mobile scanning without having to scan a connection QR code.';
      default: return 'Push notifications allow for an easier launch of mobile scanning without having to scan a connection QR code.';
    }
  }
  public get pushConnectionNotAvailable(): string {
    return Labels.pushConnectionNotAvailable(this.settings.language);
  }
  public static pushConnectionNotAvailable(language: string): string {
    switch (language) {
      case 'de': return 'Push-Nachrichten nicht verfügbar';
      case 'fr': return 'Messages push non disponibles';
      case 'it': return 'Messaggi push non disponibili';
      case 'en': return 'Push notifications not available';
      default: return 'Push notifications not available';
    }
  }
  public get pushSendMessage(): string {
    return Labels.pushSendMessage(this.settings.language);
  }
  public static pushSendMessage(language: string): string {
    switch (language) {
      case 'de': return 'Push-Mitteilung senden';
      case 'fr': return 'Envoyer un message push';
      case 'it': return 'Invia un messaggio push';
      case 'en': return 'Send push message';
      default: return 'Send push message';
    }
  }
  public get pushSendMessageExplainer(): string {
    return Labels.pushSendMessageExplainer(this.settings.language);
  }
  public static pushSendMessageExplainer(language: string): string {
    switch (language) {
      case 'de': return 'Senden Sie den Verbindungscode als Push-Mitteilung auf Ihr mobiles Gerät. Öffnen Sie dort die Push-Mitteilung, um den Mobile Scan zu starten.';
      case 'fr': return 'Envoyez le code de connexion sous forme de message push sur votre appareil mobile. Ouvrez là le message push pour démarrer le scan mobile.';
      case 'it': return 'Inviare il codice di connessione come messaggio push al dispositivo mobile. Aprire lì il messaggio push per avviare la scansione mobile.';
      case 'en': return 'Send the connection code as a push message to your mobile device. Open the push message there to start the mobile scan.';
      default: return 'Send the connection code as a push message to your mobile device. Open the push message there to start the mobile scan.';
    }
  }
  public get pushStartRemoteScanningBody(): string {
    return Labels.pushStartRemoteScanningBody(this.settings.language);
  }
  public static pushStartRemoteScanningBody(language: string): string {
    switch (language) {
      case 'de': return 'Öffnen Sie diese Push-Mitteilung, um den Mobile Scan zu starten.';
      case 'fr': return 'Ouvrez ce message push pour démarrer le scan mobile.';
      case 'it': return 'Aprite questo messaggio push per avviare la scansione mobile.';
      case 'en': return 'Open this push message to start the mobile scan.';
      default: return 'Open this push message to start the mobile scan.';
    }
  }
  public get pushStartRemoteScanningTitle(): string {
    return Labels.pushStartRemoteScanningTitle(this.settings.language);
  }
  public static pushStartRemoteScanningTitle(language: string): string {
    switch (language) {
      case 'de': return 'Mobile Scan';
      case 'fr': return 'Mobile Scan';
      case 'it': return 'Mobile Scan';
      case 'en': return 'Mobile Scan';
      default: return 'Mobile Scan';
    }
  }

  public get pushSubscribe(): string {
    return Labels.pushSubscribe(this.settings.language);
  }
  public static pushSubscribe(language: string): string {
    switch (language) {
      case 'de': return 'Abonnieren';
      case 'fr': return 'Abonner';
      case 'it': return 'Iscrivetevi';
      case 'en': return 'Subscribe';
      default: return 'Subscribe';
    }
  }
  public get pushUnsubscribe(): string {
    return Labels.pushUnsubscribe(this.settings.language);
  }
  public static pushUnsubscribe(language: string): string {
    switch (language) {
      case 'de': return 'Abbestellen';
      case 'fr': return 'Désabonner';
      case 'it': return 'Annullare l\'inscrizione';
      case 'en': return 'Unsubscribe';
      default: return 'Unsubscribe';
    }
  }
  public get qrBill(): string {
    return Labels.qrBill(this.settings.language);
  }
  public static qrBill(language: string): string {
    switch (language) {
      case 'de': return 'QR-Rechnung';
      case 'fr': return 'QR-facture';
      case 'it': return 'QR-fattura';
      case 'en': return 'QR-bill';
      default: return 'QR-bill';
    }
  }
  public get qrBills(): string {
    return Labels.qrBills(this.settings.language);
  }
  public static qrBills(language: string): string {
    switch (language) {
      case 'de': return 'QR-Rechnungen';
      case 'fr': return 'QR-factures';
      case 'it': return 'QR-fatture';
      case 'en': return 'QR-bills';
      default: return 'QR-bills';
    }
  }
  public get qrCode(): string {
    return Labels.qrCode(this.settings.language);
  }
  public static qrCode(language: string): string {
    switch (language) {
      case 'de': return 'QR-Code';
      case 'fr': return 'Code QR';
      case 'it': return 'Codice QR';
      case 'en': return 'QR code';
      default: return 'QR code';
    }
  }
  public get qrCodeDetails(): string {
    return Labels.qrCodeDetails(this.settings.language);
  }
  public static qrCodeDetails(language: string): string {
    switch (language) {
      case 'de': return 'QR-Code Details';
      case 'fr': return 'Détails du code QR';
      case 'it': return 'Dettagli del codice QR';
      case 'en': return 'QR code details';
      default: return 'QR code details';
    }
  }
  public get qrIban(): string {
    return Labels.qrIban(this.settings.language);
  }
  public static qrIban(language: string): string {
    switch (language) {
      case 'de': return 'QR-IBAN';
      case 'fr': return 'QR-IBAN';
      case 'it': return 'QR-IBAN';
      case 'en': return 'QR-IBAN';
      default: return 'QR-IBAN';
    }
  }
  public get qrZahlteilChConnectedWith(): string {
    return Labels.qrZahlteilChConnectedWith(this.settings.language);
  }
  public static qrZahlteilChConnectedWith(language: string): string {
    switch (language) {
      case 'de': return 'Verbunden mit ';
      case 'fr': return 'Connecté avec ';
      case 'it': return 'Connesso con ';
      case 'en': return 'Connected with ';
      default: return 'Connected with ';
    }
  }
  public get qrZahlteilChCloseConnection(): string {
    return Labels.qrZahlteilChCloseConnection(this.settings.language);
  }
  public static qrZahlteilChCloseConnection(language: string): string {
    switch (language) {
      case 'de': return 'Verbindung schliessen';
      case 'fr': return 'Fermer la connexion';
      case 'it': return 'Connessione stretta';
      case 'en': return 'Close connection';
      default: return 'Close connection';
    }
  }
  public get qrZahlteilChResetSessionId(): string {
    return Labels.qrZahlteilChResetSessionId(this.settings.language);
  }
  public static qrZahlteilChResetSessionId(language: string): string {
    switch (language) {
      case 'de': return 'SessionID zurücksetzen';
      case 'fr': return 'Réinitialiser l\'ID de session';
      case 'it': return 'Reimposta ID sessione';
      case 'en': return 'Reset session ID';
      default: return 'Reset session ID';
    }
  }
  public get qrZahlteilChSmartphoneNotConnected(): string {
    return Labels.qrZahlteilChSmartphoneNotConnected(this.settings.language);
  }
  public static qrZahlteilChSmartphoneNotConnected(language: string): string {
    switch (language) {
      case 'de': return 'Smartphone nicht verbunden';
      case 'fr': return 'Smartphone non connecté';
      case 'it': return 'Smartphone non collegato';
      case 'en': return 'Smartphone not connected';
      default: return 'Smartphone not connected';
    }
  }
  public get qrZahlteilChTransmission(): string {
    return Labels.qrZahlteilChTransmission(this.settings.language);
  }
  public static qrZahlteilChTransmission(language: string): string {
    switch (language) {
      case 'de': return 'Datenübertragung via';
      case 'fr': return 'Transmission de données via';
      case 'it': return 'Trasmissione dati via';
      case 'en': return 'Data transmission via';
      default: return 'Data transmission via';
    }
  }
  public get rateThisWebApp(): string {
    return Labels.rateThisWebApp(this.settings.language);
  }
  public static rateThisWebApp(language: string): string {
    switch (language) {
      case 'de': return 'Bewerten Sie diese Web App';
      case 'fr': return 'Notez cette application web';
      case 'it': return 'Valuta questa web app';
      case 'en': return 'Rate this web app';
      default: return 'Rate this web app';
    }
  }
  public get receiptTitleLabel(): string {
    return Labels.receiptTitleLabel(this.settings.language);
  }
  public static receiptTitleLabel(language: string): string {
    switch (language) {
      case 'de': return 'Empfangsschein';
      case 'fr': return 'Récépissé';
      case 'it': return 'Ricevuta';
      case 'en': return 'Receipt';
      default: return 'Receipt';
    }
  }
  public get recordScannedVideo(): string {
    return Labels.recordScannedVideo(this.settings.language);
  }
  public static recordScannedVideo(language: string): string {
    switch (language) {
      case 'de': return 'Gescanntes Video aufzeichnen';
      case 'fr': return 'Enregistrer la vidéo scannée';
      case 'it': return 'Registrare video scansionati';
      case 'en': return 'Record scanned video';
      default: return 'Record scanned video';
    }
  }
  public get referenceInfoLabel(): string {
    return Labels.referenceInfoLabel(this.settings.language);
  }
  public static referenceInfoLabel(language: string): string {
    switch (language) {
      case 'de': return 'Zahlungsreferenz';
      case 'fr': return 'Référence de paiement';
      case 'it': return 'Riferimento dl pagamento';
      case 'en': return 'Payment reference';
      default: return 'Payment reference';
    }
  }
  public get referenceInfoToolTip(): string {
    return Labels.referenceInfoToolTip(this.settings.language);
  }
  public static referenceInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Die strukturierte Referenz ist entweder eine QR-Referenz oder eine Creditor Reference (ISO 11649). Muss bei Verwendung einer QR-IBAN eine QR-Referenz enthalten. Kann bei Verwendung der IBAN eine Creditor Reference (ISO 11649) enthalten oder weggelassen werden. QR-Referenz: 27 numerische Zeichen. Creditor Reference (ISO 11649): bis 25 alphanumerische Zeichen.';
      case 'fr': return 'La référence est soit une référence QR, soit une Creditor Reference (ISO 11649). Doit contenir une référence QR si un QR-IBAN est utilisé. Une Creditor Reference (ISO 11649) peut être saisie en option si l\'IBAN est utilisé. Référence QR: 27 caractères numériques. Creditor Reference (ISO 11649): jusqu\'à 25 caractères alphanumériques.';
      case 'it': return 'Il riferimento strutturato è un riferimento QR o un riferimento creditore ISO 11649. Deve contenere un riferimento QR se viene utilizzato un QR-IBAN. Un Creditor Reference ISO 11649 può essere inserito opzionalmente se si utilizza l\'IBAN. Riferimento QR: 27 caratteri numerici. Creditor Reference (ISO 11649): max 25 caratteri alfanumerici.';
      case 'en': return 'The structured reference is either a QR reference or an ISO 11649 Creditor Reference. Must contain a QR reference if a QR-IBAN is used. An ISO 11649 Creditor Reference can optionally be entered if the IBAN is used. QR reference: 27 numeric characters. Creditor Reference (ISO 11649): max 25 alphanumeric characters.';
      default: return 'The structured reference is either a QR reference or an ISO 11649 Creditor Reference. Must contain a QR reference if a QR-IBAN is used. An ISO 11649 Creditor Reference can optionally be entered if the IBAN is used. QR reference: 27 numeric characters. Creditor Reference (ISO 11649): max 25 alphanumeric characters.';
    }
  }
  public get referenceLabel(): string {
    return Labels.referenceLabel(this.settings.language);
  }
  public static referenceLabel(language: string): string {
    switch (language) {
      case 'de': return 'Referenz';
      case 'fr': return 'Référence';
      case 'it': return 'Riferimento';
      case 'en': return 'Reference';
      default: return 'Reference';
    }
  }
  public get referenceLabelQRR(): string {
    return Labels.referenceLabelQRR(this.settings.language);
  }
  public static referenceLabelQRR(language: string): string {
    switch (language) {
      case 'de': return 'QR-Referenz';
      case 'fr': return 'Référence QR';
      case 'it': return 'Riferimento QR';
      case 'en': return 'QR Reference';
      default: return 'QR Reference';
    }
  }
  public get referenceLabelSCOR(): string {
    return Labels.referenceLabelSCOR(this.settings.language);
  }
  public static referenceLabelSCOR(language: string): string {
    switch (language) {
      case 'de': return 'Creditor Reference';
      case 'fr': return 'Creditor Reference';
      case 'it': return 'Creditor Reference';
      case 'en': return 'Creditor Reference';
      default: return 'Creditor Reference';
    }
  }
  public get referenceLineLabel(): string {
    return Labels.referenceLineLabel(this.settings.language);
  }
  public static referenceLineLabel(language: string): string {
    switch (language) {
      case 'de': return 'Referenz (Zeile)';
      case 'fr': return 'Référence (Ligne)';
      case 'it': return 'Riferimento (Linea)';
      case 'en': return 'Reference (Line)';
      default: return 'Reference (Line)';
    }
  }
  public get referenceTypeLabel(): string {
    return Labels.referenceTypeLabel(this.settings.language);
  }
  public static referenceTypeLabel(language: string): string {
    switch (language) {
      case 'de': return 'Referenztyp';
      case 'fr': return 'Type de référence';
      case 'it': return 'Tipo di riferimento';
      case 'en': return 'Reference type';
      default: return 'Reference type';
    }
  }
  public get regards(): string {
    return Labels.regards(this.settings.language);
  }
  public static regards(language: string): string {
    switch (language) {
      case 'de': return 'Freundliche Grüsse';
      case 'fr': return 'Sincères salutations';
      case 'it': return 'I migliori saluti';
      case 'en': return 'With kind regards';
      default: return 'With kind regards';
    }
  }
  public get reload(): string {
    return Labels.reload(this.settings.language);
  }
  public static reload(language: string): string {
    switch (language) {
      case 'de': return 'Neu laden';
      case 'fr': return 'Recharger';
      case 'it': return 'Ricarica';
      case 'en': return 'Reload';
      default: return 'Reload';
    }
  }
  public get reloadToUpdate(): string {
    return Labels.reloadToUpdate(this.settings.language);
  }
  public static reloadToUpdate(language: string): string {
    switch (language) {
      case 'de': return 'Die App wurde aktualisiert. Bitte laden Sie neu.';
      case 'fr': return 'L\'application a été mise à jour. Veuillez la recharger.';
      case 'it': return 'L\'applicazione è stata aggiornata. Si prega di ricaricare.';
      case 'en': return 'The app was updated. Please reload.';
      default: return 'The app was updated. Please reload.';
    }
  }
  public get rename(): string {
    return Labels.rename(this.settings.language);
  }
  public static rename(language: string): string {
    switch (language) {
      case 'de': return 'Umbenennen';
      case 'fr': return 'Renommer';
      case 'it': return 'Rinominare';
      case 'en': return 'Rename';
      default: return 'Rename';
    }
  }
  public get replay(): string {
    return Labels.replay(this.settings.language);
  }
  public static replay(language: string): string {
    switch (language) {
      case 'de': return 'Replay';
      case 'fr': return 'Replay';
      case 'it': return 'Replay';
      case 'en': return 'Replay';
      default: return 'Replay';
    }
  }
  public get replytoLabel(): string {
    return Labels.replytoLabel(this.settings.language);
  }
  public static replytoLabel(language: string): string {
    switch (language) {
      case 'de': return 'Antwort-An';
      case 'fr': return 'Répondre à';
      case 'it': return 'Rispondi a';
      case 'en': return 'Reply-To';
      default: return 'Reply-To';
    }
  }
  public get resetConnection(): string {
    return Labels.resetConnection(this.settings.language);
  }
  public static resetConnection(language: string): string {
    switch (language) {
      case 'de': return 'Verbindung zurücksetzen';
      case 'fr': return 'Réinitialiser la connexion';
      case 'it': return 'Reimpostare la connessione';
      case 'en': return 'Reset connection';
      default: return 'Reset connection';
    }
  }
  public get result(): string {
    return Labels.result(this.settings.language);
  }
  public static result(language: string): string {
    switch (language) {
      case 'de': return 'Resultat';
      case 'fr': return 'Résultat';
      case 'it': return 'Risultato';
      case 'en': return 'Result';
      default: return 'Result';
    }
  }
  public get right(): string {
    return Labels.right(this.settings.language);
  }
  public static right(language: string): string {
    switch (language) {
      case 'de': return 'Rechts';
      case 'fr': return 'Droite';
      case 'it': return 'Destra';
      case 'en': return 'Right';
      default: return 'Right';
    }
  }
  public get salutation(): string {
    return Labels.salutation(this.settings.language);
  }
  public static salutation(language: string): string {
    switch (language) {
      case 'de': return 'Sehr geehrte Damen und Herren,';
      case 'fr': return 'Chères Mesdames et Messieurs,';
      case 'it': return 'Gentili signore e signori,';
      case 'en': return 'Dear Sir or Madam,';
      default: return 'Dear Sir or Madam,';
    }
  }
  public get save(): string {
    return Labels.save(this.settings.language);
  }
  public static save(language: string): string {
    switch (language) {
      case 'de': return 'Speichern';
      case 'fr': return 'Enregistrer';
      case 'it': return 'Salva';
      case 'en': return 'Save';
      default: return 'Save';
    }
  }
  public get saveAs(): string {
    return Labels.saveAs(this.settings.language);
  }
  public static saveAs(language: string): string {
    switch (language) {
      case 'de': return 'Speichern als';
      case 'fr': return 'Enregistrer sous';
      case 'it': return 'Salva come';
      case 'en': return 'Save as';
      default: return 'Save as';
    }
  }
  public get saveAsTable(): string {
    return Labels.saveAsTable(this.settings.language);
  }
  public static saveAsTable(language: string): string {
    switch (language) {
      case 'de': return 'Als Tabelle speichern';
      case 'fr': return 'Enregistrer comme tableau';
      case 'it': return 'Salva come tabella';
      case 'en': return 'Save as table';
      default: return 'Save as table';
    }
  }
  public get saveAsTableTooltip(): string {
    return Labels.saveAsTableTooltip(this.settings.language);
  }
  public static saveAsTableTooltip(language: string): string {
    switch (language) {
      case 'de': return 'Selektierte Zahlteile als Tabelle speichern';
      case 'fr': return 'Enregistrer les sections paiment sélectionnées comme tableau';
      case 'it': return 'Salva le sezioni pagamento selezionate come tabella';
      case 'en': return 'Save selected payment parts as table';
      default: return 'Save selected payment parts as table';
    }
  }
  public get saved(): string {
    return Labels.saved(this.settings.language);
  }
  public static saved(language: string): string {
    switch (language) {
      case 'de': return 'Gespeichert';
      case 'fr': return 'Enregistré';
      case 'it': return 'Salvato';
      case 'en': return 'Saved';
      default: return 'Saved';
    }
  }
  public get savedData(): string {
    return Labels.savedData(this.settings.language);
  }
  public static savedData(language: string): string {
    switch (language) {
      case 'de': return 'Gespeicherte Daten';
      case 'fr': return 'Données enregistrées';
      case 'it': return 'Dati salvati';
      case 'en': return 'Saved Data';
      default: return 'Saved Data';
    }
  }
  public get saveModifications(): string {
    return Labels.saveModifications(this.settings.language);
  }
  public static saveModifications(language: string): string {
    switch (language) {
      case 'de': return 'Änderungen speichern';
      case 'fr': return 'Enregistrer les modifications';
      case 'it': return 'Salva modifiche';
      case 'en': return 'Save modifications';
      default: return 'Save modifications';
    }
  }
  public get savedEmailTemplates(): string {
    return Labels.savedEmailTemplates(this.settings.language);
  }
  public static savedEmailTemplates(language: string): string {
    switch (language) {
      case 'de': return 'Emailvorlagen';
      case 'fr': return 'Modèles d\'email';
      case 'it': return 'Modelli di email';
      case 'en': return 'Email templates';
      default: return 'Email templates';
    }
  }
  public get savedPaymentParts(): string {
    return Labels.savedPaymentParts(this.settings.language);
  }
  public static savedPaymentParts(language: string): string {
    switch (language) {
      case 'de': return 'Zahlteile';
      case 'fr': return 'Sections paiement';
      case 'it': return 'Sezioni pagamento';
      case 'en': return 'Payment parts';
      default: return 'Payment parts';
    }
  }
  public get savedTables(): string {
    return Labels.savedTables(this.settings.language);
  }
  public static savedTables(language: string): string {
    switch (language) {
      case 'de': return 'Tabellen';
      case 'fr': return 'Tableaux';
      case 'it': return 'Tabelle';
      case 'en': return 'Tables';
      default: return 'Tables';
    }
  }
  public get savedTemplates(): string {
    return Labels.savedTemplates(this.settings.language);
  }
  public static savedTemplates(language: string): string {
    switch (language) {
      case 'de': return 'Rechnungsvorlagen';
      case 'fr': return 'Modèles de facture';
      case 'it': return 'Modelli di fattura';
      case 'en': return 'Invoice templates';
      default: return 'Invoice templates';
    }
  }
  public get savingDisabledAlert(): string {
    return Labels.savingDisabledAlert(this.settings.language);
  }
  public static savingDisabledAlert(language: string): string {
    switch (language) {
      case 'de': return 'Das Speichern von Daten im Browser ist nicht möglich, da der Browser ' +
                        'IndexedDB nicht unterstützt oder der Zugriff auf IndexedDB verweigert wurde.';
      case 'fr': return 'L\'enregistrement de données dans le navigateur n\'est pas possible, ' +
                        'car le navigateur ne prend pas en charge IndexedDB ou l\'accès à IndexedDB a été refusé.';
      case 'it': return 'Il salvataggio dei dati nel browser non è possibile, poiché il browser ' +
                        'non supporta IndexedDB o è stato negato l\'accesso a IndexedDB.';
      case 'en': return 'Saving data in the browser is not possible, either because the browser ' +
                        'does not support IndexedDB or access to IndexedDB has been denied.';
      default: return 'Saving data in the browser is not possible, either because the browser ' +
                      'does not support IndexedDB or access to IndexedDB has been denied.';
    }
  }
  public get savingInfoLabel(): string {
    return Labels.savingInfoLabel(this.settings.language);
  }
  public static savingInfoLabel(language: string): string {
    switch (language) {
      case 'de': return 'Die Daten werden lokal im Browser gespeichert. Das Löschen von Website-Daten in Ihrem Browser löscht auch diese gespeicherten Daten. Bestimmte Browser können die gespeicherten Daten auch nach einer gewissen Zeit der Inaktivität löschen. Um Datenverlust zu vermeiden, exportieren Sie bitte die Daten und erstellen Sie ein Backup der exportierten Datei.';
      case 'fr': return 'Les données sont stockées localement dans le navigateur. En effaçant les données du site web dans votre navigateur, vous effacez également ces données stockées. Certains navigateurs peuvent également effacer les données stockées après une certaine période d\'inactivité. Pour éviter toute perte de données, veuillez exporter les données et créer une sauvegarde du fichier exporté.';
      case 'it': return 'I dati vengono memorizzati localmente nel browser. La cancellazione dei dati del sito web nel browser cancella anche questi dati memorizzati. Alcuni browser potrebbero anche cancellare i dati memorizzati dopo un certo periodo di inattività. Per evitare la perdita di dati, esportare i dati e creare un backup del file esportato.';
      case 'en': return 'The data is stored locally in the browser. Clearing website data in your browser also deletes this stored data. Certain browsers might also delete the stored data after a certain period of inactivity. To avoid data loss, please export the data and create a backup of the exported file.';
      default: return 'The data is stored locally in the browser. Clearing website data in your browser also deletes this stored data. Certain browsers might also delete the stored data after a certain period of inactivity. To avoid data loss, please export the data and create a backup of the exported file.';
    }
  }
  public get scan(): string {
    return Labels.scan(this.settings.language);
  }
  public static scan(language: string): string {
    switch (language) {
      case 'de': return 'Scannen';
      case 'fr': return 'Scan';
      case 'it': return 'Scansione';
      case 'en': return 'Scan';
      default: return 'Scan';
    }
  }

  public get scanAlertAutoCopyScannedDataToClipboardFailed(): string {
    return Labels.scanAlertAutoCopyScannedDataToClipboardFailed(this.settings.language);
  }
  public static scanAlertAutoCopyScannedDataToClipboardFailed(language: string): string {
    switch (language) {
      case 'de': return 'Die gescannten Daten konnten nicht automatisch in die Zwischenablage kopiert werden.';
      case 'fr': return 'Impossible de copier automatiquement les données numérisées dans le presse-papiers.';
      case 'it': return 'Impossibile copiare automaticamente i dati scansionati negli appunti.';
      case 'en': return 'Failed to copy scanned data to the clipboard automatically.';
      default: return 'Failed to copy scanned data to the clipboard automatically.';
    }
  }
  public get scanAlertCameraNotAllowedError(): string {
    return Labels.scanAlertCameraNotAllowedError(this.settings.language);
  }
  public static scanAlertCameraNotAllowedError(language: string): string {
    switch (language) {
      case 'de': return 'Zugriff auf die Kamera nicht erlaubt';
      case 'fr': return 'Accès à la caméra non autorisé';
      case 'it': return 'Non è consentito l\'accesso alla camera';
      case 'en': return 'Access to camera not allowed';
      default: return 'Access to camera not allowed';
    }
  }
  public get scanAlertCameraNotFoundError(): string {
    return Labels.scanAlertCameraNotFoundError(this.settings.language);
  }
  public static scanAlertCameraNotFoundError(language: string): string {
    switch (language) {
      case 'de': return 'Keine passende Kamera gefunden';
      case 'fr': return 'Aucune caméra appropriée n\'a été trouvée';
      case 'it': return 'Non è stata trovata una camera adatta';
      case 'en': return 'No suitable camera found';
      default: return 'No suitable camera found';
    }
  }
  public get scanAlertGenericError(): string {
    return Labels.scanAlertGenericError(this.settings.language);
  }
  public static scanAlertGenericError(language: string): string {
    switch (language) {
      case 'de': return 'Es ist ein Fehler aufgetreten';
      case 'fr': return 'Une erreur s\'est produite';
      case 'it': return 'Si è verificato un errore';
      case 'en': return 'An error has occurred';
      default: return 'An error has occurred';
    }
  }
  public get scanAlertMediaDevicesNotSupported(): string {
    return Labels.scanAlertMediaDevicesNotSupported(this.settings.language);
  }
  public static scanAlertMediaDevicesNotSupported(language: string): string {
    switch (language) {
      case 'de': return 'Der Browser unterstützt keine Mediengeräte';
      case 'fr': return 'Le navigateur ne supporte pas les périphériques multimédias';
      case 'it': return 'Il browser non supporta i dispositivi multimediali';
      case 'en': return 'Browser does not support media devices';
      default: return 'Browser does not support media devices';
    }
  }
  public get scanAlertNoQRFound(): string {
    return Labels.scanAlertNoQRFound(this.settings.language);
  }
  public static scanAlertNoQRFound(language: string): string {
    switch (language) {
      case 'de': return 'Kein QR-Code gefunden';
      case 'fr': return 'Aucun code QR trouvé';
      case 'it': return 'Nessun codice QR trovato';
      case 'en': return 'No QR code found';
      default: return 'No QR code found';
    }
  }
  public get scanAlertNotSwissQCode(): string {
    return Labels.scanAlertNotSwissQCode(this.settings.language);
  }
  public static scanAlertNotSwissQCode(language: string): string {
    switch (language) {
      case 'de': return 'Der gefundene QR-Code ist kein gültiger Swiss QR Code';
      case 'fr': return 'Le code QR trouvé n\'est pas un Swiss QR Code valide';
      case 'it': return 'Il codice QR trovato non è un Swiss QR Code valido';
      case 'en': return 'The QR code found is not a valid Swiss QR Code';
      default: return 'The QR code found is not a valid Swiss QR Code';
    }
  }
  public get scanAlertNotUtf8(): string {
    return Labels.scanAlertNotUtf8(this.settings.language);
  }
  public static scanAlertNotUtf8(language: string): string {
    switch (language) {
      case 'de': return 'Der gefundene Swiss QR Code ist nicht im korrekten Zeichensatz UTF-8 kodiert';
      case 'fr': return 'Le Swiss QR Code trouvé n\'est pas encodé dans le jeu de caractères UTF-8 correct';
      case 'it': return 'Il Swiss QR Code trovato non è codificato nel corretto set di caratteri UTF-8';
      case 'en': return 'The Swiss QR Code found is not encoded in the correct UTF-8 character set';
      default: return 'The Swiss QR Code found is not encoded in the correct UTF-8 character set';
    }
  }
  public get scanAlertScreenCaptureNotAllowedError(): string {
    return Labels.scanAlertScreenCaptureNotAllowedError(this.settings.language);
  }
  public static scanAlertScreenCaptureNotAllowedError(language: string): string {
    switch (language) {
      case 'de': return 'Zugriff auf die Bildschirmaufnahme nicht erlaubt';
      case 'fr': return 'L\'accès à la capture d\'écran n\'est pas autorisé';
      case 'it': return 'L\'accesso alla cattura dello schermo non è consentito';
      case 'en': return 'Access to screen capture not allowed';
      default: return 'Access to screen capture not allowed';
    }
  }
  public get scanAlertScreenCaptureNotFoundError(): string {
    return Labels.scanAlertScreenCaptureNotFoundError(this.settings.language);
  }
  public static scanAlertScreenCaptureNotFoundError(language: string): string {
    switch (language) {
      case 'de': return 'Keine passende Bildschirmaufnahme gefunden';
      case 'fr': return 'Aucune capture d\'écran appropriée n\'a été trouvée';
      case 'it': return 'Non è stata trovata alcuna cattura adeguata dello schermo';
      case 'en': return 'No suitable screen capture found';
      default: return 'No suitable screen capture found';
    }
  }
  public get scanAlertScreenCaptureNotSupportedError(): string {
    return Labels.scanAlertScreenCaptureNotSupportedError(this.settings.language);
  }
  public static scanAlertScreenCaptureNotSupportedError(language: string): string {
    switch (language) {
      case 'de': return 'Bildschirmaufzeichnung wird nicht unterstützt';
      case 'fr': return 'Capture d\'écran non prise en charge';
      case 'it': return 'Cattura schermo non supportata';
      case 'en': return 'Screen capture not supported';
      default: return 'Screen capture not supported';
    }
  }
  public get scanCamera(): string {
    return Labels.scanCamera(this.settings.language);
  }
  public static scanCamera(language: string): string {
    switch (language) {
      case 'de': return 'Mit Kamera scannen';
      case 'fr': return 'Scan avec camera';
      case 'it': return 'Scansione con fotocamera';
      case 'en': return 'Scan with camera';
      default: return 'Scan with camera';
    }
  }
  public get scanError(): string {
    return Labels.scanError(this.settings.language);
  }
  public static scanError(language: string): string {
    switch (language) {
      case 'de': return 'Konnte Swiss QR Code nicht lesen.';
      case 'fr': return 'Impossible de lire le Swiss QR Code.';
      case 'it': return 'Impossibile leggere il Swiss QR Code.';
      case 'en': return 'Could not read Swiss QR Code.';
      default: return 'Could not read Swiss QR Code.';
    }
  }
  public get scanFile(): string {
    return Labels.scanFile(this.settings.language);
  }
  public static scanFile(language: string): string {
    switch (language) {
      case 'de': return 'Datei scannen';
      case 'fr': return 'Scanner un fichier';
      case 'it': return 'Scansione file';
      case 'en': return 'Scan file';
      default: return 'Scan file';
    }
  }
  public get scanPreview(): string {
    return Labels.scanPreview(this.settings.language);
  }
  public static scanPreview(language: string): string {
    switch (language) {
      case 'de': return 'Vorschau';
      case 'fr': return 'Aperçu';
      case 'it': return 'Anteprima';
      case 'en': return 'Preview';
      default: return 'Preview';
    }
  }
  public get scanQrZahlteilCh(): string {
    return Labels.scanQrZahlteilCh(this.settings.language);
  }
  public static scanQrZahlteilCh(language: string): string {
    switch (language) {
      case 'de': return 'Mit qrzahlteil.ch App scannen';
      case 'fr': return 'Scan avec qrzahlteil.ch app';
      case 'it': return 'Scansione con qrzahlteil.ch App';
      case 'en': return 'Scan with qrzahlteil.ch App';
      default: return 'Scan with qrzahlteil.ch App';
    }
  }
  public get scanRemote(): string {
    return Labels.scanRemote(this.settings.language);
  }
  public static scanRemote(language: string): string {
    switch (language) {
      case 'de': return 'Mit Smartphone/Tablet scannen';
      case 'fr': return 'Scanner avec smartphone/tablette';
      case 'it': return 'Scansione con smartphone/tablet';
      case 'en': return 'Scan with smartphone/tablet';
      default: return 'Scan with smartphone/tablet';
    }
  }
  public get scanRemoteConnect(): string {
    return Labels.scanRemoteConnect(this.settings.language);
  }
  public static scanRemoteConnect(language: string): string {
    switch (language) {
      case 'de': return 'Mit Smartphone/Tablet verbinden';
      case 'fr': return 'Connecter à un smartphone/tablette';
      case 'it': return 'Collegare a smartphone/tablet';
      case 'en': return 'Connect with smartphone/tablet';
      default: return 'Connect with smartphone/tablet';
    }
  }
  public get scanRemoteConnected(): string {
    return Labels.scanRemoteConnected(this.settings.language);
  }
  public static scanRemoteConnected(language: string): string {
    switch (language) {
      case 'de': return 'Verbindung hergestellt';
      case 'fr': return 'Connexion établie';
      case 'it': return 'Collegamento stabilito';
      case 'en': return 'Connection established';
      default: return 'Connection established';
    }
  }
  public get scanRemoteDesktopLabelConnected(): string {
    return Labels.scanRemoteDesktopLabelConnected(this.settings.language);
  }
  public static scanRemoteDesktopLabelConnected(language: string): string {
    switch (language) {
      case 'de': return 'Folgen Sie den Anweisungen auf dem Smartphone/Tablet.';
      case 'fr': return 'Suivez les instructions sur votre smartphone/tablette.';
      case 'it': return 'Seguire le istruzioni sullo smartphone/tablet.';
      case 'en': return 'Follow the instructions on the smartphone/tablet.';
      default: return 'Follow the instructions on the smartphone/tablet.';
    }
  }
  public get scanRemoteDataLabel(): string {
    return Labels.scanRemoteDataLabel(this.settings.language);
  }
  public static scanRemoteDataLabel(language: string): string {
    switch (language) {
      case 'de': return 'Auf dem Mobilgerät scannen, Daten an den Desktop senden';
      case 'fr': return 'Scanner sur mobile, envoyer les données vers le bureau';
      case 'it': return 'Scansione su cellulare, invio dei dati al desktop';
      case 'en': return 'Scan on mobile, send data to desktop';
      default: return 'Scan on mobile, send data to desktop';
    }
  }
  public get scanRemoteDataTitle(): string {
    return Labels.scanRemoteDataTitle(this.settings.language);
  }
  public static scanRemoteDataTitle(language: string): string {
    switch (language) {
      case 'de': return 'Scannen + Senden';
      case 'fr': return 'Scanner + Envoyer';
      case 'it': return 'Scansione + Invio';
      case 'en': return 'Scan + Send';
      default: return 'Scan + Send';
    }
  }
  public get scanRemoteDesktopLabelId(): string {
    return Labels.scanRemoteDesktopLabelId(this.settings.language);
  }
  public static scanRemoteDesktopLabelId(language: string): string {
    switch (language) {
      case 'de': return 'Scannen Sie diesen Verbindungscode mit einem Smartphone/Tablet, um den untenstehenden Link zu öffnen. Oder öffnen Sie {url2} auf Ihrem Smartphone/Tablet und scannen Sie diesen Verbindungscode.';
      case 'fr': return 'Scannez ce code de connexion avec le smartphone/tablette pour ouvrir le lien ci-dessous. Ou bien ouvrez {url2} sur votre smartphone/tablette et scannez ce code de connexion.';
      case 'it': return 'Scansionare questo codice di connessione con lo smartphone/tablet per aprire il link sottostante. Oppure apri {url2} sul vostro smartphone/tablet e scansiona questo codice di connessione.';
      case 'en': return 'Scan this connection code with smartphone/tablet to open the link below. Or open {url2} on your smartphone/tablet and scan this connection code.';
      default: return 'Scan this connection code with smartphone/tablet to open the link below. Or open {url2} on your smartphone/tablet and scan this connection code.';
    }
  }
  public get scanRemoteDesktopLabelQr(): string {
    return Labels.scanRemoteDesktopLabelQr(this.settings.language);
  }
  public static scanRemoteDesktopLabelQr(language: string): string {
    switch (language) {
      case 'de': return 'QR-Rechnung mit Smartphone/Tablet scannen';
      case 'fr': return 'Scanner QR-facture avec smartphone/tablette';
      case 'it': return 'Scansione QR-fattura con smartphone/tablet';
      case 'en': return 'Scan QR-bill with smartphone/tablet';
      default: return 'Scan QR-bill with smartphone/tablet';
    }
  }
  public get scanRemoteLabelId(): string {
    return Labels.scanRemoteLabelId(this.settings.language);
  }
  public static scanRemoteLabelId(language: string): string {
    switch (language) {
      case 'de': return 'Verbindungscode scannen';
      case 'fr': return 'Scanner code de connexion';
      case 'it': return 'Scansione codice di connessione';
      case 'en': return 'Scan connection code';
      default: return 'Scan connection code';
    }
  }
  public get scanRemoteLabelQr(): string {
    return Labels.scanRemoteLabelQr(this.settings.language);
  }
  public static scanRemoteLabelQr(language: string): string {
    switch (language) {
      case 'de': return 'QR-Rechnung scannen';
      case 'fr': return 'Scanner QR-facture';
      case 'it': return 'Scansione QR-fattura';
      case 'en': return 'Scan QR-bill';
      default: return 'Scan QR-bill';
    }
  }
  public get scanRemoteVideoLabel(): string {
    return Labels.scanRemoteVideoLabel(this.settings.language);
  }
  public static scanRemoteVideoLabel(language: string): string {
    switch (language) {
      case 'de': return 'Video senden und auf dem Desktop scannen';
      case 'fr': return 'Envoyer la vidéo et la scanner sur le bureau';
      case 'it': return 'Invio di video e scansioni sul desktop';
      case 'en': return 'Send video and scan on desktop';
      default: return 'Send video and scan on desktop';
    }
  }
  public get scanRemoteVideoTitle(): string {
    return Labels.scanRemoteVideoTitle(this.settings.language);
  }
  public static scanRemoteVideoTitle(language: string): string {
    switch (language) {
      case 'de': return 'Video-Anruf';
      case 'fr': return 'Appel vidéo';
      case 'it': return 'Videochiamata';
      case 'en': return 'Video Call';
      default: return 'Video Call';
    }
  }
  public get scanScanner(): string {
    return Labels.scanScanner(this.settings.language);
  }
  public static scanScanner(language: string): string {
    switch (language) {
      case 'de': return 'Mit Scanner scannen';
      case 'fr': return 'Scan avec scanner';
      case 'it': return 'Scansione con scanner';
      case 'en': return 'Scan with scanner';
      default: return 'Scan with scanner';
    }
  }
  public get scanScannerKeyboard(): string {
    return Labels.scanScannerKeyboard(this.settings.language);
  }
  public static scanScannerKeyboard(language: string): string {
    switch (language) {
      case 'de': return 'Mit Scanner scannen (im Tastaturmodus)';
      case 'fr': return 'Scan avec scanner (en mode clavier)';
      case 'it': return 'Scansione con scanner (in modalità tastiera)';
      case 'en': return 'Scan with scanner (in keyboard mode)';
      default: return 'Scan with scanner (in keyboard mode)';
    }
  }
  public get scanScannerPlaceholder(): string {
    return Labels.scanScannerPlaceholder(this.settings.language);
  }
  public static scanScannerPlaceholder(language: string): string {
    switch (language) {
      case 'de': return 'Lassen Sie den Scanner hier die Daten eingeben';
      case 'fr': return 'Laissez le scanner entrer les données ici';
      case 'it': return 'Lasciate che lo scanner inserisca i dati qui';
      case 'en': return 'Let the scanner enter the data here';
      default: return 'Let the scanner enter the data here';
    }
  }
  public get scanScreen(): string {
    return Labels.scanScreen(this.settings.language);
  }
  public static scanScreen(language: string): string {
    switch (language) {
      case 'de': return 'Von Bildschirm scannen';
      case 'fr': return 'Scan à partir de l\'écran';
      case 'it': return 'Scansione da schermo';
      case 'en': return 'Scan from screen';
      default: return 'Scan from screen';
    }
  }
  public get scanStop(): string {
    return Labels.scanStop(this.settings.language);
  }
  public static scanStop(language: string): string {
    switch (language) {
      case 'de': return 'Scan stoppen';
      case 'fr': return 'Arrêter la numérisation';
      case 'it': return 'Interrompere la scansione';
      case 'en': return 'Stop scanning';
      default: return 'Stop scanning';
    }
  }
  public get scanSwissQRCode(): string {
    return Labels.scanSwissQRCode(this.settings.language);
  }
  public static scanSwissQRCode(language: string): string {
    switch (language) {
      case 'de': return 'Swiss QR Code scannen';
      case 'fr': return 'Scanner Swiss QR Code';
      case 'it': return 'Scansione Swiss QR Code';
      case 'en': return 'Scan Swiss QR Code';
      default: return 'Scan Swiss QR Code';
    }
  }
  public get search(): string {
    return Labels.search(this.settings.language);
  }
  public static search(language: string): string {
    switch (language) {
      case 'de': return 'Suche';
      case 'fr': return 'Recherche';
      case 'it': return 'Ricerca';
      case 'en': return 'Search';
      default: return 'Search';
    }
  }
  public get seeDetails(): string {
    return Labels.seeDetails(this.settings.language);
  }
  public static seeDetails(language: string): string {
    switch (language) {
      case 'de': return 'Details sehen';
      case 'fr': return 'Voir les détails';
      case 'it': return 'Vedi i dettagli';
      case 'en': return 'See details';
      default: return 'See details';
    }
  }
  public get selectAllNone(): string {
    return Labels.selectAllNone(this.settings.language);
  }
  public static selectAllNone(language: string): string {
    switch (language) {
      case 'de': return 'Alle/Keine';
      case 'fr': return 'Tout/Rien';
      case 'it': return 'Tutte/Nessuna';
      case 'en': return 'All/None';
      default: return 'All/None';
    }
  }
  public get selectPdf(): string {
    return Labels.selectPdf(this.settings.language);
  }
  public static selectPdf(language: string): string {
    switch (language) {
      case 'de': return 'PDF auswählen';
      case 'fr': return 'Sélectionnez PDF';
      case 'it': return 'Selezionare PDF';
      case 'en': return 'Select PDF';
      default: return 'Select PDF';
    }
  }
  public get sendFeedback(): string {
    return Labels.sendFeedback(this.settings.language);
  }
  public static sendFeedback(language: string): string {
    switch (language) {
      case 'de': return 'Feedback senden';
      case 'fr': return 'Envoyer feedback';
      case 'it': return 'Invia feedback';
      case 'en': return 'Send feedback';
      default: return 'Send feedback';
    }
  }
  public get senderLabel(): string {
    return Labels.senderLabel(this.settings.language);
  }
  public static senderLabel(language: string): string {
    switch (language) {
      case 'de': return 'Absender';
      case 'fr': return 'Expéditeur';
      case 'it': return 'Speditore';
      case 'en': return 'Sender';
      default: return 'Sender';
    }
  }
  public get sentErrorReports(): string {
    return Labels.sentErrorReports(this.settings.language);
  }
  public static sentErrorReports(language: string): string {
    switch (language) {
      case 'de': return 'Gesendete Fehlerrapporte';
      case 'fr': return 'Rapports d\'erreur envoyés';
      case 'it': return 'Rapporti di errore inviati';
      case 'en': return 'Sent error reports';
      default: return 'Sent error reports';
    }
  }
  public get seriesInputPdfContainsMultipleInvoices(): string {
    return Labels.seriesInputPdfContainsMultipleInvoices(this.settings.language);
  }
  public static seriesInputPdfContainsMultipleInvoices(language: string): string {
    switch (language) {
      case 'de': return 'Input PDF enthält mehrere Rechnungen (Seriendruck)';
      case 'fr': return 'Le PDF saisi contient plusieurs factures (fusion de courrier)';
      case 'it': return 'Il PDF di ingresso contiene più fatture (mail merge)';
      case 'en': return 'Input PDF contains multiple invoices (mail merge)';
      default: return 'Input PDF contains multiple invoices (mail merge)';
    }
  }
  public get seriesPagesPerInvoice(): string {
    return Labels.seriesPagesPerInvoice(this.settings.language);
  }
  public static seriesPagesPerInvoice(language: string): string {
    switch (language) {
      case 'de': return 'Anzahl Seiten pro Rechnung';
      case 'fr': return 'Nombre de pages par facture';
      case 'it': return 'Numero di pagine per fattura';
      case 'en': return 'Number of pages per invoice';
      default: return 'Number of pages per invoice';
    }
  }
  public get settingsLabel(): string {
    return Labels.settingsLabel(this.settings.language);
  }
  public static settingsLabel(language: string): string {
    switch (language) {
      case 'de': return 'Einstellungen';
      case 'fr': return 'Paramètres';
      case 'it': return 'Impostazioni';
      case 'en': return 'Settings';
      default: return 'Settings';
    }
  }
  public get share(): string {
    return Labels.share(this.settings.language);
  }
  public static share(language: string): string {
    switch (language) {
      case 'de': return 'Teilen';
      case 'fr': return 'Partager';
      case 'it': return 'Condividi';
      case 'en': return 'Share';
      default: return 'Share';
    }
  }
  public get shareOnline(): string {
    return Labels.shareOnline(this.settings.language);
  }
  public static shareOnline(language: string): string {
    switch (language) {
      case 'de': return 'Online teilen';
      case 'fr': return 'Partager en ligne';
      case 'it': return 'Condividi online';
      case 'en': return 'Share online';
      default: return 'Share online';
    }
  }
  public get shareOnlineFormatHtml(): string {
    return Labels.shareOnlineFormatHtml(this.settings.language);
  }
  public static shareOnlineFormatHtml(language: string): string {
    switch (language) {
      case 'de': return 'Bild + Text (HTML) - für Email, Webseite';
      case 'fr': return 'Image + Texte (HTML) - pour e-mail, site web';
      case 'it': return 'Immagine + Testo (HTML) - per e-mail, siti web';
      case 'en': return 'Image + Text (HTML) - for email, website';
      default: return 'Image + Text (HTML) - for email, website';
    }
  }
  public get shareOnlineFormatPng(): string {
    return Labels.shareOnlineFormatPng(this.settings.language);
  }
  public static shareOnlineFormatPng(language: string): string {
    switch (language) {
      case 'de': return 'Bild - für Messenger';
      case 'fr': return 'Image - pour les messagers';
      case 'it': return 'Immagine - per i messaggeri';
      case 'en': return 'Image - for messengers';
      default: return 'Image - for messengers';
    }
  }
  public get slowMotion(): string {
    return Labels.slowMotion(this.settings.language);
  }
  public static slowMotion(language: string): string {
    switch (language) {
      case 'de': return 'Zeitlupe';
      case 'fr': return 'Ralenti';
      case 'it': return 'Rallentatore';
      case 'en': return 'Slow motion';
      default: return 'Slow motion';
    }
  }
  public get sorting(): string {
    return Labels.sorting(this.settings.language);
  }
  public static sorting(language: string): string {
    switch (language) {
      case 'de': return 'Sortierung';
      case 'fr': return 'Triage';
      case 'it': return 'Ordinamento';
      case 'en': return 'Sorting';
      default: return 'Sorting';
    }
  }
  public get sortingNameAsc(): string {
    return Labels.sortingNameAsc(this.settings.language);
  }
  public static sortingNameAsc(language: string): string {
    switch (language) {
      case 'de': return 'Alphabetisch aufsteigend';
      case 'fr': return 'Alphabétique croissant';
      case 'it': return 'Alfabetico crescente';
      case 'en': return 'Alphabetically ascending';
      default: return 'Alphabetically ascending';
    }
  }
  public get sortingNameDesc(): string {
    return Labels.sortingNameDesc(this.settings.language);
  }
  public static sortingNameDesc(language: string): string {
    switch (language) {
      case 'de': return 'Alphabetisch absteigend';
      case 'fr': return 'Alphabétique décroissant';
      case 'it': return 'Alfabetico decrescente';
      case 'en': return 'Alphabetically descending';
      default: return 'Alphabetically descending';
    }
  }
  public get sortingNewestFirst(): string {
    return Labels.sortingNewestFirst(this.settings.language);
  }
  public static sortingNewestFirst(language: string): string {
    switch (language) {
      case 'de': return 'Neueste zuerst';
      case 'fr': return 'Les plus récentes d\'abord';
      case 'it': return 'Prima i più recenti';
      case 'en': return 'Latest first';
      default: return 'Latest first';
    }
  }
  public get sortingOldestFirst(): string {
    return Labels.sortingOldestFirst(this.settings.language);
  }
  public static sortingOldestFirst(language: string): string {
    switch (language) {
      case 'de': return 'Älteste zuerst';
      case 'fr': return 'Les plus anciens d\'abord';
      case 'it': return 'Prima i più vecchi';
      case 'en': return 'Oldest first';
      default: return 'Oldest first';
    }
  }

  public get specificPage(): string {
    return Labels.specificPage(this.settings.language);
  }
  public static specificPage(language: string): string {
    switch (language) {
      case 'de': return 'Spezifische Seite';
      case 'fr': return 'Page spécifique';
      case 'it': return 'Pagina specifica';
      case 'en': return 'Specific page';
      default: return 'Specific page';
    }
  }

  public get startValue(): string {
    return Labels.startValue(this.settings.language);
  }
  public static startValue(language: string): string {
    switch (language) {
      case 'de': return 'Startwert';
      case 'fr': return 'Valeur de départ';
      case 'it': return 'Valore di partenza';
      case 'en': return 'Start value';
      default: return 'Start value';
    }
  }
  public get streetNameLabel(): string {
    return Labels.streetNameLabel(this.settings.language);
  }
  public static streetNameLabel(language: string): string {
    switch (language) {
      case 'de': return 'Strasse';
      case 'fr': return 'Rue';
      case 'it': return 'Via';
      case 'en': return 'Street';
      default: return 'Street';
    }
  }
  public get structuredLabel(): string {
    return Labels.structuredLabel(this.settings.language);
  }
  public static structuredLabel(language: string): string {
    switch (language) {
      case 'de': return 'Strukturiert';
      case 'fr': return 'Structurée';
      case 'it': return 'Strutturato';
      case 'en': return 'Structured';
      default: return 'Structured';
    }
  }
  public get subject(): string {
    return Labels.subject(this.settings.language);
  }
  public static subject(language: string): string {
    switch (language) {
      case 'de': return 'Betreff';
      case 'fr': return 'Sujet';
      case 'it': return 'Oggetto';
      case 'en': return 'Subject';
      default: return 'Subject';
    }
  }
  public get support(): string {
    return Labels.support(this.settings.language);
  }
  public static support(language: string): string {
    switch (language) {
      case 'de': return 'Support';
      case 'fr': return 'Support';
      case 'it': return 'Supporto';
      case 'en': return 'Support';
      default: return 'Support';
    }
  }
  public get supportCode(): string {
    return Labels.supportCode(this.settings.language);
  }
  public static supportCode(language: string): string {
    switch (language) {
      case 'de': return 'Support Code';
      case 'fr': return 'Code de support';
      case 'it': return 'Codice di supporto';
      case 'en': return 'Support Code';
      default: return 'Support Code';
    }
  }
  public get supportCodeActivated(): string {
    return Labels.supportCodeActivated(this.settings.language);
  }
  public static supportCodeActivated(language: string): string {
    switch (language) {
      case 'de': return 'Support Code aktiviert';
      case 'fr': return 'Code de support activé';
      case 'it': return 'Codice di supporto attivato';
      case 'en': return 'Support code activated';
      default: return 'Support code activated';
    }
  }
  public get swissQRBillText(): string {
    return Labels.swissQRBillText(this.settings.language);
  }
  public static swissQRBillText(language: string): string {
    switch (language) {
      case 'de': return 'Swiss QR Bill Text';
      case 'fr': return 'Swiss QR Bill Texte';
      case 'it': return 'Swiss QR Bill Testo';
      case 'en': return 'Swiss QR Bill Text';
      default: return 'Swiss QR Bill Text';
    }
  }
  public get switchToBrightMode(): string {
    return Labels.switchToBrightMode(this.settings.language);
  }
  public static switchToBrightMode(language: string): string {
    switch (language) {
      case 'de': return 'In hellen Modus wechseln';
      case 'fr': return 'Passer en mode lumineux';
      case 'it': return 'Passa alla modalità luminosa';
      case 'en': return 'Switch to bright mode';
      default: return 'Switch to bright mode';
    }
  }
  public get switchToDarkMode(): string {
    return Labels.switchToDarkMode(this.settings.language);
  }
  public static switchToDarkMode(language: string): string {
    switch (language) {
      case 'de': return 'In dunklen Modus wechseln';
      case 'fr': return 'Passer en mode sombre';
      case 'it': return 'Passa alla modalità oscura';
      case 'en': return 'Switch to dark mode';
      default: return 'Switch to dark mode';
    }
  }
  public get table(): string {
    return Labels.table(this.settings.language);
  }
  public static table(language: string): string {
    switch (language) {
      case 'de': return 'Tabelle';
      case 'fr': return 'Tableau';
      case 'it': return 'Tabella';
      case 'en': return 'Table';
      default: return 'Table';
    }
  }
  public get tableDownloadError(): string {
    return Labels.tableDownloadError(this.settings.language);
  }
  public static tableDownloadError(language: string): string {
    switch (language) {
      case 'de': return 'Konnte Tabelle nicht herunterladen';
      case 'fr': return 'Impossible de télécharger le tableau';
      case 'it': return 'Impossibile scaricare la tabella';
      case 'en': return 'Could not donwload table';
      default: return 'Could not donwload table';
    }
  }
  public get tableLoadError(): string {
    return Labels.tableLoadError(this.settings.language);
  }
  public static tableLoadError(language: string): string {
    switch (language) {
      case 'de': return 'Konnte Tabelle nicht laden';
      case 'fr': return 'Impossible de charger le tableau';
      case 'it': return 'Impossibile caricare la tabella';
      case 'en': return 'Could not load table';
      default: return 'Could not load table';
    }
  }
  public get tableRowsPage(): string {
    return Labels.tableRowsPage(this.settings.language);
  }
  public static tableRowsPage(language: string): string {
    switch (language) {
      case 'de': return '{0}-{1} von {2}';
      case 'fr': return '{0}-{1} de {2}';
      case 'it': return '{0}-{1} di {2}';
      case 'en': return '{0}-{1} of {2}';
      default: return '{0}-{1} of {2}';
    }
  }
  public get tableRowsPerPage(): string {
    return Labels.tableRowsPerPage(this.settings.language);
  }
  public static tableRowsPerPage(language: string): string {
    switch (language) {
      case 'de': return 'Zeilen pro Seite';
      case 'fr': return 'Lignes par page';
      case 'it': return 'Righe per pagina';
      case 'en': return 'Rows per page';
      default: return 'Rows per page';
    }
  }
  public get text(): string {
    return Labels.text(this.settings.language);
  }
  public static text(language: string): string {
    switch (language) {
      case 'de': return 'Text';
      case 'fr': return 'Texte';
      case 'it': return 'Testo';
      case 'en': return 'Text';
      default: return 'Text';
    }
  }
  public get textColor(): string {
    return Labels.textColor(this.settings.language);
  }
  public static textColor(language: string): string {
    switch (language) {
      case 'de': return 'Textfarbe';
      case 'fr': return 'Couleur du texte';
      case 'it': return 'Colore del testo';
      case 'en': return 'Text color';
      default: return 'Text color';
    }
  }
  public get textStyle(): string {
    return Labels.textStyle(this.settings.language);
  }
  public static textStyle(language: string): string {
    switch (language) {
      case 'de': return 'Textstil';
      case 'fr': return 'Style de texte';
      case 'it': return 'Stile del testo';
      case 'en': return 'Text style';
      default: return 'Text style';
    }
  }
  public get textInFront(): string {
    return Labels.textInFront(this.settings.language);
  }
  public static textInFront(language: string): string {
    switch (language) {
      case 'de': return 'Text vor dem Wert';
      case 'fr': return 'Texte précédant la valeur';
      case 'it': return 'Testo prima del valore';
      case 'en': return 'Text in front of value';
      default: return 'Text in front of value';
    }
  }
  public get thanks(): string {
    return Labels.thanks(this.settings.language);
  }
  public static thanks(language: string): string {
    switch (language) {
      case 'de': return 'Danke!';
      case 'fr': return 'Merci!';
      case 'it': return 'Grazie!';
      case 'en': return 'Thank you!';
      default: return 'Thank you!';
    }
  }
  public get title(): string {
    return Labels.title(this.settings.language);
  }
  public static title(language: string): string {
    switch (language) {
      case 'de': return 'Titel';
      case 'fr': return 'Titre';
      case 'it': return 'Titolo';
      case 'en': return 'Title';
      default: return 'Title';
    }
  }
  public get titleText(): string {
    return Labels.titleText(this.settings.language);
  }
  public static titleText(language: string): string {
    switch (language) {
      case 'de': return 'Vorschau des generierten Einzahlungsscheins';
      case 'fr': return 'Aperçu du bulletin de versement généré';
      case 'it': return 'Anteprima del bollettino di pagamento generato';
      case 'en': return 'Preview of the generated payment slip';
      default: return 'Preview of the generated payment slip';
    }
  }
  public get today(): string {
    return Labels.today(this.settings.language);
  }
  public static today(language: string): string {
    switch (language) {
      case 'de': return 'Heute';
      case 'fr': return 'Aujourd\'hui';
      case 'it': return 'Oggi';
      case 'en': return 'Today';
      default: return 'Today';
    }
  }
  public get toLabel(): string {
    return Labels.toLabel(this.settings.language);
  }
  public static toLabel(language: string): string {
    switch (language) {
      case 'de': return 'An';
      case 'fr': return 'À';
      case 'it': return 'A';
      case 'en': return 'To';
      default: return 'To';
    }
  }
  public get townLabel(): string {
    return Labels.townLabel(this.settings.language);
  }
  public static townLabel(language: string): string {
    switch (language) {
      case 'de': return 'Ort';
      case 'fr': return 'Lieu';
      case 'it': return 'Località';
      case 'en': return 'Town';
      default: return 'Town';
    }
  }
  public get twint(): string {
    return Labels.twint(this.settings.language);
  }
  public static twint(language: string): string {
    switch (language) {
      case 'de': return 'TWINT';
      case 'fr': return 'TWINT';
      case 'it': return 'TWINT';
      case 'en': return 'TWINT';
      default: return 'TWINT';
    }
  }
  public get twintBannerMultiple(): string {
    return Labels.twintBannerMultiple(this.settings.language);
  }
  public static twintBannerMultiple(language: string): string {
    switch (language) {
      case 'de': return 'Diese QR-Einzahlungsscheine können auch mit TWINT gescannt werden.';
      case 'fr': return 'Ces bulletins de versement QR peuvent également être scannés avec TWINT.';
      case 'it': return 'Queste distinte di versamento QR puossono essere scansionate anche con TWINT.';
      case 'en': return 'These QR deposit slips can also be scanned using TWINT.';
      default: return 'These QR deposit slips can also be scanned using TWINT.';
    }
  }
  public get twintBannerSingle(): string {
    return Labels.twintBannerSingle(this.settings.language);
  }
  public static twintBannerSingle(language: string): string {
    switch (language) {
      case 'de': return 'Dieser QR-Einzahlungsschein kann auch mit TWINT gescannt werden.';
      case 'fr': return 'Ce bulletin de versement QR peut également être scanné avec TWINT.';
      case 'it': return 'Questa distinta di versamento QR può essere scansionata anche con TWINT.';
      case 'en': return 'This QR deposit slip can also be scanned using TWINT.';
      default: return 'This QR deposit slip can also be scanned using TWINT.';
    }
  }
  public get twintQrCode(): string {
    return Labels.twintQrCode(this.settings.language);
  }
  public static twintQrCode(language: string): string {
    switch (language) {
      case 'de': return 'Dies ist ein TWINT QR-Code';
      case 'fr': return 'Ceci est un code QR TWINT';
      case 'it': return 'Questo è un codice QR TWINT';
      case 'en': return 'This is a TWINT QR code';
      default: return 'This is a TWINT QR code';
    }
  }
  public get twintQrCodeCanImport(): string {
    return Labels.twintQrCodeCanImport(this.settings.language);
  }
  public static twintQrCodeCanImport(language: string): string {
    switch (language) {
      case 'de': return 'Dieser TWINT QR-Code kann in die QR-Rechnung eingefügt werden, so dass die QR-Rechnung auch mit der TWINT App gescannt und bezahlt werden kann.';
      case 'fr': return 'Ce code QR TWINT peut être inséré dans la QR-facture, de sorte que la QR-facture puisse également être scannée et payée avec l\'application TWINT.';
      case 'it': return 'Questo codice QR TWINT può essere inserito nella QR-fattura in modo che la QR-fattura possa anche essere scansionata e pagata con l\'app TWINT.';
      case 'en': return 'This TWINT QR code can be inserted into the QR-bill so that the QR-bill can also be scanned and paid with the TWINT app.';
      default: return 'This TWINT QR code can be inserted into the QR-bill so that the QR-bill can also be scanned and paid with the TWINT app.';
    }
  }
  public get twintQrCodeCanNotImport(): string {
    return Labels.twintQrCodeCanNotImport(this.settings.language);
  }
  public static twintQrCodeCanNotImport(language: string): string {
    switch (language) {
      case 'de': return 'Dieser TWINT QR-Code hat eine unbekannte Struktur und kann deshalb nicht in die QR-Rechnung eingefügt werden.';
      case 'fr': return 'Ce code QR TWINT a une structure inconnue et ne peut donc pas être inséré dans la QR-facture.';
      case 'it': return 'Questo codice QR TWINT ha una struttura sconosciuta e quindi non può essere inserito nella QR-fattura.';
      case 'en': return 'This TWINT QR code has an unknown structure and therefore cannot be inserted into the QR-bill.';
      default: return 'This TWINT QR code has an unknown structure and therefore cannot be inserted into the QR-bill.';
    }
  }
  public get twintParameter1(): string {
    return Labels.twintParameter1(this.settings.language);
  }
  public static twintParameter1(language: string): string {
    switch (language) {
      case 'de': return 'Parameter 1';
      case 'fr': return 'Paramètre 1';
      case 'it': return 'Parametro 1';
      case 'en': return 'Parameter 1';
      default: return 'Parameter 1';
    }
  }
  public get twintParameter2(): string {
    return Labels.twintParameter2(this.settings.language);
  }
  public static twintParameter2(language: string): string {
    switch (language) {
      case 'de': return 'Parameter 2';
      case 'fr': return 'Paramètre 2';
      case 'it': return 'Parametro 2';
      case 'en': return 'Parameter 2';
      default: return 'Parameter 2';
    }
  }
  public get ultimateCreditorAddress1InfoToolTip(): string {
    return Labels.ultimateCreditorAddress1InfoToolTip(this.settings.language);
  }
  public static ultimateCreditorAddress1InfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Adresszeile 1 mit Strasse und Hausnummer bzw. Postfach der endgültigen Zahlungsempfängeradresse. Maximal 70 Zeichen zulässig.';
      case 'fr': return 'Ligne d\'adresse 1 avec rue et numéro de maison ou case postale de l\'adresse du créancier final. 70 caractères au maximum admis.';
      case 'it': return 'Indirizzo linea 1, comprensivo di via e numero civico dell\'edificio o casella postale dall\'indirizzo del creditore finale. Massimo 70 caratteri consentiti.';
      case 'en': return 'Address line 1 including street and building number or P.O. Box from ultimate creditor\'s address. Maximum 70 characters permitted.';
      default: return 'Address line 1 including street and building number or P.O. Box from ultimate creditor\'s address. Maximum 70 characters permitted.';
    }
  }
  public get ultimateCreditorAddress2InfoToolTip(): string {
    return Labels.ultimateCreditorAddress2InfoToolTip(this.settings.language);
  }
  public static ultimateCreditorAddress2InfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Adresszeile 2 mit Postleitzahl und Ort der endgültigen Zahlungsempfängeradresse. Maximal 70 Zeichen zulässig';
      case 'fr': return 'Ligne d\'adresse 2 avec numéro postal d\'acheminement et localité de l\'adresse du créancier final. 70 caractères au maximum admis.';
      case 'it': return 'Indirizzo linea 2, compreso il codice postale e la città dall\'indirizzo del creditore finale. Massimo 70 caratteri consentiti.';
      case 'en': return 'Address line 2 including postal code and town from ultimate creditor\'s address. Maximum 70 characters permitted.';
      default: return 'Address line 2 including postal code and town from ultimate creditor\'s address. Maximum 70 characters permitted.';
    }
  }
  public get ultimateCreditorBuildingNumberInfoToolTip(): string {
    return Labels.ultimateCreditorBuildingNumberInfoToolTip(this.settings.language);
  }
  public static ultimateCreditorBuildingNumberInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Hausnummer der endgültigen Zahlungsempfängeradresse. Maximal 16 Zeichen zulässig.';
      case 'fr': return 'Numéro de maison de l\'adresse du créancier final. 16 caractères au maximum admis.';
      case 'it': return 'Numero dell\'edificio dall\'indirizzo del creditore finale. 16 caratteri ammessi.';
      case 'en': return 'Building number from ultimate creditor\'s address. 16 characters allowed.';
      default: return 'Building number from ultimate creditor\'s address. 16 characters allowed.';
    }
  }
  public get ultimateCreditorCountryInfoToolTip(): string {
    return Labels.ultimateCreditorCountryInfoToolTip(this.settings.language);
  }
  public static ultimateCreditorCountryInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Land der endgültigen Zahlungsempfängeradresse.';
      case 'fr': return 'Pays de l\'adresse du créancier final.';
      case 'it': return 'Paese dall\'indirizzo del creditore finale.';
      case 'en': return 'Country from ultimate creditor\'s address.';
      default: return 'Country from ultimate creditor\'s address.';
    }
  }
  public get ultimateCreditorLabel(): string {
    return Labels.ultimateCreditorLabel(this.settings.language);
  }
  public static ultimateCreditorLabel(language: string): string {
    switch (language) {
      case 'de': return 'Zugunsten';
      case 'fr': return 'En faveur de';
      case 'it': return 'A favore di';
      case 'en': return 'In favour of';
      default: return 'In favour of';
    }
  }
  public get ultimateCreditorNameInfoToolTip(): string {
    return Labels.ultimateCreditorNameInfoToolTip(this.settings.language);
  }
  public static ultimateCreditorNameInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Name bzw. Firma des endgültigen Zahlungsempfängers. Maximal 70 Zeichen zulässig. Vorname (optional, falls verfügbar) + Name oder Firmenbezeichnung.';
      case 'fr': return 'Nom ou entreprise du créancier final. 70 caractères au maximum admis. Prénom (optionnel, si disponible) + nom ou raison sociale.';
      case 'it': return 'Il nome o la società del creditore finale. Sono ammessi al massimo 70 caratteri. Nome (opzionale, se disponibile) + cognome o ragione sociale.';
      case 'en': return 'The ultimate creditor\'s name or company. Maximum 70 characters permitted. First name (optional, if available) + last name or company name.';
      default: return 'The ultimate creditor\'s name or company. Maximum 70 characters permitted. First name (optional, if available) + last name or company name.';
    }
  }
  public get ultimateCreditorPostalCodeInfoToolTip(): string {
    return Labels.ultimateCreditorPostalCodeInfoToolTip(this.settings.language);
  }
  public static ultimateCreditorPostalCodeInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Postleitzahl der endgültigen Zahlungsempfängeradresse. Maximal 16 Zeichen zulässig. Die Postleitzahl ist immer ohne vorangestellten Landescode anzugeben.';
      case 'fr': return 'Numéro postal d\'acheminement de l\'adresse du créancier final. 16 caractères au maximum admis. Le code postal est toujours à indiquer sans code de pays de tête.';
      case 'it': return 'Codice postale dall\'indirizzo del creditore finale. Massimo 16 caratteri ammessi. Il codice postale deve essere fornito senza prefisso del paese.';
      case 'en': return 'Postal code from ultimate creditor\'s address. Maximum 16 characters permitted. The postal code must be provided without a country code prefix.';
      default: return 'Postal code from ultimate creditor\'s address. Maximum 16 characters permitted. The postal code must be provided without a country code prefix.';
    }
  }
  public get ultimateCreditorStreetNameInfoToolTip(): string {
    return Labels.ultimateCreditorStreetNameInfoToolTip(this.settings.language);
  }
  public static ultimateCreditorStreetNameInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Strasse/Postfach der endgültigen Zahlungsempfängeradresse. Maximal 70 Zeichen zulässig.';
      case 'fr': return 'Rue/case postale de l\'adresse du créancier final. 70 caractères au maximum admis.';
      case 'it': return 'Via/Casella postale dall\'indirizzo del creditore finale. Massimo 70 caratteri consentiti.';
      case 'en': return 'Street/P.O. Box from ultimate creditor\'s address. Maximum 70 characters permitted.';
      default: return 'Street/P.O. Box from ultimate creditor\'s address. Maximum 70 characters permitted.';
    }
  }
  public get ultimateCreditorTownInfoToolTip(): string {
    return Labels.ultimateCreditorTownInfoToolTip(this.settings.language);
  }
  public static ultimateCreditorTownInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Ort der endgültigen Zahlungsempfängeradresse. Maximal 35 Zeichen zulässig.';
      case 'fr': return 'Lieu de l\'adresse du créancier final. 35 caractères au maximum admis.';
      case 'it': return 'Città dall\'indirizzo del creditore finale. Massimo 35 caratteri ammessi.';
      case 'en': return 'Town from ultimate creditor\'s address. Maximum 35 characters permitted.';
      default: return 'Town from ultimate creditor\'s address. Maximum 35 characters permitted.';
    }
  }
  public get ultimateDebtorAddress1InfoToolTip(): string {
    return Labels.ultimateDebtorAddress1InfoToolTip(this.settings.language);
  }
  public static ultimateDebtorAddress1InfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Adresszeile 1 mit Strasse und Hausnummer bzw. Postfach der endgültigen Zahlungspflichtigenadresse. Maximal 70 Zeichen zulässig.';
      case 'fr': return 'Ligne d\'adresse 1 avec rue et numéro de maison ou case postale de l\'adresse du débiteur final. 70 caractères au maximum admis.';
      case 'it': return 'Indirizzo linea 1, comprensivo di via e numero civico dell\'edificio o casella postale dall\'indirizzo del debitore finale. Massimo 70 caratteri consentiti.';
      case 'en': return 'Address line 1 including street and building number or P.O. Box from ultimate debtor\'s address. Maximum 70 characters permitted.';
      default: return 'Address line 1 including street and building number or P.O. Box from ultimate debtor\'s address. Maximum 70 characters permitted.';
    }
  }
  public get ultimateDebtorAddress2InfoToolTip(): string {
    return Labels.ultimateDebtorAddress2InfoToolTip(this.settings.language);
  }
  public static ultimateDebtorAddress2InfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Adresszeile 2 mit Postleitzahl und Ort der endgültigen Zahlungspflichtigenadresse. Maximal 70 Zeichen zulässig';
      case 'fr': return 'Ligne d\'adresse 2 avec numéro postal d\'acheminement et localité de l\'adresse du débiteur final. 70 caractères au maximum admis.';
      case 'it': return 'Indirizzo linea 2, compreso il codice postale e la città dall\'indirizzo del debitore finale. Massimo 70 caratteri consentiti.';
      case 'en': return 'Address line 2 including postal code and town from ultimate debtor\'s address. Maximum 70 characters permitted.';
      default: return 'Address line 2 including postal code and town from ultimate debtor\'s address. Maximum 70 characters permitted.';
    }
  }
  public get ultimateDebtorBuildingNumberInfoToolTip(): string {
    return Labels.ultimateDebtorBuildingNumberInfoToolTip(this.settings.language);
  }
  public static ultimateDebtorBuildingNumberInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Hausnummer der endgültigen Zahlungspflichtigenadresse. Maximal 16 Zeichen zulässig.';
      case 'fr': return 'Numéro de maison de l\'adresse du débiteur final. 16 caractères au maximum admis.';
      case 'it': return 'Numero dell\'edificio dall\'indirizzo del debitore finale. 16 caratteri ammessi.';
      case 'en': return 'Building number from ultimate debtor\'s address. 16 characters allowed.';
      default: return 'Building number from ultimate debtor\'s address. 16 characters allowed.';
    }
  }
  public get ultimateDebtorCountryInfoToolTip(): string {
    return Labels.ultimateDebtorCountryInfoToolTip(this.settings.language);
  }
  public static ultimateDebtorCountryInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Land der Adresse des endgültigen Zahlungspflichtigen.';
      case 'fr': return 'Pays de l\'adresse du débiteur final.';
      case 'it': return 'Paese dall\'indirizzo del debitore finale.';
      case 'en': return 'Country from ultimate debtor\'s address.';
      default: return 'Country from ultimate debtor\'s address.';
    }
  }
  public get ultimateDebtorNameInfoToolTip(): string {
    return Labels.ultimateDebtorNameInfoToolTip(this.settings.language);
  }
  public static ultimateDebtorNameInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Name bzw. Firma des endgültigen Zahlungspflichtigen. Maximal 70 Zeichen zulässig. Vorname (optional, falls verfügbar) + Name oder Firmenbezeichnung. Lange Namen können mit einem Zeilenumbruch auf zwei Zeilen aufgeteilt werden.';
      case 'fr': return 'Nom ou entreprise du débiteur final. 70 caractères au maximum admis. Prénom (optionnel, si disponible) + nom ou raison sociale. Les noms longs peuvent être divisés en deux lignes avec un retour à la ligne.';
      case 'it': return 'Il nome o la società del debitore finale. Sono ammessi al massimo 70 caratteri. Nome (opzionale, se disponibile) + cognome o ragione sociale. I nomi lunghi possono essere divisi in due righe con un\'interruzione di riga.';
      case 'en': return 'The ultimate debtor\'s name or company. Maximum 70 characters permitted. First name (optional, if available) + last name or company name. Long names can be split into two lines with a line break.';
      default: return 'The ultimate debtor\'s name or company. Maximum 70 characters permitted. First name (optional, if available) + last name or company name. Long names can be split into two lines with a line break.';
    }
  }
  public get ultimateDebtorPostalCodeInfoToolTip(): string {
    return Labels.ultimateDebtorPostalCodeInfoToolTip(this.settings.language);
  }
  public static ultimateDebtorPostalCodeInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Postleitzahl der Adresse des endgültigen Zahlungspflichtigen. Maximal 16 Zeichen zulässig. Die Postleitzahl ist immer ohne vorangestellten Landescode anzugeben.';
      case 'fr': return 'Numéro postal d\'acheminement de l\'adresse du débiteur final. 16 caractères au maximum admis. Le code postal est toujours à indiquer sans code de pays de tête.';
      case 'it': return 'Codice postale dall\'indirizzo del debitore finale. Massimo 16 caratteri ammessi. Il codice postale deve essere fornito senza prefisso del paese.';
      case 'en': return 'Postal code from ultimate debtor\'s address. Maximum 16 characters permitted. The postal code must be provided without a country code prefix.';
      default: return 'Postal code from ultimate debtor\'s address. Maximum 16 characters permitted. The postal code must be provided without a country code prefix.';
    }
  }
  public get ultimateDebtorStreetNameInfoToolTip(): string {
    return Labels.ultimateDebtorStreetNameInfoToolTip(this.settings.language);
  }
  public static ultimateDebtorStreetNameInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Strasse/Postfach der endgültigen Zahlungspflichtigenadresse. Maximal 70 Zeichen zulässig.';
      case 'fr': return 'Rue/case postale de l\'adresse du débiteur final. 70 caractères au maximum admis.';
      case 'it': return 'Via/Casella postale dall\'indirizzo del debitore finale. Massimo 70 caratteri consentiti.';
      case 'en': return 'Street/P.O. Box from ultimate debtor\'s address. Maximum 70 characters permitted.';
      default: return 'Street/P.O. Box from ultimate debtor\'s address. Maximum 70 characters permitted.';
    }
  }
  public get ultimateDebtorTownInfoToolTip(): string {
    return Labels.ultimateDebtorTownInfoToolTip(this.settings.language);
  }
  public static ultimateDebtorTownInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Ort der Adresse des endgültigen Zahlungspflichtigen. Maximal 35 Zeichen zulässig.';
      case 'fr': return 'Lieu de l\'adresse du débiteur final. 35 caractères au maximum admis.';
      case 'it': return 'Città dall\'indirizzo del debitore finale. Massimo 35 caratteri ammessi.';
      case 'en': return 'Town from ultimate debtor\'s address. Maximum 35 characters permitted.';
      default: return 'Town from ultimate debtor\'s address. Maximum 35 characters permitted.';
    }
  }
  public get underline(): string {
    return Labels.underline(this.settings.language);
  }
  public static underline(language: string): string {
    switch (language) {
      case 'de': return 'Unterstrichen';
      case 'fr': return 'Soulignée';
      case 'it': return 'Sottolineato';
      case 'en': return 'Underlined';
      default: return 'Underlined';
    }
  }
  public get unstructuredMessageInfoToolTip(): string {
    return Labels.unstructuredMessageInfoToolTip(this.settings.language);
  }
  public static unstructuredMessageInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Unstrukturierte Informationen können zur Angabe eines Zahlungszwecks oder für ergänzende textuelle Informationen zu Zahlungen mit strukturierter Referenz verwendet werden. Langer Text kann mit einem Zeilenumbruch auf zwei Zeilen aufgeteilt werden. Unstrukturierte Mitteilung und Rechnungsinformationen dürfen zusammen maximal 140 Zeichen enthalten.';
      case 'fr': return 'Les informations instructurées peuvent être utilisées pour l\'indication d\'un motif de paiement ou pour des informations textuelles complémentaires au sujet de paiements avec référence structurée. Du texte long peut être divisé en deux lignes avec un retour à la ligne. Communication instructurée et informations de facture peuvent contenir ensemble jusqu\'à 140 caractères.';
      case 'it': return 'Le informazioni non strutturate possono essere utilizzate per indicare lo scopo del pagamento o per ulteriori informazioni testuali sui pagamenti con un riferimento strutturato. Testo lungo può essere diviso in due righe con un\'interruzione di riga. Il messaggio non strutturato e le istruzioni di prenotazione possono contenere un totale comune fino a 140 caratteri.';
      case 'en': return 'Unstructured information can be used to indicate the payment purpose or for additional textual information about payments with a structured reference. Long text can be split into two lines with a line break. Unstructured message and Booking instructions may contain a common total of up to 140 characters.';
      default: return 'Unstructured information can be used to indicate the payment purpose or for additional textual information about payments with a structured reference. Long text can be split into two lines with a line break. Unstructured message and Booking instructions may contain a common total of up to 140 characters.';
    }
  }
  public get unstructuredMessageLabel(): string {
    return Labels.unstructuredMessageLabel(this.settings.language);
  }
  public static unstructuredMessageLabel(language: string): string {
    switch (language) {
      case 'de': return 'Unstrukturierte Mitteilung';
      case 'fr': return 'Communication instructurée';
      case 'it': return 'Messaggio non strutturato';
      case 'en': return 'Unstructured message';
      default: return 'Unstructured message';
    }
  }
  public get unstructuredMessageNotification(): string {
    return Labels.unstructuredMessageNotification(this.settings.language);
  }
  public static unstructuredMessageNotification(language: string): string {
    switch (language) {
      case 'de': return 'NICHT ZUR ZAHLUNG VERWENDEN';
      case 'fr': return 'NE PAS UTILISER POUR LE PAIEMENT';
      case 'it': return 'NON UTILIZZARE PER IL PAGAMENTO';
      case 'en': return 'DO NOT USE FOR PAYMENT';
      default: return 'DO NOT USE FOR PAYMENT';
    }
  }
  public get validationErrorTitle(): string {
    return Labels.validationErrorTitle(this.settings.language);
  }
  public static validationErrorTitle(language: string): string {
    switch (language) {
      case 'de': return 'Angaben ungültig';
      case 'fr': return 'Données non valides';
      case 'it': return 'Dati non validi';
      case 'en': return 'Invalid data';
      default: return 'Invalid data';
    }
  }
  public get validationErrorHint(): string {
    return Labels.validationErrorHint(this.settings.language);
  }
  public static validationErrorHint(language: string): string {
    switch (language) {
      case 'de': return 'Die folgenden Angaben der QR-Rechnung sind ungültig:';
      case 'fr': return 'Les données suivantes de la QR-facture ne sont pas valides:';
      case 'it': return 'I seguenti dati di QR-fattura non sono validi:';
      case 'en': return 'The following data items of the QR-bill are invalid:';
      default: return 'The following data items of the QR-bill are invalid:';
    }
  }
  public get validationErrorContinueAnyway(): string {
    return Labels.validationErrorContinueAnyway(this.settings.language);
  }
  public static validationErrorContinueAnyway(language: string): string {
    switch (language) {
      case 'de': return 'Trotzdem fortfahren';
      case 'fr': return 'Continuer quand même';
      case 'it': return 'Continua comunque';
      case 'en': return 'Continue anyway';
      default: return 'Continue anyway';
    }
  }
  public get validationSuccessTitle(): string {
    return Labels.validationSuccessTitle(this.settings.language);
  }
  public static validationSuccessTitle(language: string): string {
    switch (language) {
      case 'de': return 'Angaben gültig';
      case 'fr': return 'Données valides';
      case 'it': return 'Dati validi';
      case 'en': return 'Valid data';
      default: return 'Valid data';
    }
  }
  public get value(): string {
    return Labels.value(this.settings.language);
  }
  public static value(language: string): string {
    switch (language) {
      case 'de': return 'Wert';
      case 'fr': return 'Valeur';
      case 'it': return 'Valore';
      case 'en': return 'Value';
      default: return 'Value';
    }
  }
  public get variant(): string {
    return Labels.variant(this.settings.language);
  }
  public static variant(language: string): string {
    switch (language) {
      case 'de': return 'Ausprägung';
      case 'fr': return 'Variante';
      case 'it': return 'Variante';
      case 'en': return 'Variant';
      default: return 'Variant';
    }
  }
  public get variantInfoToolTip1(): string {
    return Labels.variantInfoToolTip1(this.settings.language);
  }
  public static variantInfoToolTip1(language: string): string {
    switch (language) {
      case 'de': return 'Es gibt drei Ausprägungen der QR-Rechnung:';
      case 'fr': return 'Il y a trois variantes de la QR-facture:';
      case 'it': return 'Ci sono tre varianti della QR-fattura:';
      case 'en': return 'There are three variants of the QR-bill:';
      default: return 'There are three variants of the QR-bill:';
    }
  }
  public get variantInfoToolTip2(): string {
    return Labels.variantInfoToolTip2(this.settings.language);
  }
  public static variantInfoToolTip2(language: string): string {
    switch (language) {
      case 'de': return '• QR-IBAN mit QR-Referenz - Ersetzt den orangen Einzahlungsschein';
      case 'fr': return '• QR-IBAN avec référence QR - Remplace le bulletin de versement orange';
      case 'it': return '• QR-IBAN con riferimento QR - sostituisce la polizza di versamento arancione';
      case 'en': return '• QR-IBAN with QR reference - Replaces the orange payment slip';
      default: return '• QR-IBAN with QR reference - Replaces the orange payment slip';
    }
  }
  public get variantInfoToolTip3(): string {
    return Labels.variantInfoToolTip3(this.settings.language);
  }
  public static variantInfoToolTip3(language: string): string {
    switch (language) {
      case 'de': return '• IBAN mit Creditor Reference - Neue Zahlungsmöglichkeit';
      case 'fr': return '• IBAN avec Creditor Reference - Nouvelle option de paiement';
      case 'it': return '• IBAN con Creditor Reference - Nuova opzione di pagamento';
      case 'en': return '• IBAN with Creditor Reference - New payment option';
      default: return '• IBAN with Creditor Reference - New payment option';
    }
  }
  public get variantInfoToolTip4(): string {
    return Labels.variantInfoToolTip4(this.settings.language);
  }
  public static variantInfoToolTip4(language: string): string {
    switch (language) {
      case 'de': return '• IBAN ohne Referenz - Ersetzt den roten Einzahlungsschein';
      case 'fr': return '• IBAN sans référence - Remplace le bulletin de versement rouge';
      case 'it': return '• IBAN senza riferimento - Sostituisce la polizza di versamento rossa';
      case 'en': return '• IBAN without reference - Replaces the red payment slip';
      default: return '• IBAN without reference - Replaces the red payment slip';
    }
  }
  public get variantNON(): string {
    return Labels.variantNON(this.settings.language);
  }
  public static variantNON(language: string): string {
    switch (language) {
      case 'de': return 'IBAN ohne Referenz';
      case 'fr': return 'IBAN sans référence';
      case 'it': return 'IBAN senza riferimento';
      case 'en': return 'IBAN without reference';
      default: return 'IBAN without reference';
    }
  }
  public get variantQRR(): string {
    return Labels.variantQRR(this.settings.language);
  }
  public static variantQRR(language: string): string {
    switch (language) {
      case 'de': return 'QR-IBAN mit QR-Referenz (ehem. ESR-Referenz)';
      case 'fr': return 'QR-IBAN avec référence QR (préc. référence BVR)';
      case 'it': return 'QR-IBAN con riferimento QR (ex riferimento PVR)';
      case 'en': return 'QR-IBAN with QR reference (formerly ESR reference)';
      default: return 'QR-IBAN with QR reference (formerly ESR reference)';
    }
  }
  public get variantSCOR(): string {
    return Labels.variantSCOR(this.settings.language);
  }
  public static variantSCOR(language: string): string {
    switch (language) {
      case 'de': return 'IBAN mit Creditor Reference (ISO 11649)';
      case 'fr': return 'IBAN avec Creditor Reference (ISO 11649)';
      case 'it': return 'IBAN con Creditor Reference (ISO 11649)';
      case 'en': return 'IBAN with creditor reference (ISO 11649)';
      default: return 'IBAN with creditor reference (ISO 11649)';
    }
  }
  public get vatAmountLabel(): string {
    return Labels.vatAmountLabel(this.settings.language);
  }
  public static vatAmountLabel(language: string): string {
    switch (language) {
      case 'de': return 'Netto-Betrag';
      case 'fr': return 'Montant net';
      case 'it': return 'Importo netto';
      case 'en': return 'Net amount';
      default: return 'Net amount';
    }
  }
  public get vatDateLabel(): string {
    return Labels.vatDateLabel(this.settings.language);
  }
  public static vatDateLabel(language: string): string {
    switch (language) {
      case 'de': return 'MWST Datum';
      case 'fr': return 'Date TVA';
      case 'it': return 'Data IVA';
      case 'en': return 'VAT date';
      default: return 'VAT date';
    }
  }
  public get vatDateLineLabel(): string {
    return Labels.vatDateLineLabel(this.settings.language);
  }
  public static vatDateLineLabel(language: string): string {
    switch (language) {
      case 'de': return 'MWST Datum (Zeile)';
      case 'fr': return 'Date TVA (Ligne)';
      case 'it': return 'Data IVA (Linea)';
      case 'en': return 'VAT date (Line)';
      default: return 'VAT date (Line)';
    }
  }
  public get vatDetailsInfoToolTip(): string {
    return Labels.vatDetailsInfoToolTip(this.settings.language);
  }
  public static vatDetailsInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'MWST Details: Satz für die Rechnung oder Liste der Sätze mit entsprechenden Nettobeträgen. Die MWST-Details beziehen sich auf den Betrag der Rechnung, ohne Skonto. MWST-Details enthalten entweder einen einzigen Prozentsatz, der auf den gesamten Betrag der Rechnung anzuwenden ist (Nettobetrag = 0), oder eine Liste der MWST-Beträge, definiert durch einen Prozentsatz und einem Nettobetrag. Der Nettobetrag entspricht dem Nettopreis (exklusiv MWST), auf den die MWST gerechnet wird. Falls eine Liste angegeben wird, müssen die Summe der Nettobeträge und deren berechnete MWST dem Betrag des QR-Codes entsprechen.';
      case 'fr': return 'Détails TVA: Taux pour la facture ou liste des taux avec montants nets correspondants. Les détails de la TVA s\'appliquent au montant de la facture, sans escompte. Les détails de la TVA contiennent soit un pourcentage unique qui s\'applique à la totalité du montant de la facture (montant net = 0), soit une liste des montants de TVA, définis par un pourcentage et un montant net. Le montant net correspond au prix net (sans TVA), à partir duquel la TVA est calculée. Si une liste est indiquée, la somme des montants nets et de la TVA calculée sur ceux-ci doit correspondre au montant du code QR.';
      case 'it': return 'Dettagli IVA: Aliquota per il calcolo o elenco delle aliquote con i corrispondenti importi netti. I dati IVA si riferiscono all\'importo fatturato, escluso lo sconto. I dati IVA contengono una percentuale unica da applicare all\'intero importo fatturato (importo netto = 0) o un elenco degli importi IVA, definiti da un\'aliquota percentuale e da un importo netto. L\'importo netto è il prezzo netto (IVA esclusa) sul quale viene calcolata l\'IVA. Se viene fornito un elenco, il totale degli importi netti e l\'IVA calcolata su di essi deve corrispondere all\'importo indicato nel QR Code.';
      case 'en': return 'VAT details: Rate for calculation or list of rates with corresponding net amounts. The VAT details refer to the invoiced amount, excluding any discount. VAT details contain either a single percentage that is to be applied to the whole invoiced amount (net amount = 0) or a list of the VAT amounts, defined by a percentage rate and a net amount. The net amount is the net price (excluding VAT) on which the VAT is calculated. If a list is given, the total of the net amounts and the VAT calculated on them must correspond to the amount in the QR Code.';
      default: return 'VAT details: Rate for calculation or list of rates with corresponding net amounts. The VAT details refer to the invoiced amount, excluding any discount. VAT details contain either a single percentage that is to be applied to the whole invoiced amount (net amount = 0) or a list of the VAT amounts, defined by a percentage rate and a net amount. The net amount is the net price (excluding VAT) on which the VAT is calculated. If a list is given, the total of the net amounts and the VAT calculated on them must correspond to the amount in the QR Code.';
    }
  }
  public get vatDetailsLabel(): string {
    return Labels.vatDetailsLabel(this.settings.language);
  }
  public static vatDetailsLabel(language: string): string {
    switch (language) {
      case 'de': return 'MWST Details';
      case 'fr': return 'Détails TVA';
      case 'it': return 'Dettagli dell\'IVA';
      case 'en': return 'VAT details';
      default: return 'VAT details';
    }
  }
  public get vatEndDateInfoToolTip(): string {
    return Labels.vatEndDateInfoToolTip(this.settings.language);
  }
  public static vatEndDateInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Enddatum der Leistung (z.B. bei einem Abonnement). Wenn das Dokument mehrere Leistungen mit unterschiedlichen Leistungsdaten vorweist, muss dieses Feld weggelassen werden (manuelle Erfassung).';
      case 'fr': return 'Date de fin de la prestation (par ex. dans le cas d\'un abonnement). Si le document se rapporte à plusieurs prestations avec des dates de prestation différentes, ce champ doit être omis (saisie manuelle).';
      case 'it': return 'Data di fine del servizio (ad esempio per un abbonamento). Se il documento si riferisce a più servizi con date di fornitura diverse, questo campo deve essere omesso (inserire manualmente).';
      case 'en': return 'End date of the service (e.g. for a subscription). If the document refers to several services with different dates of delivery, this field must be omitted (enter manually).';
      default: return 'End date of the service (e.g. for a subscription). If the document refers to several services with different dates of delivery, this field must be omitted (enter manually).';
    }
  }
  public get vatEndDateLabel(): string {
    return Labels.vatEndDateLabel(this.settings.language);
  }
  public static vatEndDateLabel(language: string): string {
    switch (language) {
      case 'de': return 'MWST End-Datum';
      case 'fr': return 'Date de fin TVA';
      case 'it': return 'Data di fine IVA';
      case 'en': return 'VAT end date';
      default: return 'VAT end date';
    }
  }
  public get vatImportAmountLabel(): string {
    return Labels.vatImportAmountLabel(this.settings.language);
  }
  public static vatImportAmountLabel(language: string): string {
    switch (language) {
      case 'de': return 'MWST Betrag';
      case 'fr': return 'Montant TVA';
      case 'it': return 'Importo IVA';
      case 'en': return 'VAT amount';
      default: return 'VAT amount';
    }
  }
  public get vatImportInfoToolTip(): string {
    return Labels.vatImportInfoToolTip(this.settings.language);
  }
  public static vatImportInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'MWST Einfuhrsteuer: Reiner MWST Betrag oder Liste der reinen MWST Beträge und entsprechenden Sätze bei Einfuhr. Bei Warenimport kann die Einfuhrsteuer in diesem Feld angegeben werden. Es handelt sich hier um den MWST-Betrag. Der Satz dient der korrekten Verbuchung der MWST in der Finanzbuchhaltung. Dies vereinfacht dem Rechnungsempfänger beim Import die Verbuchung der MWST.';
      case 'fr': return 'TVA sur les importations: Montant de la TVA seule ou liste des montants de la TVA seule et taux correspondants à l\'importation. Dans le cas d\'une importation de marchandises, il est possible d\'indiquer l\'impôt sur les importations dans ce champ. Il s\'agit ici du montant de la TVA. Le taux sert à la comptabilisation correcte de la TVA dans la comptabilité financière. Ceci simplifie la comptabilisation par le destinataire de la facture de la TVA liée aux importations.';
      case 'it': return 'Imposta di importazione IVA: Importo IVA puro o un elenco di importi IVA puro e le rispettive aliquote per l\'importazione. In caso di importazione di merci, l\'imposta di importazione può essere inserita in questo campo. L\'importo è l\'importo dell\'IVA. L\'aliquota serve alla corretta registrazione dell\'IVA nella contabilità. In questo modo è più facile per il destinatario della fattura registrare l\'IVA in caso di importazione.';
      case 'en': return 'VAT import tax: Pure VAT amount or a list of pure VAT amounts and respective rates for import. Where goods are imported, the import tax can be entered in this field. The amount is the VAT amount. The rate serves correct recording of VAT in the accounts. This makes it easier for the bill recipient to record the VAT in the case of an import.';
      default: return 'VAT import tax: Pure VAT amount or a list of pure VAT amounts and respective rates for import. Where goods are imported, the import tax can be entered in this field. The amount is the VAT amount. The rate serves correct recording of VAT in the accounts. This makes it easier for the bill recipient to record the VAT in the case of an import.';
    }
  }
  public get vatImportLabel(): string {
    return Labels.vatImportLabel(this.settings.language);
  }
  public static vatImportLabel(language: string): string {
    switch (language) {
      case 'de': return 'MWST Einfuhrsteuer';
      case 'fr': return 'TVA sur les importations';
      case 'it': return 'IVA sulle importazioni';
      case 'en': return 'VAT import tax';
      default: return 'VAT import tax';
    }
  }
  public get vatNetAmountHint(): string {
    return Labels.vatNetAmountHint(this.settings.language);
  }
  public static vatNetAmountHint(language: string): string {
    switch (language) {
      case 'de': return '0 = auf gesamtem Rechnungsbetrag';
      case 'fr': return '0 = sur le montant total de la facture';
      case 'it': return '0 = sull\'intero importo della fattura';
      case 'en': return '0 = on entire invoice amount';
      default: return '0 = on entire invoice amount';
    }
  }
  public get vatNumberInfoToolTip(): string {
    return Labels.vatNumberInfoToolTip(this.settings.language);
  }
  public static vatNumberInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'MWST Nummer, UID Nummer. Die MWST-Nummer entspricht der numerischen UID des Leistungsbringers. Die MWST-Nummer kann vom Rechnungsempfänger benutzt werden, um den Rechnungssteller eindeutig zu identifizieren. Jeder Rechnungssteller, der über eine UID verfügt, soll diese hier mitführen, auch wenn die anderen MWST-Felder weggelassen werden. Bei einer Rechnung mit mehreren MWST-Nummern muss die erste angegeben werden.';
      case 'fr': return 'Numéro de TVA, Numéro UID. Le numéro de TVA correspond à la forme numérique de l\'UID du fournisseur de la prestation. Le numéro de TVA peut être utilisé par le destinataire de la facture pour identifier de manière non équivoque l\'émetteur de la facture. Tout émetteur de facture qui dispose d\'une UID doit indiquer celle-ci dans ce champ, et ce même si les autres données relatives à la TVA sont omises. Dans le cas d\'une facture comportant plusieurs numéros de TVA, la première doit être indiquée ici.';
      case 'it': return 'Partita IVA, numero UID. Il numero di partita IVA è lo stesso dell\'UID numerico del fornitore di servizi. Il numero di partita IVA può essere utilizzato dal destinatario della fattura per identificare l\'emittente di fatture in modo inequivocabile. Tutti gli emittenti di fatture che hanno un UID devono inserirlo qui, anche se gli altri campi IVA sono omessi. Per una fattura con più di un numero di partita IVA, è necessario inserire il primo.';
      case 'en': return 'VAT number, UID number. The VAT number is the same as the numerical UID of the service provider. The VAT number can be used by the bill recipient to identify the biller unambiguously. All billers who have a UID should enter it here, even if the other VAT fields are omitted. For a bill with more than one VAT number, the first should be entered.';
      default: return 'VAT number, UID number. The VAT number is the same as the numerical UID of the service provider. The VAT number can be used by the bill recipient to identify the biller unambiguously. All billers who have a UID should enter it here, even if the other VAT fields are omitted. For a bill with more than one VAT number, the first should be entered.';
    }
  }
  public get vatNumberLabel(): string {
    return Labels.vatNumberLabel(this.settings.language);
  }
  public static vatNumberLabel(language: string): string {
    switch (language) {
      case 'de': return 'MWST UID Nummer';
      case 'fr': return 'Numéro UID de TVA';
      case 'it': return 'Numero UID IVA';
      case 'en': return 'VAT number';
      default: return 'VAT number';
    }
  }
  public get vatNumberLineLabel(): string {
    return Labels.vatNumberLineLabel(this.settings.language);
  }
  public static vatNumberLineLabel(language: string): string {
    switch (language) {
      case 'de': return 'MWST UID Nummer (Zeile)';
      case 'fr': return 'Numéro UID de TVA (Ligne)';
      case 'it': return 'Numero UID IVA (Linea)';
      case 'en': return 'VAT number (Line)';
      default: return 'VAT number (Line)';
    }
  }
  public get vatRateLabel(): string {
    return Labels.vatRateLabel(this.settings.language);
  }
  public static vatRateLabel(language: string): string {
    switch (language) {
      case 'de': return 'Satz';
      case 'fr': return 'Taux';
      case 'it': return 'Aliquota';
      case 'en': return 'Rate';
      default: return 'Rate';
    }
  }
  public get vatStartDateInfoToolTip(): string {
    return Labels.vatStartDateInfoToolTip(this.settings.language);
  }
  public static vatStartDateInfoToolTip(language: string): string {
    switch (language) {
      case 'de': return 'Datum oder Anfangsdatum der Leistung. Das MWST-Datum kann entweder dem Leistungsdatum oder dem Anfangsdatum der Leistung entsprechen (z.B. bei einem Abonnement). Wenn das Dokument mehrere Leistungen mit unterschiedlichen Leistungsdaten vorweist, muss dieses Feld weggelassen werden (manuelle Erfassung).';
      case 'fr': return 'Date ou date de début de la prestation. La date de la TVA peut correspondre soit à la date de la prestation, soit à la date de début de la prestation (par ex. dans le cas d\'un abonnement). Si le document se rapporte à plusieurs prestations avec des dates de prestation différentes, ce champ doit être omis (saisie manuelle).';
      case 'it': return 'Data o data di inizio del servizio. La data IVA può essere la data in cui il servizio è stato fornito o la data di inizio del servizio (ad esempio per un abbonamento). Se il documento si riferisce a più servizi con date di fornitura diverse, questo campo deve essere omesso (inserire manualmente).';
      case 'en': return 'Date or start date of the service. The VAT date can either be the date on which the service was provided or the start date of the service (e.g. for a subscription). If the document refers to several services with different dates of delivery, this field must be omitted (enter manually).';
      default: return 'Date or start date of the service. The VAT date can either be the date on which the service was provided or the start date of the service (e.g. for a subscription). If the document refers to several services with different dates of delivery, this field must be omitted (enter manually).';
    }
  }
  public get vatStartDateLabel(): string {
    return Labels.vatStartDateLabel(this.settings.language);
  }
  public static vatStartDateLabel(language: string): string {
    switch (language) {
      case 'de': return 'MWST (Anfangs-)Datum';
      case 'fr': return 'Date (de début) TVA';
      case 'it': return 'Data (di inizio) IVA';
      case 'en': return 'VAT (start) date';
      default: return 'VAT (start) date';
    }
  }
  public get whatIsNew(): string {
    return Labels.whatIsNew(this.settings.language);
  }
  public static whatIsNew(language: string): string {
    switch (language) {
      case 'de': return 'Was ist neu?';
      case 'fr': return 'Quoi de neuf?';
      case 'it': return 'Cosa c\'è di nuovo?';
      case 'en': return 'What\'s new?';
      default: return 'What\'s new?';
    }
  }
  public get windowEnvelopeNormC5Title(): string {
    return Labels.windowEnvelopeNormC5Title(this.settings.language);
  }
  public static windowEnvelopeNormC5Title(language: string): string {
    switch (language) {
      case 'de': return 'Norm für Fensterkuverts C5:';
      case 'fr': return 'Standard pour les enveloppes à fenêtre C5:';
      case 'it': return 'Standard per buste a finestra C5:';
      case 'en': return 'Standard for window envelopes C5:';
      default: return 'Standard for window envelopes C5:';
    }
  }
  public get windowEnvelopeNormSNLine1(): string {
    return Labels.windowEnvelopeNormSNLine1(this.settings.language);
  }
  public static windowEnvelopeNormSNLine1(language: string): string {
    switch (language) {
      case 'de': return 'Alte Schweizer Norm (SN)';
      case 'fr': return 'Ancien Standard Suisse (SN)';
      case 'it': return 'Vecchio Standard Svizzero (SN)';
      case 'en': return 'Old Swiss Standard (SN)';
      default: return 'Old Swiss Standard (SN)';
    }
  }
  public get windowEnvelopeNormSNLine2(): string {
    return Labels.windowEnvelopeNormSNLine2(this.settings.language);
  }
  public static windowEnvelopeNormSNLine2(language: string): string {
    switch (language) {
      case 'de': return 'Fensterabstand unten: 65 mm';
      case 'fr': return 'Espacement de la fenêtre en bas: 65 mm';
      case 'it': return 'Spaziatura della finestra in basso: 65 mm';
      case 'en': return 'Window spacing bottom: 65 mm';
      default: return 'Window spacing bottom: 65 mm';
    }
  }
  public get windowEnvelopeNormNNLine1(): string {
    return Labels.windowEnvelopeNormNNLine1(this.settings.language);
  }
  public static windowEnvelopeNormNNLine1(language: string): string {
    switch (language) {
      case 'de': return 'Neue Schweizer Norm (NN)';
      case 'fr': return 'Nouveau Standard Suisse (NN)';
      case 'it': return 'Nuovo Standard Svizzero (NN)';
      case 'en': return 'New Swiss Standard (NN)';
      default: return 'New Swiss Standard (NN)';
    }
  }
  public get windowEnvelopeNormNNLine2(): string {
    return Labels.windowEnvelopeNormNNLine2(this.settings.language);
  }
  public static windowEnvelopeNormNNLine2(language: string): string {
    switch (language) {
      case 'de': return 'Fensterabstand unten: 77 mm';
      case 'fr': return 'Espacement de la fenêtre en bas: 77 mm';
      case 'it': return 'Spaziatura della finestra in basso: 77 mm';
      case 'en': return 'Window spacing bottom: 77 mm';
      default: return 'Window spacing bottom: 77 mm';
    }
  }
  public get write(): string {
    return Labels.write(this.settings.language);
  }
  public static write(language: string): string {
    switch (language) {
      case 'de': return 'Schreiben';
      case 'fr': return 'Écrire';
      case 'it': return 'Scrivi';
      case 'en': return 'Write';
      default: return 'Write';
    }
  }
  public get writeEmail(): string {
    return Labels.writeEmail(this.settings.language);
  }
  public static writeEmail(language: string): string {
    switch (language) {
      case 'de': return 'Email schreiben';
      case 'fr': return 'Écrire email';
      case 'it': return 'Scrivi email';
      case 'en': return 'Write email';
      default: return 'Write email';
    }
  }
  public get writeInvoice(): string {
    return Labels.writeInvoice(this.settings.language);
  }
  public static writeInvoice(language: string): string {
    switch (language) {
      case 'de': return 'Rechnung schreiben';
      case 'fr': return 'Écrire facture';
      case 'it': return 'Scrivi fattura';
      case 'en': return 'Write invoice';
      default: return 'Write invoice';
    }
  }
  public get wrongValue(): string {
    return Labels.wrongValue(this.settings.language);
  }
  public static wrongValue(language: string): string {
    switch (language) {
      case 'de': return 'Falscher Wert';
      case 'fr': return 'Valeur non valable';
      case 'it': return 'Valore non valido';
      case 'en': return 'Wrong value';
      default: return 'Wrong value';
    }
  }
}
