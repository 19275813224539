export default class NumberHelpers {
  public static getRange(count: number, from?: number): number[] {
    return Array.from(Array(count).keys()).map(k => k + (from ?? 0));
  }

  public static parseBigInt(value: string): bigint | null {
    try {
      return BigInt(value);
    } catch {
      return null;
    }
  }
}
