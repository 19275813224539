export default class WithKey<T> {
  public key: any;
  public item: T;

  constructor(key: any, item: T) {
    this.key = key;
    this.item = item;
  }

  get combined(): any {
    const obj: any = { ...this.item };
    obj.key = this.key;
    return obj;
  }
}
