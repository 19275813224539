export default class StoredQrchList {
  public static fromObject(item: any, fallbackLanguage: string): StoredQrchList {
    const languageList = item.languageList ?? Array(item.qrdataList.length).fill(fallbackLanguage);
    const metadataList = item.metadataList ?? Array(item.qrdataList.length).fill({});
    return new StoredQrchList(item.name, languageList, item.qrdataList, metadataList);
  }

  public name = '';
  public languageList: string[] = [];
  public qrdataList: string[] = [];
  public metadataList: any[] = [];

  constructor(name: string, languageList: string[], qrdataList: string[], metadataList: any[]) {
    this.name = name;
    this.languageList = languageList;
    this.qrdataList = qrdataList;
    this.metadataList = metadataList;
  }
}
