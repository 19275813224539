import revive_payload_client_4sVQNw7RlN from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_KAumv2pUni from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_KgADcZ0jPj from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_bs2bWDGRIE from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import vuetify_no_client_hints_kyYn5AnnAK from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "E:/development/QR-Rechnung/Vue/qr-rechnung.net/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_KAumv2pUni,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  vuetify_no_client_hints_kyYn5AnnAK,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]