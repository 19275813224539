export default class StoredSettings {
  public static fromObject(item: any): StoredSettings {
    return new StoredSettings(
      item.language, 
      item.qrchSortOrder, 
      item.qrchListSortOrder, 
      item.templateSortOrder,
      item.emailTemplateSortOrder ?? 'key+',
      item.paymentPartPosition ?? 'f',
      item.linkPosition ?? 'c',
      item.pdfCutLines ?? true,
      item.displayStructuredBookingInfo ?? false,
      item.switchAddressType ?? false,
      item.windowEnvelopeNorm ?? 'SN',
      item.generatePDFA ?? true,
      item.pdfAttachSwissQRBillText ?? true,
      item.pdfBundleSize ?? 100,
      item.pdfBleed ?? false,
      item.pdfCropMarks ?? false,
      item.autoReloadLastUsedItems ?? false,
      item.autoCopyScannedDataToClipboard ?? false,
      item.forceUtf8OnTableLoad ?? false,
      item.recordScannedVideo ?? false,
      item.lastUsedQrchKey ?? '',
      item.lastUsedQrchListKey ?? '',
      item.lastUsedTemplateKey ?? '',
      item.lastUsedEmailTemplateKey ?? '',
      item.multiPaymentPartsPosition ?? '1b');
  }

  public language = '';
  public qrchSortOrder = '';
  public qrchListSortOrder = '';
  public templateSortOrder = '';
  public emailTemplateSortOrder = '';
  public paymentPartPosition = '';
  public linkPosition = '';
  public pdfCutLines = true;
  public displayStructuredBookingInfo = false;
  public switchAddressType = false;
  public windowEnvelopeNorm = '';
  public generatePDFA = true;
  public pdfAttachSwissQRBillText = true;
  public pdfBundleSize = 100;
  public pdfBleed = false;
  public pdfCropMarks = false;
  public autoReloadLastUsedItems = false;
  public autoCopyScannedDataToClipboard = false;
  public forceUtf8OnTableLoad = false;
  public recordScannedVideo = false;
  public lastUsedQrchKey = '';
  public lastUsedQrchListKey = '';
  public lastUsedTemplateKey = '';
  public lastUsedEmailTemplateKey = '';
  public multiPaymentPartsPosition = '';

  constructor(language: string, 
              qrchSortOrder: string, 
              qrchListSortOrder: string, 
              templateSortOrder: string, 
              emailTemplateSortOrder: string, 
              paymentPartPosition: string,
              linkPosition: string,
              pdfCutLines: boolean,
              displayStructuredBookingInfo: boolean,
              switchAddressType: boolean,
              windowEnvelopeNorm: string,
              generatePDFA: boolean,
              pdfAttachSwissQRBillText: boolean,
              pdfBundleSize: number,
              pdfBleed: boolean,
              pdfCropMarks: boolean,
              autoReloadLastUsedItems: boolean,
              autoCopyScannedDataToClipboard: boolean,
              forceUtf8OnTableLoad: boolean,
              recordScannedVideo: boolean,
              lastUsedQrchKey: string,
              lastUsedQrchListKey: string,
              lastUsedTemplateKey: string,
              lastUsedEmailTemplateKey: string,
              multiPaymentPartsPosition: string) {
    this.language = language;
    this.qrchSortOrder = qrchSortOrder;
    this.qrchListSortOrder = qrchListSortOrder;
    this.templateSortOrder = templateSortOrder;
    this.emailTemplateSortOrder = emailTemplateSortOrder;
    this.paymentPartPosition = paymentPartPosition;
    this.linkPosition = linkPosition;
    this.pdfCutLines = pdfCutLines;
    this.displayStructuredBookingInfo = displayStructuredBookingInfo;
    this.switchAddressType = switchAddressType;
    this.windowEnvelopeNorm = windowEnvelopeNorm;
    this.generatePDFA = generatePDFA;
    this.pdfAttachSwissQRBillText = pdfAttachSwissQRBillText;
    this.pdfBundleSize = pdfBundleSize;
    this.pdfBleed = pdfBleed;
    this.pdfCropMarks = pdfCropMarks;
    this.autoReloadLastUsedItems = autoReloadLastUsedItems;
    this.autoCopyScannedDataToClipboard = autoCopyScannedDataToClipboard;
    this.forceUtf8OnTableLoad = forceUtf8OnTableLoad;
    this.recordScannedVideo = recordScannedVideo;
    this.lastUsedQrchKey = lastUsedQrchKey;
    this.lastUsedQrchListKey = lastUsedQrchListKey;
    this.lastUsedTemplateKey = lastUsedTemplateKey;
    this.lastUsedEmailTemplateKey = lastUsedEmailTemplateKey;
    this.multiPaymentPartsPosition = multiPaymentPartsPosition;
  }

  get toObject(): any {
    const item: any = {};
    item.language = this.language;
    item.qrchSortOrder = this.qrchSortOrder;
    item.qrchListSortOrder = this.qrchListSortOrder;
    item.templateSortOrder = this.templateSortOrder;
    item.emailTemplateSortOrder = this.emailTemplateSortOrder;
    item.paymentPartPosition = this.paymentPartPosition;
    item.linkPosition = this.linkPosition;
    item.pdfCutLines = this.pdfCutLines;
    item.displayStructuredBookingInfo = this.displayStructuredBookingInfo;
    item.switchAddressType = this.switchAddressType;
    item.windowEnvelopeNorm = this.windowEnvelopeNorm;
    item.generatePDFA = this.generatePDFA;
    item.pdfAttachSwissQRBillText = this.pdfAttachSwissQRBillText;
    item.pdfBundleSize = this.pdfBundleSize;
    item.pdfBleed = this.pdfBleed;
    item.pdfCropMarks = this.pdfCropMarks;
    item.autoReloadLastUsedItems = this.autoReloadLastUsedItems;
    item.autoCopyScannedDataToClipboard = this.autoCopyScannedDataToClipboard;
    item.forceUtf8OnTableLoad = this.forceUtf8OnTableLoad;
    item.recordScannedVideo = this.recordScannedVideo;
    item.lastUsedQrchKey = this.lastUsedQrchKey;
    item.lastUsedQrchListKey = this.lastUsedQrchListKey;
    item.lastUsedTemplateKey = this.lastUsedTemplateKey;
    item.lastUsedEmailTemplateKey = this.lastUsedEmailTemplateKey;
    item.multiPaymentPartsPosition = this.multiPaymentPartsPosition;
    return item;
  }
}
