import Sanitize from './Sanitize';

export default class AlternativeScheme {
  public value = '';

  constructor(value: string) {
    this.value = value;
  }

  public updateValue(value: string) {
    this.value = value;
  }

  public get getQRData() {
    return Sanitize.text(this.value, 100);
  }
}
