<template>
  <v-app id="app">
    <v-app-bar density="compact" elevation="1" id="nav" :color="appBarColor">
      <v-btn icon @click.stop="drawer = !drawer" :title="store.settings.labels.menuButtonTooltip"><v-icon>{{ mdiMenu }}</v-icon></v-btn>
      <v-toolbar-title class="text-h5 text-uppercase">{{ store.settings.domain }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="d-mintitle-inline">
        <v-btn variant="text" to="/" :title="store.settings.labels.home"><v-icon size="x-large">{{ iconHome }}</v-icon><span class="d-none d-xl-inline"> {{ store.settings.labels.home }}</span></v-btn>
        <v-btn variant="text" to="/create" :title="store.settings.labels.createPaymentPart"><v-icon size="x-large">{{ iconCreate }}</v-icon><span class="d-none d-xl-inline"> {{ store.settings.labels.create }}</span></v-btn>
        <v-btn variant="text" to="/insert" :title="store.settings.labels.insertIntoPDF"><v-icon size="x-large">{{ iconInsert }}</v-icon><span class="d-none d-xl-inline"> {{ store.settings.labels.insert }}</span></v-btn>
        <v-btn variant="text" to="/table" :title="store.settings.labels.generateFromTable"><v-icon size="x-large">{{ iconTable }}</v-icon><span class="d-none d-xl-inline"> {{ store.settings.labels.table }}</span></v-btn>
        <v-btn variant="text" to="/write" :title="store.settings.labels.writeInvoice"><v-icon size="x-large">{{ iconWrite }}</v-icon><span class="d-none d-xl-inline"> {{ store.settings.labels.write }}</span></v-btn>
        <v-btn variant="text" to="/email" :title="store.settings.labels.writeEmail"><v-icon size="x-large">{{ iconEmail }}</v-icon><span class="d-none d-xl-inline"> {{ store.settings.labels.email }}</span></v-btn>
        <v-btn variant="text" to="/saved" :title="store.settings.labels.savedData"><v-icon size="x-large" class="d-none d-xl-flex">{{ mdiDatabase }}</v-icon><v-badge v-model="showSavedItem" location="bottom end" class="savedbadge" floating><template #badge><small>{{savedItems}}</small></template><v-icon class="d-flex d-xl-none">{{ mdiDatabase }}</v-icon><span class="d-none d-xl-inline"> {{ store.settings.labels.saved }}</span></v-badge></v-btn>
        <v-btn variant="text" to="/scan" :title="store.settings.labels.scanSwissQRCode"><v-icon size="x-large">{{ iconScan }}</v-icon><span class="d-none d-xl-inline"> {{ store.settings.labels.scan }}</span></v-btn>
      </v-toolbar-items>
      <v-spacer class="d-none d-lg-inline"></v-spacer>
      <v-toolbar-items class="d-none d-lg-inline">
        <v-btn variant="text" to="/help" :title="store.settings.labels.help"><v-icon>{{ iconHelp }}</v-icon><span class="d-none d-xl-inline"> {{ store.settings.labels.help }}</span></v-btn>
        <v-menu location="left bottom" >
          <template #activator="{ props }">
            <v-btn variant="text" v-bind="props" :title="store.settings.labels.languageLabel">{{ language }}</v-btn>
          </template>
          <v-list>
            <v-list-item v-for="newlanguage of languages" :key="newlanguage" @click="() => language = newlanguage">
              <v-list-item-title class="text-uppercase">{{ newlanguage }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn icon @click.stop="dark = !dark" :title="darkModeTitle"><v-icon v-if="dark">{{ mdiWhiteBalanceSunny }}</v-icon><v-icon v-if="!dark">{{ mdiWeatherNight }}</v-icon></v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" width="320">
      <v-list>
        <v-list-item to="/" :prepend-icon="iconHome" :title="store.settings.labels.home"></v-list-item>
        <v-list-item to="/create" :prepend-icon="iconCreate" :title="store.settings.labels.createPaymentPart">
          <template v-slot:append>
            <v-icon v-show="showCreateCheck" color="grey">{{ mdiCheckBold }}</v-icon>
          </template>          
        </v-list-item>
        <v-list-item to="/insert" :prepend-icon="iconInsert" :title="store.settings.labels.insertIntoPDF">
          <template v-slot:append>
            <v-icon v-show="showInsertCheck" color="grey">{{ mdiCheckBold }}</v-icon>
          </template>          
        </v-list-item>
        <v-list-item to="/table" :prepend-icon="iconTable" :title="store.settings.labels.generateFromTable">
          <template v-slot:append>
            <v-icon v-show="showTableCheck" color="grey">{{ mdiCheckBold }}</v-icon>
          </template>          
        </v-list-item>
        <v-list-item to="/write" :prepend-icon="iconWrite" :title="store.settings.labels.writeInvoice">
          <template v-slot:append>
            <v-icon v-show="showWriteCheck" color="grey">{{ mdiCheckBold }}</v-icon>
          </template>          
        </v-list-item>
        <v-list-item to="/email" :prepend-icon="iconEmail" :title="store.settings.labels.writeEmail">
          <template v-slot:append>
            <v-icon v-show="showEmailCheck" color="grey">{{ mdiCheckBold }}</v-icon>
          </template>          
        </v-list-item>
        <v-list-item to="/saved" :prepend-icon="mdiDatabase" :title="store.settings.labels.savedData">
          <template v-slot:append>
            <v-chip size="small" v-show="showSavedItem">{{savedItems}}</v-chip>
          </template>          
          <!--  :color="$route.path === '/saved' ? 'primary' : ''" -->
        </v-list-item>
        <v-list-item to="/scan" :prepend-icon="iconScan" :title="store.settings.labels.scanSwissQRCode"></v-list-item>
        <v-divider></v-divider>
        <v-list-item to="/help" :prepend-icon="iconHelp" :title="store.settings.labels.help"></v-list-item>
        <v-list-item to="/about" :prepend-icon="iconInfo" :title="store.settings.labels.info"></v-list-item>
        <v-list-item to="/feedback" :prepend-icon="iconFeedback" :title="store.settings.labels.feedback"></v-list-item>
        <v-list-item to="/privacy" :prepend-icon="iconPrivacy" :title="store.settings.labels.privacy"></v-list-item>
        <v-list-item to="/news" :prepend-icon="iconNews" :title="store.settings.labels.news"></v-list-item>
        <v-list-item to="/settings" :prepend-icon="iconSettings" :title="store.settings.labels.settingsLabel"></v-list-item>
        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" :prepend-icon="mdiTranslate" :title="store.settings.labels.languageLabel"></v-list-item>
          </template>
          <v-list-item v-for="newlanguage of languages" :key="newlanguage" @click="() => language = newlanguage" :active="language === newlanguage">
            <v-list-item-title class="text-uppercase">{{ newlanguage }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item @click.stop="dark = !dark" :prepend-icon="iconDarkMode" :title="darkModeTitle"></v-list-item>
        <v-list-item v-if="showInstallButton" @click.stop="installApplication" :prepend-icon="mdiDownloadOutline" :title="store.settings.labels.install"></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <NuxtPage />
      </v-container>
    </v-main>
    <v-snackbar v-model="snackbarReloadToUpdate" color="deep-purple" timeout="-1" multi-line class="elevation-24">
      <v-icon start>{{ mdiRefresh }}</v-icon>
      {{ store.settings.labels.reloadToUpdate }}
      <template v-slot:actions>
        <v-btn
          variant="text"
          @click="reload"
        >
          {{ store.settings.labels.reload }}
        </v-btn>
        <v-btn
          variant="text"
          @click="openWhatIsNew"
        >
          {{ store.settings.labels.whatIsNew }}
        </v-btn>
        <v-btn
          variant="text"
          @click="snackbarReloadToUpdate = false"
        >
          {{ store.settings.labels.close }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts" setup>
import './src/registerServiceWorker';
import { ref, computed, onMounted, getCurrentInstance } from 'vue';
import { type LocationQuery, useRouter } from 'vue-router'
import { useTheme } from 'vuetify'
import { store } from '@/store';
import { context } from '@/context';
import {
  mdiCheckBold,
  mdiCog,
  mdiCogOutline,
  mdiDatabase,
  mdiDownloadOutline,
  mdiEmail,
  mdiEmailOutline,
  mdiFileDocument,
  mdiFileDocumentOutline,
  mdiFilePlus,
  mdiFilePlusOutline,
  mdiFileTableBoxMultiple,
  mdiFileTableBoxMultipleOutline,
  mdiHelpCircle,
  mdiHelpCircleOutline,
  mdiHome,
  mdiHomeOutline,
  mdiInformation,
  mdiInformationOutline,
  mdiLock,
  mdiLockOutline,
  mdiMenu,
  mdiPlusBox,
  mdiPlusBoxOutline,
  mdiRefresh,
  mdiRss,
  mdiRssBox,
  mdiStar,
  mdiStarOutline,
  mdiTranslate,
  mdiVideo,
  mdiVideoOutline,
  mdiWeatherNight,
  mdiWhiteBalanceSunny,
} from '@mdi/js';

  const drawer = ref<boolean | null>(null);

  const router = useRouter();
  const theme = useTheme();

  const snackbarReloadToUpdate = ref(false);

  const iconHome = computed(() => {
    const route = router.currentRoute.value.path;
    return route === '/' ? mdiHome : mdiHomeOutline;
  });
  const iconCreate = computed(() => {
    const route = router.currentRoute.value.path;
    return route === '/create' ? mdiPlusBox : mdiPlusBoxOutline;
  });
  const iconInsert = computed(() => {
    const route = router.currentRoute.value.path;
    return route === '/insert' ? mdiFilePlus : mdiFilePlusOutline;
  });
  const iconTable = computed(() => {
    const route = router.currentRoute.value.path;
    return route === '/table' ? mdiFileTableBoxMultiple : mdiFileTableBoxMultipleOutline;
  });
  const iconWrite = computed(() => {
    const route = router.currentRoute.value.path;
    return route === '/write' ? mdiFileDocument : mdiFileDocumentOutline;
  });
  const iconEmail = computed(() => {
    const route = router.currentRoute.value.path;
    return route === '/email' ? mdiEmail : mdiEmailOutline;
  });
  const iconScan = computed(() => {
    const route = router.currentRoute.value.path;
    return route === '/scan' ? mdiVideo : mdiVideoOutline;
  });
  const iconHelp = computed(() => {
    const route = router.currentRoute.value.path;
    return route === '/help' ? mdiHelpCircle : mdiHelpCircleOutline;
  });
  const iconInfo = computed(() => {
    const route = router.currentRoute.value.path;
    return route === '/about' ? mdiInformation : mdiInformationOutline;
  });
  const iconFeedback = computed(() => {
    const route = router.currentRoute.value.path;
    return route === '/feedback' ? mdiStar : mdiStarOutline;
  });
  const iconPrivacy = computed(() => {
    const route = router.currentRoute.value.path;
    return route === '/privacy' ? mdiLock : mdiLockOutline;
  });
  const iconNews = computed(() => {
    const route = router.currentRoute.value.path;
    return route === '/news' ? mdiRssBox : mdiRss;
  });
  const iconSettings = computed(() => {
    const route = router.currentRoute.value.path;
    return route === '/settings' ? mdiCog : mdiCogOutline;
  });
  const iconDarkMode = computed(() => {
    return dark.value ? mdiWhiteBalanceSunny : mdiWeatherNight;
  });

  const language = computed<string>({
  get() {
    return store.settings.language;
  },
  set(value: string) {
    store.settings.updateLanguage(value);
    context.updateSettingsLanguage(value);
    store.qrch.updateLanguage(value);
    store.qrchModified = true;
  }
  });
  const languages = computed(() => {
    return store.settings.languages;
  });
  const dark = computed<boolean>({
  get() {
    return theme.global.current.value.dark;
  },
  set(value: boolean) {
    theme.global.name.value = value ? 'dark' : 'light';
  }
  });
  dark.value = typeof window !== "undefined" && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const darkModeTitle = computed(() => {
    return dark.value ? store.settings.labels.switchToBrightMode : store.settings.labels.switchToDarkMode;
  });
  const appBarColor = computed(() => {
    if (!store.settings.errorHandlingEnabled) { return dark.value ? '' : 'grey-lighten-4'; }
    return dark.value ? 'orange darken-4' : 'orange';
  });

  const showCreateCheck = computed(() => {
    return store.qrchKey !== undefined || store.qrchModified;
  });
  const showInsertCheck = computed(() => {
    return store.uploadedPdfDoc !== undefined && store.uploadedPdfModified;
  });
  const showTableCheck = computed(() => {
    return store.qrchListKey !== undefined || store.qrchListModified;
  });
  const showWriteCheck = computed(() => {
    return store.templateKey !== undefined || store.templateModified;
  });
  const showEmailCheck = computed(() => {
    return store.emailTemplateKey !== undefined || store.emailTemplateModified;
  });
  const savedItems = computed(() => {
    return store.storedQrch.length + store.storedQrchLists.length + store.storedTemplates.length + store.storedEmailTemplates.length;
  });
  const showSavedItem = computed(() => {
    return savedItems.value > 0;
  });

  const showInstallButton = computed(() => {
    return context.showInstallButton;
  });

  function installApplication() {
    context.installApplication();
  }

  // lifecycle hook
  onMounted(async () => {
    const app = getCurrentInstance();
    if (app) {
      context.errors.registerVueInstance(app.appContext.config);
    }

    // initialize on initial route navigation
    router.isReady().then(() => {
      const route = router.currentRoute.value.path;
      initFromHash(route, router.currentRoute.value.query);
      if (route.match(/^(#?)\/b,/)) {
        router.push('/create');
      } else if (route.match(/^(#?)\/view,/)) {
        router.push('/view');
      } else if (route.match(/^(#?)\/support\/(.+)/)) {
        router.push('/');
      }
    });

    // re-initialize on route changes
    router.beforeEach((to, from, next) => {
      initFromHash(to.path, to.query);
      if (to.path.match(/^(#?)\/b,/)) {
        next({path: '/create'});
      } else if (to.path.match(/^(#?)\/view,/)) {
        next({path: '/view'});
      } else if (to.path.match(/^(#?)\/support\/(.+)/)) {
        next({path: '/'});
      } else {
        next();
      }
    });

    if (typeof window === "undefined") { return; }
    // show reload snackbar if service worker was updated
    window.addEventListener('swupdated', () => {
      if (context.permalinkHash !== '') {
        const params = new URLSearchParams(context.permalinkQuery);
        //@ts-ignore
        const query = params.size > 0 ? '?' + params.toString() : '';
        window.location.replace(window.location.origin + '/#' + context.permalinkHash + query);
      } else {
        snackbarReloadToUpdate.value = true;
      }
    });
  });

  function initFromHash(hash: string, query: LocationQuery) {
    if (hash.match(/^(#?)\/b,/) || hash.match(/^(#?)\/view,/)) {
      context.permalinkHash = hash;
      context.permalinkQuery = query;
      context.initFromHash(hash);
      if ('op' in query) {
        const op = query['op'];
        if (typeof(op) === 'string') {
          context.permalinkOperation = op.toLowerCase();
        }
      }
      if ('filename' in query) {
        const filename = query['filename'];
        if (typeof(filename) === 'string') {
          context.permalinkFilename = filename;
        }
      }
      if ('cutlines' in query) {
        const cutlines = query['cutlines'];
        if (typeof(cutlines) === 'string') {
          if (cutlines.toLowerCase().startsWith('n')) {
            context.updateSettingsPdfCutLines(false);
            store.settings.updatePdfCutLines(false);
          }
          if (cutlines.toLowerCase().startsWith('y')) {
            context.updateSettingsPdfCutLines(true);
            store.settings.updatePdfCutLines(true);
          }
        }
      }
    } else {
      const m = hash.match(/^(?:#?)\/support\/(.+)/);
      if (m) {
        context.errors.enableErrorReports(m[1]);
      }
    }
  }

  function reload() {
    if (typeof window === "undefined") { return; }
    if (context.permalinkHash !== '') {
      window.location.replace(window.location.origin + '/#' + context.permalinkHash);
    } else {
      window.location.reload();
    }
  }

  function openWhatIsNew() {
    if (typeof window === "undefined") { return; }
    const url = window.location.origin + '/#/news'
    window.open(url, '_blank');
  }
</script>

<style lang="scss">
.v-app-bar .v-btn {
  font-weight: 700;
}
#nav .v-icon {
  margin-right: 3px;
}
.v-card__title .v-icon:first-child {
  margin-left: 10px;
}

.v-toolbar-title {
  flex-basis: auto !important;
}

.v-theme--dark .savedbadge > .v-badge__wrapper > .v-badge__badge {
  background-color: #555 !important;
  color: white !important;
}
.v-theme--light .savedbadge > .v-badge__wrapper > .v-badge__badge {
  background-color: lightgrey !important;
  color: black !important;
}

.d-mintitle-inline {
  display: none !important;
}

@media (min-width: 900px) {
  .d-mintitle-inline {
    display: inline !important;
  }
}

.html-markup li {
  margin-left: 20px;
}

kbd {
  border-radius: 3px;
  font-size: 85%;
  font-weight: 400;
  padding: .2em .4rem;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
}
.v-theme--light kbd {
  background: #333;
  color: #fff;
}
.v-theme--dark kbd {
  background: #ccc;
  color: #000;
}

.v-input--is-disabled:not(.v-input--is-readonly).keep-tooltip-enabled {
  pointer-events: inherit !important;
}

.v-btn--disabled.keep-tooltip-enabled {
  pointer-events: inherit !important;
}

.v-theme--dark .progress-label {
  color: white;
}

.v-theme--light .progress-label {
  color: black;
}

.v-progress-circular.no-transition svg circle.v-progress-circular__overlay {
  transition: none !important;
}

.v-progress-linear--reactive.no-transition {
  transition: none !important;
}

// Workaround for Chrome/Edge to make date picker icon better visible in dark mode
.v-theme--dark input[type="date"] {
  color-scheme: dark;
}
</style>
