import { QrCode, QrSegment, Ecc } from '@/lib/qrcodegen/qrcodegen';
import Qrch from '@/store/qrch/Qrch';
import QRCodeMatrix from '@/store/qrch/QRCodeMatrix';
import Sanitize from '@/store/qrch/Sanitize';
import Settings from '@/store//Settings';
import StringHelpers from '@/lib/StringHelpers';

export default class Qrepc {
  readonly settings!: Settings;

  public serviceTag = 'BCD';
  public version = '002';
  public coding = '1';
  public function = 'SCT';
  public bic = '';
  public creditorName = '';
  public iban = '';
  public hasAmount = false;
  public amount = '';
  public currency = 'EUR';
  public purpose = '';
  public reference = '';
  public unstructuredMessage = '';
  public displayMessage = '';
  public matrix?: QRCodeMatrix;

  public border = true;
  public borderText = 'Zahlen mit Code';
  public borderTexts = [
    'Zahlen mit Code',
    'www.scan2pay.info',
    '',
  ];

  public get ibanQR(): string {
    return this.iban.toUpperCase().replace(/[^a-zA-Z0-9]/g, '').substr(0, 34);
  }

  public get amountQR(): string {
    const value = Number(this.amount);
    return Number.isNaN(value) ? '0.00' : value.toFixed(2);
  }

  public get hasReference(): boolean {
    return this.reference !== '';
  }

  public get referenceQR(): string {
    return this.reference.toUpperCase().replace(/[^A-Z0-9]/g, '').substr(0, 35);
  }

  public get getQRFields(): string[] {
    const fields: string[] = [];
    fields.push(this.serviceTag);
    fields.push(this.version);
    fields.push(this.coding);
    fields.push(this.function);
    fields.push(this.bic);
    fields.push(Sanitize.text(this.creditorName, 70));
    fields.push(this.ibanQR);
    fields.push(this.hasAmount ? this.currency + Sanitize.text(this.amountQR, 12) : '');
    fields.push(this.purpose);
    fields.push(this.hasReference ? this.referenceQR : '');
    fields.push(this.hasReference ? '' : Sanitize.text(this.unstructuredMessage, 140));
    fields.push(this.displayMessage);
    return fields;
  }

  public get getQRData(): string {
    const fields = this.getQRFields;
    const indexLastNonEmpty = fields.map(field => field !== '').lastIndexOf(true);
    const nonEmptyFields = fields.slice(0, indexLastNonEmpty + 1);
    return nonEmptyFields.join('\r\n');
  }

  public get getQRCodeMatrix(): QRCodeMatrix {
    const text = this.getQRData;
    const matrix = this.matrix;
    if (matrix) {
      return matrix;
    } else {
      const array = StringHelpers.utf8StringToUint8Array(text);
      const bytes = Array.from(array);
      const eciUTF8 = 26;
      const eci = QrSegment.makeEci(eciUTF8);
      const seg = QrSegment.makeBytes(bytes);
      const segs = [eci, seg];
      const qr = QrCode.encodeSegments(segs, Ecc.MEDIUM);
      return QRCodeMatrix.createFromQRCode(qr);
    }
  }

  public get getQRPath(): string {
    const matrix = this.getQRCodeMatrix;
    const size = matrix.size;
    const rect = 'l1,0 0,1 -1,0 0,-1z ';
    let path = '';
    let c = 0;
    let r = 0;

    for (r = 0; r < size; r++) {
        for (c = 0; c < size; c++) {
            if (matrix.isDark(c, r)) {
                path += 'M' + c + ',' + r + rect;
            }
        }
    }
    return path;
  }

  public get getQRModulesCount(): number {
    const matrix = this.getQRCodeMatrix;
    return matrix.size;
  }

  public get getQRViewBox(): string {
    const matrix = this.getQRCodeMatrix;
    const size = matrix.size;
    return '0 0 ' + String(size) + ' ' + String(size);
  }

  constructor(settings: Settings) {
    this.settings = settings;
  }

  public clone(): Qrepc {
    const clone = new Qrepc(this.settings);
    clone.initFromQRData(this.getQRData);
    return clone;
  }

  public getSvgRects(x: number, y: number, w: number): number[][] {
    const matrix = this.getQRCodeMatrix;
    const size = matrix.size;
    const cellSize = w / size;
    const rects: number[][] = [];
    let c = 0;
    let mc = 0;
    let r = 0;
    let mr = 0;

    for (r = 0; r < size; r++) {
        mr = y + r * w / size;
        for (c = 0; c < size; c++) {
            if (matrix.isDark(c, r)) {
                mc = x + c * w / size;
                rects.push([mc, mr, cellSize, cellSize]);
            }
        }
    }
    return rects;
  }

  public updateServiceTag(serviceTag: string) {
    this.serviceTag = serviceTag;
  }
  public updateVersion(version: string) {
    this.version = version;
  }
  public updateCoding(coding: string) {
    this.coding = coding;
  }
  public updateFunction(funct: string) {
    this.function = funct;
  }
  public updateBic(bic: string) {
    this.bic = bic;
  }
  public updateCreditorName(creditorName: string) {
    this.creditorName = creditorName;
  }
  public updateIban(iban: string) {
    this.iban = iban;
  }
  public updateHasAmount(hasAmount: boolean) {
    this.hasAmount = hasAmount;
  }
  public updateAmount(amount: string) {
    this.amount = amount;
  }
  public updateCurrency(currency: string) {
    this.currency = currency;
  }
  public updatePurpose(purpose: string) {
    this.purpose = purpose;
  }
  public updateReference(reference: string) {
    this.reference = reference;
  }
  public updateUnstructuredMessage(unstructuredMessage: string) {
    this.unstructuredMessage = unstructuredMessage;
  }
  public updateDisplayMessage(displayMessage: string) {
    this.displayMessage = displayMessage;
  }

  public updateBorder(border: boolean) {
    this.border = border;
  }
  public updateBorderText(borderText: string) {
    this.borderText = borderText;
  }

  public static canInitFromQrch(qrch: Qrch): boolean {
      return qrch.currency === 'EUR' && !qrch.isQrIban;
  }
  
  public initFromQrch(qrch: Qrch)
  {
    this.creditorName = qrch.creditor.name;
    this.iban = qrch.ibanQR;
    this.hasAmount = qrch.hasAmount;
    this.amount = qrch.amountQR;
    this.currency = qrch.currency;
    this.reference = qrch.hasReference ? qrch.referenceQR : '';
    this.unstructuredMessage = qrch.hasReference ? '' : Sanitize.replaceNewlines(qrch.unstructuredMessageQR);
  }

  public initFromScannedQRData(data: string, array: Uint8ClampedArray) {
    this.initFromQRData(data);
    this.matrix = QRCodeMatrix.createFromArray(array);
  }
  public initFromQRData(data: string): Qrepc {
    const items = data.split(/(?:\r)?\n/g);  // split at CR + LF or LF

    this.clear();
    this.matrix = undefined;

    if (items.length < 1) { return this; }
    this.serviceTag = items.shift()!;
    if (items.length < 1) { return this; }
    this.version = items.shift()!;
    if (items.length < 1) { return this; }
    this.coding = items.shift()!;
    if (items.length < 1) { return this; }
    this.function = items.shift()!;
    if (items.length < 1) { return this; }
    this.bic = items.shift()!;
    if (items.length < 1) { return this; }
    this.creditorName = items.shift()!;
    if (items.length < 1) { return this; }
    this.iban = items.shift()!;
    if (items.length < 1) { return this; }
    const currencyAndAmount = items.shift()!;
    this.currency = currencyAndAmount.substring(0, 3)
    this.amount = currencyAndAmount.substring(3);
    this.hasAmount = this.amount !== '';
    if (items.length < 1) { return this; }
    this.purpose = items.shift()!;
    if (items.length < 1) { return this; }
    this.reference = items.shift()!;
    if (items.length < 1) { return this; }
    this.unstructuredMessage = items.shift()!;
    if (items.length < 1) { return this; }
    this.displayMessage = items.shift()!;
    return this;
  }

  public clear() {
    this.serviceTag = 'BCD';
    this.version = '002';
    this.coding = '1';
    this.function = 'SCT';
    this.bic = '';
    this.creditorName = '';
    this.iban = '';
    this.hasAmount = false;
    this.amount = '';
    this.currency = 'EUR';
    this.purpose = '';
    this.reference = '';
    this.unstructuredMessage = '';
    this.displayMessage = '';
    this.matrix = undefined;
  }
}
