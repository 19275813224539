import Address from './Address';
import AlternativeScheme from './AlternativeScheme';
import CheckDigits from '@/store/qrch/CheckDigits';
import Formatting from '@/store/qrch/Formatting';
import { QrCode, QrSegment, Ecc } from '@/lib/qrcodegen/qrcodegen';
import Labels from '@/store/Labels';
import MatrixCodeEvenOdd from '@/lib/MatrixCodeEvenOdd/MatrixCodeEvenOdd';
import QrBillInfo from '@/store/qrch/QrBillInfo';
import QrAdditionalInfo from '@/store/qrch/QrAdditionalInfo';
import QRCodeMatrix from '@/store/qrch/QRCodeMatrix';
import Sanitize from './Sanitize';
import Settings from '@/store//Settings';
import StringHelpers from '@/lib/StringHelpers';
import Swico from './Swico';
import SwicoVatRate from './SwicoVatRate';
import SwicoCondition from './SwicoCondition';

export default class Qrch {
  public settings: Settings;

  public language = 'de';
  public qrType = 'SPC';
  public version = '0200';
  public coding = '1';
  public iban = '';
  public creditor = new Address(true);
  public ultimateCreditor = new Address(false);
  public hasAmount = true;
  public amount = '';
  public currencies = [ 'CHF', 'EUR', 'CHW' ];
  public currency = 'CHF';
  public ultimateDebtor = new Address(true);
  public referenceType: 'QRR' | 'SCOR' | 'NON' = 'NON';
  public reference = '';
  public unstructuredMessage = '';
  public trailer = 'EPD';
  public hasStructuredBookingInformationSwico = false;
  public structuredBookingInformationSwico: Swico = new Swico();
  public structuredBookingInformationGeneric = '';
  public alternativeSchemes: AlternativeScheme[] = [];
  public matrix?: QRCodeMatrix;
  public metadata: any = {};

  public get ibanQR(): string {
    return this.iban.toUpperCase().replace(/[^a-zA-Z0-9]/g, '').substr(0, 21);
  }

  public get ibanDisplay(): string {
    const iban = Sanitize.demoIbanQR(this.ibanQR);
    const chunks: string[] = [];
    let i = 0;
    const l = iban.length;
    while (i < l) {
        chunks.push(iban.slice(i, i += 4));
    }
    return chunks.join(' ');
  }

  public get ibanValid(): number {
    // 0 = too short, 1 = correct, 2 = wrong/too long, 3 = is IBAN, needs QR-IBAN, 4 = is QR-IBAN, needs IBAN, 5 = CHW used with non-WIR IBAN, 6 = not allowed country code
    const iban = this.ibanQR;
    const regex = /^(ch|li|CH|LI)[0-9]{7}[0-9a-zA-Z]{12}$/;
    if (iban.length > 1 && !(['CH', 'LI'].includes(iban.substring(0, 2).toUpperCase()))) { return 6; }
    if (iban.length < 21) { return 0; }
    if (!regex.test(iban)) { return 2; }
    if (CheckDigits.ibanCheck(iban) !== 1) { return 2; }
    if (this.referenceType === 'QRR' && !this.isQrIban) { return 3; }
    if (this.referenceType !== 'QRR' && this.isQrIban) { return 4; }
    if (this.currency === 'CHW' && (iban.substr(4, 5) !== '08391') && (iban.substr(4, 5) !== '30124')) { return 5; }
    return 1;
  }

  public get isQrIban(): boolean {
    const iban = this.ibanQR;
    if (iban.length < 6) { return false; }
    const iid2 = iban.substr(4, 2);
    return iid2 === '30' || iid2 === '31';
  }

  public get amountQR(): string {
    const value = Number(this.amount);
    return Number.isNaN(value) ? '0.00' : value.toFixed(2);
  }

  public get amountDisplay(): string {
    return this.amountQR.replace(/\B(?=(?=\d*\.)(\d{3})+(?!\d))/g, ' ');
  }

  public get hasReference(): boolean {
    return this.referenceType !== 'NON';
  }

  public get referenceQR(): string {
    if (this.referenceType === 'SCOR') {
      return this.reference.toUpperCase().replace(/[^A-Z0-9]/g, '').substr(0, 25);
    } else if (this.referenceType === 'QRR') {
      return this.reference.toUpperCase().replace(/[^0-9]/g, '').substr(0, 27);
    } else {
      return '';
    }
  }

  public get referenceDisplay(): string {
    const reference = this.referenceQR.toUpperCase();
    const referenceType = this.referenceType;
    if (referenceType === 'SCOR') {
      return Formatting.formatIsoReferenceDisplay(reference);
    } else if (referenceType === 'QRR') {
      return Formatting.formatQrReferenceDisplay(reference);
    }
    return reference;
  }

  public get referenceValid(): number {
    // 0 = too short, 1 = correct, 2 = wrong/too long, 3 = UBS and BESR-ID missing
    const reference = this.referenceQR;
    if (this.referenceType === 'QRR') {
      if (reference.length < 27) { return 0; }
      if (reference.length > 27) { return 2; }
      const qrref = reference.substr(0, 26);
      const checkDigit = parseInt(reference.substr(26, 1), 10);
      if (CheckDigits.qrReferenceCheckDigit(qrref) !== checkDigit) { return 2; }
      return 1;
    } else if (this.referenceType === 'SCOR') {
      if (!reference.startsWith('RF')) { return 2; }
      if (CheckDigits.ibanCheck(reference) !== 1) { return 2; }
      return 1;
    } else {
      return 0;
    }
  }

  public get vatNumberValid(): number {
    // 0 = too short, 1 = correct, 2 = wrong/too long
    if (!this.hasStructuredBookingInformationSwico) { return 0; }
    const vatNumber = this.structuredBookingInformationSwico.vatNumber;
    const uid = vatNumber.replace(/(\D)/g, '');  // remove all non-digits
    if (uid.length < 9) { return 0; }
    if (uid.length > 9) { return 2; }
    const digits = uid.substr(0, 8);
    const checkDigit = parseInt(uid.substr(8, 1), 10);
    return CheckDigits.vatNumberCheckDigit(digits) === checkDigit ? 1 : 2;
  }

  public get isNotification(): boolean {
    return this.hasAmount && (this.amountQR === '0.00');
  }

  public get unstructuredMessageQR(): string {
    return this.isNotification ? Labels.unstructuredMessageNotification(this.language) : this.unstructuredMessage;
  }

  public get structuredLength(): number {
    const structured = this.hasStructuredBookingInformationSwico ?
      this.structuredBookingInformationSwico.getQRData :
      this.structuredBookingInformationGeneric;
    return structured.length;
  }

  public get hasTwint(): boolean {
    return this.alternativeSchemes.filter(sc => sc.value.toLowerCase().startsWith('twint/')).length > 0;
  }

  public get getQRFields(): string[] {
    let fields: string[] = [];
    fields.push(this.qrType);
    fields.push(this.version);
    fields.push(this.coding);
    fields.push(Sanitize.demoIbanQR(this.ibanQR));
    fields = fields.concat(this.creditor.getQRFields);
    fields = fields.concat(this.ultimateCreditor.getQRFields);
    fields.push(this.hasAmount ? Sanitize.text(this.amountQR, 12) : '');
    fields.push(this.currency);
    fields = fields.concat(this.ultimateDebtor.getQRFields);
    fields.push(this.referenceType);
    fields.push(this.hasReference ? this.referenceQR : '');
    fields.push(Sanitize.text(Sanitize.replaceNewlines(this.unstructuredMessageQR), 140 - this.structuredLength));
    fields.push(this.trailer);
    fields.push(this.hasStructuredBookingInformationSwico ?
      this.structuredBookingInformationSwico.getQRData :
      Sanitize.text(this.structuredBookingInformationGeneric, 140));
    fields = fields.concat(this.alternativeSchemes.map((alt) => alt.getQRData));
    // Remove empty fields at the end
    while (fields[fields.length - 1] === '') {
      fields.pop();
    }
    return fields;
  }

  public get getPermalinkFields(): string[] {
    let fields: string[] = [];
    fields.push(this.qrType);
    fields.push(this.version);
    fields.push(this.coding);
    fields.push(this.ibanQR);
    fields = fields.concat(this.creditor.getPermalinkFields);
    fields = fields.concat(this.ultimateCreditor.getPermalinkFields);
    fields.push(this.hasAmount ? Sanitize.text(this.amountQR, 12) : '');
    fields.push(this.currency);
    fields = fields.concat(this.ultimateDebtor.getPermalinkFields);
    fields.push(this.referenceType);
    fields.push(this.hasReference ? this.referenceQR : '');
    fields.push(Sanitize.textAndNewLines(this.unstructuredMessageQR, 140 - this.structuredLength));
    fields.push(this.trailer);
    fields.push(this.hasStructuredBookingInformationSwico ?
      this.structuredBookingInformationSwico.getQRData :
      Sanitize.text(this.structuredBookingInformationGeneric, 140));
    fields = fields.concat(this.alternativeSchemes.map((alt) => alt.getQRData));
    // Remove empty fields at the end
    while (fields[fields.length - 1] === '') {
      fields.pop();
    }
    return fields;
  }

  public get getQRData(): string {
    const fields = this.getQRFields;
    return fields.join('\r\n');
  }

  public get getStorageData(): string {
    const fields = this.getPermalinkFields.map(f => f.replace(/\r\n|\r|\n/g, '\f'));
    return fields.join('\r\n');
  }

  public get getQRCodeMatrix(): QRCodeMatrix {
    const text = this.getQRData;
    const matrix = this.matrix;
    if (matrix) {
      return matrix;
    } else {
      const array = StringHelpers.utf8StringToUint8Array(text);
      const bytes = Array.from(array);
      const eciUTF8 = 26;
      const eci = QrSegment.makeEci(eciUTF8);
      const seg = QrSegment.makeBytes(bytes);
      const segs = [eci, seg];
      const qr = QrCode.encodeSegments(segs, Ecc.MEDIUM);
      return QRCodeMatrix.createFromQRCode(qr);
    }
  }

  public get getQRPath(): string {
    const matrix = this.getQRCodeMatrix;
    const size = matrix.size;
    let path = '';
    let c = 0;
    let r = 0;
    let n = 0;
    let s = 0;
    let e = size;

    // Position patterns:
    path += `M6 0h1v7H0V0h6v1H1v5h5zM5 2H2v3h3z`;
    path += `M${size-1} 0h1v7h-7V0h6v1h-5v5h5zm-1 2h-3v3h3z`;
    path += `M6 ${size-7}h1v7H0v-7h6v1H1v5h5zm-1 2H2v3h3z`;

    for (r = 0; r < size; r++) {
      s = (r < 8 || r >= size - 8) ? 8 : 0;
      e = r < 8 ? size - 9 : size - 1;
      while(!matrix.isDark(s, r)) s++;
      while(!matrix.isDark(e, r)) e--;
      path += `M${s} ${r}`;
      n = 1;
      c = s + 1;

      while(matrix.isDark(c, r) && c <= e) { c++; n++; }
      path += `h${n}v1h-${n}z`;

      while (c <= e) {
        while(!matrix.isDark(c, r) && c <= e) { c++; n++; }
        path += `m${n} 0`;
        n = 0;
        while(matrix.isDark(c, r) && c <= e) { c++; n++; }
        path += `h${n}v1h-${n}z`;
      }
    }
    return path;
  }

  public get getQRPathEvenOdd(): string {
    const matrix = this.getQRCodeMatrix;
    return MatrixCodeEvenOdd.getEvenOddSvgPathFromBitMatrix(matrix.size, matrix.size, (x, y) => matrix.isDark(x, y));
  }

  public get getQRViewBox(): string {
    const matrix = this.getQRCodeMatrix;
    const size = matrix.size;
    return '0 0 ' + String(size) + ' ' + String(size);
  }

  constructor(settings: Settings) {
    this.settings = settings;
    this.language = settings.language;
    this.demo();
  }

  public clone(): Qrch {
    const clone = new Qrch(this.settings);
    clone.initLanguage(this.language);
    clone.initFromQRFields(this.getPermalinkFields);
    clone.metadata = JSON.parse(JSON.stringify(this.metadata));
    return clone;
  }

  public updateLanguage(language: string) {
    this.language = language;
  }
  public updateQrType(qrType: string) {
    this.qrType = qrType;
  }
  public updateVersion(version: string) {
    this.version = version;
  }
  public updateCoding(coding: string) {
    this.coding = coding;
  }
  public updateIban(iban: string) {
    this.iban = iban;
  }
  public updateHasAmount(hasAmount: boolean) {
    this.hasAmount = hasAmount;
  }
  public updateAmount(amount: string) {
    this.amount = amount;
  }
  public updateCurrency(currency: string) {
    this.currency = currency;
  }
  public updateReferenceType(referenceType: 'QRR' | 'SCOR' | 'NON') {
    this.referenceType = referenceType;
  }
  public updateReference(reference: string) {
    this.reference = reference;
  }
  public updateUnstructuredMessage(unstructuredMessage: string) {
    this.unstructuredMessage = unstructuredMessage;
  }
  public updateTrailer(trailer: string) {
    this.trailer = trailer;
  }
  public updateHasStructuredBookingInformationSwico(hasStructuredBookingInformationSwico: boolean) {
    this.hasStructuredBookingInformationSwico = hasStructuredBookingInformationSwico;
  }
  public updateStructuredBookingInformationGeneric(structuredBookingInformationGeneric: string) {
    this.structuredBookingInformationGeneric = structuredBookingInformationGeneric;
  }
  public addAlternativeScheme() {
    this.alternativeSchemes.push(new AlternativeScheme(''));
  }
  public deleteAlternativeScheme(index: number) {
    this.alternativeSchemes.splice(index, 1);
  }
  public updateMetadata(metadata: any) {
    this.metadata = metadata;
  }

  public static isQrchData(data: string) {
    const items = data.split(/(?:\r)?\n/g);  // split at CR + LF or LF
    const length = items.length;
    const qrType = items.shift()!;
    const version = items.shift()!;
    const coding = items.shift()!;
    return qrType === 'SPC' && version.substr(0, 2) === '02' && coding === '1' && length > 30;
  }

  public initFromScannedQRData(data: string, array: Uint8ClampedArray) {
    this.initFromQRData(data);
    this.matrix = QRCodeMatrix.createFromArray(array);
  }
  public initLanguage(language: string): Qrch {
    this.language = language;
    return this;
  }
  public initMetadata(metadata: any): Qrch {
    this.metadata = metadata;
    return this;
  }
  public initFromQRData(data: string): Qrch {
    const items = data.split(/(?:\r)?\n/g);  // split at CR + LF or LF
    return this.initFromQRFields(items);
  }
  public initFromStorageData(data: string): Qrch {
    let items = data.split(/(?:\r)?\n/g);  // split at CR + LF or LF
    items = items.map(i => i.replace('\f', '\n'));
    return this.initFromQRFields(items);
  }
  public initFromQRFields(items: string[]): Qrch {
    this.clear();
    this.matrix = undefined;

    if (items.length < 1) { return this; }
    this.qrType = items.shift()!;
    if (items.length < 1) { return this; }
    this.version = items.shift()!;
    if (items.length < 1) { return this; }
    this.coding = items.shift()!;
    if (items.length < 1) { return this; }
    this.iban = items.shift()!;
    if (items.length < 1) { return this; }
    this.creditor.initFromQRData(items, true);
    if (items.length < 1) { return this; }
    this.ultimateCreditor.initFromQRData(items, false);
    if (items.length < 1) { return this; }
    this.amount = items.shift()!;
    this.hasAmount = this.amount !== '';
    if (items.length < 1) { return this; }
    this.currency = items.shift()!;
    if (items.length < 1) { return this; }
    this.ultimateDebtor.initFromQRData(items, false);
    if (items.length < 1) { return this; }
    const referenceType = items.shift()!;
    if (referenceType === 'QRR' || referenceType === 'SCOR' || referenceType === 'NON') { 
      this.referenceType = referenceType;
    } else {
      this.referenceType = 'NON';
    }
    if (items.length < 1) { return this; }
    this.reference = items.shift()!;
    if (items.length < 1) { return this; }
    this.unstructuredMessage = items.shift()!;
    if (items.length < 1) { return this; }
    this.trailer = items.shift()!;
    if (items.length < 1) { return this; }
    const structured = items.shift()! || '';
    this.structuredBookingInformationGeneric = structured;
    if (Swico.canRead(structured)) {
      this.structuredBookingInformationSwico.initFromQRData(structured);
      this.hasStructuredBookingInformationSwico = true;
      this.structuredBookingInformationGeneric = '';
    } else {
      this.hasStructuredBookingInformationSwico = false;
    }
    this.alternativeSchemes = [];
    let alternativeSchemesCount = 0;
    while (items.length > 0 && alternativeSchemesCount < 2) {
      const schemeData = items.shift()!;
      this.alternativeSchemes.push(new AlternativeScheme(schemeData));
      alternativeSchemesCount += 1;
    }
    return this;
  }

  public getTableData(): any {
    const tableData: any = {};
    tableData.Lang = this.language;
    tableData.IBAN = this.ibanQR;
    this.creditor.setTableData(tableData, 'Cdtr_');
    tableData.Amt = this.hasAmount ? Sanitize.text(this.amountQR, 12) : '';
    tableData.Ccy = this.currency;
    this.ultimateDebtor.setTableData(tableData, 'UltmtDbtr_');
    tableData.RefTp = this.referenceType;
    tableData.Ref = this.hasReference ? this.referenceQR : '';
    tableData.Ustrd = Sanitize.textAndNewLines(this.unstructuredMessageQR, 140 - this.structuredLength);
    tableData.StrdBkgInf = this.hasStructuredBookingInformationSwico ?
      this.structuredBookingInformationSwico.getQRData :
      Sanitize.text(this.structuredBookingInformationGeneric, 140);
    tableData.AltPmt1 = this.alternativeSchemes.length > 0 ? this.alternativeSchemes[0].getQRData : '';
    tableData.AltPmt2 = this.alternativeSchemes.length > 1 ? this.alternativeSchemes[1].getQRData : '';
    if (Object.prototype.hasOwnProperty.call(this.metadata, 'additionalTableData')) {
      for (const key in this.metadata.additionalTableData) {
        if (Object.prototype.hasOwnProperty.call(this.metadata.additionalTableData, key)) {
          const value = this.metadata.additionalTableData[key];
          tableData[key] = value;
        }
      }
    }
    return tableData;
  }

  public static canInitFromTableData(tableData: any): boolean {
    return StringHelpers.getPropertyWithDefault(tableData, 'IBAN', '') !== '' &&
      Address.canInitFromTableData(tableData, 'Cdtr_');
  }

  private static knownTableHeaders: string[] = [
    'Lang',
    'IBAN',
    'Cdtr_AdrTp',
    'Cdtr_Name',
    'Cdtr_StrtNmOrAdrLine1',
    'Cdtr_BldgNbOrAdrLine2',
    'Cdtr_PstCd',
    'Cdtr_TwnNm',
    'Cdtr_Ctry',
    'Amt',
    'Ccy',
    'UltmtDbtr_AdrTp',
    'UltmtDbtr_Name',
    'UltmtDbtr_StrtNmOrAdrLine1',
    'UltmtDbtr_BldgNbOrAdrLine2',
    'UltmtDbtr_PstCd',
    'UltmtDbtr_TwnNm',
    'UltmtDbtr_Ctry',
    'RefTp',
    'Ref',
    'Ustrd',
    'StrdBkgInf',
    'AltPmt1',
    'AltPmt2',
  ];

  public initFromTableData(tableData: any): Qrch {
    this.clear();
    this.matrix = undefined;
    let language = StringHelpers.getPropertyWithDefault(tableData, 'Lang', this.settings.language).toLowerCase();
    if (language !== 'de' && language !== 'fr' && language !== 'it' && language !== 'en') { language = this.settings.language; }
    this.language = language;
    this.iban = StringHelpers.getPropertyWithDefault(tableData, 'IBAN', '');
    this.creditor.initFromTableData(tableData, 'Cdtr_', true);
    this.amount = StringHelpers.getPropertyWithDefault(tableData, 'Amt', '');
    if (this.amount !== '') {
      const sanitized = Sanitize.decimal(this.amount);
      const amt = Number(sanitized);
      this.amount = Number.isNaN(amt) ? '0.00' : amt.toFixed(2);
    }
    this.hasAmount = this.amount !== '';
    this.currency = StringHelpers.getPropertyWithDefault(tableData, 'Ccy', 'CHF');
    this.ultimateDebtor.initFromTableData(tableData, 'UltmtDbtr_', false);
    const referenceType = StringHelpers.getPropertyWithDefault(tableData, 'RefTp', 'NON');
    if (referenceType === 'QRR' || referenceType === 'SCOR' || referenceType === 'NON') { 
      this.referenceType = referenceType;
    } else {
      this.referenceType = 'NON';
    }
    let ref = StringHelpers.getPropertyWithDefault(tableData, 'Ref', '');
    if (referenceType === 'QRR') {
      ref = CheckDigits.qrReferenceAmendCheckDigit(ref);
    } else if (referenceType === 'SCOR') {
      ref = CheckDigits.ibanAmendCheckDigits('RF', ref);
    }
    this.reference = ref;
    this.unstructuredMessage = StringHelpers.getPropertyWithDefault(tableData, 'Ustrd', '');
    const structured = StringHelpers.getPropertyWithDefault(tableData, 'StrdBkgInf', '');
    this.structuredBookingInformationGeneric = structured;
    if (Swico.canRead(structured)) {
      this.structuredBookingInformationSwico.initFromQRData(structured);
      this.hasStructuredBookingInformationSwico = true;
      this.structuredBookingInformationGeneric = '';
    } else {
      this.hasStructuredBookingInformationSwico = false;
    }
    const alt1 = StringHelpers.getPropertyWithDefault(tableData, 'AltPmt1', '');
    if (alt1 !== '') this.alternativeSchemes.push(new AlternativeScheme(alt1));
    const alt2 = StringHelpers.getPropertyWithDefault(tableData, 'AltPmt2', '');
    if (alt2 !== '') this.alternativeSchemes.push(new AlternativeScheme(alt2));

    for (const key in tableData) {
      if (Object.prototype.hasOwnProperty.call(tableData, key)) {
        if (key === '' || key === '__EMPTY') { continue; }
        const value = tableData[key];
        if (!Qrch.knownTableHeaders.includes(key)) {
          if (!Object.prototype.hasOwnProperty.call(this.metadata, 'additionalTableData')) {
            this.metadata['additionalTableData'] = {};
          }
          this.metadata.additionalTableData[key] = value;
        }
      }
    }
    return this;
  }

  public clear() {
    this.qrType = 'SPC';
    this.version = '0200';
    this.coding = '1';
    this.iban = '';
    this.creditor.clear();
    this.ultimateCreditor.clear();
    this.amount = '';
    this.hasAmount = true;
    this.currency = 'CHF';
    this.ultimateDebtor.clear();
    this.referenceType = 'NON';
    this.reference = '';
    this.unstructuredMessage = '';
    this.trailer = 'EPD';
    this.hasStructuredBookingInformationSwico = false;
    this.structuredBookingInformationSwico.reset();
    this.structuredBookingInformationGeneric = '';
    this.alternativeSchemes = [];
    this.matrix = undefined;
  }

  public demo() {
    this.qrType = 'SPC';
    this.version = '0200';
    this.coding = '1';
    this.iban = 'CH5204835012345671000';
    this.creditor.clear();
    this.creditor.type = 'S';
    this.creditor.name = 'Max Muster & Söhne';
    this.creditor.streetNameOrAddressLine1 = 'Musterstrasse';
    this.creditor.buildingNumberOrAddressLine2 = '123';
    this.creditor.postalCode = '8000';
    this.creditor.town = 'Seldwyla';
    this.creditor.country = 'CH';
    this.ultimateCreditor.clear();
    this.amount = '1949.75';
    this.hasAmount = true;
    this.currency = 'CHF';
    this.ultimateDebtor.clear();
    this.ultimateDebtor.type = 'S';
    this.ultimateDebtor.name = 'Sarah Beispiel';
    this.ultimateDebtor.streetNameOrAddressLine1 = 'Musterstrasse';
    this.ultimateDebtor.buildingNumberOrAddressLine2 = '1';
    this.ultimateDebtor.postalCode = '8000';
    this.ultimateDebtor.town = 'Seldwyla';
    this.ultimateDebtor.country = 'CH';
    this.referenceType = 'NON';
    this.reference = '';
    this.unstructuredMessage = 'Auftrag vom ' + new Date().toLocaleDateString('de-CH', { 'day': '2-digit', 'month': '2-digit', 'year': 'numeric'});
    this.trailer = 'EPD';
    this.hasStructuredBookingInformationSwico = false;
    this.structuredBookingInformationSwico.reset();
    this.structuredBookingInformationSwico.hasInvoiceNumber = false;
    this.structuredBookingInformationSwico.hasInvoiceDate = true;
    this.structuredBookingInformationSwico.invoiceDate = new Date().toISOString().substr(0, 10);
    this.structuredBookingInformationSwico.hasCustomerReference = false;
    this.structuredBookingInformationSwico.hasVatNumber = true;
    this.structuredBookingInformationSwico.vatNumber = 'CHE-102.673.386';
    this.structuredBookingInformationSwico.hasVatStartDate = true;
    this.structuredBookingInformationSwico.vatStartDate = new Date().toISOString().substr(0, 10);
    this.structuredBookingInformationSwico.hasVatEndDate = false;
    this.structuredBookingInformationSwico.vatDetails.add(new SwicoVatRate('8.1'));
    this.structuredBookingInformationSwico.conditions.add(new SwicoCondition('0:30'));
    this.structuredBookingInformationGeneric = '';
    this.alternativeSchemes = [];
    this.matrix = undefined;
    this.metadata = {};
  }

  public static getDemoList(settings: Settings): Qrch[] {
    const qrchList = [
      new Qrch(settings).initLanguage('de').initMetadata({'additionalTableData': {'Anrede': 'Sehr geehrte Frau Beispiel', 'Nummer': '1', 'Email': 's.beispiel@example.com'}}).initFromQRData('SPC,0200,1,CH5800791123000889012,S,Verein%20%22Zum%20Beispiel%22,Musterstrasse,123,8000,Seldwyla,CH,,,,,,,,50.00,CHF,S,Sarah%20Beispiel,Musterstrasse,1,8000,Seldwyla,CH,NON,,Mitgliederbeitrag,EPD'.split(',').map(decodeURIComponent).join('\n')),
      new Qrch(settings).initLanguage('de').initMetadata({'additionalTableData': {'Anrede': 'Lieber Simon', 'Nummer': '1', 'Email': 'Simon Muster <simon.muster@example.org>'}}).initFromQRData('SPC,0200,1,CH5800791123000889012,S,Verein%20%22Zum%20Beispiel%22,Musterstrasse,123,8000,Seldwyla,CH,,,,,,,,50.00,CHF,S,Simon%20Muster,Musterstrasse,1,8000,Seldwyla,CH,NON,,Mitgliederbeitrag,EPD,,twint%2Flight%2F02%3A0123456789abcdef0123456789abcdef%230123456789abcdef0123456789abcdef01234567%23,rn%2Ftwint%2Fa~AbCdEfGhIjKlMnOpQrStUv~s~AbCdEfGhIjKlMnOpQrStUv%2Frn'.split(',').map(decodeURIComponent).join('\n')),
      new Qrch(settings).initLanguage('de').initMetadata({'additionalTableData': {'Anrede': 'Geschätzte Familie Exempel', 'Nummer': '2', 'Email': 'exempel@example.net; x.y@exempel.example.com'}}).initFromQRData('SPC,0200,1,CH5800791123000889012,S,Verein%20%22Zum%20Beispiel%22,Musterstrasse,123,8000,Seldwyla,CH,,,,,,,,100.00,CHF,S,Familie%20Exempel,Beispielstrasse,1,9490,Vaduz,LI,NON,,Mitgliederbeitrag,EPD'.split(',').map(decodeURIComponent).join('\n')),
      new Qrch(settings).initLanguage('de').initMetadata({'additionalTableData': {'Anrede': 'Sehr geehrte Damen und Herren', 'Nummer': '3', 'Email': 'info@modell-stiftung.example.org'}}).initFromQRData('SPC,0200,1,CH5800791123000889012,S,Verein%20%22Zum%20Beispiel%22,Musterstrasse,123,8000,Seldwyla,CH,,,,,,,,200.00,EUR,S,Modell%20Stiftung,Postfach,,78462,Konstanz,DE,SCOR,RF18539007547034,G%C3%B6nnerbeitrag,EPD,%2F%2FS1%2F11%2F220101%2F40%2F0%3A30'.split(',').map(decodeURIComponent).join('\n')),
      new Qrch(settings).initLanguage('fr').initMetadata({'additionalTableData': {'Anrede': 'Cher donateur', 'Nummer': '5', 'Email': 'allo@prototype.example'}}).initFromQRData('SPC,0200,1,CH4431999123000889012,S,Verein%20%22Zum%20Beispiel%22,Musterstrasse,123,8000,Seldwyla,CH,,,,,,,,123.45,CHF,S,Prototype%20SA,Place de l\'Exemple,2,25000,Besançon,FR,QRR,210000000003139471430009017,G%C3%B6nnerbeitrag,EPD'.split(',').map(decodeURIComponent).join('\n')),
      new Qrch(settings).initLanguage('it').initMetadata({'additionalTableData': {'Anrede': 'Caro donatore', 'Nummer': '8', 'Email': 'Modello Sagl <modello@example.com>'}}).initFromQRData('SPC,0200,1,CH5800791123000889012,S,Verein%20%22Zum%20Beispiel%22,Musterstrasse,123,8000,Seldwyla,CH,,,,,,,,,CHF,S,Modello%20Sagl,Via Esempio,3,20123,Milano,IT,NON,,Spende,EPD'.split(',').map(decodeURIComponent).join('\n')),
      new Qrch(settings).initLanguage('en').initMetadata({'additionalTableData': {'Anrede': 'Sehr geehrter Spender', 'Nummer': '13', 'Email': 'Schema GmbH <buchhaltung@schema.example.net>; Rechnungen <rechnungseingang@schema.example.net>'}}).initFromQRFields('SPC,0200,1,CH5800791123000889012,S,Verein%20%22Zum%20Beispiel%22,Musterstrasse,123,8000,Seldwyla,CH,,,,,,,,0.50,EUR,S,Schema%20GmbH%0AProjekt%20%22Zum%20Beispiel%22,Beispielstraße,5,6900,Bregenz,AT,NON,,Spende,EPD'.split(',').map(decodeURIComponent)),
      new Qrch(settings).initLanguage('de').initFromQRData('SPC,0200,1,CH5800791123000889012,S,Verein%20%22Zum%20Beispiel%22,Musterstrasse,123,8000,Seldwyla,CH,,,,,,,,,CHF,,,,,,,,NON,,Spende,EPD'.split(',').map(decodeURIComponent).join('\n')),
    ];
    return qrchList;
  }

  public getQrBillInfoItems(receipt: boolean, targetWidth: number, getWidth: (text: string) => number, binarySearch: boolean): QrBillInfo[] {
    const items: QrBillInfo[] = [];
    // account / payable to
    const creditorLines = StringHelpers.splitLinesToLinesOfWidth(this.creditor.getQrInfoLines(false, this.settings.language), targetWidth, getWidth, binarySearch);
    creditorLines.unshift(this.ibanDisplay);
    items.push(new QrBillInfo(Labels.payableToLabel(this.language), creditorLines));

    // reference
    if (this.referenceType !== 'NON') {
      items.push(new QrBillInfo(Labels.referenceLabel(this.language), [ this.referenceDisplay ]));
    }

    // additional information (not on receipt)
    if (!receipt) {
      const additionalInfoLines: string[] = [];
      if (this.unstructuredMessageQR !== '') {
        additionalInfoLines.push(...Sanitize.splitNewlinesOnce(Sanitize.textAndNewLines(this.unstructuredMessageQR, 140 - this.structuredLength)));
      }
      if (this.settings.displayStructuredBookingInfo) {
        if (this.hasStructuredBookingInformationSwico) {
          additionalInfoLines.push(this.structuredBookingInformationSwico.getQRData);
        } else if (this.structuredBookingInformationGeneric !== '') {
          additionalInfoLines.push(Sanitize.text(this.structuredBookingInformationGeneric, 140));
        }
      }
      if (additionalInfoLines.length > 0) {
        const lines = StringHelpers.splitLinesToLinesOfWidth(additionalInfoLines, targetWidth, getWidth, binarySearch);
        items.push(new QrBillInfo(Labels.additionalInfoLabel(this.language), lines));
      }
    }

    // payable by
    if (this.ultimateDebtor.enabled) {
      const debtorLines = StringHelpers.splitLinesToLinesOfWidth(this.ultimateDebtor.getQrInfoLines(false, this.settings.language), targetWidth, getWidth, binarySearch);
      items.push(new QrBillInfo(Labels.payableByLabel(this.language), debtorLines));
    }

    return items;
  }

  public getQrAdditionalInfoItems(targetWidth: number, getWidth: (text: string) => number): QrAdditionalInfo[] {
    const items: QrAdditionalInfo[] = [];
    // in favor of
    if (this.ultimateCreditor.enabled) {
      const header = Labels.inFavorOfLabel(this.language) + ': ';
      const ultimateCreditorLine = this.ultimateCreditor.getQrInfoLines(false, this.settings.language).join(', ');
      const trimmed = StringHelpers.trimTextToWidth(header + ultimateCreditorLine, targetWidth, getWidth);
      const trimmedValue = trimmed.substr(header.length);
      items.push(new QrAdditionalInfo(header, trimmedValue));
    }

    // alternative schemes
    let schemeIndex = 0;
    for (const scheme of this.alternativeSchemes) {
      schemeIndex++;
      const all = scheme.getQRData;
      const posSeparator = all.search(/[\W]/);
      const header = (posSeparator > -1) ? all.substring(0, posSeparator) : Labels.alternativeSchemeNameLabel(this.language) + `${schemeIndex}: `;
      const value = (posSeparator > -1) ? all.substring(posSeparator) : all;
      const trimmed = StringHelpers.trimTextToWidth(header + value, targetWidth, getWidth);
      const trimmedValue = trimmed.substr(header.length);
      items.push(new QrAdditionalInfo(header, trimmedValue));
    }

    return items;
  }

  public getPlainText(width: number, linebreak: string): string {
      const infos = this.getQrBillInfoItems(false, width, (t) => t.length, true);
      const adds = this.getQrAdditionalInfoItems(width, (t) => t.length);
      let text = '';
      text += Labels.currencyLabel(this.language) + ":" + linebreak;
      text += this.currency + linebreak + linebreak;
      if (this.hasAmount) {
        text += Labels.amountLabel(this.language) + ":" + linebreak;
        text += this.amountDisplay + linebreak + linebreak;
      }
      text += infos.map(i => i.header + ':' + linebreak + i.lines.join(linebreak) + linebreak + linebreak).join('');
      if (adds.length > 0) {
        text += Labels.alternativeSchemesLabel(this.language) + ':' + linebreak;
        text += adds.map(a => a.header + a.value).join(linebreak) + linebreak;
      }
      return text;
  }

  public getPermalink(): string {
    let h: string[] = [];
    h.push('b');    // permalink version
    h.push(this.language);
    h = h.concat(this.getPermalinkFields);
    return '#/' + h.map(encodeURIComponent).join(',');
  }

  public getFullPermalink(domain: string): string {
    return `https://${domain}/` + this.getPermalink();
  }

  public getViewlink(language: string): string {
    let h: string[] = [];
    h.push('view');
    h.push(language);
    h = h.concat(this.getQRFields);
    return '#/' + h.map(encodeURIComponent).join(',');
  }

  public getFullViewlink(domain: string, language: string): string {
    return `https://${domain}/` + this.getViewlink(language);
  }

  public getSuggestedName(defaultName: string): string {
    let suggestedName = defaultName;
    if (this.creditor.enabled) {
      suggestedName = this.creditor.name;
    }
    if (this.ultimateDebtor.enabled) {
      suggestedName = this.ultimateDebtor.name;
    }
    return suggestedName;
  }

  public getSuggestedFileName(defaultName: string, extension: string, tableColumn?: string): string {
    tableColumn = tableColumn ?? 'FileName';
    let suggestedName = defaultName;
    if (Object.prototype.hasOwnProperty.call(this.metadata, 'additionalTableData')) {
      if (Object.prototype.hasOwnProperty.call(this.metadata.additionalTableData, tableColumn)) {
        suggestedName = this.metadata.additionalTableData[tableColumn];
      }
    }
    if (!suggestedName.toLowerCase().endsWith(extension.toLowerCase())) {
      suggestedName += extension;
    }
    return suggestedName;
  }
}
