import { store, MyStore } from '@/store';
import Database from '@/lib/Database';
import Qrch from './store/qrch/Qrch';
import StoredPushSubscription from './lib/StoredPushSubscription';
import StoredQrch from './lib/StoredQrch';
import StoredQrchList from './lib/StoredQrchList';
import StoredTemplate from './lib/StoredTemplate';
import StoredEmailTemplate from './lib/StoredEmailTemplate';
import Template from './lib/Template';
import EmailTemplate from './lib/EmailTemplate';
import WithKey from './lib/WithKey';
import WithKeySelected from './lib/WithKeySelected';
import Validation from './store/Validation';
import FileHelpers from './lib/FileHelpers';
import NumberHelpers from './lib/NumberHelpers';
import StringHelpers from './lib/StringHelpers';
import StoredSettings from './lib/StoredSettings';
import ErrorHandler from './lib/ErrorHandler';

export class Context {
  public store: MyStore = store;
  public errors = new ErrorHandler(this.store.settings);
  public db: Database = new Database();

  public async addPushSubscription(subscription: any) {
    const name = this.store.settings.labels.mobileDevice + " " + (this.store.storedPushSubscriptions.length + 1);
    await this.addStoredPushSubscription(new StoredPushSubscription(name, subscription, {}));
  }
  public async removePushSubscription(endpoint: string) {
    await this.removeStoredPushSubscriptionByEndpoint(endpoint);
  }
  public validation: Validation = new Validation(this.store.settings);

  public permalinkHash = '';
  public permalinkQuery = {};
  public permalinkOperation = '';
  public permalinkFilename?: string = undefined;

  public storagePersistent = false;
  public storagePersistenceRequested = false;

  public showInstallButton = false;
  private deferredInstallPrompt?: Event = undefined;

  private templateLoadHandlers: Array<() => void> = [];
  private emailTemplateLoadHandlers: Array<() => void> = [];

  constructor() {
    this.rememberInstallPrompt();
    this.loadStoredObjectsAsync();
    this.getStoragePersistentAsync();
  }

  private rememberInstallPrompt() {
    if (typeof window === "undefined") { return; }
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredInstallPrompt = e;
      this.showInstallButton = true;
    });
  }

  public async installApplication() {
    this.showInstallButton = false
    if (this.deferredInstallPrompt) {
       // @ts-ignore
      this.deferredInstallPrompt.prompt();
       // @ts-ignore
      await this.deferredInstallPrompt.userChoice;
      this.deferredInstallPrompt = undefined;
    }
  }

  private async getStoragePersistentAsync() {
    if (typeof window === "undefined") { return; }
    if (navigator.storage && navigator.storage.persisted) {
      const persistent = await navigator.storage.persisted();
      this.storagePersistent = persistent;
    }
  }

  public async requestPersistentStorageAsync() {
    if (typeof window === "undefined") { return; }
    if (navigator.storage && navigator.storage.persist && !this.storagePersistent && !this.storagePersistenceRequested) {
      const persistent = await navigator.storage.persist();
      this.storagePersistent = persistent;
      this.storagePersistenceRequested = true;
    }
  }

  public getSvgIcon(icon: string) {
    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" ` +
      `role="img" aria-hidden="true" class="v-icon notranslate v-icon--svg"><path d="${icon}"></path></svg>`;
  }

  public initFromHash(hash: string) {
    if (hash.startsWith('#')) {
      hash = hash.slice(1);
    }
    hash = hash.slice(1);
    const h = hash.split(',').map(decodeURIComponent);
    if (h.length === 0) { return; }
    const version = h.shift();
    if (version === 'b' || version === 'view') {
        if (h.length < 1) { return; }
        const language = h.shift()!;
        this.store.qrch.initLanguage(language);
        if (h.length < 1) { return; }
        this.store.qrch.initFromQRFields(h);
    }
  }

  public async loadStoredObjectsAsync() {
    await this.db.openDatabaseAsync();
    this.store.storedSettings = await this.db.getSettingsWithDefaultAsync(new StoredSettings(this.store.settings.language, 'key+', 'key+', 'key+', 'key+', 'f', 'c', true, false, false, 'SN', true, true, 100, false, false, false, false, false, false, '', '', '', '', '1b'));
    this.copyStoredSettingsToSettings();
    this.store.qrch.initLanguage(this.store.settings.language);
    if (this.permalinkHash !== '') {
      // If initFromHash() was called before, copyStoredSettingsToSettings() might have overwritten the language
      // Call initFromHash() again to set language from permalink
      this.initFromHash(this.permalinkHash);
    }
    this.store.storedQrch = await this.db.getQRBillsAsync(this.store.settings.language);
    this.store.storedQrchLists = await this.db.getQRBillListsAsync(this.store.settings.language);
    this.store.storedTemplates = await this.db.getTemplatesAsync();
    this.store.storedEmailTemplates = await this.db.getEmailTemplatesAsync();
    this.store.storedPushSubscriptions = await this.db.getPushSubscriptionsAsync();

    if (this.store.storedSettings.autoReloadLastUsedItems) {
      if (this.permalinkHash === '') {  // Don't load last used qrch if a permalink was opened
        await this.loadQrch(this.store.storedSettings.lastUsedQrchKey);
      }
      await this.loadQrchList(this.store.storedSettings.lastUsedQrchListKey);
      await this.loadTemplate(this.store.storedSettings.lastUsedTemplateKey);
      await this.loadEmailTemplate(this.store.storedSettings.lastUsedEmailTemplateKey);
    }
  }

  public async addStoredQrch(qrch: StoredQrch): Promise<WithKeySelected<StoredQrch>> {
    await this.requestPersistentStorageAsync()
    const added = await this.db.addQRBillAsync(qrch);
    this.store.storedQrch.push(added);
    return added;
  }

  public async updateStoredQrch(qrch: WithKeySelected<StoredQrch>) {
    await this.requestPersistentStorageAsync()
    await this.db.updateQRBillAsync(qrch);
    const index = this.store.storedQrch.findIndex((item) => item.key === qrch.key);
    if (index === -1) { return; }
    this.store.storedQrch.splice(index, 1, qrch);
  }

  public async removeStoredQrch(key: string) {
    await this.db.removeQRBillAsync(key);
    const index = this.store.storedQrch.findIndex((item) => item.key === key);
    if (index === -1) { return; }
    this.store.storedQrch.splice(index, 1);
  }

  public async addStoredQrchList(qrchList: StoredQrchList): Promise<WithKeySelected<StoredQrchList>> {
    await this.requestPersistentStorageAsync()
    const added = await this.db.addQRBillListAsync(qrchList);
    this.store.storedQrchLists.push(added);
    return added;
  }

  public async updateStoredQrchList(qrchList: WithKeySelected<StoredQrchList>) {
    await this.requestPersistentStorageAsync()
    await this.db.updateQRBillListAsync(qrchList);
    const index = this.store.storedQrchLists.findIndex((item) => item.key === qrchList.key);
    if (index === -1) { return; }
    this.store.storedQrchLists.splice(index, 1, qrchList);
  }

  public async removeStoredQrchList(key: string) {
    await this.db.removeQRBillListAsync(key);
    const index = this.store.storedQrchLists.findIndex((item) => item.key === key);
    if (index === -1) { return; }
    this.store.storedQrchLists.splice(index, 1);
  }

  public async addStoredTemplate(template: StoredTemplate): Promise<WithKey<StoredTemplate>> {
    await this.requestPersistentStorageAsync()
    const added = await this.db.addTemplateAsync(template);
    this.store.storedTemplates.push(added);
    return added;
  }

  public async updateStoredTemplate(template: WithKey<StoredTemplate>) {
    await this.requestPersistentStorageAsync()
    await this.db.updateTemplateAsync(template);
    const index = this.store.storedTemplates.findIndex((item) => item.key === template.key);
    if (index === -1) { return; }
    this.store.storedTemplates.splice(index, 1, template);
  }

  public async removeStoredTemplate(key: string) {
    await this.db.removeTemplateAsync(key);
    const index = this.store.storedTemplates.findIndex((item) => item.key === key);
    if (index === -1) { return; }
    this.store.storedTemplates.splice(index, 1);
  }

  public async addStoredEmailTemplate(template: StoredEmailTemplate): Promise<WithKey<StoredEmailTemplate>> {
    await this.requestPersistentStorageAsync()
    const added = await this.db.addEmailTemplateAsync(template);
    this.store.storedEmailTemplates.push(added);
    return added;
  }

  public async updateStoredEmailTemplate(template: WithKey<StoredEmailTemplate>) {
    await this.requestPersistentStorageAsync()
    await this.db.updateEmailTemplateAsync(template);
    const index = this.store.storedEmailTemplates.findIndex((item) => item.key === template.key);
    if (index === -1) { return; }
    this.store.storedEmailTemplates.splice(index, 1, template);
  }

  public async removeStoredEmailTemplate(key: string) {
    await this.db.removeEmailTemplateAsync(key);
    const index = this.store.storedEmailTemplates.findIndex((item) => item.key === key);
    if (index === -1) { return; }
    this.store.storedEmailTemplates.splice(index, 1);
  }

  public async addStoredPushSubscription(pushSubscription: StoredPushSubscription): Promise<WithKeySelected<StoredPushSubscription>> {
    await this.requestPersistentStorageAsync()
    const added = await this.db.addPushSubscriptionAsync(pushSubscription);
    this.store.storedPushSubscriptions.push(added);
    return added;
  }

  public async updateStoredPushSubscription(pushSubscription: WithKeySelected<StoredPushSubscription>) {
    await this.requestPersistentStorageAsync()
    await this.db.updatePushSubscriptionAsync(pushSubscription);
    const index = this.store.storedPushSubscriptions.findIndex((item) => item.key === pushSubscription.key);
    if (index === -1) { return; }
    this.store.storedPushSubscriptions.splice(index, 1, pushSubscription);
  }

  public async removeStoredPushSubscription(key: string) {
    await this.db.removePushSubscriptionAsync(key);
    const index = this.store.storedPushSubscriptions.findIndex((item) => item.key === key);
    if (index === -1) { return; }
    this.store.storedPushSubscriptions.splice(index, 1);
  }

  public async removeStoredPushSubscriptionByEndpoint(endpoint: string) {
    const subscriptions = this.store.storedPushSubscriptions.filter(s => s.item.subscription.endpoint === endpoint);
    for (const subscription of subscriptions) {
      await this.removeStoredPushSubscription(subscription.key);
    }
  }

  public copyStoredSettingsToSettings() {
    this.store.settings.language = this.store.storedSettings.language;
    this.store.qrchSortOrder = this.store.storedSettings.qrchSortOrder;
    this.store.qrchListSortOrder = this.store.storedSettings.qrchListSortOrder;
    this.store.templateSortOrder = this.store.storedSettings.templateSortOrder;
    this.store.emailTemplateSortOrder = this.store.storedSettings.emailTemplateSortOrder;
    this.store.settings.paymentPartPosition = this.store.storedSettings.paymentPartPosition;
    this.store.settings.linkPosition = this.store.storedSettings.linkPosition;
    this.store.settings.pdfCutLines = this.store.storedSettings.pdfCutLines;
    this.store.settings.displayStructuredBookingInfo = this.store.storedSettings.displayStructuredBookingInfo;
    this.store.settings.switchAddressType = this.store.storedSettings.switchAddressType;
    this.store.settings.windowEnvelopeNorm = this.store.storedSettings.windowEnvelopeNorm;
    this.store.settings.generatePDFA = this.store.storedSettings.generatePDFA;
    this.store.settings.pdfAttachSwissQRBillText = this.store.storedSettings.pdfAttachSwissQRBillText;
    this.store.settings.pdfBundleSize = this.store.storedSettings.pdfBundleSize;
    this.store.settings.pdfBleed = this.store.storedSettings.pdfBleed;
    this.store.settings.pdfCropMarks = this.store.storedSettings.pdfCropMarks;
    this.store.settings.autoReloadLastUsedItems = this.store.storedSettings.autoReloadLastUsedItems;
    this.store.settings.autoCopyScannedDataToClipboard = this.store.storedSettings.autoCopyScannedDataToClipboard;
    this.store.settings.forceUtf8OnTableLoad = this.store.storedSettings.forceUtf8OnTableLoad;
    this.store.settings.recordScannedVideo = this.store.storedSettings.recordScannedVideo;
    this.store.settings.multiPaymentPartsPosition = this.store.storedSettings.multiPaymentPartsPosition;
  }

  public async updateStoredSettings(settings: StoredSettings) {
    if (this.db.enabled) {
      await this.db.updateSettingsAsync(settings);
    }
    this.store.storedSettings = settings;
  }

  public async updateSettingsLanguage(language: string) {
    this.store.storedSettings.language = language;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsQrchSortOrder(qrchSortOrder: string) {
    this.store.storedSettings.qrchSortOrder = qrchSortOrder;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsQrchListSortOrder(qrchLIstSortOrder: string) {
    this.store.storedSettings.qrchListSortOrder = qrchLIstSortOrder;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsTemplateSortOrder(templateSortOrder: string) {
    this.store.storedSettings.templateSortOrder = templateSortOrder;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsEmailTemplateSortOrder(emailTemplateSortOrder: string) {
    this.store.storedSettings.emailTemplateSortOrder = emailTemplateSortOrder;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsPaymentPartPosition(paymentPartPosition: string) {
    this.store.storedSettings.paymentPartPosition = paymentPartPosition;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsLinkPosition(linkPosition: string) {
    this.store.storedSettings.linkPosition = linkPosition;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsPdfCutLines(cutLines: boolean) {
    this.store.storedSettings.pdfCutLines = cutLines;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsDisplayStructuredBookingInfo(displayStructuredBookingInfo: boolean) {
    this.store.storedSettings.displayStructuredBookingInfo = displayStructuredBookingInfo;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsSwitchAddressType(switchAddressType: boolean) {
    this.store.storedSettings.switchAddressType = switchAddressType;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsWindowEnvelopeNorm(windowEnvelopeNorm: string) {
    this.store.storedSettings.windowEnvelopeNorm = windowEnvelopeNorm;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsGeneratePDFA(generatePDFA: boolean) {
    this.store.storedSettings.generatePDFA = generatePDFA;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsPdfAttachSwissQRBillText(pdfAttachSwissQRBillText: boolean) {
    this.store.storedSettings.pdfAttachSwissQRBillText = pdfAttachSwissQRBillText;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsPdfBundleSize(pdfBundleSize: number) {
    this.store.storedSettings.pdfBundleSize = pdfBundleSize;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsPdfBleed(pdfBleed: boolean) {
    this.store.storedSettings.pdfBleed = pdfBleed;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsPdfCropMarks(pdfCropMarks: boolean) {
    this.store.storedSettings.pdfCropMarks = pdfCropMarks;
    await this.updateStoredSettings(this.store.storedSettings);
  }
  
  public async updateSettingsAutoReloadLastUsedItems(autoReloadLastUsedItems: boolean) {
    this.store.storedSettings.autoReloadLastUsedItems = autoReloadLastUsedItems;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsAutoCopyScannedDataToClipboard(autoCopyScannedDataToClipboard: boolean) {
    this.store.storedSettings.autoCopyScannedDataToClipboard = autoCopyScannedDataToClipboard;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsForceUtf8OnTableLoad(forceUtf8OnTableLoad: boolean) {
    this.store.storedSettings.forceUtf8OnTableLoad = forceUtf8OnTableLoad;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsRecordScannedVideo(recordScannedVideo: boolean) {
    this.store.storedSettings.recordScannedVideo = recordScannedVideo;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async updateSettingsMultiPaymentPartsPosition(multiPaymentPartsPosition: string) {
    this.store.storedSettings.multiPaymentPartsPosition = multiPaymentPartsPosition;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async saveAsNewQrch(name: string) {
    const storedQrch = new StoredQrch(name, this.store.qrch.language, this.store.qrch.getStorageData, this.store.qrch.metadata);
    const withKey = await this.addStoredQrch(storedQrch);
    this.store.qrchKey = withKey.key;
    this.store.qrchName = name;
    this.store.qrchModified = false;
    this.store.storedSettings.lastUsedQrchKey = withKey.key;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async saveCurrentQrch() {
    const currentQrch = new WithKeySelected(this.store.qrchKey, new StoredQrch(this.store.qrchName!, this.store.qrch.language, this.store.qrch.getStorageData, this.store.qrch.metadata));
    await this.updateStoredQrch(currentQrch);
    this.store.qrchModified = false;
  }

  public async loadQrch(key: string) {
    for (const item of this.store.storedQrch) {
      if (item.key === key) {
        this.store.qrch.initFromStorageData(item.item.qrdata);
        this.store.qrch.initLanguage(item.item.language);
        this.store.qrch.initMetadata(item.item.metadata);
        this.store.qrchKey = key;
        this.store.qrchName = item.item.name;
        this.store.qrchModified = false;
        this.store.storedSettings.lastUsedQrchKey = key;
        await this.updateStoredSettings(this.store.storedSettings);
        return;
      }
    }
  }
  public unloadQrch() {
    this.store.qrch = new Qrch(this.store.settings);
    this.store.qrchKey = undefined;
    this.store.qrchName = undefined;
    this.store.qrchModified = false;
  }

  public async saveAsNewQrchList(name: string) {
    const storedQrchList = new StoredQrchList(name, this.store.qrchList.map(qrch => qrch.language), this.store.qrchList.map(qrch => qrch.getStorageData), this.store.qrchList.map(qrch => qrch.metadata));
    const withKey = await this.addStoredQrchList(storedQrchList);
    this.store.qrchListKey = withKey.key;
    this.store.qrchListName = name;
    this.store.qrchListModified = false;
    this.store.storedSettings.lastUsedQrchListKey = withKey.key;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async saveCurrentQrchList() {
    const currentQrchList = new WithKeySelected(this.store.qrchListKey, new StoredQrchList(this.store.qrchListName!, this.store.qrchList.map(qrch => qrch.language), this.store.qrchList.map(qrch => qrch.getStorageData), this.store.qrchList.map(qrch => qrch.metadata)));
    await this.updateStoredQrchList(currentQrchList);
    this.store.qrchListModified = false;
  }

  public async loadQrchList(key: string) {
    for (const item of this.store.storedQrchLists) {
      if (item.key === key) {
        this.store.qrchList = item.item.qrdataList.map((data, i) => new Qrch(this.store.settings).initFromStorageData(data).initLanguage(item.item.languageList[i]).initMetadata(item.item.metadataList[i]));
        this.store.qrchListKey = key;
        this.store.qrchListName = item.item.name;
        this.store.qrchListModified = false;
        this.store.qrchListSelectedIndices = NumberHelpers.getRange(this.store.qrchList.length);
        this.store.storedSettings.lastUsedQrchListKey = key;
        await this.updateStoredSettings(this.store.storedSettings);
        return;
      }
    }
  }
  public unloadQrchList() {
    this.store.qrchList = Qrch.getDemoList(this.store.settings);
    this.store.qrchListKey = undefined;
    this.store.qrchListName = undefined;
    this.store.qrchListModified = false;
    this.store.qrchListSelectedIndices = NumberHelpers.getRange(this.store.qrchList.length);
  }

  public insertIntoQrchList() {
    const newLength = this.store.qrchList.push(this.store.qrch.clone());
    this.store.qrchListSelectedIndices.push(newLength - 1);
    this.store.qrchListModified = true;
  }

  public async saveAsNewTemplate(name: string) {
    let backgroundPdfData;
    let backgroundPdfFilename;
    if (this.store.template.backgroundPdf) {
      backgroundPdfData = await FileHelpers.readAsArrayBufferAsync(this.store.template.backgroundPdf);
      backgroundPdfFilename = this.store.template.backgroundPdf.name;
    }
    const storedTemplate = new StoredTemplate(name,
                                              this.fixPlaceholder(this.store.template.heading),
                                              this.fixPlaceholder(this.store.template.sender),
                                              this.fixPlaceholder(this.store.template.address),
                                              this.fixPlaceholder(this.store.template.content),
                                              this.store.template.addressPosition,
                                              this.store.template.paymentPartPosition,
                                              backgroundPdfData,
                                              backgroundPdfFilename);
    const withKey = await this.addStoredTemplate(storedTemplate);
    this.store.templateKey = withKey.key;
    this.store.templateName = name;
    this.store.templateModified = false;
    this.store.storedSettings.lastUsedTemplateKey = withKey.key;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async saveCurrentTemplate() {
    let backgroundPdfData;
    let backgroundPdfFilename;
    if (this.store.template.backgroundPdf) {
      backgroundPdfData = await FileHelpers.readAsArrayBufferAsync(this.store.template.backgroundPdf);
      backgroundPdfFilename = this.store.template.backgroundPdf.name;
    }
    const currentTemplate = new WithKey(this.store.templateKey, new StoredTemplate(this.store.templateName!,
                                                                             this.fixPlaceholder(this.store.template.heading),
                                                                             this.fixPlaceholder(this.store.template.sender),
                                                                             this.fixPlaceholder(this.store.template.address),
                                                                             this.fixPlaceholder(this.store.template.content),
                                                                             this.store.template.addressPosition,
                                                                             this.store.template.paymentPartPosition,
                                                                             backgroundPdfData,
                                                                             backgroundPdfFilename));
    await this.updateStoredTemplate(currentTemplate);
    this.store.templateModified = false;
  }

  public async loadTemplate(key: string) {
    for (const item of this.store.storedTemplates) {
      if (item.key === key) {
        this.store.template.heading = item.item.heading;
        this.store.template.sender = item.item.sender;
        this.store.template.address = item.item.address;
        this.store.template.content = item.item.content;
        this.store.template.addressPosition = item.item.addressPosition;
        this.store.template.paymentPartPosition = item.item.paymentPartPosition;
        if (item.item.backgroundPdfData && item.item.backgroundPdfFilename) {
          this.store.template.backgroundPdf = FileHelpers.createFile([item.item.backgroundPdfData], item.item.backgroundPdfFilename, 'application/pdf');
        } else {
          this.store.template.backgroundPdf = undefined;
        }
        this.store.templateKey = key;
        this.store.templateName = item.item.name;
        this.store.templateModified = false;
        this.store.storedSettings.lastUsedTemplateKey = key;
        await this.updateStoredSettings(this.store.storedSettings);
        this.notifyTemplateLoadHandlers();
        return;
      }
    }
  }

  public unloadTemplate() {
    this.store.template = new Template();
    this.store.templateKey = undefined;
    this.store.templateName = undefined;
    this.store.templateModified = false;
    this.notifyTemplateLoadHandlers();
}

  public registerTemplateLoadHandler(handler: () => void) {
    this.templateLoadHandlers.push(handler);
  }

  public unregisterTemplateLoadHandler(handler: () => void) {
    this.templateLoadHandlers = this.templateLoadHandlers.filter((h) => h !== handler);
  }

  public notifyTemplateLoadHandlers() {
    for (const handler of this.templateLoadHandlers) {
      handler();
    }
  }

  public async saveAsNewEmailTemplate(name: string) {
    const attachments: object[] = [];
    for (const file of this.store.emailTemplate.attachments) {
      const buffer = await FileHelpers.readAsArrayBufferAsync(file);
      const b64 = StringHelpers.arrayBufferToBase64String(buffer);
      const attachment = {
        name: file.name,
        type: file.type,
        data: b64,
      };
      attachments.push(attachment);
    }
    const metadata = {...this.store.emailTemplate.metadata};
    metadata.from = this.fixPlaceholder(metadata.from);
    metadata.replyto = this.fixPlaceholder(metadata.replyto);
    const storedEmailTemplate = new StoredEmailTemplate(name,
                                              this.fixPlaceholder(this.store.emailTemplate.to),
                                              this.fixPlaceholder(this.store.emailTemplate.cc),
                                              this.fixPlaceholder(this.store.emailTemplate.bcc),
                                              this.fixPlaceholder(this.store.emailTemplate.subject),
                                              this.fixPlaceholder(this.store.emailTemplate.content),
                                              attachments,
                                              metadata);
    const withKey = await this.addStoredEmailTemplate(storedEmailTemplate);
    this.store.emailTemplateKey = withKey.key;
    this.store.emailTemplateName = name;
    this.store.emailTemplateModified = false;
    this.store.storedSettings.lastUsedEmailTemplateKey = withKey.key;
    await this.updateStoredSettings(this.store.storedSettings);
  }

  public async saveCurrentEmailTemplate() {
    const attachments: object[] = [];
    for (const file of this.store.emailTemplate.attachments) {
      const buffer = await FileHelpers.readAsArrayBufferAsync(file);
      const b64 = StringHelpers.arrayBufferToBase64String(buffer);
      const attachment = {
        name: file.name,
        type: file.type,
        data: b64,
      };
      attachments.push(attachment);
    }
    const metadata = {...this.store.emailTemplate.metadata};
    metadata.from = this.fixPlaceholder(metadata.from);
    metadata.replyto = this.fixPlaceholder(metadata.replyto);
    const currentEmailTemplate = new WithKey(this.store.emailTemplateKey, new StoredEmailTemplate(this.store.emailTemplateName!,
                                                                             this.fixPlaceholder(this.store.emailTemplate.to),
                                                                             this.fixPlaceholder(this.store.emailTemplate.cc),
                                                                             this.fixPlaceholder(this.store.emailTemplate.bcc),
                                                                             this.fixPlaceholder(this.store.emailTemplate.subject),
                                                                             this.fixPlaceholder(this.store.emailTemplate.content),
                                                                             attachments,
                                                                             metadata));
    await this.updateStoredEmailTemplate(currentEmailTemplate);
    this.store.emailTemplateModified = false;
  }

  public async loadEmailTemplate(key: string) {
    for (const item of this.store.storedEmailTemplates) {
      if (item.key === key) {
        this.store.emailTemplate.to = item.item.to;
        this.store.emailTemplate.cc = item.item.cc;
        this.store.emailTemplate.bcc = item.item.bcc;
        this.store.emailTemplate.subject = item.item.subject;
        this.store.emailTemplate.content = item.item.content;
        this.store.emailTemplate.attachments = item.item.attachments.map(a => FileHelpers.createFile([StringHelpers.base64StringToArrayBuffer(a.data)], a.name, a.type));
        this.store.emailTemplate.metadata = item.item.metadata;
        this.store.emailTemplateKey = key;
        this.store.emailTemplateName = item.item.name;
        this.store.emailTemplateModified = false;
        this.store.storedSettings.lastUsedEmailTemplateKey = key;
        await this.updateStoredSettings(this.store.storedSettings);
        this.notifyEmailTemplateLoadHandlers();
        return;
      }
    }
  }

  public unloadEmailTemplate() {
    this.store.emailTemplate = new EmailTemplate();
    this.store.emailTemplateKey = undefined;
    this.store.emailTemplateName = undefined;
    this.store.emailTemplateModified = false;
    this.notifyEmailTemplateLoadHandlers();
}

  public registerEmailTemplateLoadHandler(handler: () => void) {
    this.emailTemplateLoadHandlers.push(handler);
  }

  public unregisterEmailTemplateLoadHandler(handler: () => void) {
    this.emailTemplateLoadHandlers = this.emailTemplateLoadHandlers.filter((h) => h !== handler);
  }

  public notifyEmailTemplateLoadHandlers() {
    for (const handler of this.emailTemplateLoadHandlers) {
      handler();
    }
  }

  public async importData(data: string) {
    const json = JSON.parse(data, (key: string, value: any) => {
      if (key === 'backgroundPdfData' && value !== undefined) {
        return StringHelpers.base64StringToArrayBuffer(value);
      }
      return value;
    });
    if (Object.prototype.hasOwnProperty.call(json, 'settings')) {
      this.store.storedSettings = StoredSettings.fromObject(json.settings);
      await this.updateStoredSettings(this.store.storedSettings);
      this.copyStoredSettingsToSettings()
    }
    if (Object.prototype.hasOwnProperty.call(json, 'bills')) {
      for (const bill of json.bills) {
        await this.addStoredQrch(StoredQrch.fromObject(bill, this.store.settings.language));
      }
    }
    if (Object.prototype.hasOwnProperty.call(json, 'billLists')) {
      for (const billList of json.billLists) {
        await this.addStoredQrchList(StoredQrchList.fromObject(billList, this.store.settings.language));
      }
    }
    if (Object.prototype.hasOwnProperty.call(json, 'templates')) {
      for (const template of json.templates) {
        await this.addStoredTemplate(StoredTemplate.fromObject(template));
      }
    }
    if (Object.prototype.hasOwnProperty.call(json, 'emailTemplates')) {
      for (const emailTemplate of json.emailTemplates) {
        await this.addStoredEmailTemplate(StoredEmailTemplate.fromObject(emailTemplate));
      }
    }
    if (Object.prototype.hasOwnProperty.call(json, 'pushSubscriptions')) {
      for (const pushSubscription of json.pushSubscriptions) {
        await this.addStoredPushSubscription(StoredPushSubscription.fromObject(pushSubscription));
      }
    }
  }

  public clearData() {
    for (const qrch of this.store.storedQrch) {
      this.removeStoredQrch(qrch.key);
    }
    for (const qrchList of this.store.storedQrchLists) {
      this.removeStoredQrchList(qrchList.key);
    }
    for (const template of this.store.storedTemplates) {
      this.removeStoredTemplate(template.key);
    }
    for (const emailTemplate of this.store.storedEmailTemplates) {
      this.removeStoredEmailTemplate(emailTemplate.key);
    }
    for (const pushSubscription of this.store.storedPushSubscriptions) {
      this.removeStoredPushSubscription(pushSubscription.key);
    }
  }

  public getExportData(): string {
    const data: any = {};
    data.bills = this.store.storedQrch.map((withKey) => withKey.item);
    data.billLists = this.store.storedQrchLists.map((withKey) => withKey.item);
    data.templates = this.store.storedTemplates.map((withKey) => withKey.item);
    data.emailTemplates = this.store.storedEmailTemplates.map((withKey) => withKey.item);
    data.pushSubscriptions = this.store.storedPushSubscriptions.map((withKey) => withKey.item);
    data.settings = this.store.storedSettings.toObject;
    return this.getExportJson(data);
  }

  public getExportQrch(key: string): string {
    const data: any = {};
    data.bills = this.store.storedQrch.filter(item => item.key === key).map((withKey) => withKey.item);
    return this.getExportJson(data);
  }

  public getExportQrchList(key: string): string {
    const data: any = {};
    data.billLists = this.store.storedQrchLists.filter(item => item.key === key).map((withKey) => withKey.item);
    return this.getExportJson(data);
  }

  public getExportTemplate(key: string): string {
    const data: any = {};
    data.templates = this.store.storedTemplates.filter(item => item.key === key).map((withKey) => withKey.item);
    return this.getExportJson(data);
  }

  public getExportEmailTemplate(key: string): string {
    const data: any = {};
    data.emailTemplates = this.store.storedEmailTemplates.filter(item => item.key === key).map((withKey) => withKey.item);
    return this.getExportJson(data);
  }

  public getExportJson(data: any): string {
    return JSON.stringify(data, (key: string, value: any) => {
      if (key === 'backgroundPdfData' && value !== undefined) {
        return StringHelpers.arrayBufferToBase64String(value);
      }
      return value;
    }, 2);
  }

  private fixPlaceholder(ops: any): any {
    for (const op of ops) {
      if (typeof(op.insert) === 'object' &&  Object.prototype.hasOwnProperty.call(op.insert, 'placeholder')) {
        // The quill-placeholder-module inserts an object of type DOMStringMap
        // which is not supported by the structured clone algorithm used when storing objects with IndexedDB
        // See https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm
        // Replace the DOMStringMap with a simple object with properties id and label:
        const ph = op.insert.placeholder;
        const phObj: any = {};
        phObj.id = ph.id;
        phObj.label = ph.label;
        op.insert.placeholder = phObj;
      }
    }
    return ops;
  }
}

export const context = new Context();
