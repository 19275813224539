export default class Formatting {
  public static formatQrReferenceDisplay(ref: string): string {
    const reference = ref.toUpperCase();
    const chunks: string[] = [];
    let i = 0;
    const l = reference.length;
    chunks.push(reference.slice(i, i += 2));
    while (i < l) {
      chunks.push(reference.slice(i, i += 5));
    }
    return chunks.join(' ');
  }

  public static formatIsoReferenceDisplay(ref: string): string {
    const reference = ref.toUpperCase();
    const chunks: string[] = [];
    let i = 0;
    const l = reference.length;
    while (i < l) {
        chunks.push(reference.slice(i, i += 4));
    }
    return chunks.join(' ');
  }
}
