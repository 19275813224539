export default class StoredQrch {
  public static fromObject(item: any, fallbackLanguage: string): StoredQrch {
    return new StoredQrch(item.name, item.language ?? fallbackLanguage, item.qrdata, item.metadata ?? {});
  }

  public name = '';
  public language = '';
  public qrdata = '';
  public metadata = {};

  constructor(name: string, language: string, qrdata: string, metadata: any) {
    this.name = name;
    this.language = language;
    this.qrdata = qrdata;
    this.metadata = metadata;
  }
}
